import { useCallback, useContext, useEffect, useState } from "react";
import {
  CheckCircleIcon,
  XCircleIcon,
  ArrowDownTrayIcon,
} from "@heroicons/react/24/outline";
import {
  collectorAndCollectionPointForeignKeys,
  usersCollectionName,
} from "../../config";
import { classNames, parseDate } from "../../utils";
import { UserContext } from "../../contexts";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import NoContent from "../../components/TableNoContentPage/NoContent";
import { Timestamp } from "firebase-admin/firestore";
import Pagination from "../../components/Pagination/Pagination";
import TableRowEditMenu from "../../components/TableRowEditMenu/TableRowEditMenu";
import * as XLSX from "xlsx";

const tableHeader = [
  "N°",
  "Profil",
  "Nom/prénom",
  "Adresse",
  "Identifiant",
  "Téléphone",
  "Ville",
  "Active",
  "Date de création",
  "Actions",
];

const AffiliateCollectionPoints = () => {
  const navigate = useNavigate();

  const { user } = useContext(UserContext);
  const [collectionPoints, setCollectionPoints] = useState<any[]>([]);

  const [transDataLimitLength, setTransDataLimitLength] = useState<number>(100);

  const retrivePointOfCollects = useCallback(async () => {
    const userRef = db.collection(usersCollectionName);
    const membershipSnapshot = await db
      .collection(collectorAndCollectionPointForeignKeys)
      .where("ownerId", "==", user?.id)
      .limit(transDataLimitLength)
      .get();
    if (!membershipSnapshot.empty) {
      const data: any[] = [];
      for (const membership of membershipSnapshot.docs) {
        const res = await userRef.doc(membership?.data()?.memberId).get();
        res.exists && data.push(res.data());
      }
      setCollectionPoints(data);
    }
  }, [transDataLimitLength, user?.id]);

  const onDisplayTransactions = (collectionPoint: any) => {
    if (collectionPoint) {
      navigate(
        `/collectors/affiliate-collectionPoints/${collectionPoint?.id}/transactions`,
        {
          state: { entity: collectionPoint },
        }
      );
    }
  };
  const onDisplayCollectionPointDetails = (collectionPoint: any) => {
    if (collectionPoint) {
      navigate(
        `/collectors/affiliate-collectionPoints/${collectionPoint?.id}/details`,
        {
          state: { entity: collectionPoint },
        }
      );
    }
  };
  const exportToXLSData = useCallback(async () => {
    const worksheet = XLSX.utils.json_to_sheet(
      await Promise.all(
        (collectionPoints as [])?.map(async (user: any) => {
          return {
            "Date de création de compte": parseDate(
              user?.createdAt as Timestamp
            ),
            "Nom prénom": user.displayName ?? "-",
            Email: user.email ?? "-",
            Ville: user.city ?? "-",
            Téléphone: user.phoneNumber ?? "-",
            Identifiant: user.pointOfCollectIdentity ?? "-",
            adresse: user?.adresse ?? "-",
          };
        })
      )
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "export");

    XLSX.writeFile(
      workbook,
      `La-liste-de-${
        collectionPoints?.length
      }-points-de-vente-${new Date().toLocaleDateString()}.xlsx`,
      { compression: true }
    );
  }, [collectionPoints]);

  useEffect(() => {
    retrivePointOfCollects();
  }, [retrivePointOfCollects]);

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les points de collecte
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Gérez vos points de collecte depuis cette page. Vous pouvez
            sélectionner un point de collecte pour voir ses informations.
          </p>
        </div>
      </div>
      <div className="h-8" />
      <div className="flex justify-end w-full">
        <div className="mx-2">
          <label
            htmlFor="dateFin"
            className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
          >
            Exporter
          </label>
          <button
            type="button"
            className={classNames(
              collectionPoints.length > 0
                ? "text-green-700 border-green-300 hover:bg-green-50 focus:ring-green-500"
                : "text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-gray-500 cursor-not-allowed",
              "inline-flex items-center h-10 px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
            )}
            onClick={() => {
              collectionPoints.length > 0 && exportToXLSData();
            }}
            disabled={collectionPoints.length > 0 ? false : true}
          >
            <ArrowDownTrayIcon
              className={classNames(
                collectionPoints.length > 0
                  ? "text-green-500"
                  : "text-gray-500",
                "self-center flex-shrink-0 w-5 h-5 mr-1"
              )}
              aria-hidden="true"
            />
            <span className="sr-only">Exporter en XLS</span>
          </button>
        </div>
      </div>
      <div className="flex flex-col mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm">
              <table
                className="min-w-full border-separate"
                style={{ borderSpacing: 0 }}
              >
                <thead className="bg-gray-50">
                  <tr>
                    {tableHeader.map((th, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                      >
                        {th}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {collectionPoints && collectionPoints.length > 0 ? (
                    collectionPoints.map((user: any, userIdx: number) => (
                      <tr key={userIdx}>
                        <td
                          className={classNames(
                            userIdx !== collectionPoints.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {(userIdx as number) + 1}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== collectionPoints.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {
                            <img
                              className="w-10 h-10 p-1 border rounded-full"
                              src={
                                user.profilImageUrl
                                  ? user.profilImageUrl
                                  : `https://ui-avatars.com/api/?background=random&name=${
                                      user.profilImageUrl || "S"
                                    }`
                              }
                              alt=""
                            />
                          }
                        </td>
                        <td
                          className={classNames(
                            userIdx !== collectionPoints.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user?.displayName ?? "-"}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== collectionPoints.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user?.adresse ?? "-"}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== collectionPoints.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user?.pointOfCollectIdentity ?? "-"}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== collectionPoints.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user?.phoneNumber ?? "-"}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== collectionPoints.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user?.city ?? "-"}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== collectionPoints.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user?.active ? (
                            <CheckCircleIcon className="w-6 h-6 text-green-500" />
                          ) : (
                            <XCircleIcon className="w-6 h-6 text-red-500" />
                          )}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== collectionPoints.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {(user.createdAt as Timestamp)
                            .toDate()
                            .toLocaleString()}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== collectionPoints.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          <TableRowEditMenu
                            rowHistoriqueHandler={() =>
                              onDisplayTransactions(user)
                            }
                            rowDetailsHandler={() =>
                              onDisplayCollectionPointDetails(user)
                            }
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoContent />
                  )}
                </tbody>
              </table>
              <Pagination
                skip={collectionPoints.length}
                take={collectionPoints.length}
                total={collectionPoints.length}
                collectionName={usersCollectionName}
                onPageChange={(pageIndex: number) => pageIndex}
                onPageLimitChange={(pageLimit: number) =>
                  setTransDataLimitLength(pageLimit)
                }
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AffiliateCollectionPoints;
