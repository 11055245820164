import { updateOrDeleteauditLogCollectionName } from "../../../config";
import { db } from "../../../firebase";
import Moment from "react-moment";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../../components/FirestoreTable";
import { useEffect, useMemo, useState } from "react";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { getTimestamp } from "../../../utils";
import moment from "moment";

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "author",
    label: "Auteur",
  },
  {
    field: "action",
    label: "L'action",
  },
  {
    field: "stateBefore",
    label: "Etat avant",
  },
  {
    field: "stateAfter",
    label: "Etat après",
  },
  {
    field: "actionItems",
    label: "Description",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "author":
      return <span>{value}</span>;
    case "action":
      return <span>{value}</span>;
    case "actionItems":
      return <span>{value}</span>;
    default:
      return value as string;
  }
};

const AdminAuditLogs = () => {
  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(moment(Date.now()).startOf("hour").format("YYYY-MM-DD"));
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    moment(Date.now()).format("YYYY-MM-DD")
  );

  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithEndDateValue(value.target.value);
  };

  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);

  const firestoreTableQueriesWhere = useMemo(() => {
    let queries = [];

    if (startTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: ">=",
        value: startTime,
      });
    }

    if (endTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: "<=",
        value: endTime,
      });
    }

    return queries;
  }, [startTime, endTime]);

  const getFilteredByDate = async () => {
    if (seachWithStartDateValue) {
      setStartTime(getTimestamp(seachWithStartDateValue, true));
    }

    if (seachWithEndDateValue) {
      setEndTime(getTimestamp(seachWithEndDateValue, false));
    }
  };

  useEffect(() => {
    getFilteredByDate();
  }, []);

  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Les audit Logs
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Vous trouverez ci-dessous tous les audit logs des utilisateurs.
            </p>
          </div>
          <div className="h-8" />
          <div className="flex self-end justify-between max-md:flex-col">
            <div className="flex px-1 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
              <div className="mr-1">
                <label
                  htmlFor="dateDebut"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date début
                </label>
                <input
                  type="date"
                  id="dateDebut"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={seachWithStartDateValue}
                  onChange={handleChangeStartDateValue}
                />
              </div>
              <div className="mr-1">
                <label
                  htmlFor="dateFin"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date fin
                </label>
                <input
                  type="date"
                  id="dateFin"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={seachWithEndDateValue}
                  onChange={handleChangeEndDtaeValue}
                />
              </div>
              <div className="flex items-center justify-center mt-5 h-14">
                <div className="flex">
                  <button
                    type="button"
                    className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    onClick={() => {
                      getFilteredByDate();
                    }}
                  >
                    <FunnelIcon
                      className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                      aria-hidden="true"
                    />
                    Filtrer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FirestoreTable
          collectionRef={db.collection(updateOrDeleteauditLogCollectionName)}
          condensed
          collectionQueriesWhere={firestoreTableQueriesWhere}
          columns={columns}
          rowRender={renderRow}
          paginate={{
            pageIndex: 0,
            limit: 100,
          }}
        />
      </div>
    </>
  );
};

export default AdminAuditLogs;
