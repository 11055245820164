import { FunnelIcon } from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import PhoneInput from "react-phone-number-input";
import Pagination from "../../../components/Pagination/Pagination";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import {
  userCardRelationCollection,
  cardsCollectionName,
  usersCollectionName,
} from "../../../config";
import { db } from "../../../firebase";
import { getTimestamp, classNames, parseDate } from "../../../utils";
import toast from "react-hot-toast";
import TableRowEditMenu from "../../../components/TableRowEditMenu/TableRowEditMenu";

const tableHeader = [
  "N°",
  "ID",
  "Date d'affectation",
  "Nom de la carte",
  "Date d'expiration",
  "Action",
];

const CardsSellesLogs = () => {
  const [cards, setcards] = useState<any[]>([]);
  const collectionName = cardsCollectionName;

  const retrivePhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber && phoneNumber.length >= 12) {
      await db
        .collection(usersCollectionName)
        .where("phoneNumber", "==", phoneNumber)
        .get()
        .then((response) => {
          if (response.empty) toast.error("Veuillez entrer un numéro correct.");
          else {
            retriveCardDataByPhoneNumber(
              startTime,
              endTime,
              response.docs[0].id
            );
          }
        });
    }
  };

  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithEndDateValue(value.target.value);
  };

  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );

  const [startTime, setStartTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const [endTime, setEndTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
  );

  const getFilteredByDate = async () => {
    // const startTime = new Date(seachWithStartDateValue).getTime();
    const startTime = getTimestamp(seachWithStartDateValue, true);
    const endTime = getTimestamp(seachWithEndDateValue, false);
    setEndTime(endTime);
    setStartTime(startTime);
    retriveCards(startTime, endTime);
  };

  const handleChange = async (cardId: string) => {
    if (cardId.length >= 12) {
      const cardsAndUserRelationSnap = await db
        .collection(userCardRelationCollection)
        .orderBy("createdAt", "desc")
        .where("cardId", "==", cardId)
        .get();
      if (cardsAndUserRelationSnap.empty) {
        setcards([]);
      } else {
        const cardsData: any[] = [];
        for (const cardsAndUserRelationObj of cardsAndUserRelationSnap.docs) {
          const cards = await db
            .collection(cardsCollectionName)
            .doc(cardsAndUserRelationObj?.data()?.cardId)
            .get();
          cardsData.push(cards.data());
        }
        setcards(cardsData);
      }
    }
  };

  const retriveCardDataByPhoneNumber = async (
    startTime: any,
    endTime: any,
    userId: string
  ) => {
    const cardsAndUserRelationSnap = await db
      .collection(userCardRelationCollection)
      .orderBy("createdAt", "desc")
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .where("resellerId", "==", userId)
      .get();
    if (cardsAndUserRelationSnap.empty) {
      setcards([]);
    } else {
      const cardsData: any[] = [];
      for (const cardsAndUserRelationObj of cardsAndUserRelationSnap.docs) {
        const cards = await db
          .collection(cardsCollectionName)
          .doc(cardsAndUserRelationObj?.data()?.cardId)
          .get();
        cardsData.push(cards.data());
      }
      setcards(cardsData);
    }
  };

  const retriveCards = useCallback(
    async (startTime: any, endTime: any, pageLimit?: number) => {
      const cardsAndUserRelationSnap = await db
        .collection(userCardRelationCollection)
        .orderBy("createdAt", "desc")
        .where("createdAt", ">=", startTime)
        .where("createdAt", "<=", endTime)
        .limitToLast(pageLimit ?? 25)
        .get();
      if (cardsAndUserRelationSnap.empty) {
        setcards([]);
      } else {
        const cardsData: any[] = [];
        for (const cardsAndUserRelationObj of cardsAndUserRelationSnap.docs) {
          const cards = await db
            .collection(cardsCollectionName)
            .doc(cardsAndUserRelationObj?.data()?.cardId)
            .get();
          cardsData.push(cards.data());
        }
        setcards(cardsData);
      }
    },
    []
  );

  useEffect(() => {
    retriveCards(startTime, endTime, 25);
  }, [endTime, retriveCards, startTime]);

  const onRowDetails = (entity: any) => {
    // if (entity) {
    //   navigate(`/agents/${entity.id}/details`, {
    //     state: { entity: entity.data, id: entity.id },
    //   });
    // }
  };

  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Les cartes vendues
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Vous trouverez ci-dessous tous les cartes vendues par un
              partenaire.
            </p>
          </div>
          <div className="h-8" />
          <div className="flex justify-between my-auto max-md:flex-col">
            <div className="my-auto space-x-4">
              <input
                type="text"
                name="card"
                id="card"
                placeholder="3518012345678"
                autoComplete="off"
                // value={cardId}
                autoFocus={true}
                onChange={(e) => handleChange(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-auto p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
              />
            </div>
            <div className="my-auto mr-4">
              <PhoneInput
                international={false}
                placeholder="Numéro de téléphone"
                className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                defaultCountry="BF"
                onChange={retrivePhoneNumber}
              />
            </div>
            <div className="flex px-1 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
              <div className="mr-1">
                <label
                  htmlFor="dateDebut"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date début
                </label>
                <input
                  type="date"
                  id="dateDebut"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeStartDateValue}
                />
              </div>
              <div className="mr-1">
                <label
                  htmlFor="dateFin"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date fin
                </label>
                <input
                  type="date"
                  id="dateFin"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeEndDtaeValue}
                />
              </div>
              <div className="flex items-center justify-center mt-5 h-14">
                <div className="flex">
                  <button
                    type="button"
                    className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    onClick={() => {
                      getFilteredByDate();
                    }}
                  >
                    <FunnelIcon
                      className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                      aria-hidden="true"
                    />
                    Filtrer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-8">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm">
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      {tableHeader.map((th, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                        >
                          {th}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {cards && cards.length > 0 ? (
                      cards.map((card: any, cardIdx: number) => (
                        <tr key={cardIdx}>
                          <td
                            className={classNames(
                              cardIdx !== cards.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(cardIdx as number) + 1}
                          </td>
                          <td
                            className={classNames(
                              cardIdx !== cards.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {card.id ? card.id : "-"}
                          </td>
                          <td
                            className={classNames(
                              cardIdx !== cards.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {parseDate(card.createdAt as Timestamp)}
                          </td>
                          <td
                            className={classNames(
                              cardIdx !== cards.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {card.name ? card.name : "-"}
                          </td>
                          <td
                            className={classNames(
                              cardIdx !== cards.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {parseDate(card.expiryDate as Timestamp)}
                          </td>
                          <td
                            className={classNames(
                              cardIdx !== cards.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                            )}
                          >
                            <TableRowEditMenu
                              rowDetailsHandler={() => onRowDetails(card)}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoContent />
                    )}
                  </tbody>
                </table>
                {cards && cards.length > 0 && (
                  <Pagination
                    skip={cards.length}
                    take={cards.length}
                    total={cards.length}
                    collectionName={collectionName}
                    onPageChange={(pageIndex: number) => pageIndex}
                    onPageLimitChange={(pageLimit: number) =>
                      retriveCards(startTime, endTime, pageLimit)
                    }
                    isChangedPage={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CardsSellesLogs;
