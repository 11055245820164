import { ClipboardDocumentListIcon, KeyIcon, UserIcon } from "@heroicons/react/24/outline";
import { NavLink, Outlet } from "react-router-dom";
import { classNames } from "../../utils";

const subNavigation = [
  {
    name: "Profil",
    to: "profile",
    icon: UserIcon,
  },
  {
    name: "Sécurité",
    to: "security",
    icon: KeyIcon,
  },
  {
    name: "Audit Logs",
    to: "auditLogs",
    icon: ClipboardDocumentListIcon,
  },
];

export const Account = () => {
  return (
    <div className="bg-white relativ">
      <div className="divide-y lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
        <aside className="lg:col-span-2 xl:col-span-2">
          <nav className="">
            {subNavigation.map((item, itemIdx) => (
              <NavLink
                key={itemIdx}
                to={{
                  pathname: `${item.to}`,
                }}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "bg-slate-300 text-red-500"
                      : "text-black hover:bg-gray hover:text-red-200",
                    "group w-full p-3 flex text-md font-medium"
                  )
                }
              >
                {({ isActive }) => (
                  <>
                    <item.icon
                      className={classNames(
                        isActive
                          ? "text-red-500"
                          : "text-black group-hover:text-red-200",
                        "mr-3 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    <span className="">{item.name}</span>
                  </>
                )}
              </NavLink>
            ))}
          </nav>
        </aside>
        <div className="divide-y lg:col-span-9 xl:col-span-10">
          <Outlet />
        </div>
      </div>
    </div>
  );
};
