import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts";
import { amountFormater, classNames } from "../../utils";
import Modal from "../../components/Modal";
import { db, functions, storage } from "../../firebase";
import { AccountType, TransactionType, UserRole } from "../../interfaces";
import toast from "react-hot-toast";
import {
  accountsCollectionName,
  defaultAmount,
  feesConfigId,
  globalConfigCollectionsName,
  numberOfCompanyBulkPaiementFileData,
  usersCollectionName,
} from "../../config";
import CodePinComponent from "../../components/CodePinComponent";
import { PaymentFormType } from "../Companies/Paiement";
import { CsvModel } from "../../components/ExportCsv";
import { Form } from "../../components/FormComponents";
import Papa from "papaparse";
import { useConfirm } from "../../hooks/useConfirm";
import { format } from "date-fns";
import moment from "moment";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { read, utils, writeFileXLSX } from "xlsx";
import NoContent from "../../components/TableNoContentPage/NoContent";


const excelSMSFileHeaders = [
  { Nom: ""},
  { Email: "" },
  { Numéro: "" },
  { Montant: "" },
];

const tableHeader = [
  "Nom/prénom",
  "Email",
  "Numéro",
  "Montant à recharger",
];

const BulkPayment = () => {
  const { user, setUserAccounts, userAccounts } = useContext(UserContext);
  const [description, setDescription] = useState("");
  const location = useLocation();
  const contacts = location.state.contacts;
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [amount, setAmount] = useState<string>("0");
  const [accountType, setAccountType] = useState(AccountType.MAIN);
  const navigate = useNavigate();
  const [isLoad, setIsLoad] = useState(false);
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const [fees, setFees] = useState<string>();
  const [open, setOpen] = useState<boolean>(false)
  const [table, setTable] = useState<boolean>(false)
  const [contactFrom, setContactFom] = useState<PaymentFormType>(
    PaymentFormType.BYFORMPAYMENT
  );
  const currentPaymentFormType = location.state.paymentFormType;
  const [noSankMoneyContactFileData, setNoSankMoneyContactFileData] = useState<
    any[]
  >([]);
  const [XLSXTotalAmount, setXLSXTotalAmount] = useState(0);
  const [fileName, setFileName] = useState("");
  const { isConfirmModal } = useConfirm();
  const [items, setItems] = useState<any>()
  const [fileData, setFileData] = useState<any[]>([]);

  const userRef = db.collection(usersCollectionName);
  const handleOptionChange = (event: any) => {
    setContactFom(event.target.value);
  };
  const sankMoneyUsersBulkPaymentFn = functions.httpsCallable(
    "sankMoneyUsersBulkPaymentFn"
  );
  const accountsRef = db
    .collection(usersCollectionName)
    .doc(user?.id)
    .collection(accountsCollectionName);

  const sankMoneyUserTransactionsReportFn = functions.httpsCallable(
    "sankMoneyUserTransactionsReportFn"
  );

  const retriveFeesData = async () => {
    const globalConfigsRef = db.collection(globalConfigCollectionsName);
    const feeGlobalConfigsRef = globalConfigsRef.doc(feesConfigId);
    const feeGlobalConfigsSnapshot = await feeGlobalConfigsRef.get();
    if (feeGlobalConfigsSnapshot.exists) {
      const feeGlobalConfigs = feeGlobalConfigsSnapshot.data();
      const bulkPaymentFees = feeGlobalConfigs?.bulk_payment_fees;
      setFees(bulkPaymentFees ?? "0");
    }
  };

  const getPaiementInputs = (currentContacts: any) => {
    if (currentContacts) {
      const receiversTransInput = [];
      for (const contact of currentContacts) {
        receiversTransInput.push({
          phoneNumber: contact.phoneNumber,
          amount:
            contactFrom === PaymentFormType.BYFORMPAYMENT
              ? amount
              : contact.amount,
        });
      }
      return receiversTransInput;
    }
  };

  const downloadRepportFile = async (transactionResponses: any) => {
    const isConfirm = await isConfirmModal(
      "Télécharger le rapport",
      "Souhaitez vous télécharger le rapport de la transaction.",
      "Oui télécharger",
      true
    );

    if (isConfirm) {
      makeCSVResponse(transactionResponses, true);
    } else {
      makeCSVResponse(transactionResponses, false);
    }
  };

  const makeCSVResponse = (transactionResponses: any, isConfirm: boolean) => {
    const now = new Date();
    const formattedTime = format(now, "HHmmss");
    const fileName = `rapport-des-transactions-du-${moment(Date.now()).format(
      "YYYY-MM-DD"
    )}-${formattedTime}`;
    const csvData = transactionResponses.response.map(
      (val: {
        phoneNumber: any;
        amount: any;
        status: { statusCode: any; statusMessage: any };
      }) => {
        return {
          phoneNumber: val.phoneNumber,
          amount: val.amount,
          statusCode: val.status.statusCode,
          statusMessage: val.status.statusMessage,
        };
      }
    );
    // Convert the data to CSV format using PapaParse
    const csv = Papa.unparse(csvData);

    // Create a Blob containing the CSV data
    const csvBlob = new Blob([csv], { type: "text/csv" });

    // Create a URL for the Blob
    const csvUrl = URL.createObjectURL(csvBlob);

    // Create an invisible link element to trigger the download
    const link = document.createElement("a");
    link.href = csvUrl;
    link.download = `${fileName}.csv`;

    if (isConfirm) {
      // Trigger the download
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the URL to release resources
      URL.revokeObjectURL(csvUrl);
      document.body.removeChild(link);

      // Export csv file to storage
      exportCSVFileToStorage(csvBlob, fileName);
    } else {
      // Export csv file to storage
      exportCSVFileToStorage(csvBlob, fileName);
    }
  };
  const exportCSVFileToStorage = (csvBlob: any, fileName: string) => {
    // Upload CSV to Firebase storage
    const imageRef = ref(
      storage,
      `users/${user?.id}/rapport-des-transactions/${fileName}.csv`
    );
    uploadBytes(imageRef, csvBlob)
      .then(() => {
        // Get CSV download URL
        getDownloadURL(imageRef)
          .then(async (url) => {
            // Create report document
            await sankMoneyUserTransactionsReportFn({
              userId: user?.id,
              reportUrl: url,
              fileName,
            });
            // Set the URL wherever you need it
          })
          .catch((error) => {
            toast.error(
              `An error occurred while getting the file URL: ${error.message}`
            );
          });
      })
      .catch((error) => {
        toast.error(
          `An error occurred while uploading the CSV file: ${error.message}`
        );
      });
  };

  const handleSubmit = async (pinCode: any) => {
    const formTotalAmount =
      parseInt(amount ?? "0") *
        (contacts?.length ?? 0) *
        parseFloat(fees ?? "0") +
      parseInt(amount ?? "0") * (contacts?.length ?? 0);
    const cvsTotalAmount =
    XLSXTotalAmount +
      parseFloat((XLSXTotalAmount * parseFloat(fees ?? "0")).toFixed(3));

    if (contactFrom === PaymentFormType.BYFORMPAYMENT) {
      if (parseInt(amount) < parseInt(defaultAmount)) {
        setIsCodePinBtnDesabled(false);
        setShowPinCodeModal(false);
        return toast.error(
          `Le montant doit être suppérieur à ${defaultAmount} Fcfa`
        );
      }
      if (user && pinCode) {
        const paymentData = {
          senderId: user?.id,
          receiversPaiementInputs: getPaiementInputs(contacts),
          accountType: accountType,
          description: description ?? "",
        };
        if (
          (userAccounts?.find((doc) => doc.accountType === AccountType.MAIN)
            ?.balance as number) < formTotalAmount
        ) {
          setIsCodePinBtnDesabled(false);
          setShowPinCodeModal(false);
          return toast.error(
            `Votre solde est insuffisant pour effectuer cette transaction.`
          );
        }
        setIsLoad(true);
        setIsCodePinBtnDesabled(true);
        await sankMoneyUsersBulkPaymentFn({
          pinCode: pinCode,
          ...paymentData,
          withConnection: true,
        })
          .then(async (result) => {
            setShowPinCodeModal(false);
            setIsCodePinBtnDesabled(false);
            setIsLoad(false);
            navigate("/bulkPayment");
            const accounts = await accountsRef.get();
            setUserAccounts(accounts.docs.map((account) => account.data()));
            downloadRepportFile(result.data);
            return toast.success("La recharge a été effectuée avec succès");
          })
          .catch((err: { details: any; message: any }) => {
            setShowPinCodeModal(false);
            setIsCodePinBtnDesabled(false);
            setIsLoad(false);
            toast.error(err.message);
          });
      }
    } else {
      if (
        (userAccounts?.find((doc) => doc.accountType === AccountType.MAIN)
          ?.balance as number) < cvsTotalAmount
      ) {
        setIsCodePinBtnDesabled(false);
        setShowPinCodeModal(false);
        return toast.error(
          `Votre solde est insuffisant pour effectuer cette transaction.`
        );
      }
      if (fileData.length > 0) {
        const paymentData = {
          senderId: user?.id,
          receiversPaiementInputs: getPaiementInputs(fileData),
          accountType: accountType,
          description: description ?? "",
        };
        return toast.promise(
          sankMoneyUsersBulkPaymentFn({
            pinCode: pinCode,
            ...paymentData,
            withConnection: true,
          }).then((result) => {
            navigate("/bulkPayment");
            downloadRepportFile(result.data);
          }),
          {
            loading: `Création...`,
            success: "Le paiement a été effecter avec succès",
            error: (err) => (err.message ? err.message : "Echec de paiement."),
          }
        );
      }
    }
  };

  const handleDescriptionChange = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    setDescription(value);
  };



  const readExcel = async (event:any) => {
    setFileName(event.target.files[0]?.name)
    let noSankMoneyContact: any[] = [];
    let sankMoneyContact: any[] = [];
    
    const fileData = await event.target.files[0].arrayBuffer();
    const readFile = read(fileData);
    const xlsxData = readFile.Sheets[readFile.SheetNames[0]]
    const data = utils.sheet_to_json(xlsxData);
    setItems(data)
    if(data.length > 0){
      setTable(true)
    }

    if (data.length > numberOfCompanyBulkPaiementFileData) {
      return toast.error(
        `Vous ne pouvez pas transferer à plus de ${numberOfCompanyBulkPaiementFileData} contacts.`
      );
      }
    
      for (const el of data as any){
        if (!el.Montant) {
          return toast.error(
            `Veuilez mettre sous bonnes formes tout les elements du document : ${el.Numéro}`
          );
        }
        if (!el.Numéro) {
          return toast.error(
            `Veuilez mettre sous bonnes formes tout les elements du document : ${el.Numéro}`
          );
        }
        const phoneNumber = (el.Numéro.length > 0 && el.Numéro[0] === "+")
          ? el.Numéro
          : `+${el.Numéro}`;
        if (phoneNumber === user?.phoneNumber) {
          toast.error(
            `Vous ne pouvez pas vous rajouter dans la liste des contacts ${phoneNumber}`
          );
        }
        else {
          const userSnap = await userRef
            .orderBy("createdAt", "desc")
            .where("phoneNumber", "==", phoneNumber)
            .where("userRole", "==", UserRole.USER)
            .get();

            if (userSnap.empty) {
              const noSankObj = {
                number: (el.Numéro.length > 0 && el.Numéro[0] === "+")
                ? el.Numéro
                : `+${el.Numéro}`,
                name: el.Nom,
                email: el.Email,
                amount: parseInt(el.Montant),
                isSankAccount: false,
              };
              noSankMoneyContact.push(noSankObj);
            } else {
              const obj = {
                displayName: el.Nom,
                email: el.Email,
                phoneNumber: (el.Numéro.length > 0 && el.Numéro[0] === "+")
                ? el.Numéro
                : `+${el.Numéro}`,
                amount: parseInt(el.Montant),
                isSankAccount: true,
              };
              sankMoneyContact.push(obj);
            }
        }
    }
    setNoSankMoneyContactFileData(noSankMoneyContact);
    setFileData([
      ...sankMoneyContact,
      ...noSankMoneyContact.map((val) => {
        return {
          displayName: val.name,
          email: val.email,
          phoneNumber: val.number,
          amount: val.amount,
        };
      }),
    ]);
    setXLSXTotalAmount(
      [
        ...sankMoneyContact,
        ...noSankMoneyContact.map((val: any) => {
          return {
            displayName: val.name,
            email: val.email,
            phoneNumber: val.number,
            amount: parseInt(val.amount),
          };
        }),
      ].reduce(
        (accumulator, currentValue) =>
          accumulator + parseInt(currentValue.amount),
        0
      )
    );
  };

  const exportNoSankContactData = useCallback(() => {
    const ws = utils.json_to_sheet(noSankMoneyContactFileData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, `contacts-${new Date().toLocaleDateString()}.xlsx`);
  }, [noSankMoneyContactFileData]);

  const exportContactData = useCallback(() => {
    const ws = utils.json_to_sheet(excelSMSFileHeaders);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, `contacts-${new Date().toLocaleDateString()}.xlsx`);
  }, [excelSMSFileHeaders]);

  const handleChangeValue = (e: any) => {
    e.preventDefault();
    setAmount(e.target.value ?? "0");
  };

  useEffect(() => {
    user && retriveFeesData();
    currentPaymentFormType && setContactFom(currentPaymentFormType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex mx-auto mt-4 space-x-10 max-md:block max-md:space-x-0 max-md:space-y-10 max-md:mx-5">
      <div className="w-[660px]  max-md:w-full">
        <div className="flex mx-auto space-x-10 max-md:block max-md:space-x-0 max-md:mb-3 max-md:space-y-3 max-md:mx-5">
          <p className="max-w-lg mt-2 mb-6 font-semibold leading-normal text-gray-900 text-md dark:text-gray-300 ">
            Effectuer un paiement en masse
          </p>
          {table ? (
          <button type="button" onClick={(e)=>setOpen(!open)}
          className="inline-flex justify-center px-4 py-2 mb-4 text-sm font-medium text-sky-700 bg-white border border-sky-300 rounded-md shadow-sm dark:bg-sky-800 dark:border-sky-700 hover:bg-sky-50 hoverdark:bg-sky-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sky-500">Voir la liste des bénéficiaires</button>
          ) : <></>}
        </div>
        <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-slate-800 dark:border-slate-700 sm:flex ">
          <li className="w-full border-b border-gray-200 dark:border-slate-700 sm:border-b-0 sm:border-r">
            <div className="flex items-center pl-3">
              <input
                id="horizontal-list-radio-id"
                type="radio"
                checked={contactFrom === PaymentFormType.BYFORMPAYMENT}
                value={PaymentFormType.BYFORMPAYMENT}
                name="list-radio"
                disabled={
                  currentPaymentFormType &&
                  currentPaymentFormType === PaymentFormType.BYCSVPAYMENT
                    ? true
                    : false
                }
                onChange={handleOptionChange}
                className="w-4 h-4 bg-gray-100 border-gray-300 text-emerald-600 dark:text-emerald-600 focus:ring-emerald-500"
              />
              <label
                htmlFor="horizontal-list-radio-id"
                className="w-full py-3 ml-2 text-sm font-medium text-gray-900 cursor-pointer dark:text-gray-300"
              >
                Saisir manuellement
              </label>
            </div>
          </li>
          <li className="w-full border-b border-gray-200 dark:border-slate-700 sm:border-b-0 sm:border-r">
            <div className="flex items-center pl-3">
              <input
                id="horizontal-list-radio-license"
                type="radio"
                value={PaymentFormType.BYCSVPAYMENT}
                name="list-radio"
                disabled={
                  currentPaymentFormType &&
                  currentPaymentFormType === PaymentFormType.BYCSVPAYMENT
                    ? true
                    : false
                }
                onChange={handleOptionChange}
                className="w-4 h-4 bg-gray-100 border-gray-300 text-emerald-600 dark:text-emerald-600 focus:ring-emerald-500 "
              />
              <label
                htmlFor="horizontal-list-radio-license"
                className="w-full py-3 ml-2 text-sm font-medium text-gray-900 cursor-pointer dark:text-gray-300"
              >
                Télécharger un fichier XLSX
              </label>
            </div>
          </li>
        </ul>

        <div className="flex flex-col w-full">
          {contactFrom === PaymentFormType.BYFORMPAYMENT && (
            <div className="flex justify-center w-full">
              <div className="w-full max-w-md px-2 py-16 sm:px-0">
                <div className="flex items-center justify-center w-full font-manrope">
                  <div className="box-border w-full p-4 mx-auto bg-white border">
                    <div className="mt-6">
                      <div className="font-semibold">
                        Combien souhaitez-vous envoyer ?
                      </div>
                      <div>
                        <input
                          className="mt-1 w-full rounded-[4px] border border-[#A0ABBB] p-2"
                          type="text"
                          placeholder="Ex: 1000"
                          pattern="[0-9]*"
                          onChange={handleChangeValue}
                        />
                      </div>
                    </div>
                    <div className="mt-6">
                      <div className="flex items-center pl-4 border border-gray-200 rounded dark:border-gray-700">
                        <input
                          checked
                          id="bordered2"
                          type="radio"
                          name="bordered-radio"
                          onChange={() => {}}
                          onClick={() => setAccountType(AccountType.MAIN)}
                          className="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="bordered2"
                          onClick={() => setAccountType(AccountType.MAIN)}
                          className="w-full py-4 ml-2 text-sm font-medium text-gray-900 cursor-pointer dark:text-gray-300"
                        >
                          Compte principal
                        </label>
                      </div>
                      <div className="flex items-center pl-4 mt-2 border border-gray-200 rounded dark:border-gray-700">
                        <input
                          id="bordered1"
                          type="radio"
                          name="bordered-radio"
                          onClick={() => setAccountType(AccountType.FUEL)}
                          className="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                        />
                        <label
                          htmlFor="bordered1"
                          onClick={() => setAccountType(AccountType.FUEL)}
                          className="w-full py-4 ml-2 text-sm font-medium text-gray-900 cursor-pointer dark:text-gray-300"
                        >
                          Compte carburant
                        </label>
                      </div>
                    </div>
                    <div className="mt-6">
                      <div className="font-semibold">
                        Entrez une descriptions de la transaction
                      </div>
                      <div>
                        <textarea
                          id="message"
                          rows={4}
                          onChange={handleDescriptionChange}
                          maxLength={200}
                          className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                          placeholder="Écrivez votre description ici..."
                        ></textarea>
                      </div>
                    </div>
                    <div className="mt-6">
                      <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                        <img
                          className="w-10 h-10 rounded-full"
                          src={
                            user?.profilImageUrl
                              ? user?.profilImageUrl
                              : `https://ui-avatars.com/api/?background=random&name=${
                                  user?.displayName || "S"
                                }`
                          }
                          alt=""
                        />
                        <div>
                          <div className="font-semibold">{user?.displayName}</div>
                        </div>
                      </div>
                    </div>
                    <div className="mt-6">
                      {contacts && contacts.length > 0 && (
                        <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                          <div className="font-light">
                            {contacts.map((contact: any, index: number) => (
                              <div key={index}>
                                {contact.displayName}{" "}
                                <span className="text-xs">
                                  ({contact.phoneNumber})
                                </span>
                                ,
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    {amount && (
                      <div className="mt-6">
                        <div
                          className={classNames(
                            amount ? "bg-red-600  cursor-pointer" : "bg-gray-600",
                            "w-full rounded-[4px]  px-3 py-[6px] text-center font-semibold text-white"
                          )}
                          onClick={() => amount && setShowPinCodeModal(true)}
                        >
                          Payé :{" "}
                          {new Intl.NumberFormat().format(
                            parseInt(amount ?? "0") * (contacts?.length ?? 0)
                          )}{" "}
                          Fcfa
                        </div>
                        {amount && (
                          <div className="w-full text-sm rounded-[4px]  px-3 py-[6px] text-center text-black mt-2">
                            Montant total :{" "}
                            {new Intl.NumberFormat().format(
                              parseInt(amount ?? "0") * (contacts?.length ?? 0)
                            )}{" "}
                            Fcfa{" "}
                            <span className="text-xs">
                              (frais :{" "}
                              {parseInt(amount ?? "0") *
                                (contacts?.length ?? 0) *
                                parseFloat(fees ?? "0")}
                              )
                            </span>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {contactFrom === PaymentFormType.BYCSVPAYMENT && (
            <Form
              downloadCsvOrXlsxFileBtn={
                <button type="button" onClick={exportContactData}
                className="inline-flex items-center justify-center w-full px-4 py-2 text-sm font-semibold transition-all duration-200 bg-white border rounded-md shadow-sm gap-x-2 dark:bg-slate-800 border-emerald-700 hover:border-emerald-900 text-emerald-700 hover:text-emerald-900 dark:hover:text-emerald-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                >Télécharger le model XLSX</button>
              }
              otherBtn={
                noSankMoneyContactFileData &&
                noSankMoneyContactFileData.length > 0 ? (
                  <button type="button" onClick={exportNoSankContactData}
                  className="inline-flex items-center justify-center w-full px-4 py-2 ml-3 text-sm font-semibold text-blue-700 transition-all duration-200 bg-white border border-blue-700 rounded-md shadow-sm gap-x-2 dark:bg-slate-800 hover:border-blue-900 hover:text-blue-900 dark:hover:text-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                  >Ne sont pas des utilisateurs</button>
                ) : undefined
              }
              onSubmit={() => setShowPinCodeModal(true)}
              form={
                {
                  //
                }
              }
              submitButtonLabel={`Payé ${amountFormater(XLSXTotalAmount)} FCFA`}
            >
              {
                <div className="flex flex-col items-end">
                  <div className="flex items-center justify-center w-full mt-5">
                    <label
                      htmlFor="dropzone-file"
                      className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                    >
                      <div className="flex flex-col items-center justify-center pt-5 pb-6">
                        {fileName ? (
                          <div className="text-sm text-gray-500 dark:text-gray-400">
                            {fileName}
                            <div className="w-full text-sm rounded-[4px] py-[6px] text-center text-black mt-2">
                              Montant total : {amountFormater(XLSXTotalAmount)}{" "}
                              Fcfa{" "}
                              <span className="text-xs">
                                (frais :{" "}
                                {(
                                  XLSXTotalAmount * parseFloat(fees ?? "0")
                                ).toFixed(2)}
                                )
                              </span>
                            </div>
                          </div>
                        ) : (
                          <>
                            <svg
                              aria-hidden="true"
                              className="w-10 h-10 mb-3 text-gray-400"
                              fill="none"
                              stroke="currentColor"
                              viewBox="0 0 24 24"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                              ></path>
                            </svg>
                            <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                              <span className="font-semibold">
                                Click to upload
                              </span>{" "}
                              or drag and drop
                            </p>
                            <p className="text-xs text-gray-500 dark:text-gray-400">
                              Uploader un fichier XLSX (XLSX)
                            </p>
                          </>
                        )}
                      </div>
                      <input
                        id="dropzone-file"
                        type="file"
                        className="hidden"
                        onChange={readExcel}
                      />
                    </label>
                  </div>
                </div>
              }
            </Form>
          )}
        </div>
        <Modal
          isOpen={showPinCodeModal}
          maxSize="md"
          closeModal={() => setShowPinCodeModal(false)}
        >
          <div className="flex justify-center">
            <CodePinComponent
              onSubmit={handleSubmit}
              disabled={isCodePinBtnDesabled}
              isLoad={isLoad}
            />
          </div>
        </Modal>
      </div>
      <div className="w-[700px]  max-md:w-full">
      {open ? (
          <div className="flex flex-col items-stretch mt-4 justify-center w-full space-y-4 md:flex-row md:space-y-0 md:space-x-8">
          <table
            className="min-w-full border-separate"
            style={{ borderSpacing: 0 }}
          >
            <thead className="bg-gray-50">
              <tr>
                {tableHeader.map((th, index) => (
                  <th
                    key={index}
                    scope="col"
                    className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                  >
                    {th}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="bg-white">
              {items && items.length > 0 ? (
                items.map((user: any, userIdx: number) => (
                  <tr key={userIdx}>
                    <td
                      className={classNames(
                        userIdx !== items.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {user.Nom ? user.Nom : "-"}
                    </td>
                    <td
                      className={classNames(
                        userIdx !== items.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {user.Email ? user.Email : "-"}
                    </td>
                    <td
                      className={classNames(
                        userIdx !== items.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {user.Numéro ? `+${user.Numéro}`: "-"}
                    </td>
                    <td
                      className={classNames(
                        userIdx !== items.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {amountFormater(user.Montant) ?? "-"}
                    </td>
                  </tr>
                ))
              ) : (
                <NoContent />
              )}
            </tbody>
          </table>
        </div>
        ) : <></>}
      </div>
    </div>
  );
};
export default BulkPayment;
