import { Timestamp } from "firebase-admin/firestore";
import { usersCollectionName } from "../../config";
import * as XLSX from "xlsx";
import { parseDate } from "../../utils";
import { UserRole } from "../../interfaces";

export const exportedElementsData = (documentPah: string, data: any) => {
  let worksheet;
  switch (documentPah) {
    case data.length > 0 &&
      data[0].userRole === UserRole.USER &&
      usersCollectionName:
      worksheet = XLSX.utils.json_to_sheet(
        (data as [])?.map((user: any) => {
          return {
            "Date de création": parseDate(user.createdAt as Timestamp),
            "Nom prénom": user.displayName ?? "-",
            "Contact Sank": user.phoneNumber ?? "-",
            Email: user.email ?? "-",
            Ville: user.city ?? "-",
            "Numéro CNI": user.cnibNumber ?? "-",
            Genre: user.gender ?? "-",
            Profession: user.profession ?? "-",
            Adresse: user.adresse ?? "-",
            "Compte certifié": user.isCertify ? "Oui" : "Non",
            "Compte active": user.active ? "Oui" : "Non",
          };
        })
      );

      break;
    default:
      worksheet = XLSX.utils.json_to_sheet(
        (data as []).map((val: any) => {
          if (val.id) {
            delete val.id;
          }
          if (val.createdAt) {
            val.createdAt = parseDate(val.createdAt as Timestamp);
          }
          if (val.date) {
            val.date = parseDate(val.date as Timestamp);
          }
          if (val.updatedAt) {
            delete val.updatedAt;
          }
          if (val.userAgencySubRole) {
            val.sousRole = val.userAgencySubRole;
          }
          if (val.attendantRole) {
            val.sousRole = val.attendantRole;
          }
          delete val.slug;
          delete val.attendantRole;
          delete val.initiator;
          delete val.userAgencySubRole;
          delete val.affiliateAgentId;
          delete val.profilImageUrl;
          delete val.agencyId;
          delete val.pinCode;
          delete val.agence;
          delete val.password;
          delete val.cnibImageUrl;
          delete val.dealerId;
          return val;
        })
      );
      break;
  }
  return worksheet;
};
