import React, { useEffect, useState } from "react";
import { db, functions } from "../../../firebase";
import { usersCollectionName } from "../../../config";
import {
  ChatBubbleLeftIcon,
  PhoneIcon,
  GlobeAltIcon,
  PlusIcon,
  XMarkIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { Link, useNavigate } from "react-router-dom";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import Modal from "../../../components/Modal";
import PhoneInput from "react-phone-number-input";
import toast from "react-hot-toast";
import { classNames } from "../../../utils";

const AddReviewer = () => {
  const [reviewers, setReviewers] = useState<any[]>([]);
  const [userData, setUserData] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );

  const retriveReviewer = async () => {
    const userSnap = await db
      .collection(usersCollectionName)
      .where("reviewer", "==", true)
      .get();

    setReviewers(
      userSnap.docs.map((doc) => {
        return { id: doc.id, data: doc.data() };
      })
    );
  };

  const retrivePhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber.length >= 12) {
      await db
        .collection(usersCollectionName)
        .where("phoneNumber", "==", phoneNumber)
        .get()
        .then((response) => {
          setUserData(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
    } else {
      setUserData([]);
    }
  };

  const updateUser = (id: string, isAdd: boolean) => {
    const response = sankmoneyUpdateAccountFn({
      collectionName: usersCollectionName,
      documentData: {
        reviewer: isAdd,
      },
      documentId: id,
    }).then(() => {
      retriveReviewer();
      setShowModal(false);
    });
    toast.promise(response, {
      loading: `${isAdd ? "Ajout..." : "Retrait..."}`,
      success: `${
        isAdd
          ? "L'utilisateur a été ajouté avec succès."
          : "L'utilisateur a été retiré avec succès."
      }`,
      error: "Échec ! Veuillez réessayer.",
    });
  };

  useEffect(() => {
    retriveReviewer();
  }, []);

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h3 className="py-2 text-xl font-semibold text-gray-900">
            Listes des validateurs
          </h3>
          <p className="mt-2 text-sm text-gray-700">
            Gérez la lsites des validateurs.
          </p>
        </div>
        <div className="mt-2 sm:mt-0 sm:ml-2 sm:flex-none">
          <button
            type="button"
            className={classNames(
              reviewers?.length === 4
                ? "cursor-not-allowed text-gray-700 rounded-lg border border-gray-300 hover:bg-gray-50 focus:ring-gray-200"
                : "text-red-700 rounded-lg border border-red-300 hover:bg-red-50 focus:ring-red-200",
              "relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium bg-white focus:ring-4 focus:outline-none"
            )}
            onClick={() => setShowModal(true)}
            disabled={reviewers?.length === 4 && true}
          >
            <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
              <PlusIcon className="h-5" /> Ajouter
            </span>
          </button>
        </div>
        <div className="mt-2 sm:mt-0 sm:ml-2 sm:flex-none">
          <button
            type="button"
            className={classNames(
              reviewers?.length >= 4
                ? "text-green-700 border-green-300 hover:bg-green-50 focus:ring-green-200"
                : "text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-gray-200 cursor-not-allowed",
              "relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium rounded-lg border bg-white focus:ring-4 focus:outline-none"
            )}
            disabled={reviewers?.length < 4 && true}
            onClick={() => navigate("/reload_sank_money_account/new")}
          >
            <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
              <ArrowPathIcon
                className={classNames(
                  reviewers?.length >= 4 ? "text-green-500" : "text-gray-500",
                  "self-center flex-shrink-0 w-5 h-5 mr-1 "
                )}
                aria-hidden="true"
              />
              Initier une recharger
            </span>
          </button>
        </div>
      </div>
      <div className="flex flex-col mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm">
              <ul className="grid grid-cols-1 gap-6 my-4 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-3">
                {reviewers ? (
                  reviewers.map((user, eventIdx) => {
                    return (
                      <li
                        key={user.id}
                        className="col-span-1 m-2 bg-white border divide-y divide-gray-200 rounded-lg shadow-md dark:divide-slate-700 dark:bg-slate-800 dark:border-slate-700"
                      >
                        <div className="flex items-center justify-between w-full px-6 py-4 space-x-6">
                          <div className="flex-1 truncate">
                            <div className="flex items-center space-x-3">
                              <h3 className="text-sm font-medium text-gray-900 truncate">
                                {user.data.displayName}
                              </h3>
                            </div>
                            <p className="mt-1 text-sm text-gray-500 truncate">
                              {`${user.data.phoneNumber} ${
                                user.data.email ? user.data.email : ""
                              }`}
                            </p>
                          </div>
                          <span className="inline-block w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-slate-700">
                            <svg
                              className="w-full h-full text-gray-300"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          </span>
                        </div>
                        <div>
                          <div className="flex -mt-px divide-x divide-gray-200 dark:divide-slate-700">
                            <div className="flex flex-1 w-0">
                              <div
                                onClick={() => updateUser(user.id, false)}
                                className="relative inline-flex items-center justify-center flex-1 w-0 py-2 -mr-px text-sm font-semibold text-gray-900 border border-transparent rounded-bl-lg cursor-pointer gap-x-2 dark:text-gray-300"
                              >
                                <XMarkIcon
                                  className="w-5 h-5 text-red-400"
                                  aria-hidden="true"
                                />
                                Retirer
                              </div>
                            </div>
                            {/* <div className="flex flex-1 w-0 -ml-px">
                        <div className="relative inline-flex items-center justify-center flex-1 w-0 py-2 text-sm font-semibold text-gray-900 border border-transparent rounded-br-lg gap-x-2 dark:text-gray-300">
                          <PhoneIcon
                            className="w-5 h-5 text-emerald-400"
                            aria-hidden="true"
                          />
                          Airtime
                        </div>
                      </div> */}
                          </div>
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <NoContent />
                )}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showModal}
        maxSize="lg"
        closeModal={() => setShowModal(false)}
        modalTitle="Renseigner le numéro de téléphone"
      >
        <div className="flex justify-center">
          <div className="w-full mt-10">
            <PhoneInput
              international={false}
              placeholder="Entrer un numéro de téléphone"
              className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
              defaultCountry="BF"
              onChange={retrivePhoneNumber}
            />
            <div className="flex justify-center w-full">
              {userData && userData.length > 0 && (
                <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex flex-col items-center pb-10">
                    <img
                      className="w-24 h-24 mt-2 mb-3 rounded-full shadow-lg"
                      src={
                        userData[0].data.profilImageUrl
                          ? userData[0].data.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              userData[0].data.displayName || "S"
                            }`
                      }
                      alt=""
                    />
                    <h5 className="mb-1 text-xl font-medium text-center text-gray-900 dark:text-white">
                      {userData[0].data?.displayName}
                    </h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {userData[0].data?.phoneNumber}
                    </span>
                    <div className="flex mt-4 space-x-3 md:mt-6">
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() => {
                          updateUser(userData[0].id, true);
                          setShowModal(false);
                          setUserData([]);
                        }}
                      >
                        Ajouter
                      </div>
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                        onClick={() => {
                          setShowModal(false);
                          setUserData([]);
                        }}
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default AddReviewer;
