import { ArrowSmallDownIcon } from "@heroicons/react/24/outline";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import CodePinComponent from "../../../components/CodePinComponent";
import Modal from "../../../components/Modal";
import {
  usersCollectionName,
  accountsCollectionName,
  defaultAmount,
} from "../../../config";
import { UserContext } from "../../../contexts";
import { db, functions } from "../../../firebase";
import { useRouter } from "../../../hooks/useRouter";
import { AccountType, UserRole } from "../../../interfaces";
import { amountFormater, classNames, isNumeric } from "../../../utils";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

const ReloadCollectionPointAccountPage = () => {
  const [pointOfSaleData, setPointOfSale] = useState<any>();
  const [amount, setAmount] = useState<string>(defaultAmount);
  const { user, setUserAccounts, agencyAgent } = useContext(UserContext);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [userTypedNumber, setUserTypedNumber] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const [receiverDataGetWithPhoneNumber, setReceiverDataGetWithPhoneNumber] =
    useState<any>(null);
  const router = useRouter();
  const [description, setDescription] = useState("");
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const [isNotExistOnSystem, setIsNotExistOnSystem] = useState(true);

  const sankMoneyFundraisingFn = functions.httpsCallable(
    "sankMoneyFundraisingFn-agentReloadCollectionPointAccountFn"
  );

  const handleChangeAmountValue = (e: any) => {
    e.preventDefault();
    setAmount(`${e.target.value ?? defaultAmount}`);
  };

  const getFilterdByPosIdentity = async (value: any) => {
    if (value !== undefined) {
      const posIdentity = value.target.value;
      setUserTypedNumber(posIdentity);
      if ((posIdentity as string).length >= 6) {
        const destUserSnapshot = await db
          .collection(usersCollectionName)
          .where("pointOfSaleIdentityCode", "==", posIdentity)
          .where("userRole", "==", UserRole.USER)
          .get();
        if (destUserSnapshot.empty) {
          setIsNotExistOnSystem(true);
          toast.error("Le numéro n'est pas un utilisateur");
        } else {
          const kioskUserData = destUserSnapshot.docs.map((doc) =>
            doc.data()
          )[0];
          return setPointOfSale(kioskUserData);
        }
      } else setPointOfSale(undefined);
    } else {
      setPointOfSale(undefined);
    }
  };

  const handleDescriptionChange = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    setDescription(value);
  };

  const getFilterdByPointOfCollectIdentity = async (
    pointOfCollectIdentity: any
  ) => {
    pointOfCollectIdentity.preventDefault();
    const value = pointOfCollectIdentity.target.value;
    if (value !== undefined) {
      setUserTypedNumber(value);
      if (value?.length === 7) {
        const destUserSnapshot = await db
          .collection(usersCollectionName)
          .where("pointOfCollectIdentity", "==", value)
          .where("userRole", "==", UserRole.COLLECTIONPOINT)
          .get();
        if (destUserSnapshot.empty) {
          toast.error(
            "Le numéro saisie n'est pas celui d'un point de collecte."
          );
          router.push("/transfer/reload_collectionPoints");
        } else {
          const destreceiverData = [];
          for (const destUserDoc of destUserSnapshot.docs) {
            const destUserdAllata = destUserDoc.data();
            const destUserAccountSnapshot = await destUserDoc.ref
              .collection(accountsCollectionName)
              .get();
            const destUserAccountData = destUserAccountSnapshot.docs
              .map((accountDoc) => accountDoc.data())
              .find((data) => data.accountType === AccountType.MAIN);
            const destUserWithAccounts = {
              account: destUserAccountData,
              ...destUserdAllata,
            };
            destreceiverData.push(destUserWithAccounts);
          }
          return setReceiverDataGetWithPhoneNumber(destreceiverData[0]);
        }
      }
    } else {
      setReceiverDataGetWithPhoneNumber(null);
    }
  };

  const onSubmit = async (pinCode: string) => {
    const userRef = db.collection(usersCollectionName);
    const senderAccountRef = userRef
      .doc(user?.id)
      .collection(accountsCollectionName);

    setIsCodePinBtnDesabled(true);
    if (!isNumeric(amount)) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error(`Le montant n'est pas bien formatté`);
    }
    if (amount < defaultAmount) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error(
        `Le montant doit être suppérieur à ${defaultAmount} Fcfa`
      );
    }
    // if (userTypedNumber.length < PhoneNumberDefaultLength + 4) {
    //   setIsCodePinBtnDesabled(false);
    //   setShowPinCodeModal(false);
    //   return toast.error(
    //     `Le numéro du point de collecte n'est pas valide. ${userTypedNumber}`
    //   );
    // }
    if (amount < defaultAmount) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error(
        `Le montant doit être suppérieur à ${defaultAmount} Fcfa`
      );
    }

    if (user && receiverDataGetWithPhoneNumber) {
      if (user?.id === receiverDataGetWithPhoneNumber?.id) {
        toast.error("Vous ne pouvez pas effectuer cet type de transaction");
        setIsCodePinBtnDesabled(false);
      } else {
        setIsLoad(true);
        const data: any = {
          pinCode: pinCode,
          amount: amount.replace(/\s/g, "").trim(),
          senderPhoneNumber: (agencyAgent ? agencyAgent : user)?.phoneNumber,
          receiverPhoneNumber:
            receiverDataGetWithPhoneNumber &&
            receiverDataGetWithPhoneNumber?.phoneNumber,
          description: description ?? "",
          withConnection: true,
          pointOfSaleIdentity: pointOfSaleData?.id,
          currentUserId: user?.id,
        };
        await sankMoneyFundraisingFn(data)
          .then(async () => {
            setShowPinCodeModal(false);
            setIsLoad(false);
            const accounts = await senderAccountRef.get();
            setUserAccounts(accounts.docs.map((account) => account.data()));
            router.push("/dashboard");
            return toast.success("La recharge a été effectuée avec succès");
          })
          .catch((err: { details: any; message: any }) => {
            setShowPinCodeModal(false);
            setIsCodePinBtnDesabled(false);
            setIsLoad(false);
            toast.error(err.message);
          });
      }
    }
  };

  return (
    <div className="flex justify-center w-full">
      <div className="w-full max-w-md px-2 py-16 sm:px-0">
        <div className="flex items-center justify-center w-full font-manrope">
          <div className="box-border w-full p-4 mx-auto bg-white border">
            <div className="mt-6">
              {/* <div className="font-semibold">
                Entrez le numéro du point de collecte
              </div> */}
              <div>
                <label
                  htmlFor="code"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Entrez l' identifiant du point de Collecte.
                </label>
                <input
                  type="text"
                  id="code"
                  maxLength={7}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  placeholder="Ex: 0256878"
                  onChange={getFilterdByPointOfCollectIdentity}
                />

                {!receiverDataGetWithPhoneNumber ? (
                  userTypedNumber.length !== 7 &&
                  userTypedNumber.length > 0 && (
                    <p className="mt-1 text-xs text-red-500">
                      Identifiant invalide
                    </p>
                  )
                ) : (
                  <p className="mt-1 text-xs text-green-500">
                    Identifiant valide
                  </p>
                )}
              </div>
            </div>
            <div className="mt-6">
              <div className="font-semibold">
                Combien souhaitez-vous déposer ?
              </div>
              <div>
                <input
                  className="mt-1 w-full rounded-[4px] border border-[#A0ABBB] p-2 focus:ring-red-500 focus:border-red-500"
                  type="text"
                  placeholder="Ex: 1000"
                  pattern="[0-9]*"
                  min={0}
                  onChange={handleChangeAmountValue}
                />
              </div>
            </div>
            <div className="mt-6">
              <div>
                <label
                  htmlFor="code"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Entrez l' identifiant du point de vente.
                </label>
                <input
                  type="text"
                  id="code"
                  maxLength={7}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  placeholder="Ex: 199631"
                  onChange={getFilterdByPosIdentity}
                />
                {pointOfSaleData && (
                  <span className="text-sm text-green-500">
                    {pointOfSaleData?.displayName}
                  </span>
                )}
              </div>
            </div>
            <div className="mt-6">
              <div className="font-semibold">
                Entrez une descriptions de la transaction
              </div>
              <div>
                <textarea
                  id="message"
                  rows={4}
                  onChange={handleDescriptionChange}
                  maxLength={200}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  placeholder="Écrivez votre description ici..."
                ></textarea>
              </div>
            </div>
            <div className="mt-6">
              <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                <img
                  className="w-10 h-10 rounded-full"
                  src={
                    user?.profilImageUrl
                      ? user?.profilImageUrl
                      : `https://ui-avatars.com/api/?background=random&name=${
                          user?.displayName || "S"
                        }`
                  }
                  alt=""
                />
                <div>
                  <div className="font-semibold">{user?.displayName}</div>
                </div>
              </div>
            </div>
            {receiverDataGetWithPhoneNumber && (
              <>
                <div className="flex justify-center mt-2">
                  <ArrowSmallDownIcon className="h-6 text-red-500" />
                </div>
                <div className="mt-2">
                  <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                    <img
                      className="w-10 h-10 rounded-full"
                      src={
                        receiverDataGetWithPhoneNumber?.profilImageUrl
                          ? receiverDataGetWithPhoneNumber?.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              receiverDataGetWithPhoneNumber?.displayName || "S"
                            }`
                      }
                      alt=""
                    />
                    <div>
                      <div className="font-semibold">
                        {receiverDataGetWithPhoneNumber?.displayName}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
            <div className="mt-6">
              <button
                className={classNames(
                  receiverDataGetWithPhoneNumber && isNotExistOnSystem && amount
                    ? "bg-red-600  cursor-pointer"
                    : "bg-gray-600 cursor-not-allowed",
                  "w-full rounded-[4px]  px-3 py-[6px] text-center font-semibold text-white"
                )}
                onClick={() => amount && setShowPinCodeModal(true)}
                disabled={
                  receiverDataGetWithPhoneNumber && isNotExistOnSystem && amount
                    ? false
                    : true
                }
              >
                Déposer : {amountFormater(parseInt(amount ?? "0"))} Fcfa
              </button>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => setShowPinCodeModal(false)}
      >
        <div className="flex justify-center">
          <CodePinComponent
            onSubmit={onSubmit}
            disabled={isCodePinBtnDesabled}
            isLoad={isLoad}
          />
        </div>
      </Modal>
    </div>
  );
};

export default ReloadCollectionPointAccountPage;
