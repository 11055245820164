/* eslint-disable jsx-a11y/no-redundant-roles */
import { useContext, useEffect, useState } from "react";
import { db, functions, storage } from "../../../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../../contexts";
import toast from "react-hot-toast";
import {
    Form,
    FormInput,
} from "../../../components/FormComponents";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { eventsCollections } from "../../../config";
import { TicketInterface } from "../../../interfaces/ticket.interface";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import moment from "moment";
import { UserRole } from "../../../interfaces";


type TicketAttendantInfos = Pick<
    TicketInterface,
    | "numberOfTicket"
    | "saleStartDate"
    | "saleEndDate"
    | "availableQuantity"
    | "ticketExpiringDate"
    | "price"
    | "standard"
    | "numberOfPlace"
    | "ticketCoverUrl"
    | "ticketPrice"
>;

const TicketEditPage = () => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const location = useLocation();
    const currentType = location.state?.entity;
    const [imageUrl, setImageUrl] = useState<string>();

    const sankMoneyUpdateEventTicketTypeFn = functions.httpsCallable(
        "sankMoneyUpdateEventTicketTypeFn"
    );

    const handlechange = (value: any) => {
        const image = value.target.files[0];
        if (image) {
            const imageRef = ref(
                storage,
                `leisure_events/${currentType?.eventId}/${image.lastModified}`
            );
            uploadBytes(imageRef, image)
                .then(() => {
                    getDownloadURL(imageRef)
                        .then((url) => {
                            setImageUrl(url);
                        })
                        .catch((error) =>
                            toast.error(
                                `Une erreur s'est produit lors du chargement de l'url du fichier ${error.message}`
                            )
                        );
                    setImageUrl("");
                })
                .catch((error) =>
                    toast.error(
                        `Une erreur s'est produit lors de l'upload du fichier ${error.message}`
                    )
                );
        }
    };

    const onSubmitDefaultInfos = async (data: any) => {
        if (user) {
            const response = sankMoneyUpdateEventTicketTypeFn({
                collectionName: eventsCollections,
                documentData: {
                    ticketCoverUrl: imageUrl ? imageUrl : currentType?.ticketCoverUrl,
                    ...data
                },
                documentId: currentType?.id,
                eventId: currentType?.eventId,
            }).then(()=>{
                navigate(`/billetterie/events/${currentType?.eventId}/detail`,
                    {
                      state: { eventId: currentType?.eventId },
                    }
                  );
            })
            toast.promise(response, {
                error: "La mise à jour a échoué",
                success: `Les informations du ticket ${currentType?.standard}  ont été mis a jour avec succès`,
                loading: "Mise a jour...",
            });
        }
    };

    useEffect(() => {
    }, []);

    return (
        <>
            <div className="py-2 border-b border-gray-200 dark:border-slate-700">
                <h2 className="text-lg font-semibold leading-6 text-gray-900 dark:text-gray-300">
          Editer les type de tickets
        </h2>
        {/* <p className="mt-1 text-base text-gray-500">
          Editer un evenement
        </p> */}
            </div>
            <div className="flex mx-5 mt-4 max-md:block max-md:space-x-0 max-md:space-y-10 max-md:mx-5">
                <div className="w-full">
                    <div className="flex flex-col w-full">
                        <div className="flex justify-center w-full">
                            <div className="w-full max-w-2xl px-2 py-8 sm:px-0">
                                <div className="flex items-center justify-center w-full font-manrope">
                                    <div className="box-border w-full p-4 mx-auto bg-white border">
                                        <Form<TicketAttendantInfos>
                                            maximizeFormWidh={false}
                                            formReturnAware={() => { }}
                                            onSubmit={onSubmitDefaultInfos}
                                            submitButtonLabel={"Enregistrer"}
                                            form={{
                                                resolver: yupResolver(
                                                    yup.object().shape({
                                                        availableQuantity: yup.number().optional(),
                                                        numberOfPlace: yup.number().optional(),
                                                        saleEndDate: yup.string().optional(),
                                                        saleStartDate: yup.string().optional(),
                                                        standard: yup.string().optional(),
                                                        ticketExpiringDate: yup.string().optional(),
                                                        ticketPrice: yup.number().optional(),
                                                    })
                                                ),
                                                defaultValues: {
                                                    availableQuantity: currentType?.availableQuantity,
                                                    numberOfPlace: currentType?.numberOfPlace,
                                                    saleEndDate: moment(currentType?.saleStartDate).format("YYYY-MM-DD hh:mm:ss"),
                                                    saleStartDate: moment(currentType?.saleEndDate).format("YYYY-MM-DD hh:mm:ss"),
                                                    standard: currentType?.standard,
                                                    ticketExpiringDate: moment(currentType?.ticketExpiringDate).format("YYYY-MM-DD hh:mm:ss"),
                                                    ticketPrice: currentType?.ticketPrice,
                                                },
                                            }}
                                        >
                                            <div className="mt-6">
                                                <div className="font-semibold text-lg">
                                                    Editer le ticket de type <span className="text-blue-400"> {currentType?.standard} </span>
                                                </div>
                                            </div>
                                            <div className="flex flex-col content-center w-full px-5 mt-5 align-top md:flex-row">
                                                <div className="w-full mx-auto md:w-1/2" >
                                                    <FormInput
                                                        required
                                                        type="number"
                                                        name="availableQuantity"
                                                        label={`Nombre de ticket disponible`}
                                                        placeholder="200"
                                                        // disabled
                                                    />
                                                    <FormInput
                                                        type="datetime-local"
                                                        label="Date de debut vente"
                                                        name="saleStartDate"
                                                    />
                                                    <FormInput
                                                        type="datetime-local"
                                                        label="Date de fin de vente"
                                                        name="saleEndDate"
                                                    />
                                                    <FormInput
                                                        required
                                                        type="number"
                                                        name="numberOfPlace"
                                                        label={`Nombre de place par ticket`}
                                                        placeholder="1"
                                                    />

                                                </div>
                                                <div className="w-10 h-5"></div>
                                                <div className="w-full mx-auto md:w-1/2" >
                                                    <FormInput
                                                        required
                                                        type="text"
                                                        name="standard"
                                                        label={`Type de ticket`}
                                                        placeholder="VIP"
                                                    />
                                                    <FormInput
                                                        required
                                                        type="datetime-local"
                                                        name="ticketExpiringDate"
                                                        label={`Date d'expiration`}
                                                        placeholder=""
                                                    />
                                                    <FormInput
                                                        required
                                                        type="number"
                                                        name="ticketPrice"
                                                        label={`Prix du ticket`}
                                                        placeholder="10000"
                                                    />
                                                </div>

                                            </div>
                                            <div className="flex items-center justify-center p-6">
                                                <div className="mx-auto w-full max-w-[550px] bg-white">
                                                    <div className="pt-2 mb-4">
                                                        <div className="mb-4">
                                                            <input
                                                                type="file"
                                                                id="file"
                                                                className="sr-only"
                                                                onChange={handlechange}
                                                            />
                                                            <label
                                                                htmlFor="file"
                                                                className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                                                            >
                                                                <div>
                                                                    {imageUrl ||( currentType?.ticketCoverUrl && currentType?.ticketCoverUrl.length > 0) ? (
                                                                        <img
                                                                            className="object-cover object-center mx-auto"
                                                                            src={imageUrl || currentType?.ticketCoverUrl}
                                                                            alt={imageUrl || currentType?.ticketCoverUrl}
                                                                        />
                                                                    ) : (
                                                                        <span className="inline-flex rounded border border-[#e0e0e0] cursor-pointer py-2 px-7 text-base font-medium text-[#07074D]">
                                                                            Choisir une image
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default TicketEditPage;
