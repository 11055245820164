import React from 'react'
import UserDetailsPageComponent from '../../components/UserDetailsComponent';
import { useLocation } from 'react-router-dom';

const MakeUserDealer = () => {
  const location = useLocation();
  const currentUser = location.state?.entity;
  return (
    <UserDetailsPageComponent userDetailsData={currentUser} makeDealer={true} />
  );
}

export default MakeUserDealer