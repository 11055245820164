import { useContext, useState } from "react";
import {
  Form,
  FormInput,
  FormTextArea,
} from "../../../../components/FormComponents";
import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import * as yup from "yup";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { db, functions } from "../../../../firebase";
import { usersCollectionName } from "../../../../config";
import toast from "react-hot-toast";
import CodePinComponent from "../../../../components/CodePinComponent";
import Modal from "../../../../components/Modal";
import { UserContext } from "../../../../contexts";
import { isNumeric } from "../../../../utils";

const RefundAirtimeTransaction = () => {
  const [receiverDataGetWithPhoneNumber, setreceiverDataGetWithPhoneNumber] =
    useState<any>(null);
  const [refundData, setRefundData] = useState<any>();
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const { user } = useContext(UserContext);

  const refundUnPassedAirtimeTransactionMoneyFn = functions.httpsCallable(
    "refundUnPassedAirtimeTransactionMoneyFn"
  );

  const getFilterdByPhoneNumber = async (phoneNumber: any) => {
    if (phoneNumber !== undefined) {
      if (isValidPhoneNumber(phoneNumber)) {
        const destUserSnapshot = await db
          .collection(usersCollectionName)
          .where("phoneNumber", "==", phoneNumber)
          .get();
        if (destUserSnapshot.empty) {
          toast.error("Le numéro n'existe pas");
          setreceiverDataGetWithPhoneNumber(null);
        } else {
          const destreceiverData = [];
          for (const destUserDoc of destUserSnapshot.docs) {
            const destUserdAllata = destUserDoc.data();
            destreceiverData.push(destUserdAllata);
          }
          return setreceiverDataGetWithPhoneNumber(destreceiverData[0]);
        }
      } else {
        setreceiverDataGetWithPhoneNumber(null);
      }
    } else {
      setreceiverDataGetWithPhoneNumber(null);
    }
  };

  const onSubmit = (pinCode: any) => {
    setIsCodePinBtnDesabled(true);
    setIsLoad(true);
    if (!receiverDataGetWithPhoneNumber) {
      setIsCodePinBtnDesabled(false);
      setIsLoad(false);
      setShowPinCodeModal(false);
      return toast.error("Veuillez entre un numéro de téléphone valide.");
    }
    if (!isNumeric(refundData.amount)) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      setIsLoad(false);
      return toast.error(`Le montant n'est pas bien formatté`);
    }
    const loadId = toast.loading("Remboursement...");
    const paiementData = {
      amount: refundData.amount,
      currentUserId: user?.id,
      withConnection: true,
      receiverPhoneNumber: receiverDataGetWithPhoneNumber?.phoneNumber,
      pinCode,
      unpaidDate: (refundData.unpaidDate as Date).toLocaleDateString(),
      description: refundData.description,
    };
    refundUnPassedAirtimeTransactionMoneyFn(paiementData)
      .then(() => {
        setIsLoad(false);
        setShowPinCodeModal(false);
        setIsCodePinBtnDesabled(false);
        toast.success("Le remboursement a été effectué avec succès", {
          id: loadId,
        });
      })
      .catch((error) => {
        toast.error(error.message, {
          id: loadId,
        });
        setIsCodePinBtnDesabled(false);
        setShowPinCodeModal(false);
        setIsLoad(false);
      });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-5 sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h4 className="py-2 text-xl font-semibold text-gray-900">
            Remboursement Airtime
          </h4>
        </div>
      </div>
      <div className="flex flex-col mt-4">
        <div className="-mx-1 -my-2 sm:-mx-2 lg:-mx-2">
          <div className="inline-block w-full py-2 md:w-1/2">
            <div className="sm:flex-auto">
              <Form<{
                description: string;
                amount: string;
                unpaidDate: string;
              }>
                onSubmit={(data: any) => {
                  setShowPinCodeModal(true);
                  setRefundData(data);
                }}
                submitButtonLabel={"Rembourser"}
                form={{
                  mode: "onChange",
                  resolver: yupResolver(
                    yup.object().shape({
                      description: yup.string().optional(),
                      unpaidDate: yup
                        .date()
                        .required("Veillez choisir une date"),
                      amount: yup
                        .string()
                        .required("Veuillez entre un montant"),
                    })
                  ),
                }}
              >
                <div className="px-1 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
                  <div className="px-1 mt-1 border rounded-md">
                    <PhoneInput
                      international={false}
                      placeholder="Entrez un numéro de téléphone"
                      className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                      defaultCountry="BF"
                      onChange={getFilterdByPhoneNumber}
                    />
                    {receiverDataGetWithPhoneNumber && (
                      <div className="flex justify-between flex-1 mt-1 text-sm text-green-500">
                        <span className="font-semibold text-gray-400">
                          {receiverDataGetWithPhoneNumber?.displayName}
                        </span>
                        <span className="text-green-500">
                          {receiverDataGetWithPhoneNumber?.phoneNumber}
                        </span>
                      </div>
                    )}
                    <FormInput
                      name="amount"
                      placeholder="5000"
                      label="Le montant de remboursement."
                      helpLabel="Renseigner le montant de remboursement."
                    />
                  </div>
                  <FormInput
                    type="date"
                    name="unpaidDate"
                    label="Date"
                    helpLabel="C'est la date à la quelle le paiement à echouer."
                    defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  />
                  <FormTextArea name="description" label="Description." />
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => setShowPinCodeModal(false)}
      >
        <div className="flex justify-center">
          <CodePinComponent
            onSubmit={onSubmit}
            disabled={isCodePinBtnDesabled}
            isLoad={isLoad}
          />
        </div>
      </Modal>
    </div>
  );
};

export default RefundAirtimeTransaction;
