import React from "react";
import { CSVLink } from "react-csv";
export const CsvModel = ({
  csvData,
  fileName,
  headers,
  text,
  className,
}: {
  csvData: unknown[];
  headers: unknown[];
  fileName: string;
  text?:string,
  className:string
}) => {
  return (
    <CSVLink
      data={csvData as any}
      headers={headers as any}
      filename={`${fileName}.csv`}
      className={className}
      target="_blank"
    >
    {text}
    </CSVLink>
  );
};

export default CsvModel;
