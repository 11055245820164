/* eslint-disable jsx-a11y/no-redundant-roles */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { Menu, Transition } from "@headlessui/react";
import { EllipsisHorizontalIcon } from "@heroicons/react/24/outline";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { classNames, amountFormater } from "../../utils";
import NoContent from "../TableNoContentPage/NoContent";

interface StationsComponentProps {
  stations: any;
}

const StationsComponent = ({ stations }: StationsComponentProps) => {
  const navigate = useNavigate();
  return (
    <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
      <div className="max-w-2xl mx-auto lg:mx-0 lg:max-w-none">
        <div className="flex items-center justify-between">
          <h2 className="text-base font-semibold leading-7 text-gray-900">
            Stations récents
          </h2>
          <div
            onClick={() => navigate("/superStation/affiliate-station")}
            className="text-sm font-semibold leading-6 text-red-600 cursor-pointer hover:text-red-500"
          >
            Voir tout<span className="sr-only">, Stations</span>
          </div>
        </div>
        <ul
          role="list"
          className="grid grid-cols-1 mt-6 gap-x-6 gap-y-8 lg:grid-cols-3 xl:gap-x-8"
        >
          {stations && stations.length > 0 ? (
            stations.map((station: any, index: number) => (
              <li
                key={index}
                className="overflow-hidden border border-gray-200 rounded-xl"
              >
                <div className="flex items-center p-6 border-b gap-x-4 border-gray-900/5 bg-gray-50">
                  <img
                    src={
                      station?.profilImageUrl
                        ? station?.profilImageUrl
                        : `https://ui-avatars.com/api/?background=random&name=${
                            station?.displayName || "S"
                          }`
                    }
                    alt={station?.displayName}
                    className="flex-none object-cover w-12 h-12 bg-white rounded-lg ring-1 ring-gray-900/10"
                  />
                  <div className="text-sm font-medium leading-6 text-gray-900">
                    {station?.displayName}
                  </div>
                  <Menu as="div" className="relative ml-auto">
                    <Menu.Button className="-m-2.5 block p-2.5 text-gray-400 hover:text-gray-500">
                      <span className="sr-only">Open options</span>
                      <EllipsisHorizontalIcon
                        className="w-5 h-5"
                        aria-hidden="true"
                      />
                    </Menu.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-100"
                      enterFrom="transform opacity-0 scale-95"
                      enterTo="transform opacity-100 scale-100"
                      leave="transition ease-in duration-75"
                      leaveFrom="transform opacity-100 scale-100"
                      leaveTo="transform opacity-0 scale-95"
                    >
                      <Menu.Items className="absolute right-0 z-10 mt-0.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                        <Menu.Item>
                          {({ active }) => (
                            <div
                              onClick={() =>
                                navigate(
                                  `/superStation/affiliate-stations/${station?.id}/details`,
                                  {
                                    state: { entity: station },
                                  }
                                )
                              }
                              className={classNames(
                                active ? "bg-gray-50" : "",
                                "block px-3 cursor-pointer py-1 text-sm leading-6 text-gray-900"
                              )}
                            >
                              View
                              <span className="sr-only">
                                , {station?.displayName}
                              </span>
                            </div>
                          )}
                        </Menu.Item>
                      </Menu.Items>
                    </Transition>
                  </Menu>
                </div>
                <dl className="px-6 py-4 -my-3 text-sm leading-6 divide-y divide-gray-100">
                  <div className="flex justify-between py-3 gap-x-4">
                    <dt className="text-gray-500">Code station-service</dt>
                    <dd className="text-gray-700">{station?.gasStationCode}</dd>
                  </div>
                  <div className="flex justify-between py-3 gap-x-4">
                    <dt className="text-gray-500">Soldes</dt>
                    <dd className="flex items-start gap-x-2">
                      <div className="font-medium text-gray-900">
                        {amountFormater(station?.accounts.balance)}
                      </div>
                      <div
                        className={classNames(
                          // statuses[station.lastInvoice.status],
                          "rounded-md py-1 px-2 text-xs font-medium ring-1 ring-inset"
                        )}
                      >
                        Fcfa
                      </div>
                    </dd>
                  </div>
                </dl>
              </li>
            ))
          ) : (
            <NoContent />
          )}
        </ul>
      </div>
    </div>
  );
};

export default StationsComponent;
