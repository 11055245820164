import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { db } from "../../../firebase";
import {
  companiesMembershipCollectionName,
  usersCollectionName,
} from "../../../config";
import DisplayItemDetails from "../../../components/DisplayItemComponent";

const CompanyDetailsPage = () => {
  const location = useLocation();
  const currentUser = location.state?.entity;
  const [companyStaff, setCompanyStaff] = useState<any[]>([]);

  const retriveCompanyStaff = useCallback(async () => {
    const staffData = [];
    const memberships = await db
      .collection(companiesMembershipCollectionName)
      .orderBy("createdAt", "desc")
      .where("companyId", "==", currentUser?.id)
      .get();
    if (!memberships.empty) {
      for (const membershipSnapshot of memberships.docs) {
        const membershipData = membershipSnapshot.data();
        const staffSnapshot = await db
          .collection(usersCollectionName)
          .doc(membershipData?.memberId)
          .get();
        staffData.push(staffSnapshot.data());
      }
    }
    return setCompanyStaff(staffData);
  }, [currentUser?.id]);
  useEffect(() => {
    currentUser && retriveCompanyStaff();
  }, [currentUser, retriveCompanyStaff]);

  return (
    <DisplayItemDetails currentUser={currentUser} members={companyStaff} />
  );
};

export default CompanyDetailsPage;
