import { Timestamp } from "firebase-admin/firestore";
import { useContext, useEffect, useState } from "react";
import Modal from "../../../components/Modal";
import Pagination from "../../../components/Pagination/Pagination";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import { UserContext } from "../../../contexts";
import { db } from "../../../firebase";
import {
  OrderStatus,
} from "../../../interfaces";
import imageSrc from "../../../favicon.png";
import { QRCodeCanvas } from "qrcode.react";
import { QrCodeIcon } from "@heroicons/react/24/outline";
import {
  gasStationOrderCollectionName,
  gasStationCollectionName,
  usersCollectionName,
  gasStationMembershipCollectionName,
} from "../../../config";
import { classNames } from "../../../utils";
import { useNavigate } from "react-router-dom";

const tableHeader = [
  "N°",
  "Montant",
  "Description",
  "Status",
  "Générer un code qr",
  "Date de création",
  "Actions",
];
const collectionName = gasStationOrderCollectionName;
const statusMap = new Map<string, string>([
  ["PENDING", "En attente"],
  ["CANCELED", "Annuler"],
  ["VALID", "Payé"],
]);
const OrdersPage = () => {
  const { user, userAccounts } = useContext(UserContext);
  const [orders, setOrders] = useState<any[]>([]);
  const [paginatdlistData, setPaginatdListData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [orderId, setOrderId] = useState<string>("");
  const [gasStationMembershipId, setGasStationMembershipId] = useState("");
  const [showQrcodeReadeModal, setShowQrcodeReadeModal] =
    useState<boolean>(false);
    const navigate = useNavigate();
  const imageOptions = {
    src: imageSrc,
    height: 50,
    width: 50,
    excavate: true,
  };

  const retriveGasStationsMembership = async () => {
    const membershipRef = db.collection(gasStationMembershipCollectionName);
    const membershipSnaphot = await membershipRef
      .where("memberId", "==", user?.id)
      .get();
    if (membershipSnaphot.docs[0].exists) {
      const membershipData = membershipSnaphot.docs[0].data();
      setGasStationMembershipId(membershipData?.gasStationId);
      await db
        .collection(gasStationCollectionName)
        .doc(membershipData?.gasStationId)
        .collection(collectionName)
        .orderBy("createdAt", "desc")
        .get()
        .then((response) => {
          setOrders(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
    }
  };

  useEffect(() => {
    retriveGasStationsMembership();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageLimitChange = (pageLimit: number) => {
    db.collection(usersCollectionName)
      .doc(gasStationMembershipId)
      .collection(collectionName)
      .orderBy("createdAt", "desc")
      .limitToLast(pageLimit ?? 10)
      .get()
      .then((response) => {
        const data = response.docs.map((d) => {
          return { id: d.id, data: d.data() };
        });
        setPaginatdListData(data);
        setPage(page + 1);
      });
  };

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les commandes
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ici l'ensemble de vos commandes.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
            onClick={() => {
              navigate("/gas-stations/order/new");
            }}
          >
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
              Initier un payement
            </span>
          </button>
        </div>
      </div>
      <div className="flex flex-col mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm">
              <table
                className="min-w-full border-separate"
                style={{ borderSpacing: 0 }}
              >
                <thead className="bg-gray-50">
                  <tr>
                    {tableHeader.map((th, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                      >
                        {th}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {orders && orders.length > 0 ? (
                    (paginatdlistData.length > 0
                      ? paginatdlistData
                      : orders
                    ).map((order: any, orderIdx: number) => (
                      <tr key={orderIdx}>
                        <td
                          className={classNames(
                            order !== orders.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {(orderIdx as number) + 1}
                        </td>
                        <td
                          className={classNames(
                            order !== orders.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {new Intl.NumberFormat().format(
                            parseInt(order.data.amount ?? "0")
                          )}{" "}
                          Fcfa
                        </td>
                        <td
                          className={classNames(
                            order !== orders.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {order.data.description as string}
                        </td>
                        <td
                          className={classNames(
                            order !== orders.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {statusMap.get(order.data.orderStatus as string)}
                        </td>
                        <td
                          className={classNames(
                            order !== orders.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 flex pl-4 pr-3 text-sm sm:pl-6 lg:pl-8 justify-center"
                          )}
                        >
                          <QrCodeIcon
                            className={classNames(
                              order.data.orderStatus !== OrderStatus.PENDING
                                ? " text-gray-500"
                                : " text-red-500 cursor-pointer",
                              "w-6 h-6 "
                            )}
                            aria-hidden="true"
                            onClick={() => {
                              order.data.orderStatus === OrderStatus.PENDING &&
                                setShowQrcodeReadeModal(true);
                              setOrderId(order.id);
                            }}
                          />
                        </td>
                        <td
                          className={classNames(
                            order !== orders.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {(order.data.createdAt as Timestamp)
                            .toDate()
                            .toLocaleString()}
                        </td>
                        <td
                          className={classNames(
                            order !== orders.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                          )}
                        >
                          {/* <TableRowEditMenu
                            rowDeleteHandler={() => onDeleteRow(order)}
                            rowEditHandler={() => onEditRow(order)}
                          /> */}
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoContent />
                  )}
                </tbody>
              </table>
              {orders && orders.length > 0 && (
                <Pagination
                  skip={orders.length}
                  take={orders.length}
                  total={orders.length}
                  collectionName={collectionName}
                  onPageChange={(pageIndex: number) => pageIndex * page}
                  onPageLimitChange={(pageLimit: number) =>
                    onPageLimitChange(pageLimit)
                  }
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        modalTitle="QrCode"
        isOpen={showQrcodeReadeModal}
        maxSize="lg"
        closeModal={() => {
          setShowQrcodeReadeModal(false);
        }}
      >
        <div className="w-full max-w-md px-2 py-16 sm:px-0">
          <div
            className={classNames(
              "rounded-xl bg-white p-3 flex justify-center",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 w-full"
            )}
          >
            <QRCodeCanvas
              className="w-full"
              id="qrCode"
              value={`${gasStationMembershipId}_${
                userAccounts && userAccounts[0]?.id
              }_${orderId}`}
              size={300}
              level={"H"}
              imageSettings={imageOptions}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default OrdersPage;
