import {
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { useCallback, useContext, useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import {
  gasStationMembershipCollectionName,
  usersCollectionName,
} from "../../../config";
import { db, functions } from "../../../firebase";
import useCrudDocument from "../../../hooks/useCrudDocument";
import { AttendantRole, UserRole, userRoleMap } from "../../../interfaces";
import { UserContext } from "../../../contexts";
import { Timestamp } from "firebase-admin/firestore";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import TableRowEditMenu from "../../../components/TableRowEditMenu/TableRowEditMenu";
import { classNames, parseDate } from "../../../utils";

  const tableHeader = [
    "N°",
    "",
    "Nom/prénom",
    "Email",
    "Ville",
    "Téléphone",
    "Role",
    "Active",
    "Date de création",
    "Actions",
  ];

const GasStationAllStaffListPage = () => {

  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const adminResetPasswordFn = functions.httpsCallable("adminResetPasswordFn");
  const [gasStationMembers, setGasStationMembers] = useState<any[]>([]);

  const onEditRow = (entity: any) => {
    if (entity) {
      navigate(`/gas-stations/sank_staff/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onRowDetails = (entity: any) => {
    if (entity) {
      navigate(`/gas-stations/sank_staff/${entity.id}/details`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };

  const onResetPassword = (entity: any) => {
    if (entity) {
      const response = adminResetPasswordFn({
        email: entity.email,
        phoneNumber: entity.phoneNumber,
      });
      toast.promise(response, {
        error: (err) => `${err}`,
        success: "Le mot de passe a été reinitialiser avec succès.",
        loading: "chargement...",
      });
    }
  };

  const getStationMembersIds = useCallback(async () => {
    const gasStationMembershipRef = db.collection(
      gasStationMembershipCollectionName
    );

    if (user?.userRole === UserRole.GASSTATIONSTAFF) {
      const gasStationId = await gasStationMembershipRef
        .where("memberId", "==", user.id)
        .get()
        .then((snapShot) => snapShot.docs[0].data().gasStationId);

      const gasStationsMemberIds = await gasStationMembershipRef
        .where("gasStationId", "==", gasStationId)
        .get()
        .then((snapShot) => {
          return snapShot.docs.map((doc) => doc.data().memberId);
        });

      const members: any[] = [];
      for (const memberId of gasStationsMemberIds) {
        const memberSnap = await db
          .collection(usersCollectionName)
          .doc(memberId)
          .get();
        if (memberSnap.exists) {
          members.push(memberSnap.data());
        }
      }
      setGasStationMembers(members);
    }
  }, [user?.id, user?.userRole]);

  useEffect(() => {
    getStationMembersIds();
  }, []);

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Le personnel de la station-service
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ci-dessous tous le personnel de la station-service.
          </p>
        </div>
        {(user?.userRole === UserRole.SUPERVISOR ||
          user?.attendantRole === AttendantRole.MANAGER) && (
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
              onClick={() => {
                navigate("/gas-stations/sank_staff/new");
              }}
            >
              <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                Ajouter un membre
              </span>
            </button>
          </div>
        )}
      </div>
      <div className="flex flex-col mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm">
              <table
                className="min-w-full border-separate"
                style={{ borderSpacing: 0 }}
              >
                <thead className="bg-gray-50">
                  <tr>
                    {tableHeader.map((th, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                      >
                        {th}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {gasStationMembers && gasStationMembers.length > 0 ? (
                    gasStationMembers.map((member: any, memberIdx: number) => (
                      <tr key={memberIdx}>
                        <td
                          className={classNames(
                            memberIdx !== gasStationMembers.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {(memberIdx as number) + 1}
                        </td>
                        <td
                          className={classNames(
                            memberIdx !== gasStationMembers.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          <img
                            className="w-10 h-10 p-1 border rounded-full"
                            src={
                              member?.profilImageUrl
                                ? member?.profilImageUrl
                                : `https://ui-avatars.com/api/?background=random&name=${
                                    member?.displayName || "S"
                                  }`
                            }
                            alt=""
                          />
                        </td>
                        <td
                          className={classNames(
                            memberIdx !== gasStationMembers.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {member.displayName ? member.displayName : "-"}
                        </td>
                        <td
                          className={classNames(
                            memberIdx !== gasStationMembers.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {member.email ? member.email : "-"}
                        </td>
                        <td
                          className={classNames(
                            memberIdx !== gasStationMembers.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {member.city ? member.city : "-"}
                        </td>
                        <td
                          className={classNames(
                            memberIdx !== gasStationMembers.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {member.phoneNumber as string}
                        </td>

                        <td
                          className={classNames(
                            memberIdx !== gasStationMembers.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {userRoleMap.get(member.userRole) as string}
                        </td>
                        <td
                          className={classNames(
                            memberIdx !== gasStationMembers.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {member?.active ? (
                            <CheckCircleIcon className="w-6 h-6 text-green-500" />
                          ) : (
                            <XCircleIcon className="w-6 h-6 text-red-500" />
                          )}
                        </td>
                        <td
                          className={classNames(
                            memberIdx !== gasStationMembers.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {parseDate(member?.createdAt as Timestamp)}
                        </td>
                        <td
                          className={classNames(
                            memberIdx !== gasStationMembers.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                          )}
                        >
                          <TableRowEditMenu
                            rowEditHandler={() => onEditRow(member)}
                            rowDetailsHandler={() => onRowDetails(member)}
                            rowResetPasswordHandler={() =>
                              onResetPassword(member)
                            }
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoContent />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GasStationAllStaffListPage;
