import { Timestamp } from "firebase-admin/firestore";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import "react-phone-number-input/style.css";
import { UserContext } from "../../../contexts";
import Pagination from "../../../components/Pagination/Pagination";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import { usersCollectionName, contactsCollectionName } from "../../../config";
import { db, functions } from "../../../firebase";
import { classNames } from "../../../utils";
import {
  BanknotesIcon,
  DocumentIcon,
  MagnifyingGlassIcon,
  PencilIcon,
  PlusIcon,
  TrashIcon,
} from "@heroicons/react/24/outline";
import { useConfirm } from "../../../hooks/useConfirm";
import toast from "react-hot-toast";
import { PaymentFormType } from "../../Companies/Paiement";

const tableHeader = [
  "",
  "N°",
  "",
  "Nom/Prénom",
  "Téléphone",
  "Email",
  "Date de création",
  "Actions",
];

const ContactsList = () => {
  const [contacts, setContacts] = useState<any[]>([]);
  const [paginatdlistData, setPaginatdListData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const { user } = useContext(UserContext);
  const [selectedContacts, setSelectedContacts] = useState<any[]>([]);
  const navigate = useNavigate();
  const [searchedValue, setSearchedValue] = useState("");
  const [ContactArrayLengthLimit, setContactArrayLengthLimit] = useState(25);
  const { isConfirmModal } = useConfirm();
  const getAllcontactsRef = db
    .collection(usersCollectionName)
    .doc(user?.id)
    .collection(contactsCollectionName);

  useEffect(() => {
    user &&
      getAllcontactsRef
        .orderBy("createdAt", "desc")
        .limit(10)
        .get()
        .then((response) => {
          setContacts(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageLimitChange = (pageLimit: number) => {
    getAllcontactsRef
      .orderBy("createdAt", "desc")
      .limit(pageLimit)
      .get()
      .then((response) => {
        const data = response.docs.map((d) => {
          return { id: d.id, data: d.data() };
        });
        setPaginatdListData(data);
        setPage(page + 1);
      });
  };

  const handleChange = (checked: boolean, index: number, data: any) => {
    if (checked) {
      setSelectedContacts((prevContact) => [
        ...prevContact,
        { ...data, index },
      ]);
    } else {
      setSelectedContacts((prevContact) =>
        prevContact.filter((contact) => contact.index !== index)
      );
    }
  };

  const debouncedSearchResults = useCallback(
    async (val: string) => {
      setSearchedValue(val);
      const userSnap = await getAllcontactsRef.get();
      if (!userSnap.empty) {
        const data = userSnap.docs.map((doc) => {
          return { id: doc.id, data: doc.data() };
        });
        const filteredContacts = data.filter((value) =>
          (value.data.phoneNumber as string).includes(val)
        );
        setContacts(filteredContacts.slice(0, ContactArrayLengthLimit));
      }
    },
    [ContactArrayLengthLimit, getAllcontactsRef]
  );

  const sankMoneyDeleteUserBulkPaiementContactFn = functions.httpsCallable(
    "sankMoneyDeleteUserBulkPaiementContactFn"
  );

  const removeContact = async (contactIds: string[]) => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes-vous sûr de vouloir retirer le contact",
      "Oui retirer",
      true
    );

    if (isConfirm) {
      const response = sankMoneyDeleteUserBulkPaiementContactFn({
        userId: user?.id,
        contactIds: contactIds,
      }).then(() => {
        getAllcontactsRef
          .orderBy("createdAt", "desc")
          .limit(10)
          .get()
          .then((response) => {
            setContacts(
              response.docs.map((d) => {
                return { id: d.id, data: d.data() };
              })
            );
          });
      });
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success: "La suppression a été effectué avec succés",
        loading: "Mise à jour...",
      });
    }
  };

  return (
    <>
      <div className="flex flex-col">
        <div className="py-2 border-b border-gray-200 dark:border-slate-700">
          <h3 className="text-lg font-semibold leading-6 text-gray-900 dark:text-gray-300">
            La liste de mes contacts
          </h3>
          <p className="mt-1 text-base text-gray-500">
            {
              "Gérez vos contacts depuis cette page. Vous pouvez sélectionner un contact pour lui envoyer directement une recharge."
            }
          </p>
        </div>
        <div className="grid items-start justify-between grid-cols-1 my-3 lg:grid-cols-2 gap-x-4 ">
          <div className="flex self-stretch flex-1 w-full h-10 col-span-1 border border-b border-red-200 rounded-lg md:w-96 lg:w-96 lg:col-span-1 gap-x-4 lg:gap-x-4 bg-gray-50 dark:bg-slate-800 dark:border-slate-700">
            <form className="relative flex flex-1" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <MagnifyingGlassIcon
                className="absolute inset-y-0 w-5 h-full text-gray-400 pointer-events-none left-2"
                aria-hidden="true"
              />
              <input
                id="search-field"
                className="flex w-full h-full py-0 pl-10 pr-2 text-gray-900 border-0 rounded-lg placeholder:text-gray-400 bg-red-50 dark:bg-slate-800 focus:ring-0 sm:text-base"
                type="search"
                name="search"
                value={searchedValue}
                onChange={(e) => debouncedSearchResults(e.target.value)}
                placeholder={`7XXXXXXX`}
              />
            </form>
          </div>
          <div className="flex items-center justify-end col-span-1 my-2 space-x-2 max-md:flex-col max-md:space-y-4 lg:my-0">
            <button className="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold text-blue-700 transition-all duration-200 bg-white border border-blue-700 rounded-md shadow-sm gap-x-2 dark:bg-slate-800 hover:border-blue-900 hover:text-blue-900 dark:hover:text-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600">
              <Link to={`/bulkPayment/contacts/new`} className="flex gap-1">
                <PlusIcon className="h-5" /> Ajouter un contact
              </Link>
            </button>
            <button
              onClick={() => {
                navigate("/bulkPayment/payment", {
                  state: { contacts: selectedContacts },
                });
              }}
              disabled={selectedContacts.length > 0 ? false : true}
              className={classNames(
                selectedContacts.length > 0
                  ? "text-green-700 border-green-700 hover:border-green-900 hover:text-green-900 dark:hover:text-green-600 focus-visible:outline-green-600"
                  : "text-gray-500 border-gray-500 hover:border-gray-600 hover:text-gray-600 dark:hover:text-gray-500 focus-visible:outline-gray-500 cursor-not-allowed",
                "inline-flex items-center cursor-pointer justify-center px-4 py-2 text-sm font-semibold transition-all duration-200 bg-white border rounded-md shadow-sm gap-x-2 dark:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              )}
            >
              <BanknotesIcon className="h-5" /> Paiement
            </button>
            <button
              onClick={() => {
                navigate("/bulkPayment/payment", {
                  state: {
                    paymentFormType: PaymentFormType.BYCSVPAYMENT,
                  },
                });
              }}
              className={classNames(
                "inline-flex text-green-700 border-green-700 hover:border-green-900 hover:text-green-900 dark:hover:text-green-600 focus-visible:outline-green-600 items-center cursor-pointer justify-center px-4 py-2 text-sm font-semibold transition-all duration-200 bg-white border rounded-md shadow-sm gap-x-2 dark:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              )}
            >
              <DocumentIcon className="h-5" /> Uploader un fichier
            </button>
            <button
              onClick={() =>
                removeContact(selectedContacts.map((val) => val.id))
              }
              disabled={selectedContacts.length > 1 ? false : true}
              className={classNames(
                selectedContacts.length > 1
                  ? "text-red-700 border-red-700 hover:border-red-900 hover:text-red-900 dark:hover:text-red-600 focus-visible:outline-red-600"
                  : "text-gray-500 border-gray-500 hover:border-gray-600 hover:text-gray-600 dark:hover:text-gray-500 focus-visible:outline-gray-500 cursor-not-allowed",
                "inline-flex items-center cursor-pointer justify-center px-4 py-2 text-sm font-semibold transition-all duration-200 bg-white border rounded-md shadow-sm gap-x-2 dark:bg-slate-800 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2"
              )}
            >
              <TrashIcon className="h-5" /> Supprimer
            </button>
          </div>
        </div>
      </div>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col mt-8">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm">
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      {tableHeader.map((th, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                        >
                          {th}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {contacts && contacts.length > 0 ? (
                      (paginatdlistData.length > 0
                        ? paginatdlistData
                        : contacts
                      ).map((contact: any, contactIdx: number) => (
                        <tr
                          key={contactIdx}
                          onClick={
                            () => {}
                            //   onDetails(contact.data, contact.id)
                          }
                        >
                          <td
                            className={classNames(
                              contactIdx !== contacts.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            <input
                              id="default-checkbox"
                              type="checkbox"
                              checked={selectedContacts.some(
                                (selectItem) => selectItem.index === contactIdx
                              )}
                              onChange={(value) =>
                                handleChange(
                                  value.target.checked,
                                  contactIdx,
                                  contact.data
                                )
                              }
                              className="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 rounded cursor-pointer focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                          </td>
                          <td
                            className={classNames(
                              contactIdx !== contacts.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(contactIdx as number) + 1}
                          </td>
                          <td
                            className={classNames(
                              contactIdx !== contacts.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            <img
                              className="w-10 h-10 p-1 border rounded-full"
                              src={
                                contact.data.profilImageUrl
                                  ? contact.data.profilImageUrl
                                  : `https://ui-avatars.com/api/?background=random&name=${
                                      contact.data.displayName || "S"
                                    }`
                              }
                              alt=""
                            />
                          </td>
                          <td
                            className={classNames(
                              contactIdx !== contacts.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {contact.data.displayName as string}
                          </td>
                          <td
                            className={classNames(
                              contactIdx !== contacts.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {contact.data.phoneNumber as string}
                          </td>
                          <td
                            className={classNames(
                              contactIdx !== contacts.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {contact.data.email ?? "-"}
                          </td>
                          <td
                            className={classNames(
                              contactIdx !== contacts.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(contact.data.createdAt as Timestamp)
                              .toDate()
                              .toLocaleString()}
                          </td>
                          <td
                            className={classNames(
                              contactIdx !== contacts.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            <div className="flex gap-2">
                              <div
                                onClick={() => {
                                  navigate("/bulkPayment/payment", {
                                    state: { contacts: [contact.data] },
                                  });
                                }}
                                className="p-1 ml-1 text-red-600 bg-red-100 border border-transparent rounded-full cursor-pointer dark:bg-red-700 hover:bg-red-500 dark:hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-red-400 dark:text-red-200 hover:text-white"
                              >
                                <BanknotesIcon className="w-4 h-4 font-bold" />
                              </div>

                              <div className="flex items-center gap-1">
                                <div
                                  onClick={() =>
                                    navigate(
                                      `/bulkPayment/contacts/${contact.id}/edit`,
                                      {
                                        state: { entity: contact.data },
                                      }
                                    )
                                  }
                                  className="p-1 ml-1 text-red-600 bg-red-100 border border-transparent rounded-full cursor-pointer dark:bg-red-700 hover:bg-red-500 dark:hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-red-400 dark:text-red-200 hover:text-white"
                                >
                                  <PencilIcon className="w-4 h-4 font-bold hover:text-white" />
                                </div>
                                <div
                                  onClick={() => removeContact([contact.id])}
                                  className="p-1 ml-1 text-red-600 bg-red-100 border border-transparent rounded-full cursor-pointer dark:bg-red-700 hover:bg-red-500 dark:hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-1 focus:ring-red-400 dark:text-red-200 hover:text-white"
                                >
                                  <TrashIcon className="w-4 h-4 font-bold hover:text-white" />
                                </div>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoContent />
                    )}
                  </tbody>
                </table>
                {contacts && contacts.length > 0 && (
                  <Pagination
                    skip={contacts.length}
                    take={10}
                    total={0}
                    collectionName={contactsCollectionName}
                    isChangedPage={false}
                    onPageChange={(pageIndex: number) => pageIndex * page}
                    onPageLimitChange={(pageLimit: number) => {
                      onPageLimitChange(pageLimit);
                      setContactArrayLengthLimit(pageLimit);
                    }}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ContactsList;
