export interface GasStationInterface {
  id: string;
  displayName: string;
  email: string;
  adresse: string;
  city?: string;
  phoneNumber: string;
  password?: string;
  gasStationCode?: string;
  confirmPassword?: string;
  availableZone: string;
  lat: string;
  long: string;
  createdAt: Date;
  updatedAt: Date;
}

export interface GasStationAttendantInterface {
  id: string;
  name: string;
  phoneNumber: string;
  attendantRole: AttendantRole;
  createdAt: Date;
  updatedAt: Date;
}

export enum AttendantRole {
    MANAGER = "MANAGER",
    ATTENDANT = "ATTENDANT"
}
