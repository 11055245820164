import { db } from "../firebase";

class CrudServices {
  async save(data: any, collectionName: string) {
    await db
      .collection(collectionName)
      .doc(data.id)
      .set(data);
  }
  async saveSubCollection(data: any, parentCollectionName: string, parentCollectionId: string, subCollectionName: string) {
    await db
      .collection(parentCollectionName)
      .doc(parentCollectionId)
      .collection(subCollectionName)
      .doc(data.id).set(data);
  }
  async getAll(collectionName: string) {
    await db
      .collection(collectionName)
      .orderBy("createdAt", "desc")
      .get()
      .then((response) => {
        return response.docs.map((d) => {
          return { id: d.id, data: d.data() };
        });
      })
      .catch((e) => console.log(e));
  }

  async getById(id: string, collectionName: string) {
    await db
      .collection(collectionName)
      .doc(id)
      .get()
      .then((response) => {
        return response.data;
      })
      .catch((e) => console.log(e));
  }

  async update(id: string, collectionName: string, data: any) {
    await db
      .collection(collectionName)
      .doc(id)
      .update(data)
      .then((response) => {
        return response;
      })
      .catch((e) => console.log(e));
  }
  async delete(id: string, collectionName: string) {
    await db
      .collection(collectionName)
      .doc(id)
      .delete()
      .then((response) => {
        return response;
      })
      .catch((e) => console.log(e));
  }
}

// eslint-disable-next-line import/no-anonymous-default-export
export default new CrudServices();
