import { useCallback, useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  accountsCollectionName,
  cardLength,
  cardsCollectionName,
  defaultAmount,
  usersCollectionName,
} from "../../../config";
import { UserContext } from "../../../contexts";
import { CardType, TransactionType, UserRole } from "../../../interfaces";
import moment from "moment";
import { classNames, getTimestamp, parseDate } from "../../../utils";
import { db, functions } from "../../../firebase";
import {
  UserPlusIcon,
  FunnelIcon,
  CheckCircleIcon,
  XCircleIcon,
  CreditCardIcon,
  MagnifyingGlassIcon,
  QrCodeIcon,
} from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import HasRoles from "../../../components/HasRoles";
import Pagination from "../../../components/Pagination/Pagination";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import TableRowEditMenu from "../../../components/TableRowEditMenu/TableRowEditMenu";
import toast from "react-hot-toast";
import CodePinComponent from "../../../components/CodePinComponent";
import Modal from "../../../components/Modal";
import imageSrc from "../../../sankQrCodeImg.png";
import CardImage from "../../../340_x_200_1.png";
import { QRCodeCanvas } from "qrcode.react";
import ReadeQrcode from "../../../components/Qrcode/ReadeQrcode";

const tableHeader = [
  "N°",
  "",
  "Nom/prénom",
  "Email",
  "Ville",
  "Téléphone",
  "Role",
  "Agence",
  "Active",
  "Date de création",
  "Actions",
];

const UserAffiliatedAgentPages = () => {
  const [users, setUsers] = useState<any[]>([]);
  const navigate = useNavigate();
  const collectionName = usersCollectionName;
  const [paginatdlistData, setPaginatdListData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const { user, setUserAccounts, agentCreateUserParams } =
    useContext(UserContext);
  const userRole = UserRole.USER;
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [affiliatedUser, setAffiliatedUser] = useState<any>();
  const [amount, setAmount] = useState<string>("0");
  const [showModal, setShowModal] = useState(false);
  const [card, setCard] = useState<any[]>([]);
  const [cardId, setCardId] = useState("");

  const [showCardQrcodeReadeModal, setShowCardQrcodeReadeModal] =
    useState(false);
  const [isDataGet, setIsDataGet] = useState(false);
  const [qrcodeScannedData, setQrcodeScannedData] = useState<string[]>([]);

  const imageOptions = {
    src: imageSrc,
    x: undefined,
    y: undefined,
    height: 30,
    width: 30,
    excavate: true,
  };

  const userRef = db.collection(usersCollectionName);
  const senderAccountRef = userRef
    .doc(user?.id)
    .collection(accountsCollectionName);

  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithEndDateValue(value.target.value);
  };

  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );

  const [startTime, setStartTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const [endTime, setEndTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
  );

  const retriveUserData = useCallback(async () => {
    const userRef = db
      .collection(collectionName)
      .orderBy("createdAt", "desc")
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .where("userRole", "==", userRole)
      .where("affiliateAgentId", "==", user?.id);
    await userRef.get().then((snapshot) => {
      setUsers(
        snapshot.docs.map((d) => {
          return { id: d.id, data: d.data() };
        })
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endTime, startTime]);

  const getFilteredByDate = async () => {
    // const startTime = new Date(seachWithStartDateValue).getTime();
    const startTime = getTimestamp(seachWithStartDateValue, true);
    const endTime = getTimestamp(seachWithEndDateValue, false);
    setEndTime(endTime);
    setStartTime(startTime);
    retriveUserData();
  };

  useEffect(() => {
    retriveUserData();
  }, [retriveUserData]);

  const onPageLimitChange = async (pageLimit: number) => {
    const usersRef = db
      .collection(collectionName)
      .orderBy("createdAt", "desc")
      .where("userRole", "==", userRole)
      .limitToLast(pageLimit);
    await usersRef.get().then((response) => {
      const data = response.docs.map((d) => {
        return { id: d.id, data: d.data() };
      });
      setPaginatdListData(data);
      setPage(page + 1);
    });
  };

  const onFirstReload = async (entity: any) => {
    if (entity && !entity.data.firstReload) {
      setAmount(agentCreateUserParams?.minimum_deposit);
      setAffiliatedUser(entity.data);
      setShowPinCodeModal(true);
    } else {
      toast.error("La première recharge a été déjà effectuée.");
    }
  };
  const sankMoneyReloadMoneyFn = functions.httpsCallable(
    "sankMoneyReloadMoneyFn"
  );

  const onSubmit = async (pinCode: string) => {
    setIsLoad(true);
    if (amount < defaultAmount) {
      setShowPinCodeModal(false);
      return toast.error(
        `Le montant doit être suppérieur à ${defaultAmount} Fcfa`
      );
    }
    const data: any = {
      pinCode: pinCode,
      amount: amount?.replace(/\s/g, "").trim(),
      senderPhoneNumber: user?.phoneNumber,
      receiverPhoneNumber: affiliatedUser?.phoneNumber,
      description: "",
      transactionType: TransactionType.RELOAD,
      withConnection: true,
      currentUserId: user?.id,
    };
    await sankMoneyReloadMoneyFn(data)
      .then(async () => {
        setShowPinCodeModal(false);
        setIsLoad(false);
        const accounts = await senderAccountRef.get();
        setUserAccounts(accounts.docs.map((account) => account.data()));
        return toast.success("La recharge a été effectuée avec succès");
      })
      .catch((err: { details: any; message: any }) => {
        setShowPinCodeModal(false);
        setIsCodePinBtnDesabled(false);
        setIsLoad(false);
        toast.error(err.message);
      });
  };

  const retriveCardData = (cardId: string) => {
    db.collection(cardsCollectionName)
      .where("id", "==", cardId)
      .where("isAffected", "==", false)
      .get()
      .then((response) => {
        if (response.empty) {
          toast.error("La carte est indisponible ou déjà affecté.");
        } else {
          setCard(
            response.docs.map((doc) => {
              return { id: doc.id, data: doc.data() };
            })
          );
        }
      });
  };
  const handleChange = (value: string) => {
    setCardId(value);
  };

  const getsenderData = async () => {
    setShowCardQrcodeReadeModal(false);
    if (qrcodeScannedData.length > 0 && !isDataGet) {
      const cardId = qrcodeScannedData[0];
      await db
        .collection(cardsCollectionName)
        .doc(cardId)
        .get()
        .then(async (response) => {
          if (response.exists) {
            setIsDataGet(true);
            navigate(`/cards/${response.data()?.id}/assign-card`, {
              state: {
                entity: response.data(),
                id: response.data()?.id,
                isCardAffected: true,
              },
            });
          } else {
            toast.error("La carte est introuvable.");
          }
        });
    }
  };

  const onQrcodeResult = (result: any) => {
    setShowCardQrcodeReadeModal(false);
    if (result && !isDataGet) {
      setQrcodeScannedData(result);
      setShowCardQrcodeReadeModal(false);
      getsenderData();
    }
  };
  const onQrcodeError = (error: any) => {
    setShowCardQrcodeReadeModal(false);
  };

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="flex flex-col">
        <div className="">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les utilisateurs{" "}
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ci-dessous tous les utilisateurs affiliés à votre
            compte.
          </p>
          <p className="hidden text-xs font-medium text-red-500 md:inline-block">
            L'agent ne perçoit sa commission que si le client initie un premier
            dépôt directement chez l'agent qui à créer le compte
          </p>
        </div>
        <div className="h-8" />
        <div className="flex items-center self-end justify-between max-md:flex-col">
          <HasRoles userRole={["ADMIN", "AGENT"]}>
            <button
              type="button"
              className="my-auto relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
              onClick={() => navigate("/agent/users/new")}
            >
              <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                <UserPlusIcon
                  className="self-center flex-shrink-0 w-5 h-5 mr-1 text-orange-500"
                  aria-hidden="true"
                />
                Ajouter un utilisateur
              </span>
            </button>
          </HasRoles>
          <HasRoles userRole={["ADMIN", "AGENT"]}>
            <button
              type="button"
              className="my-auto relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
              onClick={() => setShowModal(true)}
            >
              <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                <CreditCardIcon
                  className="self-center flex-shrink-0 w-5 h-5 mr-1 text-orange-500"
                  aria-hidden="true"
                />
                Attribuer une carte
              </span>
            </button>
          </HasRoles>
          <div className="px-1 bg-white border border-gray-200 rounded-lg sm:flex-wrap md:flex-wrap lg:flex dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
            <div className="mr-1">
              <label
                htmlFor="dateDebut"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Date début
              </label>
              <input
                type="date"
                id="dateDebut"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                onChange={handleChangeStartDateValue}
              />
            </div>
            <div className="mr-1">
              <label
                htmlFor="dateFin"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Date fin
              </label>
              <input
                type="date"
                id="dateFin"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                onChange={handleChangeEndDtaeValue}
              />
            </div>
            <div className="flex items-center justify-center mt-5 h-14">
              <button
                type="button"
                className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={() => {
                  getFilteredByDate();
                }}
              >
                <FunnelIcon
                  className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                  aria-hidden="true"
                />
                Filtrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm">
              <table
                className="min-w-full border-separate"
                style={{ borderSpacing: 0 }}
              >
                <thead className="bg-gray-50">
                  <tr>
                    {tableHeader.map((th, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                      >
                        {th}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white">
                  {users && users.length > 0 ? (
                    (paginatdlistData.length > 0
                      ? paginatdlistData
                      : users
                    ).map((user: any, userIdx: number) => (
                      <tr key={userIdx}>
                        <td
                          className={classNames(
                            userIdx !== users.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {(userIdx as number) + 1}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== users.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          <img
                            className="w-10 h-10 p-1 border rounded-full"
                            src={
                              user.data?.profilImageUrl
                                ? user.data?.profilImageUrl
                                : `https://ui-avatars.com/api/?background=random&name=${
                                    user.data?.displayName || "S"
                                  }`
                            }
                            alt=""
                          />
                        </td>
                        <td
                          className={classNames(
                            userIdx !== users.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user.data.displayName ? user.data.displayName : "-"}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== users.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user.data.email ? user.data.email : "-"}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== users.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user.data.city ? user.data.city : "-"}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== users.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user.data.phoneNumber as string}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== users.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user.data.userRole as string}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== users.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user.data.userAgencySubRole ? "OUI" : "Non"}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== users.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {user.data.active ? (
                            <CheckCircleIcon className="w-6 h-6 text-green-500" />
                          ) : (
                            <XCircleIcon className="w-6 h-6 text-red-500" />
                          )}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== users.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {parseDate(user.data?.createdAt as Timestamp)}
                        </td>
                        <td
                          className={classNames(
                            userIdx !== users.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                          )}
                        >
                          <TableRowEditMenu
                            rowFirstReloadHandler={() => onFirstReload(user)}
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoContent />
                  )}
                </tbody>
              </table>
              {users && users.length > 0 && (
                <Pagination
                  skip={users.length}
                  take={users.length}
                  total={users.length}
                  collectionName={collectionName}
                  onPageChange={(pageIndex: number) => pageIndex * page}
                  onPageLimitChange={(pageLimit: number) =>
                    onPageLimitChange(pageLimit)
                  }
                  isChangedPage={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => setShowPinCodeModal(false)}
      >
        <div className="flex justify-center">
          <CodePinComponent
            onSubmit={onSubmit}
            disabled={isCodePinBtnDesabled}
            isLoad={isLoad}
          />
        </div>
      </Modal>
      <Modal
        isOpen={showModal}
        maxSize="md"
        closeModal={() => setShowModal(false)}
        modalTitle="Renseigner le numéro de la carte"
      >
        <div className="flex justify-center">
          <div className="w-full mt-10">
            <div className="">
              <div className="flex space-x-4">
                <input
                  type="text"
                  name="card"
                  id="card"
                  placeholder="3518112345678"
                  autoComplete="off"
                  autoFocus={true}
                  onChange={(e) => handleChange(e.target.value)}
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  required
                />
                <button
                  type="button"
                  className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  onClick={() => {
                    retriveCardData(cardId);
                  }}
                >
                  <MagnifyingGlassIcon
                    className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                    aria-hidden="true"
                  />
                  Rechercher
                </button>
              </div>

              <p className="my-3 font-light text-center">OU</p>

              <div className="">
                <button
                  type="submit"
                  className="relative space-x-4 inline-flex items-center justify-center  mb-2 mr-2 overflow-hidden bg-red-50 border border-red-300 text-red-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500  w-full p-2.5 dark:bg-red-700 dark:border-red-600 dark:placeholder-red-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  onClick={() => {
                    setShowModal(false);
                    setShowCardQrcodeReadeModal(true);
                  }}
                >
                  <QrCodeIcon
                    className="w-6 h-6 cursor-pointer text-red-red-500"
                    aria-hidden="true"
                  />
                  <div>Scanner le qrcode de la carte</div>
                </button>
              </div>
            </div>

            <div className="flex justify-center w-full">
              {card && card.length > 0 && (
                <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex flex-col items-center pb-10 mt-2">
                    <div
                      className="relative flex items-center justify-between w-full sm:w-[240px] sm:h-[150px] px-6 py-4 space-x-6 bg-cover rounded-md"
                      style={{
                        backgroundImage: `url(${CardImage})`,
                        width: "340px",
                        height: "200px",
                      }}
                    >
                      <div className="absolute top-0 left-0 m-2 text-sm font-semibold text-white">
                        {card[0].id}
                      </div>
                      <div className="absolute top-10 right-3">
                        <QRCodeCanvas
                          className="w-full"
                          id="qrCode"
                          value={`${card[0].id}`}
                          size={100}
                          level={"L"}
                          includeMargin={false}
                          bgColor="red"
                          fgColor="white"
                          imageSettings={imageOptions}
                        />
                      </div>
                    </div>
                    <div className="flex mt-4 space-x-3 md:mt-6">
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() =>
                          navigate(`/cards/${card[0].data.id}/assign-card`, {
                            state: {
                              entity: card[0].data,
                              id: card[0].id,
                              isCardAffected: true,
                            },
                          })
                        }
                      >
                        Continuer
                      </div>
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                        onClick={() => {
                          setShowModal(false);
                          setCard([]);
                        }}
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <ReadeQrcode
        onQrcodeResult={(result: any) => onQrcodeResult(result)}
        onQrcodeError={(error: any) => onQrcodeError(error)}
        showQrcodeReadeModal={showCardQrcodeReadeModal}
        setShowQrcodeReadeModal={setShowCardQrcodeReadeModal}
      />
    </div>
  );
};

export default UserAffiliatedAgentPages;
