import React, { useState } from "react";
import {
  ArrowPathIcon,
  CheckCircleIcon,
  UserGroupIcon,
  UserPlusIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import Moment from "react-moment";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../components/FirestoreTable";
import {
  sankmoneyRefillsAwaitingValidation,
  usersCollectionName,
} from "../../config";
import { db, functions } from "../../firebase";
import {
  SankMoneyReloadProcessStatusenum,
  sankMoneyReloadProcessStatusMap,
} from "../../interfaces";
import { useNavigate } from "react-router-dom";
import Modal from "../../components/Modal";
import toast from "react-hot-toast";
import { classNames } from "../../utils";

const columns: IColumn[] = [
  {
    field: "id",
    label: "ID de la transaction",
  },
  {
    field: "amount",
    label: "Montant",
  },
  {
    field: "description",
    label: "Description",
  },
  {
    field: "status",
    label: "Statut",
  },
  {
    field: "createdAt",
    label: "Date de création",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "id":
      return <span>{value}</span>;
    case "amount":
      return (
        <span>
          {new Intl.NumberFormat().format(parseInt(value ?? "0"))} FCFA
        </span>
      );
    case "description":
      return <span>{value}</span>;
    case "status":
      return (
        <>
          {value === SankMoneyReloadProcessStatusenum.PENDING ? (
            <span className="p-2 text-yellow-700 border border-yellow-300 rounded-md">
              {sankMoneyReloadProcessStatusMap.get(value)}
            </span>
          ) : value === SankMoneyReloadProcessStatusenum.VALIDETED ? (
            <span className="p-2 text-green-700 border border-green-300 rounded-md">
              {sankMoneyReloadProcessStatusMap.get(value)}
            </span>
          ) : (
            <span className="p-2 text-red-700 border border-red-300 rounded-md">
              {sankMoneyReloadProcessStatusMap.get(value)}
            </span>
          )}
        </>
      );
    default:
      return value as string;
  }
};

const ReloadSankMoneyAccountItemsPage = () => {
  const [
    showrefillsAwaitingValidationModal,
    setShowrefillsAwaitingValidationModal,
  ] = useState(false);
  const [refillsAwaitingValidation, setRefillsAwaitingValidation] =
    useState<any>();
  const [participantUsers, setParticipantUsers] = useState<any[]>([]);
  const navigate = useNavigate();

  const cancelledSankMoneyReloadAccountFn = functions.httpsCallable(
    "cancelledSankMoneyReloadAccountFn"
  );

  const onRowDetails = async (id: number, entity: any) => {
    setRefillsAwaitingValidation(entity);
    const participantUsers = [];
    for (const id of entity.participantIds) {
      const user = await db.collection(usersCollectionName).doc(id).get();
      if (user.exists) {
        participantUsers.push(user.data());
      }
    }
    setParticipantUsers(participantUsers);

    setShowrefillsAwaitingValidationModal(true);
  };

  const canceledRefillsAwaitingValidation = () => {
    cancelledSankMoneyReloadAccountFn(false);
    const response = cancelledSankMoneyReloadAccountFn({
      id: refillsAwaitingValidation?.id,
    }).then(() => {
      setShowrefillsAwaitingValidationModal(false);
    });
    toast.promise(response, {
      error: (error: any) => error.message,
      success: "La demande a été annulé avec succès.",
      loading: "chargement...",
    });
  };
  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les recharges du compte principal de Sank-Money
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ci-dessous tous les recharges du compte principal de
            Sank-Money.
          </p>
        </div>
        <div className="mt-2 sm:mt-0 sm:ml-2 sm:flex-none">
          <button
            type="button"
            className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-green-700 rounded-lg border border-green-300 bg-white hover:bg-green-50 focus:ring-4 focus:outline-none focus:ring-green-200 "
            onClick={() => navigate("/reload_sank_money_account/new")}
          >
            <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
              <ArrowPathIcon
                className="self-center flex-shrink-0 w-5 h-5 mr-1 text-green-500"
                aria-hidden="true"
              />
              Initier une recharger
            </span>
          </button>
        </div>
        <div className="mt-2 sm:mt-0 sm:ml-2 sm:flex-none">
          <button
            type="button"
            className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-red-200 "
            onClick={() => navigate("/reload_sank_money_account/add_reviewer")}
          >
            <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
              <UserGroupIcon
                className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                aria-hidden="true"
              />
              Les validateurs
            </span>
          </button>
        </div>
      </div>
      <FirestoreTable
        collectionRef={db.collection(sankmoneyRefillsAwaitingValidation)}
        condensed
        columns={columns}
        rowRender={renderRow}
        // rowEditHandler={onEditRow}
        // rowDeleteHandler={onDeleteRow}
        rowDetailsHandler={onRowDetails}
        displayDetails
        shouldAddEditColumn
        // displayEditBtn
        paginate={{ pageIndex: 0, limit: 100, sort: "createdAt:desc" }}
      />
      <Modal
        isOpen={showrefillsAwaitingValidationModal}
        maxSize="md"
        closeModal={() => setShowrefillsAwaitingValidationModal(false)}
        modalTitle={`Detail de la validation : ${refillsAwaitingValidation?.id}`}
      >
        <div className="p-2 my-4 bg-white border rounded-lg shadow-sm">
          <div className="">
            <ul className="-mb-8">
              <li>
                <div className="pb-8 ">
                  <div className="flex ">
                    <div>
                      <span className="flex items-center justify-center w-6 h-6 rounded-full ring-8 ring-white dark:ring-slate-800"></span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-500">
                          <span className="font-semibold text-gray-700">
                            Détails de la Recharge
                          </span>{" "}
                        </p>
                        <p className="mt-2 text-sm text-gray-500">
                          <span className="font-bold text-gray-700 dark:text-gray-400">
                            Compte :
                          </span>{" "}
                          <span className="text-sm text-gray-900 dark:text-gray-300">
                            compte principal Sank-Money
                          </span>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-bold text-gray-700 dark:text-gray-400">
                            Montant de la Recharge :
                          </span>{" "}
                          <span className="text-sm text-gray-900 dark:text-gray-300">
                            {new Intl.NumberFormat().format(
                              parseInt(refillsAwaitingValidation?.amount ?? "0")
                            )}{" "}
                            FCFA
                          </span>
                        </p>
                        <p className="mt-1 text-sm text-gray-500">
                          <span className="font-bold text-gray-700 dark:text-gray-400">
                            Date et Heure de la Demande :
                          </span>{" "}
                          <span className="text-sm text-gray-900 dark:text-gray-300">
                            <Moment
                              locale="fr"
                              format="dddd DD MMMM YYYY à HH:mm:ss"
                            >
                              {
                                refillsAwaitingValidation?.createdAt.toDate() as Date
                              }
                            </Moment>
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="pb-8 ">
                  <div className="flex ">
                    <div>
                      <span className="flex items-center justify-center w-6 h-6 rounded-full ring-8 ring-white dark:ring-slate-800"></span>
                    </div>
                    <div className="min-w-0 flex-1 pt-1.5 flex justify-between space-x-4">
                      <div>
                        <p className="text-sm text-gray-500">
                          <span className="font-semibold text-gray-700">
                            Les validateurs
                          </span>{" "}
                        </p>
                        {participantUsers.length > 0 &&
                          participantUsers.map((user, index) => (
                            <p
                              key={index}
                              className="flex justify-between mt-1 text-sm text-gray-500"
                            >
                              <span className="font-bold text-gray-700 dark:text-gray-400">
                                {user?.phoneNumber}
                              </span>{" "}
                              <span className="items-center ml-5 text-sm text-gray-900 dark:text-gray-300">
                                {refillsAwaitingValidation[`${user?.id}`] ? (
                                  <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                ) : (
                                  <XCircleIcon className="w-6 h-6 text-red-500" />
                                )}
                              </span>
                            </p>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
        <div className="flex justify-end">
          {" "}
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className={classNames(
                refillsAwaitingValidation?.status ===
                  SankMoneyReloadProcessStatusenum.CANCELLED
                  ? "text-gray-700 border-gray-300 focus:ring-gray-200 hover:bg-gray-50 cursor-not-allowed"
                  : "text-red-700 border-red-300 focus:ring-red-200 hover:bg-red-50",
                "relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium  rounded-lg border bg-white focus:ring-4 focus:outline-none"
              )}
              onClick={() => {
                canceledRefillsAwaitingValidation();
              }}
              disabled={
                refillsAwaitingValidation?.status ===
                SankMoneyReloadProcessStatusenum.CANCELLED
                  ? true
                  : false
              }
            >
              <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                Annuler la demande
              </span>
            </button>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default ReloadSankMoneyAccountItemsPage;
