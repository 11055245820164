/* eslint-disable jsx-a11y/no-redundant-roles */
  import { useContext, useEffect, useState } from "react";
  import { db, functions } from "../../../../firebase";
  import { useNavigate, useLocation } from "react-router-dom";
  import { UserContext } from "../../../../contexts";
  import toast from "react-hot-toast";
  import { UserInterface, UserRole } from "../../../../interfaces";
  import {
    Form,
    FormInput,
  } from "../../../../components/FormComponents";
  import { yupResolver } from "@hookform/resolvers/yup";
  import * as yup from "yup";
  import { organizersCollections } from "../../../../config";
  
  
  type TicketAttendantInfos = Pick<
    UserInterface,
    | "displayName"
    | "email"
    | "phoneNumber"
  >;
  
  const OrganizerProfileEditPage = () => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const location = useLocation();
    const organizer = location.state?.entity;
    const eventId = location.state?.eventId
  
    const sankMoneyUpdateOrganizerProfile = functions.httpsCallable(
      "sankMoneyUpdateOrganizerProfile"
    );
  
    const onSubmitDefaultInfos = async (data: any) => {
      if (user) {
        const response = sankMoneyUpdateOrganizerProfile({
          collectionName: organizersCollections,
          documentData: {
              name: data.displayName ?? organizer?.name,
              email: data.email ?? organizer?.email,
              phoneNumber: data.phoneNumber ?? organizer?.phoneNumber,
          },
          documentId: organizer?.id,
      }).then(()=>{
        navigate(`/billetterie/events/${eventId}/detail`,
          {
            state: { eventId: eventId },
          }
        );
      })
      toast.promise(response, {
          error: "La mise à jour a échoué",
          success: "Les informations de l'organisateur ont été mis a jour avec succès",
          loading: "Mise a jour...",
        });
        }
    };
  
    return (
      <>
        <div className="py-2 border-b border-gray-200 dark:border-slate-700">
          {/* <h2 className="text-lg font-semibold leading-6 text-gray-900 dark:text-gray-300">
            Evenements
          </h2>
          <p className="mt-1 text-base text-gray-500">
            Editer un evenement
          </p> */}
        </div>
        <div className="flex mx-5 mt-4 max-md:block max-md:space-x-0 max-md:space-y-10 max-md:mx-5">
            <div className="w-full">
            <div className="flex flex-col w-full">
                <div className="flex justify-center w-full">
                <div className="w-full max-w-lg px-2 py-8 sm:px-0">
                    <div className="flex items-center justify-center w-full font-manrope">
                    <div className="box-border w-full p-4 mx-auto bg-white border">
                        <Form<TicketAttendantInfos>
                        maximizeFormWidh={false}
                        formReturnAware={()=>{}}
                        onSubmit={onSubmitDefaultInfos}
                        submitButtonLabel={"Enregistrer"}
                        form={{
                            resolver: yupResolver(
                            yup.object().shape({
                                displayName: yup.string().required("Le montant est obligatoire!"),
                                phoneNumber: yup.string().required("Le montant est obligatoire!"),
                                email: yup.string().required("Le montant est obligatoire!"),
                            })
                            ),
                            defaultValues: {
                                displayName: organizer ? organizer?.name : "",
                                phoneNumber: organizer ? organizer?.phoneNumber : "",
                                email: organizer ? organizer?.email : ""
                            },
                        }}
                        >
                        <div className="mt-6">
                            <div className="font-semibold text-lg">
                            L' organisateur
                            </div>
                            <div>
                            <FormInput
                                required
                                type="text"
                                name="displayName"
                                label={`Nom/Prénom`}
                                placeholder=""
                            />
                            </div>
                        </div>
                        <div className="mt-6">
                            <FormInput
                            type="text"
                            label="Numero"
                            name="phoneNumber"
                            />
                        </div>
                        <div className="mt-6">
                            <FormInput
                            type="email"
                            label="Email"
                            name="email"
                            />
                        </div>                       
                        </Form>
                    </div>
                    </div>
                </div>
                </div>
            </div>
            </div>
        </div>
      </>
    );
  };
  
  export default OrganizerProfileEditPage;
  