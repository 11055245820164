
const AvatarInput = (props: {
  imageUrl?: string;
  displayName?: string;
  handleChange: (value: any) => void;
  inputLabel: any;
}) => {
  const { imageUrl, handleChange, inputLabel, displayName } = props;
  return (
    <div className="py-3 mx-auto mt-20">
      <div className="px-4 py-5 text-center w-80 ">
        <div className="flex justify-center mb-4">
          {imageUrl ? (
            <img
              className="object-cover object-center w-48 h-48 mx-auto rounded-full"
              src={imageUrl}
              alt={imageUrl}
            />
          ) : (
            <img
              className="w-48 h-48 rounded-full"
              src={`https://ui-avatars.com/api/?background=random&name=${
                displayName || "S"
              }`}
              alt={displayName}
            />
          )}
        </div>
        <label className="mt-6 cursor-pointer">
          <span className="px-4 py-2 mt-2 text-base leading-normal text-white bg-blue-500 rounded-full">
            {inputLabel}
          </span>
          <input type="file" name="image" hidden onChange={handleChange} />
        </label>
      </div>
    </div>
  );
};

export default AvatarInput;
