export interface CompanyInterface {
  id: string;
  displayName: string;
  email: string;
  adresse: string;
  city?: string;
  phoneNumber: string;
  availableZone: string;
  password?: string;
  confirmPassword?: string;
  lat: string;
  long: string;
  createdAt: Date;
  updatedAt: Date;
}

export enum CompanyCollaboratorRole {
  MANAGER = "MANAGER",
  COLLABORATOR = "COLLABORATOR",
  ATTENDANT = "ATTENDANT",
}

export const CompanyCollabRoleMap = new Map<string, string>([
  ["MANAGER", "Manager"],
  ["COLLABORATOR", "Collaborateur"],
]);
