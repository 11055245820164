import { BanknotesIcon, BookOpenIcon } from '@heroicons/react/24/outline';
import React from 'react'
import { NavLink, Outlet } from 'react-router-dom';
import { classNames } from '../../../../utils';

const AdminManageCommission = () => {
  return (
    <div className="bg-white relativ">
      <div className="divide-y lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
        <aside className="lg:col-span-2 xl:col-span-2">
          <nav className="">
            {/* <NavLink
              to={{
                pathname: "paid_commission",
              }}
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? "bg-slate-300 text-red-500"
                    : "text-black hover:bg-gray hover:text-red-200",
                  "group w-full p-3 flex text-md font-medium"
                )
              }
            >
              {({ isActive }) => (
                <>
                  <BanknotesIcon
                    className={classNames(
                      isActive
                        ? "text-red-500"
                        : "text-black group-hover:text-red-200",
                      "mr-3 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  <span className="">Payement</span>
                </>
              )}
            </NavLink> */}
            <NavLink
              to={{
                pathname: "paid_commission_history",
              }}
              className={({ isActive }) =>
                classNames(
                  isActive
                    ? "bg-slate-300 text-red-500"
                    : "text-black hover:bg-gray hover:text-red-200",
                  "group w-full p-3 flex text-md font-medium"
                )
              }
            >
              {({ isActive }) => (
                <>
                  <BookOpenIcon
                    className={classNames(
                      isActive
                        ? "text-red-500"
                        : "text-black group-hover:text-red-200",
                      "mr-3 h-6 w-6"
                    )}
                    aria-hidden="true"
                  />
                  <span className="">Historique des commissions payées</span>
                </>
              )}
            </NavLink>
          </nav>
        </aside>
        <div className="divide-y lg:col-span-9 xl:col-span-10">
          <Outlet />
        </div>
      </div>
    </div>
  );
}

export default AdminManageCommission
