import React from "react";
import { classNames } from "../../utils";

export type DashboardReportProps = {
  title: string;
  value: string | React.ReactNode;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  iconClasses?: string;
  valueClasses?: string;
  titleClasses?: string;
  className?: string;
};

export const DashboardReport = ({
  title,
  value,
  leftIcon,
  rightIcon,
  iconClasses,
  valueClasses,
  titleClasses,
  className,
}: DashboardReportProps) => {
  return (
    <>
      <div
        className={classNames(
          className ?? "",
          "flex p-2 min-w-[200px] overflow-hidden bg-white rounded-lg border shadow"
        )}
      >
        {leftIcon && (
          <div
            className={classNames(
              iconClasses ?? "",
              "p-2 mr-4 max-md:p-2 bg-red-500 rounded-md my-auto mx-auto"
            )}
          >
            {leftIcon}
          </div>
        )}
        <div className={classNames("w-full px-2", leftIcon ? "" : "")}>
          <p
            className={classNames(
              titleClasses ?? "",
              "text-sm font-sans text-gray-600 truncate"
            )}
          >
            {title}
          </p>
          <div className="flex items-baseline">
            <span
              className={classNames(
                valueClasses ?? "",
                "text-sm font-sans font-semibold text-gray-600 truncate"
              )}
            >
              {value}
            </span>
          </div>
        </div>
        {rightIcon && (
          <div
            className={classNames(
              iconClasses ?? "",
              "p-2 ml-4 max-md:p-2 bg-red-500 rounded-md my-auto mx-auto"
            )}
          >
            {rightIcon}
          </div>
        )}
      </div>
    </>
  );
};
