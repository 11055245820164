import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { accountMap, transactionMap, TransactionType } from "../../../interfaces";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import { Timestamp } from "firebase-admin/firestore";
import { classNames, getTimestamp } from "../../../utils";
import { BoltIcon, CheckCircleIcon, FunnelIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { db } from "../../../firebase";
import { transactionCollectionName } from "../../../config";
import SankMoneyIcon from "../../../CFA.png";
import Pagination from "../../../components/Pagination/Pagination";
import moment from "moment";

const tableHeader = [
  "N°",
  "",
  "Nom d'expediteur",
  "Numéro d'expediteur",
  "Montant",
  "Type de transaction",
  "Compte",
  "Frais",
  "Status",
  "La campagne",
  "Date de création",
];

const TransactionList = () => {
  const location = useLocation();
  const currentUser = location.state?.entity;
  const currentCompany = location.state?.user;
  const [transactions, setTransactions] = useState<any[]>([]);
  const [transactionTotalSum, setTransactionTotalSum] = useState<number>(0);
  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );

  const transactionType = [
    TransactionType.TRANSFER,
    TransactionType.RELOAD,
  ]

  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    const date = new Date(value.target.value);

    function subtractMonths(date: Date, months: number) {
      date.setMonth(date.getMonth() - months);

      return date;
    }

    const limitDate = subtractMonths(new Date(), 12);

    if (date.getTime() < limitDate.getTime()) {
      return setSetSeachWithStartDateValue(
        moment(limitDate).format("YYYY-MM-DD")
      );
    }

    setSetSeachWithStartDateValue(moment(date).format("YYYY-MM-DD"));
  };

  const handleChangeEndDateValue = (value: any) => {
    value.preventDefault();
    const date = new Date(value.target.value);

    function subtractMonths(date: Date, months: number) {
      date.setMonth(date.getMonth() - months);
      return date;
    }

    const limitDate = subtractMonths(new Date(), 12);

    if (date.getTime() < limitDate.getTime()) {
      return setSetSeachWithEndDateValue(
        moment(limitDate).format("YYYY-MM-DD")
      );
    } else if (date.getTime() > new Date().getTime()) {
      return setSetSeachWithEndDateValue(
        moment(new Date()).format("YYYY-MM-DD")
      );
    }

    setSetSeachWithEndDateValue(moment(date).format("YYYY-MM-DD"));
  };

  const getUserAllHistories = useCallback(async () => {
    const startTime = getTimestamp(seachWithStartDateValue, true);
    const endTime = getTimestamp(seachWithEndDateValue, false);
    await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("participantsIds", "array-contains", currentUser?.id)
      .where("senderID", "==", currentCompany?.id)
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .get()
      .then((response) => {
        setTransactions(
          response.docs.map((d) => {
            return { id: d.id, data: d.data() };
          })
        );
        let sum = 0;
        const amount = response.docs.reduce(
          (accumulator, currentValue) =>
            accumulator + parseInt(currentValue.data().amount),
          sum
        );
        setTransactionTotalSum(amount);
      });
  }, [currentCompany?.id, currentUser?.id, seachWithEndDateValue, seachWithStartDateValue]);

  const paginationPage = useCallback(async (pageLimit: number) => {
    const startTime = getTimestamp(seachWithStartDateValue, true);
    const endTime = getTimestamp(seachWithEndDateValue, false);
    await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("participantsIds", "array-contains", currentUser?.id)
      .where("senderID", "==", currentCompany?.id)
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .limit(pageLimit)
      .get()
      .then((response) => {
        setTransactions(
          response.docs.map((d) => {
            return { id: d.id, data: d.data() };
          })
        );
        let sum = 0;
        const amount = response.docs.reduce(
          (accumulator, currentValue) =>
            accumulator + parseInt(currentValue.data().amount),
          sum
        );
        setTransactionTotalSum(amount);
      });
  }, [currentCompany?.id, currentUser?.id, seachWithEndDateValue, seachWithStartDateValue]);

  const getFilteredTransactionType = async (option: TransactionType)=>{
    const startTime = getTimestamp(seachWithStartDateValue, true);
    const endTime = getTimestamp(seachWithEndDateValue, false);
    const getAllTransRef = db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("transactionType", "==", option ?? TransactionType.TRANSFER)
      .where("participantsIds", "array-contains", currentUser?.id)
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .limit(100);
    await getAllTransRef.get().then((trans)=>{
      setTransactions(
        trans.docs.map((d) => {
          return { id: d.id, data: d.data() };
        })
      );
      let sum = 0;
      const amount = trans.docs.reduce(
        (accumulator, currentValue) =>
          accumulator + parseInt(currentValue.data().amount),
        sum
      );
      setTransactionTotalSum(amount);
    })
  };

  useEffect(() => {
    if (currentUser && currentCompany) {
      getUserAllHistories();
    }
  }, [])

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="flex flex-col">
        <div className="">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Mes historiques de transactions{" "}
            <span className="hidden text-xs font-medium text-red-500 md:inline-block">
              (
              {`${new Intl.NumberFormat().format(transactionTotalSum)}
                            Fcfa`}
              )
            </span>
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ici l'ensemble des transactions reçu par{" "}
            <span className="hidden text-xs font-medium text-blue-500 md:inline-block">
              (
              {" " + currentUser?.displayName}
              )
            </span>
          </p>
        </div>
        <div className="h-4" />
        <div className="flex self-end justify-between max-md:flex-col">
          <div className="px-1 bg-white border border-gray-200 rounded-lg sm:flex-wrap md:flex-wrap lg:flex dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
            <div className="mr-1 sm:mr-1 lg:mr-1">
              <label
                htmlFor="transType"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Type de transaction
              </label>
              <select
                id="transType"
                name="currency"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
              onChange={(e) => {
                getFilteredTransactionType(
                  e.target.value as TransactionType
                );
              }}
              >
                {transactionType.map((opt, index) => (
                  <option key={index} value={opt}>
                    {transactionMap.get(opt)}
                  </option>
                ))}
              </select>
            </div>
            <div className="mr-1">
              <label
                htmlFor="dateDebut"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Date début
              </label>
              <input
                type="date"
                id="dateDebut"
                value={seachWithStartDateValue}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                // defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                onChange={handleChangeStartDateValue}
              />
            </div>
            <div className="mr-1">
              <label
                htmlFor="dateFin"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Date fin
              </label>
              <input
                type="date"
                id="dateFin"
                value={seachWithEndDateValue}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                // defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                onChange={handleChangeEndDateValue}
              />
            </div>
            <div className="flex items-center mt-5 h-14">
              <button
                type="button"
                className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={() => {
                  getUserAllHistories();
                }}
              >
                <FunnelIcon
                  className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                  aria-hidden="true"
                />
                Filtrer
              </button>
            </div>
          </div>
        </div>
        <div className="h-6" />
        <div className="flex flex-col mt-8">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm">
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      {tableHeader.map((th, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                        >
                          {th}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {transactions && transactions.length > 0 ? (
                      transactions.map((history: any, historyIdx: number) => (
                        <tr key={historyIdx}>
                          <td
                            className={classNames(
                              historyIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(historyIdx as number) + 1}
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {
                              <img
                                className="w-10 h-10 p-1 border rounded-full"
                                src={
                                  history.data.senderProfilImageUrl
                                    ? history.data.senderProfilImageUrl
                                    : `https://ui-avatars.com/api/?background=random&name=${history.data.senderDisplayName || "S"
                                    }`
                                }
                                alt=""
                              />
                            }
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {history.data.senderDisplayName as string}
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {history.data.senderPhoneNumber as string}
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {new Intl.NumberFormat().format(
                              history.data.amount
                            )}{" "}
                            Fcfa
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {history.data.transactionType ?? "-"}
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {history.data.accountType === "MAIN" ? (
                              <img
                                className="w-auto h-5"
                                src={SankMoneyIcon}
                                alt="Sank"
                              />
                            ) : (
                              // <CurrencyDollarIcon className="w-6 h-6 text-red-500" />
                              <BoltIcon className="w-6 h-6 text-red-500" />
                            )}
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {history.data.fees as string} Fcfa
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {history.data.isComplete ? (
                              <CheckCircleIcon className="w-6 h-6 text-green-500" />
                            ) : (
                              <XCircleIcon className="w-6 h-6 text-red-500" />
                            )}
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm relative group text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            <span className="block truncate max-w-xs">
                              {history.data.description ?? "-" as string}
                            </span>
                            <span className="absolute hidden group-hover:block bg-gray-700 text-white text-sm p-2 text-wrap rounded shadow-lg max-w-2xl -top-10 left-0 z-10">
                              {history.data.description ?? "-" as string}
                            </span>
                          </td>
                          <td
                            className={classNames(
                              historyIdx !== transactions.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(history.data.createdAt as Timestamp)
                              .toDate()
                              .toLocaleString()}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoContent />
                    )}
                  </tbody>
                </table>
                <Pagination
                  skip={transactions.length}
                  take={transactions.length}
                  total={transactions.length}
                  collectionName={transactionCollectionName}
                  isChangedPage={true}
                  onPageChange={(pageIndex: number) => pageIndex}
                  onPageLimitChange={(pageLimit: number) =>
                    paginationPage(pageLimit)
                  }
                  balance={transactionTotalSum}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
export default TransactionList;