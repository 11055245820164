import { CalendarDaysIcon } from "@heroicons/react/24/outline";
import HasRoles from "../../../components/HasRoles";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../../utils";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import Pagination from "../../../components/Pagination/Pagination";
import { useCallback, useContext, useEffect, useState } from "react";
import TableRowEditMenu from "../../../components/TableRowEditMenu/TableRowEditMenu";
import { eventsCollections } from "../../../config";
import { db, functions } from "../../../firebase";
import { UserContext } from "../../../contexts";
import toast from "react-hot-toast";
import { EventsStatus } from "../../../interfaces/ticket.interface";
import { useConfirm } from "../../../hooks/useConfirm";

const tableHeader = [
  "N°",
  "Evenements",
  "Lieu",
  "Nombre de place",
  "Ville",
  "Marchants",
  "Categorie",
  "Status",
  "Etat",
  "Actions",
];

const AdminEventsPage = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState<any[]>([]);
  const [retrieveBilletriesLimit, setRetrieveBilletriesLimit] = useState(100);
  const { user } = useContext(UserContext);
  const { isConfirmModal } = useConfirm();
  const [isLoading, setIsLoading] = useState(true);

  const getAllEvents = useCallback(async () => {
    setIsLoading(true);
    const eventsSnap = await db
      .collection(eventsCollections)
      .limit(retrieveBilletriesLimit)
      .get();

    const events: any[] = [];

    for (const d of eventsSnap.docs) {
      const categoryRef = (await d.data()?.categoryRef.get()).data();
      const merchantRef = (await d.data()?.merchantRef.get()).data();
      const dt = d.data();

      events.push({
        id: d.id,
        data: {
          title: dt.title,
          locationName: dt.locationName,
          maxCapacity: dt.maxCapacity,
          city: dt.city,
          merchant: merchantRef.displayName,
          category: categoryRef.categoryType,
          status: dt.status,
          active: dt.active,
          eventEndDate: dt.eventEndDate,
        },
      });
    }

    setEvents(events);
    setIsLoading(false);
  }, [retrieveBilletriesLimit]);

  const sankMoneyUpdateEventsFn = functions.httpsCallable(
    "sankMoneyUpdateEventsFn"
  );

  const onBlockedEvent = async (id: string, status: string) => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir bloquer cet evenement ?",
      "Oui blocquer",
      true
    );

    if (isConfirm) {
      if (status === EventsStatus.BLOCKED) {
        toast.error("L' evenement a déja été blocqué");
        navigate(`/events`);
      } else {
        if (status === EventsStatus.PENDING) {
          toast.error(`L' evenement ne peut être blocqué.`);
        } else {
          const response = sankMoneyUpdateEventsFn({
            collectionName: eventsCollections,
            documentData: {
              status: EventsStatus.BLOCKED,
            },
            documentId: id,
          }).then(() => {
            navigate(`/events`);
          });
          toast.promise(response, {
            loading: "Blocage...",
            success: "L'evenement a été bloqué avec succès.",
            error: "Échec ! Veuillez réessayer.",
          });
        }
      }
    }
  };

  const unLockedEvent = async (id: string, status: string) => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir débloquer cet evenement ?",
      "Oui déblocquer",
      true
    );

    if (isConfirm) {
      if (status === EventsStatus.UNLOCKED) {
        toast.error("L' evenement a déja été débloqué");
        navigate(`/events`);
      } else {
        if (status !== EventsStatus.BLOCKED) {
          toast.error(`L' evenement ne peut être débloqué`);
        } else {
          const response = sankMoneyUpdateEventsFn({
            collectionName: eventsCollections,
            documentData: {
              status: EventsStatus.UNLOCKED,
            },
            documentId: id,
          }).then(() => {
            navigate(`/events`);
          });
          toast.promise(response, {
            loading: "Déblocage...",
            success: "L'evenement a été débloqué avec succès.",
            error: "Échec ! Veuillez réessayer.",
          });
        }
      }
    }
  };

  const validatedEvent = async (id: string, status: string) => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir Valider cet evenement ?",
      "Oui valider",
      true
    );

    if (isConfirm) {
      if (status === EventsStatus.VALIDATED) {
        toast.error("L' evenement a déja été validé");
        navigate(`/events`);
      } else {
        if (status !== EventsStatus.PENDING) {
          toast.error(`L' evenement ne peut être validé.`);
        } else {
          const response = sankMoneyUpdateEventsFn({
            collectionName: eventsCollections,
            documentData: {
              status: EventsStatus.VALIDATED,
            },
            documentId: id,
          }).then(() => {
            navigate(`/events`);
          });
          toast.promise(response, {
            loading: "Validation...",
            success: "L'evenement a été validé avec succès.",
            error: "Échec ! Veuillez réessayer.",
          });
        }
      }
    }
  };

  const onEventDetails = useCallback(
    (row: any, id: string) => {
      if (row) {
        navigate(`/billetterie/events/${id}/detail`, {
          state: { eventId: id },
        });
      }
    },
    [navigate]
  );

  // const onTicketDetails = useCallback((row: any, id: string) => {
  //   if (row) {
  //     navigate(`/billetterie/events/${id}/detail_ticket`,
  //       {
  //         state: { eventId: id },
  //       }
  //     );
  //   }
  // }, [navigate])

  useEffect(() => {
    getAllEvents();
  }, [getAllEvents]);

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="flex flex-col">
        <div className="">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les evènements
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ici l'ensemble des evènements.
          </p>
        </div>
      </div>
      <div className="h-6" />
      <div className="flex self-end justify-end max-md:flex-col">
        <HasRoles userRole={["MERCHANT"]}>
          <>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
                onClick={(e) => {
                  navigate("/billetterie/events/new_event");
                }}
              >
                <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                  <CalendarDaysIcon
                    className="self-center flex-shrink-0 w-5 h-5 mr-1 text-orange-500"
                    aria-hidden="true"
                  />
                  Ajouter un evènement
                </span>
              </button>
            </div>
          </>
        </HasRoles>
      </div>
      <div className="flex flex-col mt-8">
      {
        isLoading ? (
          <>
            <div className="p-4 animate-pulse">
            <div className="h-4 mt-3 mb-6 bg-gray-400 rounded"></div>
            {[...Array(5)].map((e, i) => (
              <div key={i}>
                <div className="h-4 mb-6 bg-gray-200 rounded"></div>
                <div className="h-4 mb-6 bg-gray-300 rounded"></div>
              </div>
            ))}
          </div>
          </>
        ) : (
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm">
              <table
                className="min-w-full border-separate"
                style={{ borderSpacing: 0 }}
              >
                <thead className="bg-gray-50">
                  <tr>
                    {tableHeader.map((th, index) => (
                      <th
                        key={index}
                        scope="col"
                        className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                      >
                        {th}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {events && events.length > 0 ? (
                    events.map((event: any, eventIdx: number) => (
                      <tr
                        key={eventIdx}
                        className={
                          eventIdx % 2 === 0
                            ? "bg-white"
                            : "bg-gray-100 cursor-pointer"
                        }
                        // onClick={() =>
                        //   onDetails(transaction.data, transaction.id)
                        // }
                      >
                        <td
                          className={classNames(
                            eventIdx !== events.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {(eventIdx as number) + 1}
                        </td>
                        <td
                          className={classNames(
                            eventIdx !== events.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {event.data?.title ?? ("-" as string)}
                        </td>
                        <td
                          className={classNames(
                            eventIdx !== events.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {event.data?.locationName ?? ("-" as string)}
                        </td>
                        <td
                          className={classNames(
                            eventIdx !== events.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {parseInt(event.data?.maxCapacity) ?? (0 as number)}
                        </td>

                        <td
                          className={classNames(
                            eventIdx !== events.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {event.data?.city ?? "-"}
                        </td>
                        <td
                          className={classNames(
                            eventIdx !== events.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {event.data.merchant ?? "-"}
                        </td>
                        <td
                          className={classNames(
                            eventIdx !== events.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          {event.data.category ?? "-"}
                        </td>
                        <td
                          className={classNames(
                            eventIdx !== events.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            `whitespace-nowrap py-4 pl-4 pr-3 text-sm ${
                              event.data?.status === EventsStatus.BLOCKED
                                ? "text-red-500"
                                : event.data?.status === EventsStatus.PENDING
                                ? "text-orange-500"
                                : "text-green-500"
                            }  sm:pl-6 lg:pl-8`
                          )}
                        >
                          {event.data?.status ?? "-"}
                        </td>
                        <td
                          className={classNames(
                            eventIdx !== events.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            `whitespace-nowrap py-4 pl-4 pr-3 text-sm ${
                              (new Date(event.data?.eventEndDate.toDate()) < new Date())
                                ? "text-sky-700"
                                : "text-red-700"
                            }  sm:pl-6 lg:pl-8`
                          )}
                        >
                          {(new Date(event.data?.eventEndDate.toDate()) < new Date()) ? "Encours.." : "Terminé"}
                        </td>
                        <td
                          className={classNames(
                            eventIdx !== events.length - 1
                              ? "border-b border-gray-200"
                              : "",
                            "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                          )}
                        >
                          <TableRowEditMenu                 
                            rowBlockedEventHandler={() =>
                              onBlockedEvent(event?.id, event.data?.status)
                            }
                            rowDeblockedEventHandler={() =>
                              unLockedEvent(event?.id, event.data?.status)
                            }
                            rowValidatedEventHandler={() =>
                              validatedEvent(event?.id, event.data?.status)
                            }
                            rowDetailsHandler={() =>
                              onEventDetails(event.data, event?.id)
                            }
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <NoContent />
                  )}
                </tbody>
              </table>
              <Pagination
                skip={events.length}
                take={events.length}
                total={events.length}
                collectionName={eventsCollections}
                isChangedPage={true}
                onPageChange={(pageIndex: number) => pageIndex}
                onPageLimitChange={(limit) => setRetrieveBilletriesLimit(limit)}
                // balance={transactionTotalSum}
              />
            </div>
          </div>
        </div>
        )
      }
        
      </div>
    </div>
  );
};
export default AdminEventsPage;
