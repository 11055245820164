/* eslint-disable jsx-a11y/no-redundant-roles */
import { useContext, useEffect, useState, useCallback, useRef } from "react";
import { db, functions, storage } from "../../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../contexts";
import { eventsCollections } from "../../config";
import {
    categorieCollections
} from "../../config";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import toast from "react-hot-toast";
import { TicketInterface } from "../../interfaces/ticket.interface"
import {
    Form,
    FormInput,
} from "../../components/FormComponents";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import moment from "moment";


type TicketAttendantInfos = Pick<
    TicketInterface,
    | "title"
    | "eventStartTime"
    | "eventEndTime"
    | "adresse"
    | "city"
    | "numberOfTicket"
    | "lat"
    | "long"
    | "saleStartDate"
    | "saleEndDate"
    | "availableQuantity"
    | "ticketExpiringDate"
    | "price"
    | "standard"
    | "maxCapacity"
    | "locationName"
    | "eventStartDate"
    | "eventEndDate"
>;

const EventEditPage = () => {
    const navigate = useNavigate();
    const { user } = useContext(UserContext);
    const [categories, setCategories] = useState<any[]>([]);
    const [imageUrl, setImageUrl] = useState<string[]>([]);
    const [description, setDescription] = useState("");
    const [formField, setFormField] = useState<TicketInterface>()
    const [files, setFiles] = useState<any[]>([]);
    // const hiddenFileInput = useRef<HTMLInputElement>(null);
    const overlayRef = useRef(null);
    const [counter, setCounter] = useState(0);
    // const [event, setevent] = useState<any>()

    const location = useLocation();
    const currentEvent = location.state?.entity;

    const sankMoneyUpdateEventsFn = functions.httpsCallable(
        "sankMoneyUpdateEventsFn"
    );

    const handleDescriptionChange = (e: any) => {
        const value = e.target.value;
        setDescription(value);
    };
    const handlechange = async (value: any) => {
        const fileList = value.target.files;
        if (!fileList) return;

        const urls: string[] = [];
        const newFiles: any[] = [];

        for (let i = 0; i < fileList.length; i++) {
            const file = fileList[i];
            const imageRef = ref(storage, `leisure_events/${currentEvent?.id}/${file.lastModified}`);
            await uploadBytes(imageRef, file);
            const url = await getDownloadURL(imageRef);
            urls.push(url);

            newFiles.push({
                id: url,
                name: file.name,
                size: file.size,
                type: file.type.match("image.*") ? "image" : "file",
                url: url,
            });
        }
        setImageUrl(urls);
        setFiles((prev) => [...prev, ...newFiles]);

    }

    // const handleClick = () => hiddenFileInput.current?.click();

    const handleDelete = (fileId: string) => {
        setFiles((prevFiles) => prevFiles.filter((file) => file.id !== fileId));
        setImageUrl((prevUrls) => prevUrls.filter((url) => url !== fileId));
    };

    const onSubmitDefaultInfos = async (data: any) => {
        if (user) {
            const response = sankMoneyUpdateEventsFn({
                collectionName: eventsCollections,
                documentData: {
                    eventImageUrls: (imageUrl && imageUrl.length > 0) ? imageUrl : currentEvent?.eventImageUrls,
                    description: description ? description : currentEvent?.description,
                    ...data,
                },
                documentId: currentEvent?.id,
            }).then(() => {
                navigate(`/billetterie/events/${currentEvent?.id}/detail`,
                    {
                        state: { eventId: currentEvent?.id },
                    }
                );
            })
            toast.promise(response, {
                error: "La mise à jour a échoué",
                success: "L'evenement a été mis a jour avec succès",
                loading: "Mise a jour...",
            });
        }
    };

    const getCategories = async () => {
        const getCategorieRef = db
            .collection(categorieCollections)

        await getCategorieRef.get()
            .then((categorie) => {
                setCategories(
                    categorie.docs.map((element) => {
                        return { id: element.id, data: element.data() }
                    })
                )
            })
    };
    // const getEventWithId = useCallback(async () => {
    //     const getEventRef = db
    //         .collection(eventsCollections)
    //         .doc(eventId)

    //     await getEventRef.get().then((event) => {
    //         console.log(event.data());
    //         setevent(event.data());
    //     })
    // }, [eventId])

    useEffect(() => {
        getCategories();
    }, []);

    return (
        <>
            <div className="py-2 border-b border-gray-200 dark:border-slate-700">
                <h2 className="text-lg font-semibold leading-6 text-gray-900 dark:text-gray-300">
                    Evenements
                </h2>
                <p className="mt-1 text-base text-gray-500">
                    Editer l' evenement
                </p>
            </div>
            <div className="flex mx-5 mt-4 max-md:block max-md:space-x-0 max-md:space-y-10 max-md:mx-5">
                <div className="w-full">
                    <div className="flex flex-col w-full">
                        <div className="flex justify-center w-full">
                            <div className="w-full max-w-4xl px-2 py-8 sm:px-0">
                                <div className="flex items-center justify-center w-full font-manrope">
                                    <div className="box-border w-full p-4 mx-auto bg-white border">
                                        <Form<TicketAttendantInfos>
                                            maximizeFormWidh={false}
                                            formReturnAware={() => setFormField}
                                            onSubmit={onSubmitDefaultInfos}
                                            submitButtonLabel={"Enregistrer"}
                                            form={{
                                                resolver: yupResolver(
                                                    yup.object().shape({
                                                        title: yup
                                                            .string()
                                                            .required("Le montant est obligatoire!"),
                                                        eventStartTime: yup.string().optional(),
                                                        eventEndTime: yup.string().optional(),
                                                        locationName: yup.string().optional(),
                                                        city: yup.string().optional(),
                                                        maxCapacity: yup.number(),
                                                        long: yup.string().optional(),
                                                        lat: yup.string().optional(),
                                                    })
                                                ),
                                                defaultValues: {
                                                    title: currentEvent ? currentEvent?.title : "",
                                                    locationName: currentEvent ? currentEvent?.locationName : "",
                                                    city: currentEvent ? currentEvent?.city : "",
                                                    maxCapacity: currentEvent ? currentEvent?.maxCapacity : "",
                                                    long: currentEvent ? currentEvent?.long : "",
                                                    lat: currentEvent ? currentEvent?.lat : "",
                                                    eventStartDate: moment(currentEvent?.eventStartTime).format("YYYY-MM-DD hh:mm:ss"),
                                                    eventEndDate: moment(currentEvent?.eventEndTime).format("YYYY-MM-DD hh:mm:ss"),
                                                },
                                            }}
                                        >
                                            <div className="flex flex-col content-center w-full px-5 mt-5 align-top md:flex-row">
                                                <div className="w-full mx-auto md:w-1/2" >
                                                    <FormInput
                                                        required
                                                        type="text"
                                                        name="title"
                                                        label={`Titre`}
                                                        placeholder="Sank festival"
                                                    />
                                                    <FormInput
                                                        type="datetime-local"
                                                        label="Date de debut"
                                                        name="eventStartDate"
                                                    />
                                                    <FormInput
                                                        type="datetime-local"
                                                        label="Date de fin"
                                                        name="eventEndDate"
                                                    />
                                                    <FormInput
                                                        required
                                                        type="text"
                                                        name="locationName"
                                                        label={`Lieu`}
                                                        placeholder="secteur 30, Ouaga 2000"
                                                    />
                                                    <FormInput
                                                        required
                                                        type="text"
                                                        name="long"
                                                        label={`Longitude`}
                                                        placeholder="-1.002546"
                                                    />
                                                </div>
                                                <div className="w-10 h-5"></div>
                                                <div className="w-full mx-auto md:w-1/2" >
                                                    <FormInput
                                                        required
                                                        type="text"
                                                        name="lat"
                                                        label={`Latitude`}
                                                        placeholder="12.00003"
                                                    />
                                                    <FormInput
                                                        required
                                                        type="text"
                                                        name="city"
                                                        label={`Ville`}
                                                        placeholder="Ouagadougou"
                                                    />
                                                    <FormInput
                                                        required
                                                        type="number"
                                                        name="maxCapacity"
                                                        label={`Nombre de ticket`}
                                                        placeholder="1000"
                                                    />
                                                    <div className="font-semibold">
                                                        Entrez une descriptions de l'évènement
                                                    </div>
                                                    <div>
                                                        <textarea
                                                            id="message"
                                                            rows={4}
                                                            onChange={handleDescriptionChange}
                                                            maxLength={200}
                                                            value={currentEvent?.description ? currentEvent?.description : description}
                                                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                                                            placeholder="Écrivez votre description ici..."
                                                        ></textarea>
                                                    </div>
                                                </div>

                                            </div>
                                            <div className="flex items-center justify-center p-6">
                                                <div className="bg-gray-500 h-[600px] w-screen sm:px-8 md:px-16 sm:py-8">
                                                    <main className="container mx-auto max-w-screen-lg h-full">
                                                        <article
                                                            aria-label="File Upload Modal"
                                                            className="relative h-full flex flex-col bg-white shadow-xl rounded-md"
                                                            onDrop={(e) => e.preventDefault()}
                                                            onDragOver={(e) => e.preventDefault()}
                                                            onDragEnter={() => setCounter((count) => count + 1)}
                                                            onDragLeave={() => setCounter((count) => Math.max(count - 1, 0))}
                                                        >
                                                            <div
                                                                ref={overlayRef}
                                                                id="overlay"
                                                                className={`w-full h-full absolute top-0 left-0 pointer-events-none z-50 flex flex-col items-center justify-center rounded-md ${counter > 0 ? "draggedover" : ""
                                                                    }`}
                                                            >
                                                                <i>
                                                                    <svg className="fill-current w-12 h-12 mb-3 text-blue-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                        <path d="M19.479 10.092c-.212-3.951-3.473-7.092-7.479-7.092-4.005 0-7.267 3.141-7.479 7.092-2.57.463-4.521 2.706-4.521 5.408 0 3.037 2.463 5.5 5.5 5.5h13c3.037 0 5.5-2.463 5.5-5.5 0-2.702-1.951-4.945-4.521-5.408zm-7.479-1.092l4 4h-3v4h-2v-4h-3l4-4z" />
                                                                    </svg>
                                                                </i>
                                                                <p className="text-lg text-blue-700">Drop files to upload</p>
                                                            </div>

                                                            <section className="h-full overflow-auto p-8 w-full flex flex-col">
                                                                <header className="border-dashed border-2 border-gray-400 py-12 flex flex-col justify-center items-center">
                                                                    <p className="mb-3 font-semibold text-gray-900 flex justify-center">
                                                                        <span>Choisir une ou plusieurs images</span>
                                                                    </p>
                                                                    <input
                                                                        // ref={hiddenFileInput}
                                                                        id="file"
                                                                        type="file"
                                                                        multiple
                                                                        className="sr-only"
                                                                        onChange={handlechange}
                                                                    />
                                                                    <label
                                                                        htmlFor="file"
                                                                        className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                                                                    >
                                                                        <span className="inline-flex rounded border border-[#e0e0e0] cursor-pointer py-2 px-7 text-base font-medium text-[#07074D]">
                                                                            Choisir une image
                                                                        </span>
                                                                    </label>
                                                                    {/* <button
                                                                        id="button"
                                                                        className="mt-2 rounded-sm px-3 py-1 bg-gray-200 hover:bg-gray-300 focus:outline-none"
                                                                        onClick={handleClick}
                                                                    >
                                                                        Upload a file
                                                                    </button> */}
                                                                </header>

                                                                <ul id="gallery" className="flex flex-wrap -m-1">
                                                                    {files.length === 0 ? (
                                                                        <li className="h-full w-full text-center flex flex-col items-center justify-center">
                                                                            <img
                                                                                className="mx-auto w-32"
                                                                                src="https://user-images.githubusercontent.com/507615/54591670-ac0a0180-4a65-11e9-846c-e55ffce0fe7b.png"
                                                                                alt="no data"
                                                                            />
                                                                            <span className="text-small text-gray-500">Aucune images selectionné</span>
                                                                        </li>
                                                                    ) : (
                                                                        (files).map((file: any) => (
                                                                            <li key={file.id} className="block p-1 w-1/2 sm:w-1/3 md:w-1/4 lg:w-1/6 xl:w-1/8 h-24">
                                                                                <article className="group w-full h-full rounded-md bg-gray-100 cursor-pointer relative shadow-sm">
                                                                                    {file.type === "image" && (
                                                                                        <img alt="upload preview" className="w-full h-full object-cover rounded-md" src={file.url} />
                                                                                    )}
                                                                                    <button
                                                                                        className="absolute top-2 right-2 bg-white p-1 rounded"
                                                                                        onClick={() => handleDelete(file.id)}
                                                                                    >
                                                                                        <svg className="fill-current w-4 h-4 text-gray-700" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
                                                                                            <path d="M3 6l3 18h12l3-18h-18zm19-4v2h-20v-2h5.711c.9 0 1.631-1.099 1.631-2h5.316c0 .901.73 2 1.631 2h5.711z" />
                                                                                        </svg>
                                                                                    </button>
                                                                                </article>
                                                                            </li>
                                                                        ))
                                                                    )}
                                                                </ul>
                                                            </section>
                                                        </article>
                                                    </main>
                                                </div>
                                            </div>
                                            {/* <div className="flex items-center justify-center p-6">
                                                <div className="mx-auto w-full max-w-[550px] bg-white">
                                                    <div className="pt-2 mb-4">
                                                        <div className="mb-4">
                                                            <input
                                                                type="file"
                                                                id="file"
                                                                multiple
                                                                className="sr-only"
                                                                onChange={handlechange}
                                                            />
                                                            <label
                                                                htmlFor="file"
                                                                className="relative flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] p-12 text-center"
                                                            >
                                                                <div>
                                                                    {imageUrl?.length > 0 || currentEvent?.eventImageUrls?.length > 0 ? (
                                                                        (imageUrl?.length > 0 ? imageUrl : currentEvent?.eventImageUrls)?.map((url: string, index: number) => (
                                                                            <img
                                                                                key={index}
                                                                                className="object-cover object-center mx-auto flex justify-between"
                                                                                src={url}
                                                                                alt="Uploaded Images"
                                                                            />
                                                                        ))
                                                                    ) : (
                                                                        <span className="inline-flex rounded border border-[#e0e0e0] cursor-pointer py-2 px-7 text-base font-medium text-[#07074D]">
                                                                            Choisir une image
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> */}
                                        </Form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EventEditPage;
