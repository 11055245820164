import React from "react";

interface FolderComponentProps {
  title: string;
  reportUrl: string;
}

const FileComponent = ({ title, reportUrl }: FolderComponentProps) => {
  const downloadURI = (uri: string, name: string) => {
    const link = document.createElement("a");
    link.download = name;
    link.href = uri;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div
      className="flex items-center justify-between w-48 px-3 pb-3 mt-2"
      onClick={() => downloadURI(reportUrl, `${title}.csv`)}
    >
      <div className="items-center p-3 transition duration-500 ease-in-out rounded-md cursor-pointer hover:shadow ">
        <div className="flex text-blue-500 justify-self-auto">
          <svg
            width="80px"
            height="80px"
            viewBox="0 0 1024 1024"
            className="icon"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M853.333333 960H170.666667V64h469.333333l213.333333 213.333333z"
              fill="#f43f5e"
            />
            <path d="M821.333333 298.666667H618.666667V96z" fill="#E1F5FE" />
          </svg>
        </div>
        <div className="">
          <h4 className="font-sans">{title}</h4>
        </div>
      </div>
    </div>
  );
};
// stroke="#f43f5e"

export default FileComponent;
