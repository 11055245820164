import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { Link } from "react-router-dom";
import { ExclamationCircleIcon } from "@heroicons/react/24/solid";

/* eslint-disable-next-line */
export interface AlertProps {
  type: "warning" | "danger" | "success";
  title: string;
  content?: string | null;
  titleLink?: string | null;
  link?: string | null;
  onClickLink?: () => void;
}

export function Alert({
  type,
  title,
  content,
  titleLink,
  link,
  onClickLink,
}: AlertProps) {
  const bgColor =
    type === "warning"
      ? "border-yellow-200 bg-yellow-50"
      : type === "danger"
      ? "border-red-200 bg-red-50"
      : "border-green-200 bg-green-50";
  const titleColor =
    type === "warning"
      ? "text-yellow-800"
      : type === "danger"
      ? "text-red-800"
      : "text-green-800";
  const contentColor =
    type === "warning"
      ? "text-yellow-700"
      : type === "danger"
      ? "text-red-700"
      : "text-green-700";
  return (
    <div className={"shadow border rounded-md p-2 " + bgColor}>
      <div className="flex">
        <div className="flex-shrink-0 my-auto">
          {type === "warning" && (
            <ExclamationCircleIcon
              className="w-6 h-6 text-yellow-400"
              aria-hidden="true"
            />
          )}
          {type === "danger" && (
            <XCircleIcon className="w-6 h-6 text-red-400" aria-hidden="true" />
          )}
          {type === "success" && (
            <CheckCircleIcon
              className="w-6 h-6 text-green-400"
              aria-hidden="true"
            />
          )}
        </div>
        <div className="ml-3">
          <h3 className={"text-sm font-medium " + titleColor}>{title}</h3>
          <div className={"mt-2 text-sm " + contentColor}>
            {content && (
              <p>
                {content}{" "}
                {link && (
                  <Link
                    onClick={onClickLink}
                    className="font-bold underline"
                    to={link}
                  >
                    {titleLink}
                  </Link>
                )}
                {onClickLink && (
                  <span onClick={onClickLink} className="font-bold underline cursor-pointer">
                    {titleLink}
                  </span>
                )}
              </p>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Alert;
