import { QRCodeCanvas } from "qrcode.react";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import imageSrc from "../../../sankQrCodeImg.png";
import CardImage from "../../../340_x_200_1.png";
import { db, functions } from "../../../firebase";
import {
  accountsCollectionName,
  userCardRelationCollection,
  usersCollectionName,
} from "../../../config";
import { amountFormater, classNames, parseObjectDate } from "../../../utils";
import {
  ArrowPathIcon,
  BoltSlashIcon,
  MinusCircleIcon,
} from "@heroicons/react/24/outline";
import HasRoles from "../../../components/HasRoles";
import toast from "react-hot-toast";
import { useConfirm } from "../../../hooks/useConfirm";
import {
  accountMap,
  mapsCardType,
  mapsSubRole,
  mapsUserRole,
  userRoleMap,
} from "../../../interfaces";

const CardDetails = () => {
  const [userData, setUserData] = useState<any>();
  const [sellerData, setsellerData] = useState<any>();
  const [userAccountsData, setUserAccountsData] = useState<any[]>([]);
  const location = useLocation();
  const currentCard = location.state?.entity;
  const { isConfirmModal } = useConfirm();
  const navigate = useNavigate();
  const imageOptions = {
    src: imageSrc,
    x: undefined,
    y: undefined,
    height: 30,
    width: 30,
    excavate: true,
  };

  const sankMoneyUpdateCardDataFn = functions.httpsCallable(
    "sankMoneyUpdateCardDataFn"
  );
  const sankMoneyadminUpdateCardPinCodeFn = functions.httpsCallable(
    "sankMoneyadminUpdateCardPinCodeFn"
  );

  const retriveUserData = () => {
    db.collection(userCardRelationCollection)
      .where("cardId", "==", currentCard?.id)
      .get()
      .then(async (response) => {
        if (response.empty) {
          setUserData(undefined);
          return;
        }
        await db
          .collection(usersCollectionName)
          .doc(response.docs[0].data()?.userId)
          .get()
          .then(async (res) => {
            const data = res.data();
            if (res.exists) {
              setUserData(data);
              const accounts = await res.ref
                .collection(accountsCollectionName)
                .get();
              setUserAccountsData(
                accounts.docs.map((account) => account.data())
              );
            } else setUserData(undefined);
            return data;
          });
        await db
          .collection(usersCollectionName)
          .doc(response.docs[0].data()?.resellerId)
          .get()
          .then(async (res) => {
            const data = res.data();
            if (res.exists) {
              setsellerData(data);
            } else setsellerData(undefined);
            return data;
          });
      });
  };

  const handleBlocked = async (card: any) => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      `Êtes-vous sûr de vouloir ${
        card.blocked ? "débloquer" : "bloquer"
      } la carte.`,
      `Oui ${card.blocked ? "débloquer" : "bloquer"}`,
      true
    );

    if (isConfirm) {
      const response = sankMoneyUpdateCardDataFn({
        cardId: card?.id,
        documentData: {
          blocked: card.blocked ? false : true,
        },
      }).then(() => {
        navigate("/cards");
      });
      toast.promise(response, {
        error: `Le ${
          card.blocked ? "déblocage" : "blocage"
        } de la carte à echoué.`,
        success: `La carte a été ${card.blocked ? "débloquée" : "bloquée"}`,
        loading: `${card.blocked ? "Déblocage..." : "Blocage..."}`,
      });
    }
  };
  const handleDiseabled = async (card: any) => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      `Êtes-vous sûr de vouloir ${
        card.active ? "désactiver" : "activer"
      } la carte.`,
      `Oui ${card.active ? "désactiver" : "activer"}`,
      true
    );

    if (isConfirm) {
      const response = sankMoneyUpdateCardDataFn({
        cardId: card?.id,
        documentData: {
          active: card.active ? false : true,
        },
      }).then(() => {
        navigate("/cards");
      });
      toast.promise(response, {
        error: `${
          card.active ? "La désactivation" : "L'activation"
        } de la carte à echoué.`,
        success: `La carte a été ${card.active ? "désactivée" : "activée"}`,
        loading: `${card.active ? "Désactivation..." : "Activation..."}`,
      });
    }
  };
  const handleResetPinCode = async (card: any) => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      `Êtes vous sûr de vouloir réinitialiser le code pin de l'utilisateur.`,
      `Oui réinitialiser`,
      true
    );

    if (isConfirm) {
      const response = sankMoneyadminUpdateCardPinCodeFn({
        cardId: card?.id,
      }).then(() => {
        navigate("/cards");
      });
      toast.promise(response, {
        error: `La réinitialiser du pin code de la carte à echoué.`,
        success: `Le pin code de la carte a été réinitialisée`,
        loading: "Réinitialisation...",
      });
    }
  };

  useEffect(() => {
    currentCard && retriveUserData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Details da le carte{" "}
            <span className="text-transparent bg-clip-text bg-gradient-to-r to-red-600 from-red-900">
              {currentCard?.id}
            </span>
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Les informations de la carte
          </p>
        </div>
      </div>
      <div className="lg:border-t lg:border-gray-200">
        <div className="flex-1 min-w-0">
          <div className="sm:flex-wrap lg:flex md:flex-wrap">
            <HasRoles userRole={["ADMIN"]}>
              <div className="flex mt-6 space-x-3 md:ml-4">
                <button
                  type="button"
                  disabled={currentCard?.active ? false : true}
                  className={classNames(
                    currentCard?.active
                      ? "text-green-700 border-green-300 hover:bg-green-50 focus:ring-green-500"
                      : "text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-gray-500",
                    "inline-flex items-center px-4 py-2 text-sm font-medium  bg-white border  rounded-md shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2"
                  )}
                  onClick={() => {
                    handleResetPinCode(currentCard);
                  }}
                >
                  <ArrowPathIcon
                    className={classNames(
                      currentCard?.active ? "text-green-500" : "text-gray-500 ",
                      "self-center flex-shrink-0 w-5 h-5 mr-1"
                    )}
                    aria-hidden="true"
                  />
                  Reset code pin
                </button>
              </div>
            </HasRoles>
            <HasRoles userRole={["USER", "ADMIN"]}>
              <div className="flex mt-6 space-x-3 md:ml-4">
                <button
                  type="button"
                  className={classNames(
                    currentCard?.active
                      ? "text-blue-700 border-blue-300 hover:bg-blue-50 focus:ring-blue-500"
                      : "text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-gray-500",
                    "inline-flex items-center px-4 py-2 text-sm font-medium  bg-white border  rounded-md shadow-sm  focus:outline-none focus:ring-2  focus:ring-offset-2"
                  )}
                  onClick={() => handleBlocked(currentCard)}
                  disabled={currentCard?.active ? false : true}
                >
                  <BoltSlashIcon
                    className={classNames(
                      currentCard?.active ? "text-blue-500" : "text-gray-500 ",
                      "self-center flex-shrink-0 w-5 h-5 mr-1"
                    )}
                    aria-hidden="true"
                  />
                  {currentCard.blocked ? "Débloquer" : "Bloquer"}
                </button>
              </div>
            </HasRoles>
            <HasRoles userRole={["ADMIN"]}>
              <div className="flex mt-6 space-x-3 md:ml-4">
                <button
                  type="button"
                  className={classNames(
                    currentCard.active
                      ? "text-red-700 border-red-300 focus:ring-red-500"
                      : "text-green-700 border-green-300 focus:ring-green-500",
                    "inline-flex items-center px-4 py-2 text-sm font-medium  bg-white border  rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2  focus:ring-offset-2"
                  )}
                  onClick={() => handleDiseabled(currentCard)}
                >
                  <MinusCircleIcon
                    className={classNames(
                      currentCard.active ? "text-red-500" : "text-green-500",
                      "self-center flex-shrink-0 w-5 h-5 mr-1 "
                    )}
                    aria-hidden="true"
                  />
                  {currentCard.active ? "Desactiver" : "Activer"}
                </button>
              </div>
            </HasRoles>
          </div>
        </div>
        <div className="mt-3 main-form">
          <div
            className="relative flex items-center justify-between w-full sm:w-[240px] sm:h-[150px] px-6 py-4 space-x-6 bg-cover rounded-md"
            style={{
              backgroundImage: `url(${CardImage})`,
              width: "340px",
              height: "200px",
            }}
          >
            <div className="absolute top-0 right-0 m-2 text-sm font-semibold text-white">
              SankMoney
            </div>
            <div className="absolute bottom-0 m-2 text-sm font-semibold text-white right-2">
              {currentCard?.id}
            </div>
            <div className="absolute top-0 left-0 m-2 text-sm font-light text-white">
              {mapsCardType.get(currentCard?.cardType)}
            </div>
            <div className="absolute top-10 right-3">
              <QRCodeCanvas
                className="w-full"
                id="qrCode"
                value={`${currentCard?.id}`}
                size={100}
                level={"L"}
                includeMargin={false}
                bgColor="red"
                fgColor="white"
                imageSettings={imageOptions}
              />
            </div>
            <div className="absolute bottom-0 m-2 text-xs font-light text-white left-2">
              Exp :{" "}
              {currentCard?.expiryDate &&
                parseObjectDate(currentCard?.expiryDate)}
            </div>
          </div>

          <div className="flex flex-col md:w-2/4">
            {userData && (
              <>
                <div className="flex items-center justify-between py-4 border-b border-gray-200">
                  <p className="text-base leading-4 text-gray-800">
                    Nom/Prénom
                  </p>
                  <div className="flex items-center justify-center">
                    <p className="text-sm leading-none text-gray-600">
                      {userData?.displayName ? userData?.displayName : "-"}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between py-4 border-b border-gray-200">
                  <p className="text-base leading-4 text-gray-800">Email</p>
                  <div className="flex items-center justify-center">
                    <p className="mr-3 text-sm leading-none text-gray-600">
                      {userData?.email ? userData?.email : "-"}
                    </p>
                  </div>
                </div>
                <div className="flex items-center justify-between py-4 border-b border-gray-200">
                  <p className="text-base leading-4 text-gray-800">Téléphone</p>
                  <div className="flex items-center justify-center">
                    <p className="mr-3 text-sm leading-none text-gray-600">
                      {userData?.phoneNumber ? userData?.phoneNumber : "-"}
                    </p>
                  </div>
                </div>
                {userAccountsData &&
                  userAccountsData.length > 0 &&
                  userAccountsData.map((account, index) => (
                    <div
                      key={index}
                      className="flex items-center justify-between py-4 border-b border-gray-200"
                    >
                      <p className="text-base leading-4 text-gray-800">
                        {accountMap.get(account.accountType)}
                      </p>
                      <div className="flex items-center justify-center">
                        <p className="mr-3 text-sm leading-none text-gray-600">
                          {amountFormater(account.balance)} Fcfa
                        </p>
                      </div>
                    </div>
                  ))}
              </>
            )}
          </div>
          <HasRoles userRole={["ADMIN"]}>
            <div className="flex flex-col md:w-2/4">
              <h2 className="my-4 text-2xl font-extrabold leading-none tracking-tight text-gray-900 md:text-4xl dark:text-white">
                Information du partenaire
              </h2>
              {sellerData && (
                <>
                  <div className="flex items-center justify-between py-4 border-b border-gray-200">
                    <p className="text-base leading-4 text-gray-800">
                      Nom/Prénom
                    </p>
                    <div className="flex items-center justify-center">
                      <p className="text-sm leading-none text-gray-600">
                        {sellerData?.displayName
                          ? sellerData?.displayName
                          : "-"}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-between py-4 border-b border-gray-200">
                    <p className="text-base leading-4 text-gray-800">Email</p>
                    <div className="flex items-center justify-center">
                      <p className="mr-3 text-sm leading-none text-gray-600">
                        {sellerData?.email ? sellerData?.email : "-"}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-between py-4 border-b border-gray-200">
                    <p className="text-base leading-4 text-gray-800">
                      Téléphone
                    </p>
                    <div className="flex items-center justify-center">
                      <p className="mr-3 text-sm leading-none text-gray-600">
                        {sellerData?.phoneNumber
                          ? sellerData?.phoneNumber
                          : "-"}
                      </p>
                    </div>
                  </div>
                  <div className="flex items-center justify-between py-4 border-b border-gray-200">
                    <p className="text-base leading-4 text-gray-800">Rôle</p>
                    <div className="flex items-center justify-center">
                      <p className="mr-3 text-sm leading-none text-gray-600">
                        {sellerData?.userRole
                          ? userRoleMap.get(sellerData?.userRole)
                          : "-"}
                      </p>
                    </div>
                  </div>
                  {sellerData?.userAgencySubRole && (
                    <div className="flex items-center justify-between py-4 border-b border-gray-200">
                      <p className="text-base leading-4 text-gray-800">
                        Sous rôle
                      </p>
                      <div className="flex items-center justify-center">
                        <p className="mr-3 text-sm leading-none text-gray-600">
                          {userRoleMap.get(sellerData?.userAgencySubRole)}
                        </p>
                      </div>
                    </div>
                  )}
                </>
              )}
            </div>
          </HasRoles>
        </div>
      </div>
    </div>
  );
};

export default CardDetails;
