import {
  FunnelIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";
import moment from "moment";
import { useState } from "react";
import PhoneInput from "react-phone-number-input";
import Pagination from "../../../components/Pagination/Pagination";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import { usersCollectionName } from "../../../config";
import { db } from "../../../firebase";
import { UserRole } from "../../../interfaces";
import { getTimestamp, classNames, parseDate } from "../../../utils";
import toast from "react-hot-toast";

const tableHeader = [
  "N°",
  "",
  "Nom/prénom",
  "Email",
  "Ville",
  "Téléphone",
  "Active",
  "Date de création",
];

const AgentCreateUserHistories = () => {
  const collectionName = usersCollectionName;
  const [paginatdlistData, setPaginatdListData] = useState<any[]>([]);
  const [page, setPage] = useState(1);
  const [retriveUserByPhoneNumber, setRetriveUserByPhoneNumber] = useState<
    any[]
  >([]);
  const [users, setUsers] = useState<any[]>([]);

  const retriveUserData = async (
    startTime: any,
    endTime: any,
    pageLimit?: number
  ) => {
    if (retriveUserByPhoneNumber.length > 0) {
      await db
        .collection(collectionName)
        .orderBy("createdAt", "desc")
        .where("createdAt", ">=", startTime)
        .where("createdAt", "<=", endTime)
        .where("userRole", "==", UserRole.USER)
        .where("affiliateAgentId", "==", retriveUserByPhoneNumber[0]?.id)
        .limitToLast(pageLimit ?? 25)
        .get()
        .then((response) => {
          const data = response.docs.map((d) => {
            return { id: d.id, data: d.data() };
          });
          setUsers(data);
          setPaginatdListData(data);
          setPage(page + 1);
        });
    } else toast.error("Veuillez saisir un numéro de téléphone valide.");
  };

  const retrivePhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber && phoneNumber.length >= 12) {
      await db
        .collection(collectionName)
        .where("userRole", "==", UserRole.AGENT)
        .where("phoneNumber", "==", phoneNumber)
        .get()
        .then((response) => {
          setRetriveUserByPhoneNumber([response.docs[0].data()]);
        });
    } else {
      setRetriveUserByPhoneNumber([]);
    }
  };

  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithEndDateValue(value.target.value);
  };

  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );

  const [startTime, setStartTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const [endTime, setEndTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
  );

  const getFilteredByDate = async () => {
    // const startTime = new Date(seachWithStartDateValue).getTime();
    const startTime = getTimestamp(seachWithStartDateValue, true);
    const endTime = getTimestamp(seachWithEndDateValue, false);
    setEndTime(endTime);
    setStartTime(startTime);
    retriveUserData(startTime, endTime);
  };
  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Les utilisateurs créés par un agent
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Vous trouverez ci-dessous tous les utilisateurs créés par un agent
              spécifique.
            </p>
          </div>
          <div className="h-8" />
          <div className="self-end justify-between  max-md:flex-col flex">
            <div className="mr-4 my-auto">
              <PhoneInput
                international={false}
                placeholder="Numéro de téléphone"
                className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                defaultCountry="BF"
                onChange={retrivePhoneNumber}
              />
            </div>
            <div className="flex px-1 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
              <div className="mr-1">
                <label
                  htmlFor="dateDebut"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date début
                </label>
                <input
                  type="date"
                  id="dateDebut"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeStartDateValue}
                />
              </div>
              <div className="mr-1">
                <label
                  htmlFor="dateFin"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date fin
                </label>
                <input
                  type="date"
                  id="dateFin"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeEndDtaeValue}
                />
              </div>
              <div className="flex items-center justify-center mt-5 h-14">
                <div className="flex">
                  <button
                    type="button"
                    className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    onClick={() => {
                      getFilteredByDate();
                    }}
                  >
                    <FunnelIcon
                      className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                      aria-hidden="true"
                    />
                    Filtrer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-8">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm">
                <table
                  className="min-w-full border-separate"
                  style={{ borderSpacing: 0 }}
                >
                  <thead className="bg-gray-50">
                    <tr>
                      {tableHeader.map((th, index) => (
                        <th
                          key={index}
                          scope="col"
                          className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                        >
                          {th}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody className="bg-white">
                    {users && users.length > 0 ? (
                      (paginatdlistData.length > 0
                        ? paginatdlistData
                        : users
                      ).map((agent: any, agentIdx: number) => (
                        <tr key={agentIdx}>
                          <td
                            className={classNames(
                              agentIdx !== users.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {(agentIdx as number) + 1}
                          </td>
                          <td
                            className={classNames(
                              agentIdx !== users.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            <img
                              className="w-10 h-10 p-1 border rounded-full"
                              src={
                                agent.data?.profilImageUrl
                                  ? agent.data?.profilImageUrl
                                  : `https://ui-avatars.com/api/?background=random&name=${
                                      agent.data?.displayName || "S"
                                    }`
                              }
                              alt=""
                            />
                          </td>
                          <td
                            className={classNames(
                              agentIdx !== users.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {agent.data.displayName
                              ? agent.data.displayName
                              : "-"}
                          </td>
                          <td
                            className={classNames(
                              agentIdx !== users.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {agent.data.email ? agent.data.email : "-"}
                          </td>
                          <td
                            className={classNames(
                              agentIdx !== users.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {agent.data.city ? agent.data.city : "-"}
                          </td>
                          <td
                            className={classNames(
                              agentIdx !== users.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {agent.data.phoneNumber as string}
                          </td>
                          <td
                            className={classNames(
                              agentIdx !== users.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {agent.data.active ? (
                              <CheckCircleIcon className="w-6 h-6 text-green-500" />
                            ) : (
                              <XCircleIcon className="w-6 h-6 text-red-500" />
                            )}
                          </td>
                          <td
                            className={classNames(
                              agentIdx !== users.length - 1
                                ? "border-b border-gray-200"
                                : "",
                              "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                            )}
                          >
                            {parseDate(agent.data?.createdAt as Timestamp)}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <NoContent />
                    )}
                  </tbody>
                </table>
                {users && users.length > 0 && (
                  <Pagination
                    skip={users.length}
                    take={users.length}
                    total={users.length}
                    collectionName={collectionName}
                    onPageChange={(pageIndex: number) => pageIndex * page}
                    onPageLimitChange={(pageLimit: number) =>
                      retriveUserData(startTime, endTime, pageLimit)
                    }
                    isChangedPage={false}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AgentCreateUserHistories;
