import React from "react";
import { useLocation } from "react-router-dom";
import { accountMap } from "../../../interfaces";

const TransactionHistoriqueDetailsPage = () => {
  const location = useLocation();
  const currentTransaction = location.state?.entity;

  return (
    <div className="max-w-lg px-4 py-8 mx-auto my-10 border border-gray-100 shadow-lg rounded-xl">
      <div className="flex justify-between pb-3 mb-4 border-b">
        <p className="text-xl font-bold text-gray-700">
          Détails de la transaction
        </p>
        <div className="text-sm font-medium text-red-500">
          {accountMap.get(currentTransaction.accountType)}
        </div>
      </div>
      <div>
        <div className="py-2 mb-3 space-y-4 " tabIndex={0}>
          <div className="relative flex items-center">
            <img
              className="object-cover w-10 h-10 rounded-full"
              src={
                currentTransaction.senderProfilImageUrl
                  ? currentTransaction.senderProfilImageUrl
                  : `https://ui-avatars.com/api/?background=random&name=${
                      currentTransaction.senderDisplayName || "S"
                    }`
              }
              alt=""
            />
            <div className="flex flex-col ml-4 sm:w-96">
              <p className="mb-1 font-medium text-gray-700">
                {currentTransaction.senderDisplayName}
              </p>
              <div className="text-sm text-gray-400">
                <span className="mr-1 shrink-0 text-rose-500">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="inline w-3 h-3"
                  >
                    <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
                  </svg>
                </span>
                <span className="mr-1 font-medium text-rose-500">
                  Numéro de téléphone:
                </span>
                <span className="">{currentTransaction.senderPhoneNumber}</span>
              </div>
            </div>
            <span className="absolute top-0 text-sm text-gray-400 right-2">
              à envoyer{" "}
              {new Intl.NumberFormat().format(
                parseInt(currentTransaction.amount ?? "0")
              )}{" "}
              Fcfa
            </span>
          </div>
        </div>
        <div className="py-2 mb-3 space-y-4 " tabIndex={0}>
          <div className="relative flex items-center">
            <img
              className="object-cover w-10 h-10 rounded-full"
              src={
                currentTransaction.receiverProfilImageUrl
                  ? currentTransaction.receiverProfilImageUrl
                  : `https://ui-avatars.com/api/?background=random&name=${
                      currentTransaction.receiverDisplayName || "S"
                    }`
              }
              alt=""
            />
            <div className="flex flex-col ml-4 sm:w-96">
              <p className="mb-1 font-medium text-gray-700">
                {currentTransaction.receiverDisplayName}
              </p>
              <div className="mb-2 text-sm text-gray-400">
                <span className="mr-1 text-blue-500 shrink-0">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                    className="inline w-3 h-3"
                  >
                    <path d="M4.913 2.658c2.075-.27 4.19-.408 6.337-.408 2.147 0 4.262.139 6.337.408 1.922.25 3.291 1.861 3.405 3.727a4.403 4.403 0 00-1.032-.211 50.89 50.89 0 00-8.42 0c-2.358.196-4.04 2.19-4.04 4.434v4.286a4.47 4.47 0 002.433 3.984L7.28 21.53A.75.75 0 016 21v-4.03a48.527 48.527 0 01-1.087-.128C2.905 16.58 1.5 14.833 1.5 12.862V6.638c0-1.97 1.405-3.718 3.413-3.979z" />
                    <path d="M15.75 7.5c-1.376 0-2.739.057-4.086.169C10.124 7.797 9 9.103 9 10.609v4.285c0 1.507 1.128 2.814 2.67 2.94 1.243.102 2.5.157 3.768.165l2.782 2.781a.75.75 0 001.28-.53v-2.39l.33-.026c1.542-.125 2.67-1.433 2.67-2.94v-4.286c0-1.505-1.125-2.811-2.664-2.94A49.392 49.392 0 0015.75 7.5z" />
                  </svg>
                </span>
                <span className="mr-1 font-medium text-blue-500">
                  Numéro de téléphone:
                </span>
                <span className="">
                  {currentTransaction.receiverPhoneNumber}
                </span>
              </div>
            </div>
            <span className="absolute top-0 text-sm text-gray-400 right-2">
              à Reçu{" "}
              {new Intl.NumberFormat().format(
                parseInt(currentTransaction.amount ?? "0")
              )}{" "}
              Fcfa
            </span>
          </div>
        </div>
        {currentTransaction?.description && (
          <div className="py-2 mb-3 space-y-4 " tabIndex={0}>
            <div className="relative flex items-center">
              <div className="flex flex-col ml-4 sm:w-96">
                <div className="p-4 text-gray-600 rounded-xl bg-blue-50">
                  <div className="flex items-center overflow-auto">
                    <div className="pr-10 ml-4">
                      <p className="mb-1 text-sm font-medium text-gray-700">
                        {currentTransaction?.description}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TransactionHistoriqueDetailsPage;
