import { useCallback, useContext, useEffect, useState } from "react";
import NoContent from "../../components/TableNoContentPage/NoContent";
import Pagination from "../../components/Pagination/Pagination";
import {
  ArrowPathIcon,
  BookOpenIcon,
  CubeTransparentIcon,
  ListBulletIcon,
  Squares2X2Icon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import HasRoles from "../../components/HasRoles";
import {
  accountsCollectionName,
  gasStationCollectionName,
  superStationAndStationsForeingKeysCollectionsName,
  usersCollectionName,
} from "../../config";
import { AccountType, UserRole } from "../../interfaces";
import { amountFormater, classNames } from "../../utils";
import { UserContext } from "../../contexts";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import { Timestamp } from "firebase-admin/firestore";
import TableRowEditMenu from "../../components/TableRowEditMenu/TableRowEditMenu";

const tableHeader = [
  "N°",
  "Branding",
  "Code de paiement",
  "email",
  "Solde",
  "Status",
  "Date de création",
  "Actions",
];

const AffiliateStations = () => {
  const [isTableView, setIsTableView] = useState(false);
  const { user } = useContext(UserContext);
  const [stations, setStations] = useState<any[]>([]);
  const navigate = useNavigate();

  const retriveAffilitedStations = useCallback(async () => {
    db.collection(superStationAndStationsForeingKeysCollectionsName)
      .where("ownerId", "==", user?.id)
      .get()
      .then(async (response) => {
        const members = [];
        for (const doc of response.docs) {
          const data = await db
            .collection(gasStationCollectionName)
            .doc(doc.data()?.memberId)
            .get();
          const stationAccount = (
            await data.ref.collection(accountsCollectionName).get()
          ).docs
            .find((doc) => doc.data().accountType === AccountType.FUEL)
            ?.data();
          members.push({
            ...data.data(),
            account: stationAccount,
          });
        }
        setStations(members);
      });
  }, [user?.id]);

  const filterWithPhoneNumber = useCallback(
    (value: string) => {
      const numberOfStation = stations.length;
      const filteredStations = stations.filter((doc) => {
        return (doc.gasStationCode as string).includes(value);
      });
      setStations(filteredStations.slice(0, numberOfStation));
    },
    [stations]
  );

  const onDisplaystations = (station: any) => {
    if (station) {
      navigate(`/superStation/affiliate-stations/${station?.id}/transactions`, {
        state: { entity: station },
      });
    }
  };
  const onDisplayStationDetails = (station: any) => {
    if (station) {
      navigate(`/superStation/affiliate-stations/${station?.id}/details`, {
        state: { entity: station },
      });
    }
  };

  useEffect(() => {
    retriveAffilitedStations();
  }, [retriveAffilitedStations]);

  return (
    <div className="flex flex-col max-w-5xl 2xl:max-w-7xl">
      <div className="flex flex-col">
        <div className="py-2 border-b border-gray-200 dark:border-slate-700">
          <h3 className="text-lg font-semibold leading-6 text-gray-900 dark:text-gray-300">
            La liste des stations
          </h3>
          <p className="mt-1 text-base text-gray-500">
            {`Gérez ${
              user?.userRole === UserRole.ADMIN ? "les" : "vos"
            } stations depuis cette page. Vous pouvez sélectionner une station pour voir ses informations.`}
          </p>
        </div>
        <div className="flex flex-col items-end justify-between py-4 space-y-3 md:flex-row md:space-y-0 md:space-x-3">
          <div className="w-full md:w-1/2">
            <form className="flex items-center">
              <label htmlFor="simple-search" className="sr-only">
                rechercher par le code
              </label>
              <div className="relative w-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <svg
                    aria-hidden="true"
                    className="w-5 h-5 text-gray-500 dark:text-gray-400"
                    fill="currentColor"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      fillRule="evenodd"
                      d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
                <input
                  onChange={(e) => filterWithPhoneNumber(e.target.value)}
                  type="text"
                  id="simple-search"
                  className="block w-full p-2 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  placeholder="Rechercher par le code"
                />
              </div>
            </form>
          </div>

          <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
            <button className="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold text-green-700 transition-all duration-200 bg-white border border-green-700 rounded-md shadow-sm gap-x-2 dark:bg-slate-800 hover:border-green-900 hover:text-green-900 dark:hover:text-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
              <div
                onClick={() => {
                  retriveAffilitedStations();
                }}
                className="flex gap-1"
              >
                <ArrowPathIcon className="h-5" /> Refresh
              </div>
            </button>
            <span className="inline-flex rounded-md shadow-sm isolate">
              <button
                type="button"
                onClick={() => setIsTableView(true)}
                className={classNames(
                  isTableView
                    ? "bg-red-100 dark:bg-red-900 border-red-500 border"
                    : "bg-white dark:bg-slate-800",
                  "relative inline-flex items-center rounded-l-md  px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-slate-700 hover:bg-gray-50 focus:z-10"
                )}
              >
                <ListBulletIcon className="w-5 h-5" />
              </button>
              <button
                type="button"
                onClick={() => setIsTableView(false)}
                className={classNames(
                  !isTableView
                    ? "bg-red-100 dark:bg-red-900 border-red-500 border"
                    : "bg-white dark:bg-slate-800",
                  "relative -ml-px inline-flex items-center rounded-r-md  px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-slate-700 hover:bg-gray-50 focus:z-10"
                )}
              >
                <Squares2X2Icon className="w-5 h-5" />
              </button>
            </span>
          </div>
        </div>
        {!isTableView && (
          <>
            {stations && stations.length > 0 ? (
              <>
                <ul className="grid grid-cols-1 gap-6 my-4 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                  {stations.map((station, eventIdx) => {
                    return (
                      <li
                        key={eventIdx}
                        className="col-span-1 bg-white border divide-y divide-gray-200 rounded-lg shadow-md dark:divide-slate-700 dark:bg-slate-800 dark:border-slate-700"
                      >
                        <div className="flex items-center justify-between w-full px-6 py-4 space-x-6">
                          <div className="flex-1 truncate">
                            <div className="flex items-center space-x-3">
                              <h3 className="text-sm font-medium text-gray-900 truncate dark:text-gray-300">
                                {station.displayName} (
                                {station.account
                                  ? `${amountFormater(
                                      station.account.balance
                                    )} Fcfa`
                                  : ""}
                                )
                              </h3>
                            </div>
                            {station.gasStationCode && (
                              <p className="mt-1 text-sm text-gray-500 truncate">
                                {`Code: ${station.gasStationCode}`}
                              </p>
                            )}
                            {station.email && (
                              <p className="text-xs text-gray-500 truncate">
                                {`E-mail: ${station.email}`}
                              </p>
                            )}
                          </div>
                          <span className="inline-block w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-slate-700">
                            <svg
                              className="w-full h-full text-gray-300"
                              fill="currentColor"
                              viewBox="0 0 24 24"
                            >
                              <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                            </svg>
                          </span>
                        </div>
                        <div>
                          <div className="flex -mt-px divide-x divide-gray-200 dark:divide-slate-700">
                            <div className="flex flex-1 w-0">
                              <div
                                onClick={() => onDisplayStationDetails(station)}
                                className="relative inline-flex items-center justify-center flex-1 w-0 py-2 -mr-px text-sm font-semibold text-gray-900 border border-transparent rounded-bl-lg cursor-pointer gap-x-2 dark:text-gray-300"
                              >
                                <button
                                  disabled={!station?.active}
                                  className="relative inline-flex items-center justify-center flex-1 w-0 py-2 text-sm font-semibold text-gray-900 border border-transparent rounded-br-lg gap-x-2 dark:text-gray-300"
                                >
                                  <BookOpenIcon
                                    className={classNames(
                                      !station?.active
                                        ? "text-gray-400"
                                        : "text-red-400",
                                      "w-5 h-5 "
                                    )}
                                    aria-hidden="true"
                                  />
                                  Details
                                </button>
                              </div>
                            </div>
                            <HasRoles userRole={["SUPERSTATION"]}>
                              <div
                                onClick={() => onDisplaystations(station)}
                                className="flex flex-1 w-0 -ml-px cursor-pointer"
                              >
                                <div className="relative inline-flex items-center justify-center flex-1 w-0 py-2 text-sm font-semibold text-gray-900 border border-transparent rounded-br-lg gap-x-2 dark:text-gray-300">
                                  <button
                                    disabled={!station?.active}
                                    className="relative inline-flex items-center justify-center flex-1 w-0 py-2 text-sm font-semibold text-gray-900 border border-transparent rounded-br-lg gap-x-2 dark:text-gray-300"
                                  >
                                    <CubeTransparentIcon
                                      className={classNames(
                                        !station?.active
                                          ? "text-gray-400"
                                          : "text-red-400",
                                        "w-5 h-5 "
                                      )}
                                      aria-hidden="true"
                                    />
                                    Historique
                                  </button>
                                </div>
                              </div>
                            </HasRoles>
                          </div>
                        </div>
                      </li>
                    );
                  })}
                </ul>
                <Pagination
                  skip={stations.length}
                  take={stations.length}
                  total={stations.length}
                  collectionName={usersCollectionName}
                  onPageChange={(pageIndex: number) => pageIndex}
                  onPageLimitChange={(pageLimit: number) => {
                    // setStationsDataLimitLength(pageLimit);
                  }}
                />
              </>
            ) : (
              <NoContent />
            )}
          </>
        )}
        {isTableView && (
          <div className="mt-8 overflow-x-auto">
            <table
              className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
              style={{ borderSpacing: 0 }}
            >
              <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                <tr>
                  {tableHeader.map((th, index) => (
                    <th key={index} scope="col" className="px-4 py-3">
                      {th}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="items-center">
                {stations && stations.length > 0 ? (
                  stations.map((station: any, stationIdx: number) => (
                    <tr
                      key={stationIdx}
                      className={
                        stationIdx % 2 === 0 ? "bg-white" : "bg-gray-100"
                      }
                    >
                      <th
                        className={classNames(
                          stationIdx !== stations.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                        )}
                      >
                        {(stationIdx as number) + 1}
                      </th>
                      <td
                        className={classNames(
                          stationIdx !== stations.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                        )}
                      >
                        {station.displayName}
                      </td>
                      <td
                        className={classNames(
                          stationIdx !== stations.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                        )}
                      >
                        {station.gasStationCode}
                      </td>
                      <td
                        className={classNames(
                          stationIdx !== stations.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                        )}
                      >
                        {station.email}{" "}
                      </td>
                      <td
                        className={classNames(
                          stationIdx !== stations.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                        )}
                      >
                        {amountFormater(parseFloat(station.account.balance))}{" "}
                        Fcfa
                      </td>
                      <td
                        className={classNames(
                          stationIdx !== stations.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                        )}
                      >
                        {station.active ? (
                          <CheckCircleIcon className="w-6 h-6 text-green-500" />
                        ) : (
                          <XCircleIcon className="w-6 h-6 text-red-500" />
                        )}
                      </td>
                      <td
                        className={classNames(
                          stationIdx !== stations.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                        )}
                      >
                        {(station.createdAt as Timestamp)
                          .toDate()
                          .toLocaleString()}
                      </td>
                      <td
                        className={classNames(
                          stationIdx !== stations.length - 1
                            ? "border-b border-gray-200"
                            : "",
                          "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                        )}
                      >
                        <TableRowEditMenu
                          rowTransDetailsHandler={() =>
                            onDisplayStationDetails(station)
                          }
                          rowHistoriqueHandler={() =>
                            onDisplaystations(station)
                          }
                        />
                      </td>
                    </tr>
                  ))
                ) : (
                  <NoContent />
                )}
              </tbody>
            </table>

            <Pagination
              skip={stations.length}
              take={stations.length}
              total={stations.length}
              collectionName={usersCollectionName}
              isChangedPage={true}
              onPageChange={(pageIndex: number) => pageIndex}
              onPageLimitChange={(pageLimit: number) => {}}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default AffiliateStations;
