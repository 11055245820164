import { useCallback, useContext, useEffect, useState } from "react";
import { transactionMap, TransactionType } from "../../interfaces";
import moment from "moment";
import toast from "react-hot-toast";
import { defaultAmount, transactionCollectionName } from "../../config";
import { db, functions } from "../../firebase";
import {
  amountFormater,
  classNames,
  getTimestamp,
  isNumeric,
  parseDate,
} from "../../utils";
import { useLocation } from "react-router-dom";
import { Timestamp } from "firebase-admin/firestore";
import * as XLSX from "xlsx";
import {
  ArrowDownTrayIcon,
  BoltIcon,
  BanknotesIcon,
} from "@heroicons/react/24/outline";
import Pagination from "../../components/Pagination/Pagination";
import NoContent from "../../components/TableNoContentPage/NoContent";
import SankMoneyIcon from "../../CFA.png";
import Modal from "../../components/Modal";
import { Form, FormInput } from "../../components/FormComponents";
import { yupResolver } from "@hookform/resolvers/yup";
import FormCheckToggle from "../../components/FormComponents/FormCheckToggle";
import * as yup from "yup";
import CodePinComponent from "../../components/CodePinComponent";
import { UserContext } from "../../contexts";
import HasRoles from "../../components/HasRoles";

const tableHeader = [
  "N°",
  "",
  "Nom destinataire",
  "Numéro destinataire",
  "Montant",
  "Type de transaction",
  "Compte",
  "Frais",
  "Date de création",
];

const AddSuperStationDepositAmount = () => {
  const location = useLocation();
  const currentSuperStation = location.state?.entity;

  const { user } = useContext(UserContext);

  const [transactions, setTransactions] = useState<any[]>([]);
  const [reloadData, setReloadData] = useState<any>();

  const [defaultLoadHistories, setDefaultLoadHistories] = useState<number>(10);
  const [transactionTotalSum, setTransactionTotalSum] = useState<number>(0);
  const [transDataLimitLength, setTransDataLimitLength] = useState<number>(100);
  const [depositAmount, setDepositAmount] = useState(0);

  const [showAddDepositModal, setShowAddDepositModal] = useState(false);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);

  const [searchWithStartDateValue, setSetsearchWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [searchWithEndDateValue, setSetsearchWithEndDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);

  const handleChangeStartDateValue = (value: any) => {
    setSetsearchWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    setSetsearchWithEndDateValue(value.target.value);
  };

  const reloadSuperStationDepositAccountFn = functions.httpsCallable(
    "reloadSuperStationDepositAccountFn"
  );

  const onSubmit = async (pinCode: string) => {
    setIsCodePinBtnDesabled(true);
    setIsLoad(true);
    const toastId = toast.loading("Recharge...");
    if (!isNumeric(`${depositAmount}`)) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error(`Le montant n'est pas bien formatté`);
    }
    if (`${depositAmount}` < defaultAmount) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error(
        `Le montant doit être suppérieur à ${defaultAmount} Fcfa`
      );
    }

    const data = {
      superStationId: currentSuperStation?.id,
      amount: depositAmount,
      withConnection: true,
      haveDepositAccount: true,
      canReceiveCommission: reloadData.canReceiveCommission ?? false,
      pinCode,
      currentUserId: user?.id,
    };

    await reloadSuperStationDepositAccountFn(data)
      .then(async () => {
        setShowPinCodeModal(false);
        setIsCodePinBtnDesabled(false);
        setIsLoad(false);
        return toast.success("La recharge a été effectuée avec succès", {
          id: toastId,
        });
      })
      .catch((err: { details: any; message: any }) => {
        setShowPinCodeModal(false);
        setIsCodePinBtnDesabled(false);
        setIsLoad(false);
        toast.error(err.message, { id: toastId });
      });
  };

  const retrieveFilteredTransactions = useCallback(async () => {
    const startTime = getTimestamp(searchWithStartDateValue, true);
    const endTime = getTimestamp(searchWithEndDateValue, false);
    if (startTime > endTime) {
      return toast.error(
        "La date de fin ne doit pas etre inférieur à la date de début."
      );
    }
    if (!currentSuperStation?.id) {
      return;
    }
    const transSnap = await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("participantsIds", "array-contains", currentSuperStation?.id)
      .where("transactionType", "==", TransactionType.RELOADDEPOSITACCOUNT)
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .limit(transDataLimitLength)
      .get();
    if (transSnap.empty) {
      setTransactions([]);
    } else {
      const trans = transSnap.docs.map((doc) => {
        return { id: doc.id, data: doc.data() };
      });
      let sum = 0;
      transSnap.forEach((doc) => (sum = sum + parseFloat(doc.data()?.amount)));
      setDefaultLoadHistories(transSnap.size);
      setTransactionTotalSum(sum);
      setTransactions(trans);
    }
  }, [
    currentSuperStation?.id,
    searchWithEndDateValue,
    searchWithStartDateValue,
    transDataLimitLength,
  ]);

  const exportToXLSData = useCallback(() => {
    const worksheet = XLSX.utils.json_to_sheet(
      transactions.map((transaction) => {
        return {
          Expéditeur: transaction.data.senderPhoneNumber,
          Destinataire: transaction.data.receiverPhoneNumber,
          Montant: transaction.data.amount,
          "Type de Transaction": transaction.data.transactionType,
          Frais: transaction.data.fees,
          "Type de compte": transaction.data.accountType,
          Statut: transaction.data.status ?? "---",
          Date: parseDate(transaction.data?.createdAt as Timestamp),
        };
      })
    );

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "export");

    XLSX.writeFile(
      workbook,
      `transactions_du_${searchWithStartDateValue}_au_${searchWithEndDateValue}.xlsx`,
      { compression: true }
    );
  }, [searchWithEndDateValue, searchWithStartDateValue, transactions]);

  useEffect(() => {
    retrieveFilteredTransactions();
  }, [retrieveFilteredTransactions]);

  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="flex">
            <div>
              <h1 className="text-xl font-semibold text-gray-900">
                Historiques des transactions de recharge{" "}
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Vous trouverez ici l'ensemble des historiques de transactions de
                recharge compte caution.
              </p>
            </div>
            <span className="hidden text-xs font-medium text-red-500 md:inline-block">
              ({`${amountFormater(transactionTotalSum)} Fcfa`})
            </span>
          </div>

          <div className="h-8" />
          <div className="flex flex-col items-end justify-end p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
            <div className="flex flex-col items-stretch justify-end flex-shrink-0 w-full space-y-2 md:w-auto md:flex-row md:space-y-0 md:items-center md:space-x-3">
              <HasRoles userRole={["ADMIN", "AGENCYSTAFF"]}>
                <button
                  type="button"
                  className="flex items-center justify-center px-4 py-2 text-sm font-medium text-white bg-red-500 rounded-lg hover:bg-red-600 focus:ring-4 focus:ring-red-300 dark:bg-red-500 dark:hover:bg-red-600 focus:outline-none dark:focus:ring-red-700"
                  onClick={() => setShowAddDepositModal(true)}
                >
                  <BanknotesIcon
                    className="self-center flex-shrink-0 w-5 h-5 mr-1"
                    aria-hidden="true"
                  />
                  Déposer
                </button>
              </HasRoles>
              <div className="flex justify-between md:space-x-3 md:w-auto">
                <input
                  type="date"
                  id="dateDebut"
                  className="p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeStartDateValue}
                />
                <input
                  type="date"
                  id="dateDebut"
                  className="p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeEndDtaeValue}
                />
              </div>
              <div className="flex justify-end">
                <button
                  type="button"
                  className={classNames(
                    transactions && transactions.length > 0
                      ? "text-green-700 border-green-300 hover:bg-green-50 focus:ring-green-500"
                      : "text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-gray-500 cursor-not-allowed",
                    "inline-flex items-center h-10 px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                  )}
                  onClick={() => exportToXLSData()}
                  disabled={
                    transactions && transactions.length > 0 ? false : true
                  }
                >
                  <ArrowDownTrayIcon
                    className={classNames(
                      transactions && transactions.length > 0
                        ? "text-green-500"
                        : "text-gray-500",
                      "self-center flex-shrink-0 w-5 h-5"
                    )}
                    aria-hidden="true"
                  />
                  <span className="sr-only">Exporter en XLS</span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-8 overflow-x-auto">
          <table
            className="w-full text-sm text-left text-gray-500 dark:text-gray-400"
            style={{ borderSpacing: 0 }}
          >
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                {tableHeader.map((th, index) => (
                  <th key={index} scope="col" className="px-4 py-3">
                    {th}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="items-center">
              {transactions && transactions.length > 0 ? (
                transactions.map((transaction: any, transactionIdx: number) => (
                  <tr
                    key={transactionIdx}
                    className={
                      transactionIdx % 2 === 0 ? "bg-white" : "bg-gray-100"
                    }
                  >
                    <th
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {(transactionIdx as number) + 1}
                    </th>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {currentSuperStation?.id === transaction.data.senderID ? (
                        <img
                          className="w-10 h-10 p-1 border rounded-full"
                          src={
                            transaction.data.receiverProfilImageUrl
                              ? transaction.data.receiverProfilImageUrl
                              : `https://ui-avatars.com/api/?background=random&name=${
                                  transaction.data.receiverDisplayName || "S"
                                }`
                          }
                          alt=""
                        />
                      ) : (
                        <img
                          className="w-10 h-10 p-1 border rounded-full lg:w-10"
                          src={
                            transaction.data.senderProfilImageUrl
                              ? transaction.data.senderProfilImageUrl
                              : `https://ui-avatars.com/api/?background=random&name=${
                                  transaction.data.senderDisplayName || "S"
                                }`
                          }
                          alt=""
                        />
                      )}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {transaction.data.receiverDisplayName ?? "-"}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {transaction.data.receiverPhoneNumber ?? "-"}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {new Intl.NumberFormat().format(transaction.data.amount)}{" "}
                      Fcfa
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {transactionMap.get(transaction.data.transactionType)}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {transaction.data.accountType === "MAIN" ? (
                        <img
                          className="w-auto h-5"
                          src={SankMoneyIcon}
                          alt="Sank"
                        />
                      ) : (
                        <BoltIcon className="w-6 h-6 text-red-500" />
                      )}
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {transaction.data.fees as string} Fcfa
                    </td>
                    <td
                      className={classNames(
                        transactionIdx !== transactions.length - 1
                          ? "border-b border-gray-200"
                          : "",
                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                      )}
                    >
                      {(transaction.data.createdAt as Timestamp)
                        .toDate()
                        .toLocaleString()}
                    </td>
                  </tr>
                ))
              ) : (
                <NoContent />
              )}
            </tbody>
          </table>

          <Pagination
            skip={defaultLoadHistories}
            take={defaultLoadHistories}
            total={defaultLoadHistories}
            collectionName={transactionCollectionName}
            isChangedPage={true}
            onPageChange={(pageIndex: number) => pageIndex}
            onPageLimitChange={(pageLimit: number) =>
              setTransDataLimitLength(pageLimit)
            }
          />
        </div>
      </div>
      <Modal
        isOpen={showAddDepositModal}
        maxSize="md"
        closeModal={() => setShowAddDepositModal(false)}
        modalTitle="Déposé une caution."
      >
        <div>
          <Form
            onSubmit={(data) => {
              setReloadData(data);
              setShowPinCodeModal(true);
              setShowAddDepositModal(false);
            }}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  canReceiveCommission: yup.boolean().optional(),
                  amount: yup
                    .string()
                    .required("Le champs montant est obligatoire"),
                })
              ),
              defaultValues: {
                canReceiveCommission: user ? user.canReceiveCommission : false,
              },
            }}
            cancelButtonLabel="Annuler"
            onCancel={() => {
              setShowAddDepositModal(false);
            }}
            submitButtonLabel="Déposer"
          >
            <div className="flex flex-col content-center w-full px-5 mt-5 align-top md:flex-row">
              <div className="w-full mx-auto ">
                <FormCheckToggle
                  name="canReceiveCommission"
                  label="Les stations doivent percevoir une commission"
                />

                <FormInput
                  name="amount"
                  label={`Montant (${amountFormater(depositAmount)} FCFA)`}
                  type="number"
                  onChange={(e) =>
                    setDepositAmount(
                      parseInt(e.target.value ? e.target.value : "0")
                    )
                  }
                  min={100}
                  placeholder="100.000"
                />
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => setShowPinCodeModal(false)}
      >
        <div className="flex justify-center">
          <CodePinComponent
            onSubmit={onSubmit}
            disabled={isCodePinBtnDesabled}
            isLoad={isLoad}
          />
        </div>
      </Modal>
    </>
  );
};

export default AddSuperStationDepositAmount;
