import {
  CalendarDaysIcon,
  ViewfinderCircleIcon,
} from "@heroicons/react/24/outline";
import HasRoles from "../../components/HasRoles";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../utils";
import NoContent from "../../components/TableNoContentPage/NoContent";
import Pagination from "../../components/Pagination/Pagination";
import { useCallback, useContext, useEffect, useState } from "react";
import TableRowEditMenu from "../../components/TableRowEditMenu/TableRowEditMenu";
import {
  eventsCollections,
  ticketsCollections,
  usersCollectionName,
} from "../../config";
import { db, functions } from "../../firebase";
import { UserContext } from "../../contexts";
import { EventsStatus } from "../../interfaces/ticket.interface";
import Modal from "../../components/Modal";
import ReadeQrcode from "../../components/Qrcode/ReadeQrcode";
import { useConfirm } from "../../hooks/useConfirm";
import toast from "react-hot-toast";
import { Timestamp } from "firebase-admin/firestore";

const tableHeader = [
  "N°",
  "Evenements",
  "Lieu",
  "Participants",
  "Ville",
  "Organisateur",
  "Categorie",
  "Status",
  "Actions",
];

const OrganizerProfilePage = () => {
  const navigate = useNavigate();
  const [events, setEvents] = useState<any[]>([]);
  const [eventData, setEventData] = useState<any>();
  const [userData, setUserData] = useState<any>();
  const [ticketType, setTicketType] = useState<any>();
  const { user } = useContext(UserContext);
  const [isDataGet, setIsDataGet] = useState(false);
  const { isConfirmModal } = useConfirm();
  const [qrcodeScannedData, setQrcodeScannedData] = useState<string>("");
  const [ticketData, setTicketData] = useState<any>();
  const [showTicketQrcodeReadeModal, setShowTicketQrcodeReadeModal] =
    useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [retrieveBilletriesLimit, setRetrieveBilletriesLimit] = useState(100);
  const [isLoading, setIsLoading] = useState(true);

  const sankMoneyUpdateTicketStatusFn = functions.httpsCallable(
    "sankMoneyUpdateTicketStatusFn"
  );

  const getAllEvents = useCallback(async () => {
    setIsLoading(true);
    const merchantRef = db.doc(`${usersCollectionName}/${user?.id}`);
    const eventsSnap = await db
      .collection(eventsCollections)
      .where("merchantRef", "==", merchantRef)
      .limit(retrieveBilletriesLimit)
      .get();

    const events: any[] = [];
    for (const d of eventsSnap.docs) {
      const organizer = (await d.data()?.organizatorRef.get()).data();
      const categoryRef = (await d.data()?.categoryRef.get()).data();

      const dt = d.data();
      events.push({
        id: d.id,
        data: {
          title: dt.title,
          locationName: dt.locationName,
          maxCapacity: dt.maxCapacity,
          organizer: organizer.name,
          city: dt.city,
          category: categoryRef.categoryType,
          status: dt.status,
        },
      });
    }
    setEvents(events);
    setIsLoading(false);
  }, [retrieveBilletriesLimit, user?.id]);

  const onEventDetails = useCallback(
    (row: any, id: string) => {
      if (row) {
        navigate(`/billetterie/events/${id}/detail`, {
          state: { eventId: id },
        });
      }
    },
    [navigate]
  );

  const onTicketDetails = useCallback(
    (row: any, id: string) => {
      if (row) {
        navigate(`/billetterie/events/${id}/detail_ticket`, {
          state: { eventId: id },
        });
      }
    },
    [navigate]
  );

  const onMemberToScanTicket = useCallback(
    (row: any, id: string) => {
      if (row) {
        navigate(`/billetterie/events/${id}/members`, {
          state: { eventId: id },
        });
      }
    },
    [navigate]
  );

  const changeUserState = async (status: string, typeRef: any) => {
    const type = await typeRef.get();
    if (new Date(type.data()?.ticketExpiringDate.toDate()) > new Date()) {
      if (status === "out") {
        const isConfirm = await isConfirmModal(
          "Opération irréversible",
          "Voulez-vous valider le ticket ?",
          "Oui valider",
          true
        );
        if (isConfirm) {
          const response = sankMoneyUpdateTicketStatusFn({
            collectionName: ticketsCollections,
            documentData: {
              status: "in"
            },
            documentId: qrcodeScannedData
          }).then(() => {
            navigate("/billetterie")
            setShowDetailsModal(false);
          })
          toast.promise(response, {
            error: "La Validation a échouer",
            success: `Le ticket a été Validé`,
            loading: "Validation...",
          });
        }
      } else {
        const isConfirm = await isConfirmModal(
          "Opération irréversible",
          "Le client demande une permission pour sortir. Voulez-vous accorder ?",
          "Oui accorder",
          true
        );
        if (isConfirm) {
          const response = sankMoneyUpdateTicketStatusFn({
            collectionName: ticketsCollections,
            documentData: {
              status: "out"
            },
            documentId: qrcodeScannedData
          }).then(() => {
            navigate("/billetterie")
            setShowDetailsModal(false);
          })
          toast.promise(response, {
            error: "L' accord de la permission a échouer",
            success: `Permission accordée`,
            loading: "Demande de permission...",
          });
        } else {
          toast.error("Permission annulée");
          navigate("/billetterie");
        }
      }
    }
  };

  const getTicketData = async () => {
    if (qrcodeScannedData && !isDataGet) {
      await db
        .collection(ticketsCollections)
        .doc(qrcodeScannedData)
        .get()
        .then(async (doc) => {
          const data = doc.data();
          setShowDetailsModal(true);
          setShowTicketQrcodeReadeModal(false);
          setIsDataGet(true);
          setTicketData({ ...data });
          getEventData(data?.eventRef);
          getUserData(data?.userId);
          getTicketTypeData(data?.ticketTypeRef);
          // return data;
        });
    } else {
      // setShowTicketQrcodeReadeModal(true);
    }
  };

  const getEventData = useCallback(async (eventRef: any) => {
    try {
      const event = await eventRef.get();
      if (event.exists) {
        setEventData(event.data());
      }
    } catch (error) {
      console.log("");
    }
  }, []);

  const getUserData = useCallback(async (userId: any) => {
    try {
      const user = await db.collection(usersCollectionName).doc(userId).get();
      if (user.exists) {
        setUserData(user.data());
      }
    } catch (error) {
      console.log("User don't exist", error);
    }
  }, []);

  const getTicketTypeData = useCallback(async (typeRef: any) => {
    try {
      const type = await typeRef.get();
      if (type.exists) {
        setTicketType(type.data());
      }
    } catch (error) {
      console.log("not fund type", error);
    }
  }, []);

  const onQrcodeResult = (result: any) => {
    setShowTicketQrcodeReadeModal(false);
    if (result) {
      setQrcodeScannedData(result[0]);
      setShowTicketQrcodeReadeModal(false);
      getTicketData();
    }
  };
  const onQrcodeError = (error: any) => {
    setShowTicketQrcodeReadeModal(false);
  };

  useEffect(() => {
    getAllEvents();
  }, [getAllEvents]);

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="flex flex-col">
        <div className="">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les evènements
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ici l'ensemble de vos evènements.
          </p>
        </div>
      </div>
      <div className="h-6" />
      <div className="flex self-end justify-end max-md:flex-col">
        <HasRoles userRole={["MERCHANT"]}>
          <>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
                onClick={(e) => {
                  navigate("/billetterie/events/new_event");
                }}
              >
                <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                  <CalendarDaysIcon
                    className="self-center flex-shrink-0 w-5 h-5 mr-1 text-orange-500"
                    aria-hidden="true"
                  />
                  Ajouter un evènement
                </span>
              </button>
            </div>
          </>
        </HasRoles>
        <HasRoles userRole={["MERCHANT"]}>
          <>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <button
                type="button"
                className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
                onClick={(e) => {
                  setShowTicketQrcodeReadeModal(true);
                }}
              >
                <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                  <ViewfinderCircleIcon
                    className="self-center flex-shrink-0 w-5 h-5 mr-1 text-orange-500"
                    aria-hidden="true"
                  />
                  Scanner le ticket
                </span>
              </button>
            </div>
          </>
        </HasRoles>
      </div>
      <div className="flex flex-col mt-8">
        {
          isLoading ? (
            <>
              <div className="p-4 animate-pulse">
                <div className="h-4 mt-3 mb-6 bg-gray-400 rounded"></div>
                {[...Array(5)].map((e, i) => (
                  <div key={i}>
                    <div className="h-4 mb-6 bg-gray-200 rounded"></div>
                    <div className="h-4 mb-6 bg-gray-300 rounded"></div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="shadow-sm">
                  <table
                    className="min-w-full border-separate"
                    style={{ borderSpacing: 0 }}
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        {tableHeader.map((th, index) => (
                          <th
                            key={index}
                            scope="col"
                            className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                          >
                            {th}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {events && events.length > 0 ? (
                        events.map((event: any, eventIdx: number) => (
                          <tr
                            key={eventIdx}
                            className={
                              eventIdx % 2 === 0
                                ? "bg-white"
                                : "bg-gray-100 cursor-pointer"
                            }
                          // onClick={() =>
                          //   onDetails(transaction.data, transaction.id)
                          // }
                          >
                            <td
                              className={classNames(
                                eventIdx !== events.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(eventIdx as number) + 1}
                            </td>
                            <td
                              className={classNames(
                                eventIdx !== events.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {event.data?.title ?? ("-" as string)}
                            </td>
                            <td
                              className={classNames(
                                eventIdx !== events.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {event.data?.locationName ?? ("-" as string)}
                            </td>
                            <td
                              className={classNames(
                                eventIdx !== events.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {parseInt(event.data?.maxCapacity) ?? (0 as number)}
                            </td>

                            <td
                              className={classNames(
                                eventIdx !== events.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {event.data?.city ?? "-"}
                            </td>
                            <td
                              className={classNames(
                                eventIdx !== events.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {event.data.organizer ?? "-"}
                            </td>
                            <td
                              className={classNames(
                                eventIdx !== events.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {event.data.category ?? "-"}
                            </td>
                            <td
                              className={classNames(
                                eventIdx !== events.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                `whitespace-nowrap py-4 pl-4 pr-3 text-sm ${event.data?.status === EventsStatus.BLOCKED
                                  ? "text-red-500"
                                  : event.data?.status === EventsStatus.PENDING
                                    ? "text-orange-500"
                                    : "text-green-500"
                                }  sm:pl-6 lg:pl-8`
                              )}
                            >
                              {event.data?.status ?? "-"}
                            </td>
                            <td
                              className={classNames(
                                eventIdx !== events.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              <TableRowEditMenu
                                rowDetailsHandler={() =>
                                  onEventDetails(event, event?.id)
                                }
                                rowTicketHandler={() =>
                                  onTicketDetails(event, event?.id)
                                }
                                rowMemberToScanTicketHandler={() =>
                                  onMemberToScanTicket(event, event?.id)
                                }
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoContent />
                      )}
                    </tbody>
                  </table>
                  <Pagination
                    skip={events.length}
                    take={events.length}
                    total={events.length}
                    collectionName={eventsCollections}
                    isChangedPage={true}
                    onPageChange={(pageIndex: number) => pageIndex}
                    onPageLimitChange={(limit) => setRetrieveBilletriesLimit(limit)}
                  // balance={transactionTotalSum}
                  />
                </div>
              </div>
            </div>
          )
        }

        <Modal
          isOpen={showDetailsModal}
          maxSize="lg"
          closeModal={() => setShowDetailsModal(false)}
        >
          {ticketData && (
            <div className="flex justify-center">
              <div className="w-full">
                <div className="m-3 sm:max-w-lg sm:w-full sm:mx-auto">
                  <div className="relative flex flex-col bg-white shadow-lg pointer-events-auto rounded-xl dark:bg-gray-800">
                    <div className="p-4 overflow-y-auto sm:p-7">
                      <div className="text-center">
                        <h3 className="text-lg font-semibold text-gray-800 dark:text-gray-200">
                          Evénement:{" "}
                          <span className="font-bold text-sky-400">
                            {eventData?.title ?? ""}
                          </span>
                        </h3>
                        {/* <p className="text-sm text-gray-500">
                        <span className="font-bold">{ticketData?.transId}</span>
                      </p> */}
                      </div>

                      <div className="grid grid-cols-2 gap-5 mt-5 sm:mt-10 sm:grid-cols-2">
                        <div>
                          <span className="block text-xs text-gray-500 uppercase">
                            Nom du client:
                          </span>
                          <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                            {userData?.displayName ?? ""}
                          </span>
                        </div>

                        <div>
                          <span className="block text-xs text-gray-500 uppercase">
                            N° du Ticket:
                          </span>
                          <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                            {ticketData?.id ?? ""}
                          </span>
                        </div>
                        <div>
                          <span className="block text-xs text-gray-500 uppercase">
                            Numero du client:
                          </span>
                          <span className="block text-sm font-medium text-gray-800 dark:text-gray-200">
                            {userData?.phoneNumber ?? ""}
                          </span>
                        </div>
                      </div>
                      {new Date(ticketType?.ticketExpiringDate.toDate()) <
                        new Date() ? (
                        <div className="mt-5 sm:mt-10">
                          <h4 className="text-xs font-semibold text-red-800 uppercase dark:text-gray-200">
                            Le ticket pour cet événement a expiré
                          </h4>
                        </div>
                      ) : !ticketData?.isValid ||
                        eventData?.status === EventsStatus.BLOCKED ? (
                        <div className="mt-5 sm:mt-10">
                          <h4 className="text-xs font-semibold text-red-800 uppercase dark:text-gray-200">
                            {`Le ticket pour cet evénement est ${eventData?.status === EventsStatus.BLOCKED
                              ? "bloquer"
                              : !ticketData?.isValid
                                ? "invalide"
                                : "indisponible"
                              }`}
                          </h4>
                        </div>
                      ) : (
                        <div className="mt-5 sm:mt-10">
                          <h4 className="text-xs font-semibold text-gray-800 uppercase dark:text-gray-200">
                            Details
                          </h4>

                          <ul className="flex flex-col mt-3">
                            <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                              <div className="flex items-center justify-between w-full">
                                <span>Nombre de place</span>
                                <span>{ticketData?.numberOfPlace ?? 0}</span>
                              </div>
                            </li>
                            <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                              <div className="flex items-center justify-between w-full">
                                <span>Prix</span>
                                <span>{ticketData?.paidTicketPrice ?? 0}</span>
                              </div>
                            </li>
                            <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                              <div className="flex items-center justify-between w-full">
                                <span>Position du client</span>
                                <span>{ticketData?.status}</span>
                              </div>
                            </li>
                            <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                              <div className="flex items-center justify-between w-full">
                                <span>Type de ticket</span>
                                <span>{ticketType?.standard ?? ""}</span>
                              </div>
                            </li>
                            <li className="inline-flex items-center px-4 py-3 -mt-px text-sm text-gray-800 border gap-x-2 first:rounded-t-lg first:mt-0 last:rounded-b-lg dark:border-gray-700 dark:text-gray-200">
                              <div className="flex items-center justify-between w-full">
                                <span>Date de paiement</span>
                                <span>{
                                  (ticketData.createdAt as Timestamp)
                                    .toDate()
                                    .toLocaleString()}</span>
                              </div>
                            </li>
                          </ul>
                        </div>
                      )}
                      {
                        ticketData?.status === "out" ? (
                          <div className="flex mt-4 space-x-3 md:mt-5">
                            <div
                              className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                              onClick={() => changeUserState(ticketData?.status, ticketData?.ticketTypeRef)}
                            >
                              Valider
                            </div>
                            <div
                              className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                              onClick={() => {
                                setShowDetailsModal(false);
                              }}
                            >
                              Annuler
                            </div>
                          </div>
                        ) : (
                          <div className="flex mt-4 space-x-3 md:mt-5">
                            <div
                              className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                              onClick={() => changeUserState(ticketData?.status, ticketData?.ticketTypeRef)}
                            >
                              Demande de permission
                            </div>
                            <div
                              className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                              onClick={() => {
                                setShowDetailsModal(false);
                              }}
                            >
                              Annuler
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </div>
      <ReadeQrcode
        onQrcodeResult={(result: any) => onQrcodeResult(result)}
        onQrcodeError={(error: any) => onQrcodeError(error)}
        showQrcodeReadeModal={showTicketQrcodeReadeModal}
        setShowQrcodeReadeModal={setShowTicketQrcodeReadeModal}
      />
    </div>
  );
};
export default OrganizerProfilePage;
