import { useCallback, useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../../components/FirestoreTable";
import { transactionMap } from "../../../interfaces";
import { getTimestamp, parseDate } from "../../../utils";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { auditLogCollectionName } from "../../../config";
import { db } from "../../../firebase";
import PhoneInput from "react-phone-number-input";
import moment from "moment";
import { Timestamp } from "firebase-admin/firestore";

const columns: IColumn[] = [
  {
    field: "date",
    label: "Date d'initiation",
  },
  {
    field: "senderPhoneNumber",
    label: "Expéditeur",
  },
  {
    field: "receiverPhoneNumber",
    label: "Destinataire",
  },
  {
    field: "transactionType",
    label: "Type d'opération",
  },
  {
    field: "amount",
    label: "Montant",
  },
  {
    field: "createdAt",
    label: "Date de création",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return parseDate(value as Timestamp);
    case "date":
      return parseDate(value as Timestamp);
    case "senderPhoneNumber":
      return <span>{value}</span>;
    case "receiverPhoneNumber":
      return <span>{value}</span>;
    case "amount":
      return <span>{new Intl.NumberFormat().format(value)} Fcfa</span>;
    case "transactionType":
      return <span>{transactionMap.get(value as string)} </span>;
    default:
      return value as string;
  }
};

const TransactionsAuditLogs = () => {
  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(moment(Date.now()).startOf("hour").format("YYYY-MM-DD"));
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    moment(Date.now()).format("YYYY-MM-DD")
  );

  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithEndDateValue(value.target.value);
  };

  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);

  const [phoneNumber, setPhoneNumber] = useState<string | null>(null);

  const retrivePhoneNumber = useCallback(async (phoneNumber: string) => {
    if (phoneNumber && phoneNumber.length >= 12) {
      setPhoneNumber(phoneNumber);
    }

    if (!phoneNumber || phoneNumber.length < 12) {
      setPhoneNumber(null);
    }
  }, []);

  const firestoreTableQueriesWhere = useMemo(() => {
    let queries: {
      fieldPath: string;
      opStr: string;
      value: string | any;
    }[] = [];

    if (startTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: ">=",
        value: startTime,
      });
    }

    if (endTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: "<=",
        value: endTime,
      });
    }

    if (phoneNumber) {
      queries = [
        {
          fieldPath: "senderPhoneNumber",
          opStr: "==",
          value: phoneNumber,
        },
      ];
    }

    return queries;
  }, [startTime, endTime, phoneNumber]);

  const getFilteredByDate = async () => {
    if (seachWithStartDateValue) {
      setStartTime(getTimestamp(seachWithStartDateValue, true));
    }

    if (seachWithEndDateValue) {
      setEndTime(getTimestamp(seachWithEndDateValue, false));
    }
  };

  useEffect(() => {
    getFilteredByDate();
  }, []);

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="flex flex-col">
        <div className="">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les audit Logs
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ci-dessous tous les audit logs des utilisateurs.
          </p>
        </div>
        <div className="h-8" />
        <div className="flex self-end justify-between max-md:flex-col">
          <div className="mt-4 mr-2 sm:mt-0 sm:ml-1 sm:flex-none">
            <PhoneInput
              international={false}
              placeholder="Numéro de téléphone"
              className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
              defaultCountry="BF"
              onChange={retrivePhoneNumber}
            />
          </div>
          <div className="flex px-1 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
            <div className="mr-1">
              <label
                htmlFor="dateDebut"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Date début
              </label>
              <input
                type="date"
                id="dateDebut"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={seachWithStartDateValue}
                onChange={handleChangeStartDateValue}
              />
            </div>
            <div className="mr-1">
              <label
                htmlFor="dateFin"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Date fin
              </label>
              <input
                type="date"
                id="dateFin"
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                value={seachWithEndDateValue}
                onChange={handleChangeEndDtaeValue}
              />
            </div>
            <div className="flex items-center justify-center mt-5 h-14">
              <div className="flex">
                <button
                  type="button"
                  className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  onClick={() => {
                    getFilteredByDate();
                  }}
                >
                  <FunnelIcon
                    className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                    aria-hidden="true"
                  />
                  Filtrer
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FirestoreTable
        collectionRef={db.collection(auditLogCollectionName)}
        condensed
        collectionQueriesWhere={firestoreTableQueriesWhere}
        columns={columns}
        rowRender={renderRow}
        paginate={{
          pageIndex: 0,
          limit: 100,
        }}
      />
    </div>
  );
};

export default TransactionsAuditLogs;
