import { CheckCircleIcon, XCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import toast, { Toaster, ToastPosition } from 'react-hot-toast';
import { classNames } from '../../utils';

/* eslint-disable-next-line */
export interface CustomToasterProps {
  position?: ToastPosition;
  duration?: number;
  showProgressBar?: boolean;
}

export function CustomToaster({
  position = 'top-right',
  duration = 5000,
  showProgressBar = true,
}: CustomToasterProps) {
  return (
    <Toaster position={position} toastOptions={{ duration }}>
      {(toaster) => {
        const { type, message } = toaster;
        return (
          <div
            className={classNames(
              toaster.visible ? 'animate-enter' : 'animate-leave',
              type === 'success'
                ? 'bg-green-500 ring-green-900 ring-opacity-30'
                : '',
              type === 'error' ? 'bg-red-500 ring-red-900 ring-opacity-30' : '',
              type !== 'error' && type !== 'success'
                ? 'bg-white ring-black ring-opacity-5'
                : '',
              'max-w-sm w-full shadow-lg rounded-lg pointer-events-auto ring-1  overflow-hidden'
            )}
          >
            <div className="relative p-4">
              <div className="flex items-start">
                <div className="flex-shrink-0">
                  {type === 'loading' && (
                    <svg
                      className="w-8 h-8 mr-3 -ml-1 text-gray-500 animate-spin"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                      ></path>
                    </svg>
                  )}
                  {type === 'success' && (
                    <CheckCircleIcon
                      className="w-8 h-8 text-gray-50"
                      aria-hidden="true"
                    />
                  )}
                  {type === 'error' && (
                    <XCircleIcon
                      className="w-8 h-8 text-gray-50"
                      aria-hidden="true"
                    />
                  )}
                </div>
                <div className="ml-3 w-0 flex-1 pt-0.5">
                  <p
                    className={classNames(
                      !['success', 'error'].includes(type)
                        ? 'text-gray-700'
                        : 'text-white',
                      'text-sm font-medium  '
                    )}
                  >
                    {message?.toString()}
                  </p>
                </div>
                {type !== 'loading' && (
                  <div className="flex flex-shrink-0 ml-4">
                    <button
                      className={classNames(
                        type === 'success'
                          ? 'text-green-500 hover:text-green-700 bg-green-50 focus:ring-green-700'
                          : '',
                        type === 'error'
                          ? 'text-red-500 hover:text-red-700 bg-red-50 focus:ring-red-700'
                          : '',
                        !['success', 'error'].includes(type)
                          ? 'text-gray-400 hover:text-gray-500 bg-white focus:ring-indigo-500'
                          : '',
                        'inline-flex rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 '
                      )}
                      onClick={() => {
                        toast.dismiss(toaster.id);
                      }}
                    >
                      <span className="sr-only">Close</span>
                      <XMarkIcon className="w-5 h-5" aria-hidden="true" />
                    </button>
                  </div>
                )}
              </div>
              {/* ProgressBar */}
              {showProgressBar && (
                <div
                  className="absolute bottom-0 left-0 w-full h-1 bg-transparent rounded"
                  role="progressbar"
                  aria-valuemin={0}
                  aria-valuemax={100}
                >
                  <div
                    className={classNames(
                      'bg-white rounded h-1 transition-width '
                    )}
                    style={{
                      width: `${toaster.type !== 'loading' ? 0 : 100}%`,
                      transitionDuration: `${duration + 1000}ms`,
                    }}
                  ></div>
                </div>
              )}
            </div>
          </div>
        );
      }}
    </Toaster>
  );
}

export default CustomToaster;
