// Import the functions you need from the SDKs you need
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import "firebase/compat/firestore";
import "firebase/compat/analytics";
import "firebase/compat/app";
import "firebase/compat/storage";
import 'firebase/compat/functions';

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBvCpw-lT_32tyd_b19UmJCbX8HQ0gHoL0",
  authDomain: "sank-refonte.firebaseapp.com",
  projectId: "sank-refonte",
  storageBucket: "sank-refonte.appspot.com",
  messagingSenderId: "163539199203",
  appId: "1:163539199203:web:7a86f08074fad4669151d6",
};

// Initialize Firebase
const app = firebase.initializeApp(firebaseConfig);
const analytics = firebase.analytics(app);
const auth = firebase.auth(app);
auth.languageCode = "fr";
auth.useDeviceLanguage();
const functions = app.functions('europe-west1');
const db = firebase.firestore(app);
const storage = firebase.storage(app)
// window.recaptchaVerifier = new RecaptchaVerifier(
//   document.getElementsByClassName("firebaseui-recaptcha-container"),
//   {
//     size: "invisible",
//     callback: (response) => {
//       // reCAPTCHA solved, allow signInWithPhoneNumber.
//       //onSignInSubmit();
//     },
//   },
//   app.auth()
// );


export { auth, db, analytics, functions, storage };
