import { CalendarDaysIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import HasRoles from "../../../components/HasRoles";
import { useNavigate, useLocation } from "react-router-dom";
import { classNames } from "../../../utils";
import NoContent from "../../../components/TableNoContentPage/NoContent";
//  import Pagination from "../../../components/Pagination/Pagination";
import { useCallback, useContext, useEffect, useState } from "react";
import TableRowEditMenu from "../../../components/TableRowEditMenu/TableRowEditMenu";
import { sankMoneyTicketScanMembers, usersCollectionName } from "../../../config";
import { db, functions } from "../../../firebase";
import { UserContext } from "../../../contexts";
import Modal from "../../../components/Modal";
import PhoneInput from "react-phone-number-input";
import { UserInterface } from "../../../interfaces";
import { useConfirm } from "../../../hooks/useConfirm";
import toast from "react-hot-toast";


const tableHeader = [
    "N°",
    "Nom/Prénoms",
    "Téléphone",
    "Ville",
    "Active",
    "Certifié",
    "Actions",
];

const UserToScanTicketPage = () => {
    const navigate = useNavigate();
    const [usersDataToScan, setUsersDataToScan] = useState<UserInterface[] | undefined>([])
    const [userData, setUserData] = useState<UserInterface | any>();
    const [documentId, setDocumentId] = useState<string>("")
    const { user } = useContext(UserContext)
    const { isConfirmModal } = useConfirm();
    const [showDetailsModal, setShowDetailsModal] = useState(false);
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(true);
    const CurrentEventId = location.state?.eventId;

    const sankMoneyAddMembersToEventsFn = functions.httpsCallable(
        "sankMoneyAddMembersToEventsFn"
    );
    const sankMoneyAllowTicketScanningFn = functions.httpsCallable(
        "sankMoneyAllowTicketScanningFn"
    );

    const getAllEvents = useCallback(async () => {
        setIsLoading(true);
        await db
            .collection(sankMoneyTicketScanMembers)
            .where("merchantId", "==", user?.id)
            .where("eventIds", "array-contains", CurrentEventId)
            .get()
            .then(async (event) => {
                const usersData: any[] = [];
                await Promise.all(
                    event.docs.map(async (d: any) => {
                        setDocumentId(d.data()?.id)
                        const userDoc = await db.collection(usersCollectionName).doc(d.data()?.userId).get();
                        if (userDoc.exists) {
                            usersData.push(userDoc.data());
                        }
                    })
                );

                setUsersDataToScan(usersData);
                setIsLoading(false);
            });
    }, [CurrentEventId, user?.id]);

    const retrivePhoneNumber = async (phoneNumber: string) => {
        if (phoneNumber.length >= 12) {
            await db
                .collection(usersCollectionName)
                .where("phoneNumber", "==", phoneNumber)
                .get()
                .then((response) => {
                    setUserData(
                        response.docs.map((d) => {
                            return { id: d.id, data: d.data() };
                        })
                    );
                });
        } else {
            setUserData([]);
        }
    };

    const onScanTicket = async () => {
        const isConfirm = await isConfirmModal(
            "Opération irréversible",
            "Êtes vous sûr de vouloir autoriser le scan des tickets de cet evenement ?",
            "Oui autorisé",
            true
        );
        if (isConfirm) {
            const response = sankMoneyAllowTicketScanningFn({
                collectionName: sankMoneyTicketScanMembers,
                documentData: {
                    [CurrentEventId] : true,
                },
                documentId: documentId
            })
            toast.promise(response, {
                error: "L' autorisation à échouer",
                success: "Autorisation accordée",
                loading: "Autorisation encours...",
            });
            
        }
    };
    const onBlockedScanTicket = async () => {
        const isConfirm = await isConfirmModal(
            "Opération irréversible",
            "Êtes vous sûr de vouloir bloquer le scan des tickets de cet evenement par cet utilisateur ?",
            "Oui bloquer",
            true
        );
        if (isConfirm) {
            const response = sankMoneyAllowTicketScanningFn({
                collectionName: sankMoneyTicketScanMembers,
                documentData: {
                    [CurrentEventId] : false,
                },
                documentId: documentId
            })
            toast.promise(response, {
                error: "L' autorisation à échouer",
                success: "Bloqué avec succès",
                loading: "Bloquage encours...",
            });
           
        }
    };

    const addMembersToEvents = async (userId: string) => {
        const data = {
            userId: userId,
            merchantId: user?.id,
            eventId: CurrentEventId
        }
        const response = sankMoneyAddMembersToEventsFn(data).then(()=>{
            setShowDetailsModal(false);
        })
        toast.promise(response, {
            error: (e) => e.message,
            success:
              "L' utilisateur a été ajouté avec succès",
            loading: "Ajout de membre...",
          });
    }

    useEffect(() => {
        getAllEvents();
    }, [CurrentEventId])
    return (
        <div className="px-4 mt-10 sm:px-6 lg:px-8">
            <div className="flex flex-col">
                <div className="">
                    <h1 className="py-2 text-xl font-semibold text-gray-900">
                        Les membres
                    </h1>
                    <p className="mt-2 text-sm text-gray-700">
                        Vous trouverez ici l'ensemble de membres pouvant scanner cet evenement.
                    </p>
                </div>
            </div>
            <div className="h-6" />
            <div className="flex self-end justify-end max-md:flex-col">
                <HasRoles userRole={["MERCHANT"]}>
                    <>
                        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                            <button
                                type="button"
                                className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
                                onClick={(e) => {
                                    setShowDetailsModal(true);
                                }}
                            >
                                <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                                    <CalendarDaysIcon
                                        className="self-center flex-shrink-0 w-5 h-5 mr-1 text-orange-500"
                                        aria-hidden="true"
                                    />
                                    Ajouter une personne
                                </span>
                            </button>
                        </div>
                    </>
                </HasRoles>
            </div>
            <div className="flex flex-col mt-8">
                {
                    isLoading ? (
                        <>
                        <div className="p-4 animate-pulse">
                        <div className="h-4 mt-3 mb-6 bg-gray-400 rounded"></div>
                        {[...Array(5)].map((e, i) => (
                          <div key={i}>
                            <div className="h-4 mb-6 bg-gray-200 rounded"></div>
                            <div className="h-4 mb-6 bg-gray-300 rounded"></div>
                          </div>
                        ))}
                      </div>
                      </>
                    ) : (
                        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle">
                        <div className="shadow-sm">
                            <table
                                className="min-w-full border-separate"
                                style={{ borderSpacing: 0 }}
                            >
                                <thead className="bg-gray-50">
                                    <tr>
                                        {tableHeader.map((th, index) => (
                                            <th
                                                key={index}
                                                scope="col"
                                                className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                                            >
                                                {th}
                                            </th>
                                        ))}
                                    </tr>
                                </thead>
                                <tbody className="bg-white divide-y divide-gray-200">
                                    {usersDataToScan && usersDataToScan.length > 0 ? (
                                        usersDataToScan.map((user: any, userIdx: number) => (
                                            <tr
                                                key={userIdx}
                                                className={
                                                    userIdx % 2 === 0
                                                        ? "bg-white"
                                                        : "bg-gray-100 cursor-pointer"
                                                }
                                            // onClick={() =>
                                            //   onDetails(transaction.data, transaction.id)
                                            // }
                                            >
                                                <td
                                                    className={classNames(
                                                        userIdx !== usersDataToScan.length - 1
                                                            ? "border-b border-gray-200"
                                                            : "",
                                                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                                    )}
                                                >
                                                    {(userIdx as number) + 1}
                                                </td>
                                                <td
                                                    className={classNames(
                                                        userIdx !== usersDataToScan.length - 1
                                                            ? "border-b border-gray-200"
                                                            : "",
                                                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                                    )}
                                                >
                                                    {(user?.displayName ?? "-" as string)}
                                                </td>
                                                <td
                                                    className={classNames(
                                                        userIdx !== usersDataToScan.length - 1
                                                            ? "border-b border-gray-200"
                                                            : "",
                                                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                                    )}
                                                >
                                                    {(user?.phoneNumber ?? "-" as string)}
                                                </td>
                                                <td
                                                    className={classNames(
                                                        userIdx !== usersDataToScan.length - 1
                                                            ? "border-b border-gray-200"
                                                            : "",
                                                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                                    )}
                                                >
                                                    {(user?.city ?? "-" as string)}
                                                </td>
                                                <td
                                                    className={classNames(
                                                        userIdx !== usersDataToScan.length - 1
                                                            ? "border-b border-gray-200"
                                                            : "",
                                                        "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                                                    )}
                                                >
                                                    {user?.active ? (
                                                        <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                                    ) : (
                                                        <XCircleIcon className="w-6 h-6 text-red-500" />
                                                    )}
                                                </td>
                                                <td
                                                    className={classNames(
                                                        userIdx !== usersDataToScan.length - 1
                                                            ? "border-b border-gray-200"
                                                            : "",
                                                        `whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8`
                                                    )}
                                                >
                                                    {user?.isCertify ? (
                                                        <CheckCircleIcon className="w-6 h-6 text-green-500" />
                                                    ) : (
                                                        <XCircleIcon className="w-6 h-6 text-red-500" />
                                                    )}
                                                </td>
                                                <td
                                                    className={classNames(
                                                        userIdx !== usersDataToScan.length - 1
                                                            ? "border-b border-gray-200"
                                                            : "",
                                                        `whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8`
                                                    )}
                                                >
                                                    <TableRowEditMenu
                                                        rowScanTicketHandler={() => onScanTicket()}
                                                        rowBlockedScanTicketHandler={() => onBlockedScanTicket()}
                                                    />
                                                </td>
                                            </tr>
                                        ))
                                    ) : (
                                        <NoContent />
                                    )}
                                </tbody>
                            </table>
                            {/* <Pagination
                skip={events.length}
                take={events.length}
                total={events.length}
                collectionName={eventsCollections}
                isChangedPage={true}
                onPageChange={(pageIndex: number) => pageIndex}
                onPageLimitChange={() =>
                  getAllEvents()
                }
              // balance={transactionTotalSum}
              /> */}
                        </div>
                    </div>
                </div>
                    )
                }
                
                <Modal
                    isOpen={showDetailsModal}
                    maxSize="lg"
                    closeModal={() => setShowDetailsModal(false)}
                    modalTitle="Rechercher un utilisateur"
                >
                    <div className="flex justify-center">
                        <div className="w-full mt-10">
                            <PhoneInput
                                international={false}
                                placeholder="Entrer un numéro de téléphone"
                                className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                                defaultCountry="BF"
                                onChange={retrivePhoneNumber}
                            />
                            <div className="flex justify-center w-full">
                                {userData && userData.length > 0 && (
                                    <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                                        <div className="flex flex-col items-center pb-10">
                                            <img
                                                className="w-24 h-24 mt-2 mb-3 rounded-full shadow-lg"
                                                src={
                                                    userData[0].data.profilImageUrl
                                                        ? userData[0].data.profilImageUrl
                                                        : `https://ui-avatars.com/api/?background=random&name=${userData[0].data.displayName || "S"
                                                        }`
                                                }
                                                alt=""
                                            />
                                            <h5 className="mb-1 text-xl font-medium text-center text-gray-900 dark:text-white">
                                                {userData[0].data.displayName}
                                            </h5>
                                            <span className="text-sm text-gray-500 dark:text-gray-400">
                                                {userData[0].data.phoneNumber}
                                            </span>
                                            <span className="text-sm text-gray-500 dark:text-gray-400">
                                                Membre SankMoney depuis : {new Date(
                                                    userData[0].data.createdAt.seconds * 1000 +
                                                    userData[0].data.createdAt.nanoseconds / 1000000
                                                ).toLocaleDateString()}
                                            </span>
                                            <div className="flex mt-4 space-x-3 md:mt-5">
                                                <div
                                                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                                                    onClick={() => addMembersToEvents(userData[0]?.id)}
                                                >
                                                    Ajouter
                                                </div>
                                                <div
                                                    className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                                                    onClick={() => {
                                                        setShowDetailsModal(false);
                                                        setUserData([]);
                                                    }}
                                                >
                                                    Annuler
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </Modal>
            </div>
        </div>
    );
}
export default UserToScanTicketPage;