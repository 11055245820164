import {
  ChevronDownIcon,
  ChevronLeftIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { Typography } from "@material-tailwind/react";
import theme from "@material-tailwind/react/theme";
import React, { useState } from "react";
import {
  Sidebar,
  Menu,
  MenuItem,
  SubMenu,
  menuClasses,
  MenuItemStyles,
} from "react-pro-sidebar";
import { classNames } from "../../utils";
import { Link, NavLink } from "react-router-dom";
import { useRouter } from "../../hooks/useRouter";

const themes = {
  light: {
    sidebar: {
      backgroundColor: "#ff2d00",
      color: "#ffffff",
    },
    menu: {
      menuContent: "#ff2d00",
      icon: "#ffffff",
      hover: {
        backgroundColor: "#bf1e07",
        color: "#ffffff",
      },
      disabled: {
        color: "#9fb6cf",
      },
    },
  },
};
const hexToRgba = (hex: string, alpha: number) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${alpha})`;
};

// hex to rgba converter

interface MenuSideBarInterface {
  menuItems: any[];
}
type Theme = "light" | "dark";

const MenuSideBar = ({ menuItems }: MenuSideBarInterface) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [toggled, setToggled] = React.useState(false);
  const [broken, setBroken] = React.useState(false);
  const [hasImage, setHasImage] = React.useState(false);
  const router = useRouter();

  const menuItemStyles: MenuItemStyles = {
    root: {
      fontSize: "14px",
      fontWeight: 600,
    },
    icon: {
      color: themes["light"].menu.icon,
      [`&.${menuClasses.disabled}`]: {
        color: themes["light"].menu.disabled.color,
      },
    },
    SubMenuExpandIcon: {
      color: "white",
    },
    subMenuContent: ({ level }) => ({
      backgroundColor: sidebarCollapsed ? "#ff2d00" : "transparent",
    }),
    button: {
      [`&.${menuClasses.disabled}`]: {
        color: themes["light"].menu.disabled.color,
      },
      [`&.${menuClasses.active}`]: {
        backgroundColor: hexToRgba(
          themes["light"].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1
        ),
        color: themes["light"].menu.hover.color,
      },
      "&:hover": {
        backgroundColor: hexToRgba(
          themes["light"].menu.hover.backgroundColor,
          hasImage ? 0.8 : 1
        ),
        color: themes["light"].menu.hover.color,
      },
    },
    label: ({ open }) => ({
      fontWeight: open ? 600 : undefined,
    }),
  };

  return (
    <div className="flex flex-shrink-0 w-full mt-2">
      <div className="relative flex">
        {!broken && (
          <div className="absolute -top-[25px] -right-[16px] z-20">
            <button
              onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
              className={classNames(
                sidebarCollapsed === true ? "outline-none" : " border-2",
                "w-8 h-8 rounded-md text-white hover:bg-white hover:text-red-500 focus:outline-none"
              )}
            >
              <ChevronLeftIcon
                className={classNames(
                  sidebarCollapsed === true ? "rotate-180" : "rotate-0",
                  "h-4 w-4 font-semibold transition-transform ease-in-out duration-700"
                )}
              />
            </button>
          </div>
        )}
        {sidebarCollapsed && broken && (
          <div className="absolute top-0 right-0 z-20 pt-2 -mr-12">
            <button
              type="button"
              className="flex items-center justify-center w-10 h-10 ml-1 border-2 rounded-full bg-slate-700 dark:bg-slate-800 border-slate-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
              onClick={() => setSidebarCollapsed(false)}
            >
              <span className="sr-only">Close sidebar</span>
              <XMarkIcon className="w-6 h-6 text-gray-300" aria-hidden="true" />
            </button>
          </div>
        )}
        <Sidebar
          breakPoint="lg"
          backgroundColor="transparent"
          rootStyles={{
            color: "white",
          }}
          toggled={toggled}
          onBreakPoint={setBroken}
          collapsed={sidebarCollapsed}
          onBackdropClick={() => setToggled(false)}
          style={{
            borderRight: "0px",
          }}
          className="mt-3"
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
            }}
          >
            {/* Header */}
            <div style={{ flex: 1, marginBottom: "32px" }}>
              <Menu closeOnClick={true} menuItemStyles={menuItemStyles}>
                {menuItems.map((menu, itemIdx) => (
                  <>
                    <SubMenu
                      active={(menu.paths as []).some((path) =>
                        router.pathname.includes(path)
                      )}
                      label={menu.category}
                      icon={<menu.icon className="h-6" />}
                      key={itemIdx}
                    >
                      {(menu.items as []).map((item: any, index) => (
                        <>
                          <MenuItem
                            active={router.pathname.includes(item.path)}
                            style={{
                              height: "2.5rem",
                              // margin: "0.1rem 0.4rem",
                              borderRadius: "8px",
                              // paddingLeft: "0.6rem",
                            }}
                            className={`text-sm font-semibold text-white hover:text-black`}
                            // icon={<item.icon className="h-6" />}
                            key={index}
                            component={<Link to={item.path} />}
                          >
                            {item.name}
                          </MenuItem>
                          {/* <MenuItem
                          // active={router.pathname.includes(item.sideBarNavigationRef)}
                          style={{
                            height: "2.5rem",
                            margin: "0.1rem 0.4rem",
                            borderRadius: "8px",
                            paddingLeft: "0.6rem",
                          }}
                          className={`text-sm font-semibold text-white hover:text-black`}
                          icon={<item.icon className="h-6" />}
                          key={itemIdx}
                          component={<Link to={item.path} />}
                        >
                          {item.name}
                        </MenuItem> */}
                        </>
                      ))}
                    </SubMenu>
                  </>
                ))}

                {/* {navigationWithSubMenu.map((item, itemIdx) => (
            <SubMenu
              active={router.pathname.includes(item.to)}
              className={`font-semibold text-white text-sm`}
              style={{
                height: '2.5rem',
                margin: '0.1rem 0.5rem',
                borderRadius: '8px',
                padding: '0.1rem 0.5rem',
              }}
              key={itemIdx}
              icon={<item.icon className="h-6" />}
              defaultOpen={router.pathname.startsWith(item.to)}
              label={item.label}
            >
              {item.to === '/contacts'
                ? subNavigationContact.map((item2, itemIdx2) => (
                  <MenuItem
                    active={router.pathname.includes(item2.to)}
                    className={`text-xs font-semibold hover:text-black bg-gr`}
                    style={{
                      color: router.pathname.includes(item2.to) ? 'black' : '',
                      height: '2.5rem',
                      marginLeft: router.pathname.includes(item2.to)
                        ? '1rem'
                        : '0.2rem',
                      transition: 'margin-left 0.7s',
                      paddingLeft: '4.1rem',
                      marginRight: '0.5rem',
                      marginTop: '0.1rem',
                      marginBottom: '0.1rem',
                      borderRadius: '8px',
                      backgroundColor: router.pathname.includes(item2.to)
                        ? 'rgb(209 213 219)'
                        : '',
                    }}
                    key={itemIdx2}
                    component={<Link to={item2.to} />}
                  >
                    {item2.name}
                  </MenuItem>
                ))
                : undefined
              }
            </SubMenu>
          ))} */}
              </Menu>
            </div>
          </div>
        </Sidebar>
      </div>
    </div>
  );
};

export default MenuSideBar;
