/* eslint-disable jsx-a11y/anchor-is-valid */
import { useCallback, useContext, useEffect } from "react";

import { useState } from "react";
import { amountFormater, getTimestamp } from "../../utils";
import { transactionCollectionName } from "../../config";
import { db } from "../../firebase";
import { UserContext } from "../../contexts";
import { useNavigate } from "react-router-dom";
import { AccountType, TransactionType } from "../../interfaces";
import StatsComponent from "../../components/SuperMerchantComponent/StatsComponent";
import MerchantsComponent from "../../components/SuperMerchantComponent/MerchantsComponent";
import {
  getCountFromServer,
  query,
  collection,
  where,
} from "firebase/firestore";
import moment from "moment";
import { BanknotesIcon } from "@heroicons/react/24/outline";
import StationsComponent from "../../components/SuperStationComponent/StationComponent";

const secondaryNavigation = [{ name: "Reporting", current: true }];

const SuperStationHomePage = () => {
  const { user, userAccounts, superStationAffiliatedStations } =
    useContext(UserContext);
  const navigate = useNavigate();
  const [retirveAllTransactions, setRetirveAllTransactions] =
    useState<number>(0);
  const [currentTransactionsAmountSum, setCurrentTransactionsAmountSum] =
    useState(0);
  const [seachWithStartDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );
  const [seachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );
  const startTime = getTimestamp(seachWithStartDateValue, true);
  const endTime = getTimestamp(seachWithEndDateValue, false);
  const [gasstationTotalAmount, setGasstationTotalAmount] = useState<number>(0);

  const retirveTransactions = useCallback(async () => {
    const nbOfAllTransactions = (
      await getCountFromServer(
        query(
          collection(db, transactionCollectionName),
          where("participantsIds", "array-contains", user?.id),
          where("createdAt", ">=", startTime),
          where("createdAt", "<=", endTime)
        )
      )
    ).data().count;

    setRetirveAllTransactions(nbOfAllTransactions);
  }, [endTime, startTime, user?.id]);

  const retirveTransactionsAmontSum = useCallback(async () => {
    const transSnap = await db
      .collection(transactionCollectionName)
      .orderBy("createdAt", "desc")
      .where("participantsIds", "array-contains", user?.id)
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime)
      .get();
    if (transSnap.empty) {
      setCurrentTransactionsAmountSum(0);
    } else {
      const amount = transSnap.docs.reduce(
        (accumulator, currentValue) =>
          accumulator + parseInt(`${currentValue.data().amount}`),
        0
      );
      setCurrentTransactionsAmountSum(amount);
    }
  }, [endTime, startTime, user?.id]);

  const retrieveAllStationsBalances = useCallback(() => {
    const sum =
      superStationAffiliatedStations &&
      superStationAffiliatedStations.length > 0
        ? superStationAffiliatedStations?.reduce(
            (a, b) => a + b?.accounts.balance,
            0
          )
        : 0;
    setGasstationTotalAmount(sum);
  }, [superStationAffiliatedStations]);

  useEffect(() => {
    retirveTransactions();
    retirveTransactionsAmontSum();
    retrieveAllStationsBalances();
  }, [
    retirveTransactions,
    retirveTransactionsAmontSum,
    retrieveAllStationsBalances,
  ]);

  return (
    <div>
      <div className="relative pt-16 overflow-hidden isolate">
        {/* Secondary navigation */}
        <header className="pt-6 pb-4 sm:pb-6">
          <div className="flex flex-wrap items-center gap-6 px-4 mx-auto max-w-7xl sm:flex-nowrap sm:px-6 lg:px-8">
            <h1 className="text-base font-semibold leading-7 text-gray-900">
              Flux
            </h1>
            <div className="flex order-last w-full text-sm font-semibold leading-6 gap-x-8 sm:order-none sm:w-auto sm:border-l sm:border-gray-200 sm:pl-6 sm:leading-7">
              {secondaryNavigation.map((item) => (
                <div
                  key={item.name}
                  className={item.current ? "text-indigo-600" : "text-gray-700"}
                >
                  {item.name}
                </div>
              ))}
            </div>
            {user && !user.haveDepositAccount && (
              <div className="flex items-center px-3 py-2 ml-auto text-sm font-semibold text-white gap-x-1 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2">
                <button
                  type="button"
                  className="inline-flex items-center px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm text-lime-700 border-lime-300 hover:bg-lime-50 focus:outline-none focus:ring-2 focus:ring-lime-500 focus:ring-offset-2"
                  onClick={() => {
                    navigate("/superStation/withdraw_station");
                  }}
                >
                  <BanknotesIcon
                    className="self-center flex-shrink-0 w-5 h-5 mr-1 text-lime-500"
                    aria-hidden="true"
                  />
                  Retrait à l'agence
                </button>
              </div>
            )}
          </div>
        </header>

        {/* Stats */}
        <div className="border-b border-b-gray-900/10 lg:border-t lg:border-t-gray-900/5">
          <dl className="grid grid-cols-1 mx-auto max-w-7xl sm:grid-cols-2 lg:grid-cols-4 lg:px-2 xl:px-0">
            <StatsComponent
              stats={[
                {
                  name: "Compte principale",
                  value: `${amountFormater(
                    userAccounts?.find(
                      (el) => el.accountType === AccountType.FUEL
                    )?.balance as number
                  )} Fcfa`,
                  changeType: "positive",
                },
                {
                  name: "Compte de caution",
                  value: `${amountFormater(
                    userAccounts?.find(
                      (el) => el.accountType === AccountType.DEPOSITACCOUNT
                    )?.balance as number
                  )} Fcfa`,
                  changeType: "positive",
                },
                {
                  name: "Totals station-service",
                  value: `${superStationAffiliatedStations?.length}`,
                  changeType: "positive",
                },
                {
                  name: "Cumule des soldes stations",
                  value: `${amountFormater(gasstationTotalAmount)} Fcfa`,
                  changeType: "positive",
                },
                {
                  name: "Totals transactions du jours",
                  value: `${retirveAllTransactions}`,
                  changeType: "positive",
                },
                {
                  name: "Total montant du jours",
                  value: `${amountFormater(currentTransactionsAmountSum)} Fcfa`,
                  changeType: "positive",
                },
              ]}
            />
          </dl>
        </div>

        <div
          className="absolute left-0 origin-top-left -rotate-90 translate-y-40 top-full -z-10 mt-96 transform-gpu opacity-20 blur-3xl sm:left-1/2 sm:-ml-96 sm:-mt-10 sm:translate-y-0 sm:rotate-0 sm:transform-gpu sm:opacity-50"
          aria-hidden="true"
        >
          <div
            className="aspect-[1154/678] w-[72.125rem] bg-gradient-to-br from-[#fc7575] to-[#f40b0b]"
            style={{
              clipPath:
                "polygon(100% 38.5%, 82.6% 100%, 60.2% 37.7%, 52.4% 32.1%, 47.5% 41.8%, 45.2% 65.6%, 27.5% 23.4%, 0.1% 35.3%, 17.9% 0%, 27.7% 23.4%, 76.2% 2.5%, 74.2% 56%, 100% 38.5%)",
            }}
          />
        </div>
      </div>

      <div className="py-16 space-y-16 xl:space-y-20">
        {/* Recent client list*/}
        <StationsComponent stations={superStationAffiliatedStations} />
        {/* Activité récente table */}
      </div>
    </div>
  );
};

export default SuperStationHomePage;
