import React from 'react'
import { useLocation } from 'react-router-dom';
import DisplayItemDetails from '../../../components/DisplayItemComponent';

const GasStationStaffDetailsPape = () => {
  const location = useLocation();
  const currentUser = location.state?.entity;
  return <DisplayItemDetails currentUser={currentUser} />;
};

export default GasStationStaffDetailsPape