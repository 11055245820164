/* eslint-disable jsx-a11y/role-has-required-aria-props */
import { Listbox, Transition } from "@headlessui/react";
import {
  CheckIcon,
  ChevronUpDownIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import React, { Fragment, useState } from "react";
import { classNames } from "../../utils";

interface AssigneToSelectorInputInterface {
  title: string;
  data: any[];
  defaultValue?: any;
  retriveData: (value: any) => void;
}

const AssigneToSelectorInput = ({
  title,
  data,
  retriveData,
  defaultValue,
}: AssigneToSelectorInputInterface) => {
  const [selected, setSelected] = useState<any>(data[0]);
  const handleClick = (value: any) => {
    setSelected(value);
    retriveData(value);
  };
  return (
    <div className=" w-72">
      <Listbox value={selected} onChange={handleClick}>
        <div className="relative mt-1">
          <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:border-indigo-500 focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75 focus-visible:ring-offset-2 focus-visible:ring-offset-orange-300 sm:text-sm">
            {selected ? (
              <span className="block truncate">{selected?.displayName}</span>
            ) : (
              <span className="block truncate">Choisir un membre</span>
            )}
            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
              <ChevronUpDownIcon
                className="w-5 h-5 text-gray-400"
                aria-hidden="true"
              />
            </span>
          </Listbox.Button>
          <Transition
            as={Fragment}
            leave="transition ease-in duration-100"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
              {data.map((person, personIdx) => (
                <Listbox.Option
                  key={personIdx}
                  className={({ active }) =>
                    `relative cursor-default select-none py-2 pl-10 pr-4 ${
                      active ? "bg-amber-100 text-amber-900" : "text-gray-900"
                    }`
                  }
                  value={person}
                >
                  {({ selected }) => (
                    <>
                      <div className="flex items-center">
                        <img
                          src={
                            person.profilImageUrl
                              ? person.profilImageUrl
                              : `https://ui-avatars.com/api/?background=random&name=${
                                  person.displayName || "S"
                                }`
                          }
                          alt=""
                          className="flex-shrink-0 w-6 h-6 rounded-full"
                        />
                        <span
                          className={classNames(
                            selected ? "font-semibold" : "font-normal",
                            "ml-3 block truncate"
                          )}
                        >
                          {person.displayName}
                        </span>
                      </div>

                      {selected ? (
                        <span className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600">
                          <CheckIcon className="w-5 h-5" aria-hidden="true" />
                        </span>
                      ) : null}
                    </>
                  )}
                </Listbox.Option>
              ))}
            </Listbox.Options>
          </Transition>
        </div>
      </Listbox>
    </div>
  );
};

export default AssigneToSelectorInput;
