import { Switch } from "@headlessui/react";
import { classNames } from "../../../utils";
import { InputHTMLAttributes } from "react";
import { Controller, useFormContext } from "react-hook-form";

export interface CheckToggleProps
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "id" | "className"> {
  name: string;
  label: string;
  description?: string;
  condensed?: boolean;
  onValueChanged?: (isChecked: boolean) => void;
  checked?: boolean;
}

export function FormCheckToggle({
  name,
  checked,
  label,
  description,
  condensed,
  onValueChanged,
  ...rest
}: CheckToggleProps): JSX.Element {
  const methods = useFormContext();
  return (
    <div className={classNames(condensed ? "my-1" : "my-4")}>
      <Controller
        control={methods.control}
        name={name}
        render={({ field }) => (
          <Switch.Group as="div" className="flex items-center justify-between">
            <span className="flex flex-col flex-grow py-2 space-y-2">
              <Switch.Label
                as="span"
                className="text-sm font-medium text-gray-900"
                passive
              >
                {label}
              </Switch.Label>
              {description && (
                <Switch.Description as="span" className="text-sm text-gray-500">
                  {description}
                </Switch.Description>
              )}
            </span>
            <Switch
              checked={checked ? checked : field.value}
              onChange={(e) => {
                field.onChange(e);
                onValueChanged && onValueChanged(e);
              }}
              className={classNames(
                checked || field.value ? "bg-red-600" : "bg-gray-200",
                "relative inline-flex rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 flex-shrink-0 h-6 w-11 border-2 border-transparent cursor-pointer transition-colors ease-in-out duration-200"
              )}
            >
              <span
                aria-hidden="true"
                className={classNames(
                  checked || field.value ? "translate-x-5" : "translate-x-0",
                  "pointer-events-none rounded-full inline-block h-5 w-5 bg-white shadow transform ring-0 transition ease-in-out duration-200"
                )}
              />
            </Switch>
          </Switch.Group>
        )}
      />
      {(methods as any).formState.errors[name] && (
        <p className="mt-2 text-sm text-red-600">
          {(methods as any).formState.errors[name].message}
        </p>
      )}
    </div>
  );
}

export default FormCheckToggle;
