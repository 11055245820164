/* eslint-disable jsx-a11y/anchor-is-valid */
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";
import {
  CheckCircleIcon,
  MagnifyingGlassIcon,
  UserIcon,
  UserPlusIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { AgencyStaffRole, UserRole } from "../../interfaces";
import { usersCollectionName } from "../../config";
import Moment from "react-moment";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../components/FirestoreTable";
import { useCallback, useContext, useEffect, useState } from "react";
import Modal from "../../components/Modal";
import PhoneInput from "react-phone-number-input";
import HasRoles from "../../components/HasRoles";
import { UserContext } from "../../contexts";

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "profilImageUrl",
    label: "Profil",
  },
  {
    field: "displayName",
    label: "Nom/prénom",
  },
  {
    field: "adresse",
    label: "Adresse",
  },
  {
    field: "pointOfCollectIdentity",
    label: "Identifiant",
  },
  {
    field: "phoneNumber",
    label: "Téléphone",
  },
  {
    field: "city",
    label: "Ville",
  },
  {
    field: "active",
    label: "Active",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "displayName":
      return <span>{value ? value : "-"}</span>;
    case "profilImageUrl":
      return (
        <span>
          {" "}
          {value ? (
            <img
              className="w-10 h-10 p-1 border rounded-full"
              src={
                value
                  ? value
                  : `https://ui-avatars.com/api/?background=random&name=${
                      value || "S"
                    }`
              }
              alt=""
            />
          ) : (
            <UserIcon className="w-10 h-10 p-1 border rounded-full" />
          )}
        </span>
      );
    case "adresse":
      return <span>{value ? value : "-"}</span>;
    case "city":
      return <span>{value ? value : "-"}</span>;
    case "phoneNumber":
      return <span>{value ?? "-"}</span>;
    case "pointOfCollectIdentity":
      return <span>{value ?? "-"}</span>;
    case "active":
      return (
        <span>
          {" "}
          {value ? (
            <CheckCircleIcon className="w-6 h-6 text-green-500" />
          ) : (
            <XCircleIcon className="w-6 h-6 text-red-500" />
          )}
        </span>
      );
    default:
      return value ? value : "-";
  }
};

const CollectionsPointPage = () => {
  const navigate = useNavigate();
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [userData, setUserData] = useState<any>();
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const { user } = useContext(UserContext);

  const [phoneNumberSearch, setPhoneNumberSearch] = useState<
    string | undefined
  >();

  const onEditRow = (id: number, entity: any) => {
    if (entity) {
      navigate(`/collections_point/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onRowDetails = (id: number, entity: any) => {
    if (entity) {
      navigate(`/collections_point/${entity.id}/details`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };

  const retrivePhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber.length >= 12) {
      await db
        .collection(usersCollectionName)
        .where("userRole", "==", UserRole.USER)
        .where("phoneNumber", "==", phoneNumber)
        .get()
        .then((response) => {
          setUserData(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
    } else {
      setUserData([]);
    }
  };

  const retriveCollectionByPhoneNumber = useCallback(async () => {
    await db
      .collection(usersCollectionName)
      .where("userRole", "==", UserRole.COLLECTIONPOINT)
      .where("phoneNumber", "==", phoneNumberSearch ?? "")
      .get()
      .then((response) => {
        setUserData(
          response.docs.map((d) => {
            return { id: d.id, data: d.data() };
          })
        );
      });
  }, [phoneNumberSearch]);

  useEffect(() => {
    if (phoneNumberSearch?.length === 12) {
        retriveCollectionByPhoneNumber();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [phoneNumberSearch]);

  const handleChangePhoneNumberSearch = (value: string) => {
    setPhoneNumberSearch(value);
  };

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les points de collecte
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ci-dessous tous les points de collecte.
          </p>
        </div>
        <HasRoles
          userRole={["ADMIN", "AGENCYSTAFF", "SUPERVISOR", "TECHNICALSUPPORT"]}
        >
          <>
            {(user?.userRole === UserRole.ADMIN ||
              user?.userRole === UserRole.SUPERVISOR ||
              user?.userRole === UserRole.TECHNICALSUPPORT ||
              user?.userAgencySubRole === AgencyStaffRole.MANAGER) && (
              <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                <button
                  type="button"
                  className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
                  onClick={(e) => {
                    e.preventDefault();
                    setShowPinCodeModal(true);
                  }}
                >
                  <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                    <UserPlusIcon
                      className="self-center flex-shrink-0 w-5 h-5 mr-1 text-orange-500"
                      aria-hidden="true"
                    />
                    Ajouter un point de collecte
                  </span>
                </button>
              </div>
            )}
          </>
        </HasRoles>
        <div className="mt-4 sm:mt-0 sm:ml-1 sm:flex-none">
          <button
            type="button"
            className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
            onClick={(e) => {
              e.preventDefault();
              setShowDetailsModal(true);
            }}
          >
            <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
              <MagnifyingGlassIcon
                className="self-center flex-shrink-0 w-5 h-5 mr-1 text-orange-500"
                aria-hidden="true"
              />
              Rechercher un point de collecte
            </span>
          </button>
        </div>
      </div>
      <FirestoreTable
        collectionRef={db.collection(usersCollectionName)}
        collectionQueriesWhere={[
          {
            fieldPath: "userRole",
            opStr: "==",
            value: UserRole.COLLECTIONPOINT,
          },
        ]}
        condensed
        columns={columns}
        rowRender={renderRow}
        rowEditHandler={onEditRow}
        rowDetailsHandler={onRowDetails}
        displayDetails
        shouldAddEditColumn
        displayEditBtn
        paginate={{ pageIndex: 0, limit: 100, sort: "createdAt:desc" }}
      />
      <Modal
        isOpen={showPinCodeModal}
        maxSize="lg"
        closeModal={() => setShowPinCodeModal(false)}
        modalTitle="Renseigner le numéro de téléphone"
      >
        <div className="flex justify-center">
          <div className="w-full mt-10">
            <PhoneInput
              international={false}
              placeholder="Entrer un numéro de téléphone"
              className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
              defaultCountry="BF"
              onChange={retrivePhoneNumber}
            //   onCountryChange={(value: any) => {
            //     // setCountry(country);
            //   }}
            />
            <div className="flex justify-center w-full">
              {userData && userData.length > 0 && (
                <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex flex-col items-center pb-10">
                    <img
                      className="w-24 h-24 mt-2 mb-3 rounded-full shadow-lg"
                      src={
                        userData[0].data.profilImageUrl
                          ? userData[0].data.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              userData[0].data.displayName || "S"
                            }`
                      }
                      alt=""
                    />
                    <h5 className="mb-1 text-xl font-medium text-center text-gray-900 dark:text-white">
                      {userData[0].data.displayName}
                    </h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {userData[0].data.phoneNumber}
                    </span>
                    <div className="flex mt-4 space-x-3 md:mt-6">
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() =>
                          navigate(`/collections_point/new`, {
                            state: {
                              entity: userData[0].data,
                              id: userData[0].id,
                            },
                          })
                        }
                      >
                        Continuer
                      </div>
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                        onClick={() => {
                          setShowPinCodeModal(false);
                          setUserData([]);
                        }}
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showDetailsModal}
        maxSize="md"
        closeModal={() => setShowDetailsModal(false)}
        modalTitle="Rechercher un marchand"
      >
        <div className="flex justify-center">
          <div className="w-full mt-10">
            <div className="">
              <PhoneInput
                international={false}
                placeholder="Entrer un numéro de téléphone"
                className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                defaultCountry="BF"
                // onChange={retriveAgentPhoneNumber}
                value={phoneNumberSearch}
                onChange={handleChangePhoneNumberSearch}
              />
            </div>
            <div className="flex justify-center w-full">
              {userData && userData.length > 0 && (
                <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex flex-col items-center pb-10">
                    <img
                      className="w-24 h-24 mt-2 mb-3 rounded-full shadow-lg"
                      src={
                        userData[0].data.profilImageUrl
                          ? userData[0].data.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              userData[0].data.displayName || "S"
                            }`
                      }
                      alt=""
                    />
                    <h5 className="mb-1 text-xl font-medium text-center text-gray-900 dark:text-white">
                      {userData[0].data.displayName}
                    </h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {userData[0].data.phoneNumber}
                    </span>
                    <div className="flex mt-4 space-x-3 md:mt-6">
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() =>
                          navigate(`/users/${userData[0].id}/details`, {
                            state: {
                              entity: userData[0].data,
                              id: userData[0].id,
                              isSearch: true,
                            },
                          })
                        }
                      >
                        Details
                      </div>
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                        onClick={() => {
                          setShowDetailsModal(false);
                          setUserData([]);
                        }}
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default CollectionsPointPage;
