export interface TicketInterface {
    title?: string;
    eventStartTime?: any;
    eventEndTime?: any;
    eventEndDate?:any;
    eventStartDate?:any;
    adresse?: string;
    city?: string;
    numberOfTicket?: number;
    lat?: string;
    long?: string;
    saleStartDate?: any;
    saleEndDate?: any;
    availableQuantity?: string;
    ticketExpiringDate?: any;
    price?: string;
    standard?: string;
    ticketCoverUrl?: string;
    numberOfPlace?: string;
    ticketPrice?: string;
    maxCapacity?: number;
    locationName?: string;
};

export enum EventsStatus {
    BLOCKED = "BLOCKED",
    UNLOCKED = "UNLOCKED",
    VALIDATED = "VALIDATED",
    PENDING = "PENDING",
}