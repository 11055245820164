import { useContext, useEffect, useMemo, useState } from "react";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../../components/FirestoreTable";
import { AgencyStaffRole, transactionMap } from "../../../interfaces";
import {
  membershipCollectionName,
  transactionCollectionName,
  usersCollectionName,
} from "../../../config";
import { db } from "../../../firebase";
import { UserContext } from "../../../contexts";
import AssigneToSelectorInput from "../../../components/SelectInput";
import { Timestamp } from "firebase-admin/firestore";

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "senderPhoneNumber",
    label: "Expéditeur",
  },
  {
    field: "receiverPhoneNumber",
    label: "Destinataire",
  },
  {
    field: "receiverDisplayName",
    label: "Destinataire",
  },
  {
    field: "transactionType",
    label: "Type d'opération",
  },
  {
    field: "amount",
    label: "Montant",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return <span>{(value as Timestamp).toDate().toLocaleString()}</span>;
    case "senderPhoneNumber":
      return <span>{value}</span>;
    case "receiverPhoneNumber":
      return <span>{value}</span>;
    case "receiverDisplayName":
      return <span>{value}</span>;
    case "amount":
      return <span>{new Intl.NumberFormat().format(value)} Fcfa</span>;
    case "transactionType":
      return <span>{transactionMap.get(value as string)} </span>;
    default:
      return value as string;
  }
};

const MembersAuditLogs = () => {
  const { user, agencyAgent } = useContext(UserContext);
  const [members, setMembers] = useState<any[]>([]);
  const [member, setMember] = useState<any>();
  const retirveMembers = async () => {
    const membershipSnap = await db
      .collection(membershipCollectionName)
      .where("agencyId", "==", agencyAgent?.agencyId)
      .get();
    const members = [];
    for (const doc of membershipSnap.docs) {
      const userSnap = await db
        .collection(usersCollectionName)
        .doc(doc.data()?.memberId)
        .get();
      if (
        userSnap.exists &&
        userSnap.data()?.userAgencySubRole === AgencyStaffRole.CASHIER
      ) {
        members.push(userSnap.data());
      }
    }
    setMembers(members);
  };

  const firestoreTableQueriesWhere = useMemo(() => {
    let queries = [];

    if (member || agencyAgent) {
      queries.push({
        fieldPath: "participantsIds",
        opStr: "array-contains",
        value: member ? member.id : agencyAgent?.id,
      });
    }

    return queries;
  }, [member, agencyAgent]);

  useEffect(() => {
    if (user) {
      retirveMembers();
    }
  }, []);

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les audit Logs
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ci-dessous tous les audit logs des utilisateurs.
          </p>
        </div>
        {members && (
          <div className="justify-between sm:flex-wrap lg:flex">
            <div className="flex w-auto px-1 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
              <div className="flex items-center justify-center mt-5 h-14">
                <AssigneToSelectorInput
                  title={"Les membres caissières de l'agence"}
                  data={members}
                  retriveData={(value) => setMember(value)}
                />
                {/* <div className="flex">
                <button
                  type="button"
                  className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                  onClick={() => {
                    // getFilteredByDate();
                  }}
                >
                  <FunnelIcon
                    className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                    aria-hidden="true"
                  />
                  Filtrer
                </button>
              </div> */}
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="flex flex-col mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="shadow-sm">
              <FirestoreTable
                collectionRef={db.collection(transactionCollectionName)}
                condensed
                collectionQueriesWhere={firestoreTableQueriesWhere}
                columns={columns}
                rowRender={renderRow}
                paginate={{ pageIndex: 0, limit: 100, sort: "createdAt:desc" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MembersAuditLogs;
