import { NoSymbolIcon, CheckIcon } from "@heroicons/react/24/outline";
import { useContext, useEffect, useState } from "react";
import HasRoles from "../../components/HasRoles";
import { mapsGender, UserRole } from "../../interfaces";
import { classNames } from "../../utils";
import { useLocation, useNavigate } from "react-router-dom";
import {
  accountsCollectionName,
  defaultAmount,
  gasStationCollectionName,
} from "../../config";
import { db, functions } from "../../firebase";
import { useConfirm } from "../../hooks/useConfirm";
import toast from "react-hot-toast";
import Modal from "../../components/Modal";
import { UserContext } from "../../contexts";
import Form from "../../components/FormComponents/Form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { FormInput } from "../../components/FormComponents";

const StationDetails = () => {
  const location = useLocation();
  const stationDetailsData = location.state?.entity;
  const [fuelAmmount, setFuelAmount] = useState(0);
  const { isConfirmModal } = useConfirm();
  const navigate = useNavigate();
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const { user } = useContext(UserContext);

  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );

  const onBlockedUserAccount = async () => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir desactiver le compte de paiement de cette station",
      "Oui desactiver",
      true
    );

    if (isConfirm) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: gasStationCollectionName,
        documentData: {
          canReceivePayment: false,
        },
        documentId: stationDetailsData.id,
      }).then(() => {
        navigate(`/superStation/affiliate-merchants`);
      });
      toast.promise(response, {
        loading: "Blocage...",
        success: "Le compte de paiement a été bloqué avec succès.",
        error: "Échec ! Veuillez réessayer.",
      });
    }
  };
  const onActiveUserAccount = async () => {
    const isConfirm = await isConfirmModal(
      "Opération irréversible",
      "Êtes vous sûr de vouloir activer le compte de paiement de cete station",
      "Oui activer",
      true
    );

    if (isConfirm) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: gasStationCollectionName,
        documentData: {
          canReceivePayment: true,
        },
        documentId: stationDetailsData.id,
      }).then(() => {
        navigate(`/superStation/affiliate-station`);
      });
      toast.promise(response, {
        loading: "Activation...",
        success: "L'utilisateur a été activé avec succès.",
        error: "Échec ! Veuillez réessayer.",
      });
    }
  };

  const transferAllGasstationsBalanceToSuperStationAccountFn =
    functions.httpsCallable(
      "transferAllGasstationsBalanceToSuperStationAccountFn"
    );

  const onSubmit = (data: any) => {
    setIsCodePinBtnDesabled(true);
    setIsLoad(true);
    if (data.amount < defaultAmount) {
      setIsCodePinBtnDesabled(false);
      setShowPinCodeModal(false);
      return toast.error(
        `Le montant doit être suppérieur à ${defaultAmount} Fcfa`
      );
    }
    const dataValue = {
      superGasstationPhoneNumber: user?.phoneNumber,
      stationCode: stationDetailsData?.gasStationCode,
      pinCode: data.pinCode,
      amount: data.amount,
    };
    const toastId = toast.loading("Transfert...");
    transferAllGasstationsBalanceToSuperStationAccountFn(dataValue)
      .then((response) => {
        toast.success("Le transfert a été effectué avec sccès", {
          id: toastId,
        });
        setIsLoad(false);
        setShowPinCodeModal(false);
        setIsCodePinBtnDesabled(false);
      })
      .catch((error) => {
        toast.error(error.message, {
          id: toastId,
        });
        setIsCodePinBtnDesabled(false);
        setIsLoad(false);
      });
  };

  useEffect(() => {
    (async () => {
      const userRef = db
        .collection(gasStationCollectionName)
        .doc(stationDetailsData.id);
      const sankAcountRef = userRef.collection(accountsCollectionName);

      const sankAcountsData = await sankAcountRef.get();

      sankAcountsData.docs.forEach((snapShot) => {
        const data = snapShot.data();

        if (data.accountType === "FUEL") {
          setFuelAmount(data.balance);
        }
      });
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="mx-auto">
      <div className="flex mx-auto space-x-10 max-md:block max-md:space-x-0 max-md:space-y-10 max-md:mx-5">
        <img
          className="w-40 mb-auto rounded-full max-md:mx-auto"
          alt={stationDetailsData?.displayName}
          src={
            stationDetailsData?.profilImageUrl
              ? stationDetailsData?.profilImageUrl
              : `https://ui-avatars.com/api/?background=random&name=${
                  stationDetailsData?.displayName || "S"
                }`
          }
        />

        <div className="w-96 max-md:w-full">
          <div className="pb-6 ">
            <p className="text-lg font-semibold leading-none text-gray-800">
              Les informations de la station
            </p>
            <h1 className="mt-2 text-xl font-semibold leading-7 text-gray-800 lg:text-2xl lg:leading-6">
              {stationDetailsData?.phoneNumber}
            </h1>
          </div>

          <HasRoles userRole={["SUPERSTATION"]}>
            <div className="mb-5">
              <div className="flex items-center justify-between py-2 ">
                <p className="text-base font-semibold leading-4 text-gray-800">
                  Compte Principale
                </p>
                <div className="flex items-center justify-center">
                  <p className="text-lg font-semibold leading-none text-gray-800">
                    {new Intl.NumberFormat().format(fuelAmmount)} Fcfa
                  </p>
                </div>
              </div>
            </div>
          </HasRoles>
          <div className="flex items-center justify-between py-2 border-b border-gray-200">
            <p className="text-base leading-4 text-gray-800">Nom/Prénom</p>
            <div className="flex items-center justify-center">
              <p className="font-mono text-sm leading-none text-gray-800">
                {stationDetailsData?.displayName
                  ? stationDetailsData?.displayName
                  : "-"}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between py-2 border-b border-gray-200">
            <p className="text-base leading-4 text-gray-800">Email</p>
            <div className="flex items-center justify-center">
              <p className="font-mono text-sm leading-none text-gray-800">
                {stationDetailsData?.email ? stationDetailsData?.email : "-"}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between py-2 border-b border-gray-200">
            <p className="text-base leading-4 text-gray-800">
              Numéro CNI/Passport
            </p>
            <div className="flex items-center justify-center">
              <p className="font-mono text-sm leading-none text-gray-800">
                {stationDetailsData?.gasStationCode
                  ? stationDetailsData?.gasStationCode
                  : "-"}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between py-2 border-b border-gray-200">
            <p className="text-base leading-4 text-gray-800">Ville</p>
            <div className="flex items-center justify-center">
              <p className="font-mono text-sm leading-none text-gray-800">
                {stationDetailsData?.city ? stationDetailsData?.city : "-"}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between py-2 border-b border-gray-200">
            <p className="text-base leading-4 text-gray-800">ADRESSE</p>
            <div className="flex items-center justify-center">
              <p className="font-mono text-sm leading-none text-gray-800">
                {stationDetailsData?.adresse
                  ? stationDetailsData?.adresse
                  : "-"}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between py-2 border-b border-gray-200">
            <p className="text-base leading-4 text-gray-800">Genre</p>
            <div className="flex items-center justify-center">
              <p className="font-mono text-sm leading-none text-gray-800">
                {stationDetailsData?.gender
                  ? mapsGender.get(stationDetailsData?.gender)
                  : "-"}
              </p>
            </div>
          </div>
          <div className="flex items-center justify-between py-2 border-b border-gray-200">
            <p className="text-base leading-4 text-gray-800">
              Membre SankMoney depuis
            </p>
            <div className="flex items-center justify-center">
              <p className="font-mono text-sm leading-none text-gray-800">
                {new Date(
                  stationDetailsData.createdAt.seconds * 1000 +
                    stationDetailsData.createdAt.nanoseconds / 1000000
                ).toLocaleDateString()}
              </p>
            </div>
          </div>
        </div>

        <div className="w-72 max-md:w-full">
          <HasRoles userRole={["SUPERSTATION"]}>
            <>
              {stationDetailsData &&
                stationDetailsData.userRole === UserRole.GASSTATION && (
                  <button
                    onClick={() =>
                      stationDetailsData?.canReceivePayment
                        ? onBlockedUserAccount()
                        : onActiveUserAccount()
                    }
                    className={classNames(
                      stationDetailsData?.canReceivePayment
                        ? " bg-green-500"
                        : " bg-red-500",
                      "flex items-center justify-center mx-auto rounded-md my-4 w-full py-2 font-semibold mt-2 text-base leading-none text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 hover:bg-gray-700 px-4 "
                    )}
                  >
                    {stationDetailsData?.active ? (
                      <>
                        <NoSymbolIcon className="w-5 h-5 mr-2 text-white" />
                        Bloquer le compte de paiement
                      </>
                    ) : (
                      <>
                        <CheckIcon className="w-5 h-5 mr-2 text-white" />
                        Activer le compte de paiement
                      </>
                    )}
                  </button>
                )}
            </>
          </HasRoles>
          <HasRoles userRole={["SUPERSTATION"]}>
            <>
              {stationDetailsData &&
                stationDetailsData.userRole === UserRole.GASSTATION && (
                  <button
                    onClick={() => setShowPinCodeModal(true)}
                    className="flex items-center justify-center w-full px-4 py-2 mx-auto my-4 mt-2 text-base font-semibold leading-none text-white bg-green-500 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-800 hover:bg-gray-700"
                  >
                    Transférer le solde de la satation
                  </button>
                )}
            </>
          </HasRoles>
        </div>
      </div>
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => setShowPinCodeModal(false)}
      >
        <Form
          onSubmit={onSubmit}
          form={{
            resolver: yupResolver(
              yup.object().shape({
                amount: yup
                  .number()
                  .required("Saisir le montant à transférer "),
                pinCode: yup.string().required("Saisir votre mot de passe "),
              })
            ),
          }}
          isSubmitBtnDisabled={isCodePinBtnDesabled}
          submitButtonLabel="Valider la transaction"
          className="flex justify-center"
        >
          <FormInput
            name="amount"
            label="Montant à transférer"
            placeholder="25.000"
          />
          <FormInput
            type="password"
            name="pinCode"
            label="Entrer votre Code pin"
            placeholder="...."
          />
        </Form>
      </Modal>
    </div>
  );
};

export default StationDetails;
