import React, { useEffect, useState } from "react";
import {
  gasStationMembershipCollectionName,
  usersCollectionName,
} from "../../../config";
import { useLocation } from "react-router-dom";
import DisplayItemDetails from "../../../components/DisplayItemComponent";
import { db } from "../../../firebase";

const GasStationDetailsPage = () => {
  const location = useLocation();
  const currentUser = location.state?.entity;
  const [gasStationStaffData, setGasStationStaffData] = useState<any[]>([]);

  const retriveGasStationStaff = async () => {
    const staffData = [];
    const memberships = await db
      .collection(gasStationMembershipCollectionName)
      .orderBy("createdAt", "desc")
      .where("gasStationId", "==", currentUser?.id)
      .get();
    if (!memberships.empty) {
      for (const membershipSnapshot of memberships.docs) {
        const membershipData = membershipSnapshot.data();
        const staffSnapshot = await db
          .collection(usersCollectionName)
          .doc(membershipData?.memberId)
          .get();
        staffData.push(staffSnapshot.data());
      }
    }
    return setGasStationStaffData(staffData);
  };
  useEffect(() => {
    currentUser && retriveGasStationStaff();
  }, []);

  return (
    <DisplayItemDetails
      currentUser={currentUser}
      members={gasStationStaffData}
    />
  );
};

export default GasStationDetailsPage;
