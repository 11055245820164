/* eslint-disable jsx-a11y/no-redundant-roles */
import { useCallback, useContext, useRef, useState } from "react";
import { UserInterface, mapsGender } from "../../../../interfaces";
import {
  ArrowUpTrayIcon,
  HomeIcon,
  PhoneIcon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { usersCollectionName } from "../../../../config";
import { useLocation, useNavigate } from "react-router-dom";
import { classNames } from "../../../../utils";
import { functions, storage } from "../../../../firebase";
import toast from "react-hot-toast";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  Form,
  FormInput,
  FormSelect,
} from "../../../../components/FormComponents";
import { UserContext } from "../../../../contexts";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import Webcam from "react-webcam";

type UserAffiliateAgentInfos = Pick<
  UserInterface,
  | "displayName"
  | "email"
  | "active"
  | "agence"
  | "city"
  | "adresse"
  | "cnibNumber"
  | "createdAt"
  | "gender"
  | "phoneNumber"
  | "profession"
  | "userRole"
  | "userAgencySubRole"
  | "updatedAt"
>;

type Step = {
  id:
    | "UserAffiliateAgentMinInfos"
    | "UserAffiliateAgentInfos"
    | "UserAffiliateAgentFileInfos";
  index: number;
  name: string;
  description: string;
  icon: any;
};

const steps: Step[] = [
  {
    id: "UserAffiliateAgentMinInfos",
    index: 0,
    name: "Les informations de connexion",
    description: "Renseigner les informations de connexion de l'utilisateur",
    icon: HomeIcon,
  },
  {
    id: "UserAffiliateAgentInfos",
    index: 1,
    name: "Les Informations de l'utilisateur",
    description: "Renseigner les informations de l'utilisateur",
    icon: UserCircleIcon,
  },
  {
    id: "UserAffiliateAgentFileInfos",
    index: 2,
    name: "Rattacher des fichiers",
    description: "Rattacher des fichiers (PNG,JPEG)",
    icon: ArrowUpTrayIcon,
  },
];

const collectionName = usersCollectionName;

const date = new Date().toLocaleDateString().toString().split("/").join("_");
const time = new Date()
  .toLocaleTimeString()
  .toString()
  .split(" ")[0]
  .split(":")
  .join("_");

const UserAffiliatedAgentEditPage = () => {
  //React native Hook
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = location.state?.entity;

  //API functions
  const sankMoneySendOtpCodeFn = functions.httpsCallable(
    "sankMoneySendOtpCodeFn"
  );
  const sankMoneyverifyAgentCreateUserOtpCodeFn = functions.httpsCallable(
    "sankMoneyverifyAgentCreateUserOtpCodeFn"
  );
  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );

  //Set State variable
  const [currentFormStep, setCurrentFormStep] = useState<Step>(steps[0]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isDisplayOtpForm, setIsDisplayOtpForm] = useState(false);
  const [otp, setOtp] = useState("");
  const [UserUid, setUserUid] = useState("");
  const [inputError, setInputError] = useState(false);
  const [btnDesable, setBtnDesable] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [inputDiseable, setInputDiseable] = useState(true);
  const { user } = useContext(UserContext);
  const [imageUrl, setImageUrl] = useState<string>();
  const [isChooseOnRepertory, setIsChooseOnRepertory] = useState(false);

  const webcamRef = useRef<any>(null);
  const videoConstraints = {
    facingMode: { exact: "environment" },
  };
  const [imgSrc, setImgSrc] = useState<any>(null);

  //Action functions
  const retrivePhoneNumber = async (e: any) => {
    const phoneNumber = e.target.value;
    if (phoneNumber >= 8) {
      setPhoneNumber(`+226${phoneNumber}`);
    }
  };

  const sendOtp = async () => {
    setIsDisplayOtpForm(true);
    setBtnDesable(true);
    if (phoneNumber) {
      await sankMoneySendOtpCodeFn({
        phoneNumber: phoneNumber,
      })
        .then((result) => {
          if (result.data.result) {
            if (!result.data.active) {
              return toast.error(
                "Votre compte sur SankMoney a été temporairement bloqué. Merci de contacter le service client"
              );
            }
            setIsDisplayOtpForm(false);
            setBtnDesable(false);
            setInputDiseable(false);
          }
        })
        .catch((err: any) => {
          toast.error(err);
          setIsDisplayOtpForm(false);
          setBtnDesable(false);
        });
    } else toast.error("veuillez renseigner un numéro de téléphone.");
  };

  const verifyOtpCode = async () => {
    setIsLoad(true);
    setBtnDesable(true);
    if (otp.length === 6) {
      sankMoneyverifyAgentCreateUserOtpCodeFn({
        phoneNumber: phoneNumber,
        otpCode: otp,
      })
        .then(async (result) => {
          setInputError(false);
          setIsLoad(false);
          setBtnDesable(false);
          if (result.data.uid) {
            setUserUid(result.data.uid);
            setCurrentFormStep(steps[1]);
          } else {
            toast.error("L'utilisateur existe déjà");
            return;
          }
        })
        .catch((err: any) => {
          toast.error(err);
          setIsLoad(false);
          setInputError(true);
          setBtnDesable(true);
        });
    }
  };

  const onSubmitInfos = async (data: any) => {
    if (UserUid) {
      const dataValue = {
        id: UserUid,
        ...data,
        displayName: `${data.firstName} ${data.lastName}`,
        personToNotify: `${data.personToNotifyName}  ${data.personToNotifyPhone}`,
        phoneNumber: phoneNumber,
        affiliateAgentId: user?.id,
      };

      delete dataValue.personToNotifyName;
      delete dataValue.personToNotifyPhone;

      const response = sankmoneyUpdateAccountFn({
        collectionName: collectionName,
        documentData: dataValue,
        documentId: UserUid,
      })
        .then((result) => {
          setCurrentFormStep(steps[2]);
        })
        .catch((err: { message: any }) => toast.error(err.message));
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success:
          "Les informations additionnel de l'utilisateur ont été enregistré avec succès",
        loading: "chargement...",
      });
    }
  };

  function dataURLtoFile(url: string, filename: string, mimeType: any) {
    if (url.startsWith("data:")) {
      var arr: any = url.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[arr.length - 1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      var file = new File([u8arr], filename, { type: mime || mimeType });
      return Promise.resolve(file);
    }
    return fetch(url)
      .then((res) => res.arrayBuffer())
      .then((buf) => new File([buf], filename, { type: mimeType }));
  }

  const handlechange = async (value: any) => {
    setIsLoad(true);
    const image = isChooseOnRepertory
      ? value.target.files[0]
      : await dataURLtoFile(value, `${date}_${time}`, "image/jpeg");
    if (image) {
      const imageRef = ref(
        storage,
        `users/${UserUid}/cnibImage/${
          isChooseOnRepertory ? image.lastModified : `${date}_${time}`
        }`
      );
      uploadBytes(imageRef, image)
        .then(() => {
          getDownloadURL(imageRef)
            .then((url) => {
              setIsLoad(false);
              setImageUrl(url);
            })
            .catch((error) =>
              toast.error(
                `Une erreur s'est produit lors du chargement de l'url du fichier ${error.message}`
              )
            );
          setImageUrl("");
        })
        .catch((error) =>
          toast.error(
            `Une erreur s'est produit lors de l'upload du fichier ${error.message}`
          )
        );
    }
  };

  const onSubmitFile = () => {
    const response = sankmoneyUpdateAccountFn({
      collectionName: collectionName,
      documentData: {
        cnibImageUrl: imageUrl,
      },
      documentId: UserUid,
    }).then((result) => {
      navigate("/agent/users");
    });
    toast.promise(response, {
      error: "Une erreur s'est produite. Veuillez réessayer !",
      success:
        "Les informations additionnel de l'utilisateur ont été enregistré avec succès",
      loading: "chargement...",
    });
  };

  const HandleOtpChange = (e: any) => {
    const otp = e.target.value;
    if (otp >= 6) setOtp(otp);
    else setInputError(true);
  };

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    setImgSrc(imageSrc);
    handlechange(imageSrc);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [webcamRef, setImgSrc]);

  return (
    <>
      <div className="lg:border-t lg:border-b lg:border-gray-200">
        <nav
          className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
          aria-label="Progress"
        >
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
          >
            <li className="relative px-2 py-4 overflow-hidden lg:py-2">
              <button
                className="p-1 border border-transparent rounded-full hover:border-gray-200 hover:bg-gray-50"
                onClick={() => navigate("/agent/users")}
              >
                <XMarkIcon className="w-8 h-8 text-gray-700" />
              </button>
            </li>
            {steps.map((step, stepIdx) => (
              <li key={stepIdx} className="relative overflow-hidden lg:flex-1">
                <div
                  className={classNames(
                    stepIdx === 0 ? "border-b-0 rounded-t-md" : "",
                    stepIdx === steps.length - 1
                      ? "border-t-0 rounded-b-md"
                      : "",
                    "border border-gray-200 overflow-hidden lg:border-0 h-full"
                  )}
                >
                  <button
                    onClick={() => void 0}
                    className={classNames(
                      "w-full group h-full cursor-not-allowed"
                    )}
                  >
                    <span
                      className={classNames(
                        step.index !== currentFormStep.index
                          ? "group-hover:bg-gray-200 bg-transparent "
                          : "bg-red-600",
                        "absolute top-0 left-0 w-1 h-full  lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "px-4 py-4 lg:py-2 flex items-start text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span
                          className={classNames(
                            step.index < currentFormStep.index
                              ? "bg-red-600"
                              : "",
                            step.index === currentFormStep.index
                              ? "bg-white border-2 border-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "bg-white border-2 border-gray-300"
                              : "",
                            "flex items-center justify-center w-10 h-10  rounded-full"
                          )}
                        >
                          <step.icon
                            className={classNames(
                              step.index < currentFormStep.index
                                ? "text-white"
                                : "",
                              step.index === currentFormStep.index
                                ? "text-red-600"
                                : "",
                              step.index > currentFormStep.index
                                ? "text-gray-500"
                                : "",
                              "w-6 h-6 "
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex-col flex  xl:flex">
                        <span
                          className={classNames(
                            step.index === currentFormStep.index
                              ? "text-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "text-gray-500"
                              : "",
                            "text-xs font-semibold tracking-wide uppercase md:block lg:hidden xl:block"
                          )}
                        >
                          {step.name}
                        </span>
                        <span className="flex items-start text-sm font-medium text-gray-500">
                          {step.description}
                        </span>
                      </span>
                    </span>
                  </button>
                  <div
                    className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="w-full h-full text-gray-300"
                      viewBox="0 0 12 82"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0.5 0V31L10.5 41L0.5 51V82"
                        stroke="currentcolor"
                        vectorEffect="non-scaling-stroke"
                      />
                    </svg>
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="items-center w-full mx-auto md:w-11/12 lg:w-9/12">
        {currentFormStep.id === "UserAffiliateAgentMinInfos" && (
          <div className="mt-10 text-black sm:space-y-4 md:w-2/4 lg:w-2/4 ">
            <label
              htmlFor="hs-inline-leading-pricing-select-label"
              className="text-base text-left text-gray-500 dark:text-gray-400"
            >
              Vérifiez le numéro de téléphone du client.
            </label>
            <div className="sm:flex-wrap md:flex-wrap lg:flex">
              <label
                htmlFor="search"
                className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
              >
                Veuillez entrer le numéro de téléphone du client
              </label>
              <div className="relative w-full">
                <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                  <PhoneIcon
                    className="self-center flex-shrink-0 w-4 h-4 mr-1 text-red-500"
                    aria-hidden="true"
                  />
                </div>
                <input
                  type="text"
                  id="search"
                  maxLength={8}
                  minLength={8}
                  onChange={retrivePhoneNumber}
                  className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  placeholder="Numéro de téléphone"
                />
                <button
                  type="submit"
                  onClick={() =>
                    isDisplayOtpForm ? setIsDisplayOtpForm(false) : sendOtp()
                  }
                  className="text-white absolute right-2.5 bottom-2.5 bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                >
                  {isDisplayOtpForm ? "Modifier le numéro" : "Envoyer"}
                </button>
              </div>
            </div>
            <div className="flex flex-col mt-10 space-y-2">
              <div>
                <label
                  htmlFor="otp"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Votre code OTP
                </label>
                <input
                  type="text"
                  id="otp"
                  disabled={inputDiseable}
                  maxLength={6}
                  minLength={6}
                  onChange={HandleOtpChange}
                  className={classNames(
                    inputError
                      ? "focus:ring-red-500 focus:border-red-500 dark:focus:ring-red-500 dark:focus:border-red-500"
                      : "focus:ring-green-500 focus:border-green-500 dark:focus:ring-green-500 dark:focus:border-green-500",
                    "bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg  block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white "
                  )}
                  placeholder="123456"
                />
              </div>

              <div className="flex flex-col space-y-5">
                <div>
                  <button
                    className={classNames(
                      inputDiseable ? "cursor-not-allowed" : "cursor-pointer",
                      "flex flex-row items-center justify-center w-full py-5 text-base font-semibold text-center text-white bg-red-600 border border-none shadow-sm outline-none rounded-xl"
                    )}
                    onClick={() => verifyOtpCode()}
                    disabled={inputDiseable ? inputDiseable : btnDesable}
                  >
                    {isLoad && (
                      <svg
                        className="w-5 h-5 mr-5 -ml-1 text-white animate-spin"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                    )}
                    Vérifier
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {currentFormStep.id === "UserAffiliateAgentInfos" && (
          <Form<UserAffiliateAgentInfos>
            onSubmit={onSubmitInfos}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  firstName: yup.string().required("Le champs est obligatoire"),
                  lastName: yup.string().required("Le champs est obligatoire"),
                  personToNotifyName: yup
                    .string()
                    .required("Le champs est obligatoire"),
                  personToNotifyPhone: yup
                    .string()
                    .required("Le champs est obligatoire"),
                  email: yup.string().optional(),
                  city: yup.string().optional(),
                  profession: yup.string().optional(),
                  adresse: yup.string().optional(),
                  cnibNumber: yup.string().optional(),
                  phoneNumber: yup.string().optional(),
                })
              ),
            }}
            submitButtonLabel="Enregistrer"
            className="flex justify-center"
          >
            <div className="flex flex-col content-center w-full px-5 mt-5 align-top md:flex-row">
              <div className="w-full mx-auto md:w-1/2">
                <FormInput name="firstName" label="Nom" placeholder="Doe" />
                <FormInput name="lastName" label="Prénom" placeholder="John" />
                <FormInput
                  name="city"
                  label="Ville"
                  optional
                  placeholder="Ouagadougou"
                />
                <FormInput
                  name="adresse"
                  optional
                  label="Adresse"
                  placeholder="Rue 10.34, secteur 30, Ouaga2000"
                />
                <FormInput
                  name="email"
                  label="E-mail"
                  placeholder="@exemple.com"
                  optional
                />
              </div>
              <div className="w-10 h-5"></div>
              <div className="w-full mx-auto md:w-1/2">
                <FormInput
                  name="cnibNumber"
                  label="Numéro de CNI/Passport"
                  placeholder="XXXXXXX"
                />
                <FormInput
                  name="profession"
                  label="Profession"
                  optional
                  placeholder="Agent"
                />
                <FormSelect<string>
                  label={"Genre"}
                  name="gender"
                  selectedOption={
                    currentUser ? currentUser.gender : "Choisir..."
                  }
                  options={["FEMALE", "MALE", "NONBINARY", "UNKNOWN"]}
                  optionLabel={(option) =>
                    (mapsGender.get(option) as string) ?? "Choisir..."
                  }
                />
                <FormInput
                  name="personToNotifyName"
                  label="Nom / Prénoms (Personne à prévenir)"
                  placeholder="John Doe"
                />
                <FormInput
                  name="personToNotifyPhone"
                  label="Téléphone (Personne à prévenir)"
                  placeholder="+226 85 85 85 85"
                />
              </div>
            </div>
          </Form>
        )}
        {currentFormStep.id === "UserAffiliateAgentFileInfos" && (
          <div className="flex items-center justify-center w-full md:p-12 lg:p-12 sm:p-1">
            <div className="mx-auto w-full md:w-[550px] lg:w-[550px] sm:w-full bg-white">
              <div className="pt-4 mb-6">
                <label className="mb-5 block text-xl font-semibold text-[#07074D]">
                  Charger le fichier d'identification utilisateur
                  (CNI/Passport).
                </label>
                <div className="items-center justify-center mb-4 space-y-4 sm:flex sm:space-y-0 sm:space-x-4">
                  <div className="w-full sm:w-auto cursor-pointer bg-red-800 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 text-white rounded-lg inline-flex items-center justify-center px-4 py-2.5 dark:bg-red-700 dark:hover:bg-red-600 dark:focus:ring-red-700">
                    <label
                      htmlFor="file"
                      className="text-lef cursor-pointert"
                      onClick={() => setIsChooseOnRepertory(true)}
                    >
                      <input
                        type="file"
                        id="file"
                        accept="image/png, image/jpeg"
                        className="sr-only"
                        onChange={handlechange}
                      />
                      <div className="-mt-1 font-sans text-sm font-semibold">
                        Choisir dans le repertoire
                      </div>
                    </label>
                  </div>
                  <div className="w-full sm:w-auto cursor-pointer bg-red-800 hover:bg-red-700 focus:ring-4 focus:outline-none focus:ring-red-300 text-white rounded-lg md:inline-flex items-center justify-center px-4 py-2.5 dark:bg-red-700 dark:hover:bg-red-600 dark:focus:ring-red-700">
                    <div
                      onClick={() => {
                        capture();
                        setIsChooseOnRepertory(true);
                      }}
                      className="text-left cursor-pointer"
                    >
                      <div className="flex justify-center -mt-1 font-sans text-sm font-semibold">
                        Prendre une photo
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="mb-8">
                    <div className="relative md:flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] md:p-12 lg:p-12 text-center">
                      <Webcam
                        audio={false}
                        ref={webcamRef}
                        screenshotFormat="image/jpeg"
                        videoConstraints={videoConstraints}
                      />
                    </div>
                    <div className="relative cursor-pointer mt-2 flex min-h-[200px] items-center justify-center rounded-md border border-dashed border-[#e0e0e0] md:p-12 lg:p-12 text-center">
                      <div>
                        {imageUrl ? (
                          <img
                            className="object-cover object-center mx-auto"
                            src={imageUrl}
                            alt={imageUrl}
                          />
                        ) : isLoad ? (
                          <div>
                            <svg
                              className="w-5 h-5 mr-3 -ml-1 text-red-500 animate-spin"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              ></circle>
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              ></path>
                            </svg>
                          </div>
                        ) : (
                          <span
                            onClick={() => {
                              capture();
                              setIsChooseOnRepertory(true);
                            }}
                            className="hidden md:inline-flex rounded border border-[#e0e0e0] cursor-pointer py-2 px-7 text-base font-medium text-[#07074D]"
                          >
                            Prendre une photo
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="justify-between sm:flex-wrap md:flex-wrap lg:flex">
                <div className="mt-4 sm:mt-0 sm:flex-none">
                  <button
                    type="button"
                    disabled={!imageUrl}
                    className="inline-flex disabled:cursor-not-allowed p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
                    onClick={onSubmitFile}
                  >
                    <span className="flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                      Enregistrer
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default UserAffiliatedAgentEditPage;
