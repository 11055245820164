import { useContext, useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form, FormInput } from "../../FormComponents";
import SankLogoTransparent from "../../../Sank_red.png";
import firebase from "firebase/compat/app";
import Alert from "../../Alert";
import { db, functions } from "../../../firebase";
import { UserContext } from "../../../contexts";
import { UserInterface, UserRole } from "../../../interfaces";
import { useRouter } from "../../../hooks/useRouter";
import OtpInput from "react-otp-input";
import {
  usersCollectionName,
  accountsCollectionName,
  gasStationCollectionName,
  membershipCollectionName,
  gasStationMembershipCollectionName,
} from "../../../config";
import toast from "react-hot-toast";

interface FormFields {
  email: string;
  password: string;
}

const inputFieldStyle =
  "flex flex-col items-center text-black py-1 mx-1 md:py-1 w-10 h-10 justify-center text-lg text-center bg-white border border-gray-200 outline-none rounded-xl focus:bg-gray-50 focus:border-green focus:ring-1 ring-green-700";

const LoginWithEmail = () => {
  const [, setFormReturn] = useState<UseFormReturn<FormFields>>();
  const [error, setError] = useState<any>();
  const { setUser, setUserAccounts, setAgencyAgent } = useContext(UserContext);
  const router = useRouter();
  const [isDisplayOtpForm, setIsDisplayOtpForm] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [btnDesable, setBtnDesable] = useState(false);
  const [otp, setOtp] = useState("");

  const sankMoneyVerifyOtpByEmailFn = functions.httpsCallable(
    "sankMoneyVerifyOtpByEmailFn"
  );
  const sankMoneySendOtpByEmailFn = functions.httpsCallable(
    "sankMoneySendOtpByEmailFn"
  );

  const getAllUserWithAccounts = async (id: string) => {
    const userSnapshot = await db.collection(usersCollectionName).doc(id).get();
    const usersData = userSnapshot.data();
    if (usersData) {
      setUser(usersData);
      switch (usersData.userRole) {
        case UserRole.AGENCYSTAFF:
          retriveAgencyData(id, usersData as UserInterface);
          break;
        case UserRole.GASSTATIONSTAFF:
          retriveGasStationData(id, usersData as UserInterface);
          break;
        default:
          const userAccountSnapShot = await userSnapshot.ref
            .collection(accountsCollectionName)
            .get();
          const userAccountsData = userAccountSnapShot.docs.map((account) =>
            account.data()
          );
          setUserAccounts(userAccountsData);
          return redirectTo(usersData as UserInterface);
      }
    }
  };

  const retriveAgencyData = async (id: string, usersData: UserInterface) => {
    const membershipRef = db.collection(membershipCollectionName);
    const membershipSnaphot = await membershipRef
      .where("memberId", "==", id)
      .get();
    const userRef = db.collection(usersCollectionName);
    const membershipData = membershipSnaphot.docs[0].data();
    if (membershipData) {
      const agencyAgentData = await userRef
        .where("userRole", "==", UserRole.AGENT)
        .where("agencyId", "==", membershipData?.agencyId)
        .get();
      if (agencyAgentData.empty) {
        setUserAccounts([]);
      }
      setAgencyAgent(agencyAgentData.docs[0]?.data() as UserInterface);
      const userAccountSnapShot = await userRef
        .doc(agencyAgentData.docs[0]?.id)
        .collection(accountsCollectionName)
        .get();
      const userAccountsData = userAccountSnapShot.docs.map((account) =>
        account.data()
      );
      setUserAccounts(userAccountsData);
    }
    return redirectTo(usersData);
  };

  const retriveGasStationData = async (
    id: string,
    usersData: UserInterface
  ) => {
    const membershipRef = db.collection(gasStationMembershipCollectionName);
    const membershipSnaphot = await membershipRef
      .where("memberId", "==", id)
      .get();
    const membershipData = membershipSnaphot.docs[0].data();
    if (membershipData) {
      const userAccountSnapShot = await db
        .collection(gasStationCollectionName)
        .doc(membershipData?.gasStationId)
        .collection(accountsCollectionName)
        .get();
      const userAccountsData = userAccountSnapShot.docs.map((account) =>
        account.data()
      );
      setUserAccounts(userAccountsData);
    }
    return redirectTo(usersData);
  };

  const onSubmit = async (data: FormFields) => {
    setEmail(data.email)
    await firebase
      .auth()
      .signInWithEmailAndPassword(data.email, data.password)
      .then(async (credential) => {
        setIsDisplayOtpForm(true)
        sankMoneySendOtpByEmailFn({email: data.email}).then((result) => {
          if (result.data) {
            if (!result.data) {
              return toast.error(
                "Votre compte sur SankMoney a été temporairement bloqué. Merci de contacter le service client"
              );
            }
            setIsDisplayOtpForm(true);
          }
        })
        .catch((err: any) => toast.error(err));
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  const verifyOtpCode = async () => {
    setIsLoad(true);
    setBtnDesable(true);
    if (otp.length === 6) {
      sankMoneyVerifyOtpByEmailFn({
        email: email,
        otpCode: otp,
      })
        .then(async (result) => {
          if (result.data.token) {
            await firebase
              .auth()
              .signInWithCustomToken(result.data.token)
              .then(async (result) => {
                setIsDisplayOtpForm(false);
                setInputError(false);
                getAllUserWithAccounts(result.user?.uid!)
                
              });
          }
        })
        .catch((err: any) => {
          // setError(err);
          setIsLoad(false);
          setInputError(true);
          setBtnDesable(false);
        });
    }
  };

  const redirectTo = (user: UserInterface) => {
    if (user.userRole) {
      router.push(`/dashboard`);
    }
  };
  return (
    <div className="m-0 font-sans text-base antialiased font-normal bg-white text-start leading-default text-slate-500">
      <main className="mt-0 transition-all duration-200 ease-soft-in-out">
          <div className="relative flex items-center justify-center p-0 mx-auto overflow-hidden bg-center bg-cover min-h-75-screen">
            <div className="container z-10 mt-24">
              <div className="flex flex-wrap mt-0 -mx-3">
                <div className="flex flex-col w-full max-w-full mx-auto border rounded-md border-gradient-to-r from-pink-500 via-red-500 to-yellow-500 md:flex-0 shrink-0 md:w-6/12 lg:w-5/12 xl:w-4/12">
                  <div className="relative flex flex-col min-w-0 mt-10 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                      <div className="flex justify-center">
                        <img
                          className="w-auto h-44"
                          src={SankLogoTransparent}
                          alt="Sank"
                        />
                      </div>
                    </div>
                    <div className="p-4 pb-0 mb-0 bg-transparent border-b-0 rounded-t-2xl">
                      <h3 className="relative z-10 font-bold text-transparent bg-gradient-to-tl from-black to-red-300 bg-clip-text">
                        Accédez à Sank
                      </h3>
                      <p className="mb-0">
                        Entrez votre email et votre mot de passe pour vous
                        connecter
                      </p>
                    </div>
                    {error && (
                      <Alert
                        type="danger"
                        title="Authentification à échouer !"
                        content={error}
                      />
                    )}
                    <div className="flex-auto px-6 py-3">
                      {
                        !isDisplayOtpForm ? (
                          <Form<FormFields>
                            onSubmit={onSubmit}
                            formReturnAware={setFormReturn}
                            form={{
                              resolver: yupResolver(
                                yup.object().shape({
                                  email: yup
                                    .string()
                                    .email()
                                    .required("Le champs est obligatoire"),
                                  password: yup
                                    .string()
                                    .required("Le champs est obligatoire"),
                                })
                              ),
                            }}
                            submitButtonFullWidth
                            submitButtonLabel="Se connecter"
                          >
                            <div className="space-y-4 text-black md:space-y-6">
                              <FormInput
                                name="email"
                                label="Adresse mail"
                                type="email"
                              />
                              <FormInput
                                name="password"
                                label="Mot de passe"
                                type="password"
                              />
                            </div>
                          </Form>
                        ) : (
                          <div className="relative flex flex-col justify-center overflow-hidden">
                            <div className="my-4">
                              <h2 className="text-3xl font-semibold text-center">
                                Vérification
                              </h2>
                              <div className="flex flex-row text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                                {email && (
                                  <p>Entrez le code envoyé à {email}</p>
                                )}
                              </div>
                            </div>
                            <div className="flex flex-col my-4 space-y-2">
                              <OtpInput
                                inputStyle="inputStyle"
                                value={otp}
                                onChange={setOtp}
                                numInputs={6}
                                shouldAutoFocus
                                renderInput={(props) => (
                                  <input
                                    {...props}
                                    className={inputFieldStyle}
                                    style={{
                                      color: "black",
                                      borderColor: inputError
                                        ? "red"
                                        : "rgb(230, 230, 230)",
                                    }}
                                  />
                                )}
                                containerStyle="flex flex-row items-center text-black justify-between w-full"
                              />
                            </div>
                            <div className="flex flex-col space-y-5">
                              <div>
                                <button
                                  className="flex flex-row items-center justify-center w-full py-3 text-base font-semibold text-center text-white bg-red-600 border border-none shadow-sm outline-none rounded-xl"
                                  onClick={() => verifyOtpCode()}
                                  disabled={btnDesable}
                                >
                                  {isLoad && (
                                    <svg
                                      className="w-5 h-5 mr-5 -ml-1 text-white animate-spin"
                                      xmlns="http://www.w3.org/2000/svg"
                                      fill="none"
                                      viewBox="0 0 24 24"
                                    >
                                      <circle
                                        className="opacity-25"
                                        cx="12"
                                        cy="12"
                                        r="10"
                                        stroke="currentColor"
                                        strokeWidth="4"
                                      ></circle>
                                      <path
                                        className="opacity-75"
                                        fill="currentColor"
                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                      ></path>
                                    </svg>
                                  )}
                                  Vérifier
                                </button>
                              </div>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </main>
    </div>
  );
};

export default LoginWithEmail;
