/* eslint-disable jsx-a11y/no-redundant-roles */
import { mapUserAvailableZone, userAvailableZonesOptions, UserInterface, UserRole } from "../../../interfaces";
import { useEffect, useState } from "react";
import {
  Form,
  FormInput,
  FormSelect,
} from "../../../components/FormComponents";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { useLocation, useNavigate } from "react-router-dom";
import { db, functions } from "../../../firebase";
import { AtSymbolIcon, BuildingLibraryIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { classNames } from "../../../utils";
import {
  generatedPointOfCollectCodeCollectionName,
  usersCollectionName,
} from "../../../config";

const collectionName = usersCollectionName;
const mapsGender = new Map<string, string>([
  ["MALE", "Homme"],
  ["FEMALE", "Femme"],
  ["NONBINARY", "Non binaire"],
  ["UNKNOWN", "Inconnu"],
]);
type AdditionnalInfos = Pick<
  UserInterface,
  | "displayName"
  | "email"
  | "active"
  | "agence"
  | "city"
  | "adresse"
  | "cnibNumber"
  | "createdAt"
  | "merchantCode"
  | "gender"
  | "phoneNumber"
  | "profession"
  | "userRole"
  | "availableZone"
  | "pointOfCollectIdentity"
  | "updatedAt"
>;
type Step = {
  id: "additionnalInfo" | "affiliation" ;
  index: number;
  name: string;
  description: string;
  icon: any;
};
const steps: Step[] = [
  {
    id: "additionnalInfo",
    index: 0,
    name: "Informations additionnelles",
    description: "Renseigner les informations additionnelles de l'utilisateur",
    icon: AtSymbolIcon,
  },
  {
    id: "affiliation",
    index: 1,
    name: "Affiliations",
    description: "Affiliations des points points de collecte aux collecteurs",
    icon: BuildingLibraryIcon,
  }
];
const MakeUserACollectionPoint = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = location.state?.entity;
  const [currentFormStep, setCurrentFormStep] = useState<Step>(steps[0]);
  const userRef = db.collection(usersCollectionName).doc(currentUser.id);
  const [pointOfCollectIdentity, setPointOfCollectIdentity] = useState<string>();
  const [collectorData, setCollectorData] = useState<any[]>([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  
  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );

  const affiliateCollectionPointToCollectorFn = functions.httpsCallable(
    "affiliateCollectionPointToCollectorFn"
  );

  const generatedCodeCollectionPointRef = db.collection(
    generatedPointOfCollectCodeCollectionName
  );

  const handleChange = async (e: any) => {
    const identificationCode = e.target.value;
    if (identificationCode.length === 7) {
      const generatedMerchantCodeSnap = await generatedCodeCollectionPointRef
        .where("isAffected", "==", false)
        .where("code", "==", identificationCode)
        .get();
      if (!generatedMerchantCodeSnap.empty) {
        toast.success("L' identifiant du point de collecte que vous avez entré est correct");
        return setPointOfCollectIdentity(generatedMerchantCodeSnap.docs[0].id);
      } else {
        toast.error("L' identifiant du point de collecte que vous avez entré est indisponible");
      }
    }
  };

  const affiliateCollectionPointToCollector = async (id: string) => {
    const data = { ownerId: id, memberId: currentUser?.id };
    const res = affiliateCollectionPointToCollectorFn(data);
    toast.promise(
      res.then(() => {
        setCollectorData([]);
        navigate("/collections_point")
      }),
      {
        loading: "Affiliation en cours...",
        success: "Le point de collecte a été affilié avec succès",
        error: (e) => e.message,
      }
    );
  };

  const onSubmitAdditionnalInfo = async (data: any) => {
    if (currentUser) {
      data.active = true;
      data.userRole = UserRole.COLLECTIONPOINT;
      delete currentUser.createdAt;
      delete currentUser.updatedAt;
      delete currentUser.phoneNumber;
      const dataValue = {
        id: currentUser.id,
        isUserRoleGettingTransformed: true,
        ...data,
      };
      if (pointOfCollectIdentity) {
        dataValue.pointOfCollectIdentity = pointOfCollectIdentity;
        await generatedCodeCollectionPointRef
          .doc(pointOfCollectIdentity)
          .update({ isAffected: true });
      }
      const response = sankmoneyUpdateAccountFn({
        collectionName: collectionName,
        documentData: dataValue,
        documentId: currentUser.id,
      })
        .then(async () => {
          setCurrentFormStep(steps[1]);
        })
        .catch((err: { details: any; message: any }) =>
          toast.error(err.details.message)
        );
      toast.promise(response, {
        error: "La mise à jour a échoué",
        success: "L'utilisateur a été mise jour avec succès",
        loading: "chargement...",
      });
    } else
      toast.error(
        `L'indentifiant de l'utilisateur est incorrect veuiller réessayer de nouveau !`
      );
  };

  const handlechange = (value: string) => {
    if (value) {
      setPhoneNumber(value);
    }
  };

  const retriveAgencyData = async () => {
    if (phoneNumber) {
      await db
        .collection(usersCollectionName)
        .where("phoneNumber", "==", phoneNumber)
        .where("userRole", "==", UserRole.COLLECTOR)
        .get()
        .then((response) => {
          setCollectorData(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
    }
  };

  useEffect(() => {
    currentUser && setCurrentFormStep(steps[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="lg:border-t lg:border-b lg:border-gray-200">
        <nav
          className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
          aria-label="Progress"
        >
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
          >
            <li className="relative px-2 py-4 overflow-hidden lg:py-2">
              <button
                className="p-1 border border-transparent rounded-full hover:border-gray-200 hover:bg-gray-50"
                onClick={() => navigate("/collections_point")}
              >
                <XMarkIcon className="w-8 h-8 text-gray-700" />
              </button>
            </li>
            {steps.map((step, stepIdx) => {
              return (
                <li
                  key={stepIdx}
                  className="relative overflow-hidden lg:flex-1"
                >
                  <div
                    className={classNames(
                      stepIdx === 0 ? "border-b-0 rounded-t-md" : "",
                      stepIdx === steps.length - 1
                        ? "border-t-0 rounded-b-md"
                        : "",
                      "border border-gray-200 overflow-hidden lg:border-0 h-full"
                    )}
                  >
                    <button
                      className={classNames(
                        !currentUser ? "cursor-not-allowed" : "",
                        currentUser && step.index === 2 ? "" : "",
                        "w-full group h-full"
                      )}
                    >
                      <span
                        className={classNames(
                          step.index !== currentFormStep.index
                            ? "group-hover:bg-gray-200 bg-transparent "
                            : "bg-red-600",
                          "absolute top-0 left-0 w-1 h-full  lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                        )}
                        aria-hidden="true"
                      />
                      <span
                        className={classNames(
                          stepIdx !== 0 ? "lg:pl-9" : "",
                          "px-4 py-4 lg:py-2 flex items-start text-sm font-medium"
                        )}
                      >
                        <span className="flex-shrink-0">
                          <span
                            className={classNames(
                              step.index < currentFormStep.index
                                ? "bg-red-600"
                                : "",
                              step.index === currentFormStep.index
                                ? "bg-white border-2 border-red-600"
                                : "",
                              step.index > currentFormStep.index
                                ? "bg-white border-2 border-gray-300"
                                : "",
                              "flex items-center justify-center w-10 h-10  rounded-full"
                            )}
                          >
                            <step.icon
                              className={classNames(
                                step.index < currentFormStep.index
                                  ? "text-white"
                                  : "",
                                step.index === currentFormStep.index
                                  ? "text-red-600"
                                  : "",
                                step.index > currentFormStep.index
                                  ? "text-gray-500"
                                  : "",
                                "w-6 h-6 "
                              )}
                              aria-hidden="true"
                            />
                          </span>
                        </span>
                        <span className="mt-0.5 ml-4 min-w-0 flex-col flex  xl:flex">
                          <span
                            className={classNames(
                              step.index === currentFormStep.index
                                ? "text-red-600"
                                : "",
                              step.index > currentFormStep.index
                                ? "text-gray-500"
                                : "",
                              "text-xs font-semibold tracking-wide uppercase md:block lg:hidden xl:block"
                            )}
                          >
                            {step.name}
                          </span>
                          <span className="flex items-start text-sm font-medium text-gray-500">
                            {step.description}
                          </span>
                        </span>
                      </span>
                    </button>
                    <div
                      className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                      aria-hidden="true"
                    >
                      <svg
                        className="w-full h-full text-gray-300"
                        viewBox="0 0 12 82"
                        fill="none"
                        preserveAspectRatio="none"
                      >
                        <path
                          d="M0.5 0V31L10.5 41L0.5 51V82"
                          stroke="currentcolor"
                          vectorEffect="non-scaling-stroke"
                        />
                      </svg>
                    </div>
                  </div>
                </li>
              );
            })}
          </ol>
        </nav>
      </div>
      <div className="items-center w-full mx-auto md:w-11/12 lg:w-9/12">
        {currentFormStep.id === "additionnalInfo" && (
          <Form<AdditionnalInfos>
            onSubmit={onSubmitAdditionnalInfo}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  displayName: yup
                    .string()
                    .required("Le champs est obligatoire"),
                  email: yup.string().optional(),
                  city: yup.string().optional(),
                  profession: yup.string().optional(),
                  adresse: yup.string().optional(),
                  cnibNumber: yup.string().optional(),
                  phoneNumber: yup.string().optional(),
                  availableZone: yup.string().optional(),
                  pointOfCollectIdentity: yup.string().required("Le champs est obligatoire")
                })
              ),
              defaultValues: {
                displayName: currentUser ? currentUser.displayName : "",
                city: currentUser ? currentUser.city : "",
                adresse: currentUser ? currentUser.adresse : "",
                profession: currentUser ? currentUser.profession : "",
                phoneNumber: currentUser ? currentUser.phoneNumber : "",
                cnibNumber: currentUser ? currentUser.cnibNumber : "",
                email: currentUser ? currentUser.email : "",
                pointOfCollectIdentity: currentUser?.identificationCode ?? ""
              },
            }}
            submitButtonLabel="Enregistrer"
            className="flex justify-center"
            isSubmitBtnDisabled={pointOfCollectIdentity ? false : true}
          >
            <div className="flex flex-col content-center w-full px-5 mt-5 align-top md:flex-row">
              <div className="w-full mx-auto md:w-1/2">
                <FormInput
                  name="displayName"
                  label="Nom/Prénom"
                  placeholder="John Doe"
                />
                <FormInput
                  name="city"
                  label="Ville"
                  optional
                  placeholder="Ouagadougou"
                />
                <FormInput
                  name="adresse"
                  optional
                  label="Adresse"
                  placeholder="Rue 10.34, secteur 30, Ouaga2000"
                />
                <FormInput
                  name="email"
                  label="E-mail"
                  placeholder="@exemple.com"
                  optional
                />
                <FormInput
                  name="profession"
                  label="Profession"
                  optional
                  placeholder="Marchand"
                />
              </div>

              <div className="w-10 h-5"></div>

              <div className="w-full mx-auto md:w-1/2">
                <FormInput
                  name="phoneNumber"
                  label="Numéro de téléphone"
                  placeholder="7X XX XX XX"
                  disabled={currentUser ? true : false}
                />
                <FormInput
                  name="cnibNumber"
                  label="Numéro de CNIB"
                  placeholder="BXXXXXXX"
                />

                <FormSelect<string>
                  label={"Zone"}
                  name="availableZone"
                  selectedOption={
                    currentUser ? currentUser.availableZone : "Choisir..."
                  }
                  options={userAvailableZonesOptions}
                  optionLabel={(option) =>
                    (mapUserAvailableZone.get(option) as string) ?? "Choisir..."
                  }
                  disabled={
                    currentUser && currentUser.availableZone ? true : false
                  }
                />

                <FormSelect<string>
                  label={"Genre"}
                  name="gender"
                  selectedOption={
                    currentUser ? currentUser.gender : "Choisir..."
                  }
                  options={["FEMALE", "MALE", "NONBINARY", "UNKNOWN"]}
                  optionLabel={(option) =>
                    (mapsGender.get(option) as string) ?? "Choisir..."
                  }
                />
                <FormInput
                  name="pointOfCollectIdentity"
                  label="Saisir le code du point de collecte"
                  placeholder="Ex: 1234567"
                  onChange={handleChange}
                  helpLabel={`${
                    pointOfCollectIdentity
                      ? ""
                      : "Veuillez entrer un code qui est disponible"
                  }`}
                  maxLength={7}
                />
              </div>
            </div>
          </Form>
        )}
        {currentFormStep.id === "affiliation" && (
          <div className="px-4 sm:px-6 lg:px-8">
            <div className="lg:border-t lg:border-gray-200">
              <div className="items-center w-full mx-auto md:w-11/12 lg:w-9/12">
                <div className="mt-3 main-form">
                  <div className="items-center justify-between ">
                    <div className="items-center justify-between flex-1 max-w-sm">
                      <label
                        htmlFor="search"
                        className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white"
                      >
                        Rechercher
                      </label>
                      <div className="relative">
                        <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
                          <svg
                            className="w-4 h-4 text-gray-500 dark:text-gray-400"
                            aria-hidden="true"
                            xmlns="http://www.w3.org/2000/svg"
                            fill="none"
                            viewBox="0 0 20 20"
                          >
                            <path
                              stroke="currentColor"
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
                            />
                          </svg>
                        </div>
                        <input
                          type="search"
                          id="search"
                          className="block w-full p-4 pl-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                          placeholder="+22670707070"
                          onChange={(e) => handlechange(e.target.value)}
                        />
                        <button
                          type="submit"
                          className="text-white absolute right-2.5 bottom-2.5 bg-red-700 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                          onClick={() => retriveAgencyData()}
                        >
                          Rechercher
                        </button>
                      </div>
                    </div>
                    {collectorData && collectorData.length > 0 && (
                      <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                        <div className="flex flex-col items-center pb-10">
                          <img
                            className="w-24 h-24 mb-3 rounded-full shadow-lg"
                            src={
                              collectorData[0].data.profilImageUrl
                                ? collectorData[0].data.profilImageUrl
                                : `https://ui-avatars.com/api/?background=random&name=${
                                    collectorData[0].data.displayName || "S"
                                  }`
                            }
                            alt=""
                          />
                          <h5 className="mb-1 text-xl font-medium text-gray-900 dark:text-white">
                            {collectorData[0].data.displayName}
                          </h5>
                          <span className="text-sm text-gray-500 dark:text-gray-400">
                            {collectorData[0].data.phoneNumber}
                          </span>
                          <div className="flex mt-4 space-x-3 md:mt-6">
                            <div
                              className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                              onClick={() => affiliateCollectionPointToCollector(collectorData[0]?.id)}
                            >
                              Enregistrer
                            </div>
                            <div
                              className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                              onClick={() => setCollectorData([])}
                            >
                              Annuler
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default MakeUserACollectionPoint;
