import { useState } from "react";
import crudsService from "../services/cruds-service";

export default function useCrudDocument() {
  const [error, setError] = useState(null);

  const createDocument = async (data: any, collectionName: string) => {
    try {
      const response = await crudsService.save(data, collectionName);
      return response;
    } catch (err: any) {
      setError(err);
      return undefined;
    }
  };
  const createSubCollectiont = async (
    data: any,
    parentCollectionName: string,
    parentCollectionId: string,
    subCollectionName: string
  ) => {
    try {
      const response = await crudsService.saveSubCollection(
        data,
        parentCollectionName,
        parentCollectionId,
        subCollectionName
      );
      return response;
    } catch (err: any) {
      setError(err);
      return undefined;
    }
  };
  const getAllDocuments = async (collectionName: string) => {
    try {
      const response = await crudsService.getAll(collectionName);
      return response;
    } catch (err: any) {
      setError(err);
      return undefined;
    }
  };
  const getDocumentById = async (id: string, collectionName: string) => {
    try {
      const response = await crudsService.getById(id, collectionName);
      return response;
    } catch (err: any) {
      setError(err);
      return undefined;
    }
  };
  const updateDocument = async (
    id: string,
    collectionName: string,
    data: any
  ) => {
    try {
      const response = await crudsService.update(id, collectionName, data);
      return response;
    } catch (err: any) {
      setError(err);
      return undefined;
    }
  };
  const deleteDocument = async (id: string, collectionName: string) => {
    try {
      const response = await crudsService.delete(id, collectionName);
      return response;
    } catch (err: any) {
      setError(err);
      return undefined;
    }
  };

  return {
    createDocument,
    getAllDocuments,
    error,
    getDocumentById,
    updateDocument,
    deleteDocument,
    createSubCollectiont,
  };
}
