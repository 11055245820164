import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { InputHTMLAttributes, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { classNames, unwindObject } from '../../../utils';
import { HelpTooltip } from '../HelpTooltip';

export interface MultiSelectProps<T>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, 'id' | 'className'> {
  name: string;
  label?: string;
  options: T[];
  optionLabel?: (option: T) => string;
  optionValue?: (option: T) => unknown;
  selectedOptions?: T[];
  tooltip?: string;
}

export function FormMultiSelect<T>({
  name,
  label,
  options,
  optionLabel = (option) => `${option}`,
  optionValue = (option) => option,
  selectedOptions = [],
  tooltip,
  ...rest
}: MultiSelectProps<T>): JSX.Element {
  const methods = useFormContext();
  const [selected, setSelected] = useState(selectedOptions);
  return (
    <div className="my-4">
      <Controller
        control={methods.control}
        defaultValue={selected}
        name={name}
        render={({ field }) => (
          <>
            {label && (
              <label
                htmlFor={name}
                className="flex justify-between text-sm font-medium text-gray-700"
              >
                <span
                  className={classNames(
                    rest.required ? 'is-required' : '',
                    'block',
                  )}
                >
                  {label}
                </span>
                {tooltip && <HelpTooltip value={tooltip} />}
              </label>
            )}
            <div className="relative mt-1 rounded-md shadow-sm">
              <select
                id={name}
                multiple={true}
                onChange={(e: any) => {
                  const value = Array.from(
                    e.target.selectedOptions,
                    (option) => (option as any).value,
                  );
                  field.onChange(value);
                  setSelected(value);
                }}
                className={classNames(
                  unwindObject((methods as any).formState.errors, name)
                    ? 'border-red-300 focus:ring-red-500 focus:border-red-500'
                    : 'focus:ring-red-500 focus:border-red-500',
                  'appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none  sm:text-sm',
                )}
              >
                {options.map((option, optionIndex) => (
                  <option
                    key={optionIndex}
                    value={optionValue(option) as string}
                    className="px-3 py-2"
                  >
                    {optionLabel(option)}
                  </option>
                ))}
              </select>
              {(methods as any).formState.errors[name] && (
                <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
                  <ExclamationCircleIcon
                    className="w-5 h-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
            {(methods as any).formState.errors[name] && (
              <p className="mt-2 text-sm text-red-600">
                {(methods as any).formState.errors[name].message}
              </p>
            )}
          </>
        )}
      />
    </div>
  );
}

export default FormMultiSelect;
