import { useLocation } from "react-router-dom";
import FileComponent from "../../../components/FolderComponent/FileComponent";
import { useEffect, useState } from "react";
import NoContent from "../../../components/TableNoContentPage/NoContent";

const ReportTransactionsItemsPage = () => {
  const location = useLocation();
  const reportIems = location.state.reportIems;
  const [formatReportItems, setFormatReportItems] = useState<any[]>([]);

  const formatItems = () => {
    const groupedData: any[] = [];

    reportIems.forEach((item: any) => {
      const dateMatch = item.reportFileName.match(/(\d{4}-\d{2}-\d{2}-\d{6})/);
      if (dateMatch) {
        let year = dateMatch[0];
        const existingYears = groupedData;
        let count = 1;
        // eslint-disable-next-line no-loop-func
        while (existingYears.find((val) => val.reportFileName === year)) {
          year = `${dateMatch[0]} (${count})`;
          count++;
        }
        item.reportFileName = year;

        groupedData.push(item);
      }
    });
    setFormatReportItems(groupedData);
  };

  useEffect(() => {
    reportIems && formatItems();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col max-w-3xl mx-4">
      <p className="mb-4 text-base font-extrabold leading-none tracking-tight text-gray-900 md:text-5xl lg:text-2xl dark:text-white">
        Vos rapports
      </p>
      <div className="grid grid-cols-2 mt-6 gap-x-6 gap-y-10 sm:grid-cols-3 lg:grid-cols-5 xl:gap-x-8">
        {(formatReportItems as []).length > 0 ? (
          (formatReportItems as []).map((val: any, index) => (
            <div className="relative group" key={index}>
              <FileComponent
                title={val.reportFileName}
                reportUrl={val.reportUrl}
              />
            </div>
          ))
        ) : (
          <NoContent />
        )}
      </div>
    </div>
  );
};

export default ReportTransactionsItemsPage;
