import {
  ArrowSmallDownIcon,
  CheckIcon,
  EyeIcon,
  StarIcon,
  UserGroupIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import { useConfirm } from "../../../hooks/useConfirm";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../../utils";
import {
  Form,
  FormInput,
  FormSelect,
  FormTextArea,
} from "../../../components/FormComponents";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { db, functions } from "../../../firebase";
import { PhoneNumberDefaultLength, usersCollectionName } from "../../../config";
import {
  AccountType,
  SankMoneyReloadProcessStatusenum,
  UserRole,
  accountMap,
} from "../../../interfaces";
import toast from "react-hot-toast";
import Modal from "../../../components/Modal";
import CodePinComponent from "../../../components/CodePinComponent";
import PhoneInput from "react-phone-number-input";

type ReloadSankAccountType = {
  id?: string;
  amount: string;
  description: string;
  accountType: string;
};
type ReloadSankAccountDetailsType = {
  id?: string;
  amount?: string;
  description?: string;
  accountType?: string;
};

type Step = {
  id: "initialized" | "detail";
  index: number;
  name: string;
  description: string;
  icon: any;
};

const steps: Step[] = [
  {
    id: "initialized",
    index: 0,
    name: "Initier une recharge",
    description: "Initier une recharge pour le compte principale de Sank Money",
    icon: StarIcon,
  },
  {
    id: "detail",
    index: 1,
    name: "Details de la recharge",
    description: "Les details de la recharge",
    icon: EyeIcon,
  },
];
const accountType = [AccountType.MAIN, AccountType.FUEL];

const ReloadSankMoneyDealerAccount = () => {
  const [currentFormStep, setCurrentFormStep] = useState<Step>(steps[0]);
  const { isConfirmModal } = useConfirm();
  const navigate = useNavigate();
  const [reloadData, setReloadData] = useState<ReloadSankAccountType>();
  const [reviewers, setReviewers] = useState<any[]>([]);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const [isLoad, setIsLoad] = useState(false);
  const [receiverDataGetWithPhoneNumber, setreceiverDataGetWithPhoneNumber] =
    useState<any>(null);
  const [
    reloadSankMoneyAccountFormReturn,
    setReloadSankMoneyAccountFormReturn,
  ] = useState<UseFormReturn<ReloadSankAccountType>>();
  const [
    reloadSankMoneyAccountFormSubmitButton,
    setReloadSankMoneyAccountFormSubmitButton,
  ] = useState<HTMLButtonElement | null>(null);
  const [
    reloadSankMoneyAccountDetailsFormReturn,
    setReloadSankMoneyAccountDetailsFormReturn,
  ] = useState<UseFormReturn<ReloadSankAccountDetailsType>>();
  const [
    reloadSankMoneyAccountDetailsFormSubmitButton,
    setReloadSankMoneyAccountDetailsFormSubmitButton,
  ] = useState<HTMLButtonElement | null>(null);

  const initSankMoneyDealerReloadAccountFn = functions.httpsCallable(
    "initSankMoneyDealerReloadAccountFn"
  );

  const onSelectedStepChanged: any = async (step: Step) => {
    let canSwitchTab = true;
    switch (currentFormStep.index) {
      case 0:
        if (reloadSankMoneyAccountFormReturn?.formState.isValid) {
          if (reloadSankMoneyAccountFormReturn.formState.isDirty) {
            const isConfirm = await isConfirmModal(
              "Vous avez des modifications non enregistrées.",
              "Voulez vous les sauvegarder avant de quitter cet écran?",
              "Sauvegarder mes modifications",
              false,
              false,
              "Annuler" as any
            );
            if (isConfirm) {
              await reloadSankMoneyAccountFormReturn.trigger();
              reloadSankMoneyAccountFormSubmitButton &&
                reloadSankMoneyAccountFormSubmitButton.click();
            }
          }
        } else {
          canSwitchTab = false;
          reloadSankMoneyAccountFormReturn?.trigger();
        }
        break;
      case 1:
        if (reloadSankMoneyAccountDetailsFormReturn?.formState.isValid) {
          if (reloadSankMoneyAccountDetailsFormReturn.formState.isDirty) {
            const isConfirm = await isConfirmModal(
              "Vous avez des modifications non enregistrées.",
              "Voulez vous les sauvegarder avant de quitter cet écran?",
              "Sauvegarder mes modifications",
              false,
              false,
              "Annuler" as any
            );
            if (isConfirm) {
              await reloadSankMoneyAccountDetailsFormReturn.trigger();
              reloadSankMoneyAccountDetailsFormSubmitButton &&
                reloadSankMoneyAccountDetailsFormSubmitButton.click();
            }
          }
        } else {
          canSwitchTab = false;
          reloadSankMoneyAccountDetailsFormReturn?.trigger();
        }
        break;
      default:
        break;
    }
    if (canSwitchTab) {
      setCurrentFormStep(step);
    }
  };

  const retriveReviewer = async () => {
    const userSnap = await db
      .collection(usersCollectionName)
      .where("dealerReviewer", "==", true)
      .get();

    setReviewers(
      userSnap.docs.map((doc) => {
        return { id: doc.id, data: doc.data() };
      })
    );
  };

  const saveReloadData = (data: ReloadSankAccountType) => {
    setReloadData(data);
    setCurrentFormStep(steps[1]);
    retriveReviewer();
  };

  const onSubmit = (pinCode: string) => {
    const reviewersIds: any = {};
    const participantIds: any[] = [];
    reviewers.forEach((el) => (reviewersIds[`${el.id}`] = false));
    reviewers.forEach((el) => participantIds.push(el.id));
    setIsLoad(true);
    const response = initSankMoneyDealerReloadAccountFn({
      amount: reloadData?.amount,
      description: reloadData?.description,
      participantIds: participantIds,
      dealerId: receiverDataGetWithPhoneNumber?.id,
      dealerName: receiverDataGetWithPhoneNumber.displayName ?? "",
      dealerNumber: receiverDataGetWithPhoneNumber.phoneNumber ?? "",
      accountType: AccountType.MAIN,
      ...reviewersIds,
      pinCode,
    })
      .then(() => {
        navigate("/reload_sank_money_dealer_account");
        setIsLoad(false);
        setShowPinCodeModal(false);
        setIsCodePinBtnDesabled(false);
      })
      .catch((e) => {
        setIsLoad(false);
        setShowPinCodeModal(false);
        setIsCodePinBtnDesabled(false);
      });
    toast.promise(response, {
      error: (error: any) => error.message,
      success: "La transaction a été initié avec succès.",
      loading: "chargement...",
    });
  };

  const getFilterdByPhoneNumber = async (phoneNumber: any) => {
    if (phoneNumber !== undefined) {
      if (phoneNumber?.length >= PhoneNumberDefaultLength + 4) {
        const destUserSnapshot = await db
          .collection(usersCollectionName)
          .where("phoneNumber", "==", phoneNumber)
          .where("userRole", "==", UserRole.DEALER)
          .get();
        if (destUserSnapshot.empty) {
          toast.error("Le numéro n'est pas un dealer");
        } else {
          return setreceiverDataGetWithPhoneNumber(
            destUserSnapshot.docs[0].data()
          );
        }
      } else setreceiverDataGetWithPhoneNumber(null);
    } else setreceiverDataGetWithPhoneNumber(null);
  };

  return (
    <>
      {" "}
      <div className="lg:border-t lg:border-b lg:border-gray-200">
        <nav
          className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
          aria-label="Progress"
        >
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
          >
            <li className="relative px-2 py-4 overflow-hidden lg:py-2">
              <button
                className="p-1 border border-transparent rounded-full hover:border-gray-200 hover:bg-gray-50"
                onClick={() => navigate("/reload_sank_money_dealer_account")}
              >
                <XMarkIcon className="w-8 h-8 text-gray-700" />
              </button>
            </li>
            {steps.map((step, stepIdx) => (
              <li key={stepIdx} className="relative overflow-hidden lg:flex-1">
                <div
                  className={classNames(
                    stepIdx === 0 ? "border-b-0 rounded-t-md" : "",
                    stepIdx === steps.length - 1
                      ? "border-t-0 rounded-b-md"
                      : "",
                    "border border-gray-200 overflow-hidden lg:border-0 h-full"
                  )}
                >
                  <button
                    onClick={() =>
                      "currentAgency" ? onSelectedStepChanged(step) : void 0
                    }
                    className={classNames(
                      !"currentAgency" ? "cursor-not-allowed" : "",
                      "currentAgency" && step.index === 2 ? "" : "",
                      "w-full group h-full"
                    )}
                  >
                    <span
                      className={classNames(
                        step.index !== currentFormStep.index
                          ? "group-hover:bg-gray-200 bg-transparent "
                          : "bg-red-600",
                        "absolute top-0 left-0 w-1 h-full  lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "px-4 py-4 lg:py-2 flex items-start text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span
                          className={classNames(
                            step.index < currentFormStep.index
                              ? "bg-red-600"
                              : "",
                            step.index === currentFormStep.index
                              ? "bg-white border-2 border-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "bg-white border-2 border-gray-300"
                              : "",
                            "flex items-center justify-center w-10 h-10  rounded-full"
                          )}
                        >
                          <step.icon
                            className={classNames(
                              step.index < currentFormStep.index
                                ? "text-white"
                                : "",
                              step.index === currentFormStep.index
                                ? "text-red-600"
                                : "",
                              step.index > currentFormStep.index
                                ? "text-gray-500"
                                : "",
                              "w-6 h-6 "
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex-col flex  xl:flex">
                        <span
                          className={classNames(
                            step.index === currentFormStep.index
                              ? "text-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "text-gray-500"
                              : "",
                            "text-xs font-semibold tracking-wide uppercase md:block lg:hidden xl:block"
                          )}
                        >
                          {step.name}
                        </span>
                        <span className="flex items-start text-sm font-medium text-gray-500">
                          {step.description}
                        </span>
                      </span>
                    </span>
                  </button>
                  <div
                    className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="w-full h-full text-gray-300"
                      viewBox="0 0 12 82"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0.5 0V31L10.5 41L0.5 51V82"
                        stroke="currentcolor"
                        vectorEffect="non-scaling-stroke"
                      />
                    </svg>
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="items-center w-full mx-auto md:w-11/12 lg:w-9/12">
        {currentFormStep.id === "initialized" && (
          <Form<ReloadSankAccountType>
            onSubmit={saveReloadData}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  amount: yup.string().required("Le champs est obligatoire"),
                  description: yup
                    .string()
                    .required("Le champs est obligatoire"),
                })
              ),
            }}
            submitButtonLabel="Enregistrer"
            isSubmitBtnDisabled={receiverDataGetWithPhoneNumber ? false : true}
          >
            <div className="flex flex-col content-center w-full px-5 mt-5 align-top md:flex-row">
              <div className="w-full mx-auto ">
                <PhoneInput
                  international={false}
                  placeholder="Entrez un numéro de téléphone"
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                  defaultCountry="BF"
                  onChange={getFilterdByPhoneNumber}
                />
                <FormInput
                  name="amount"
                  label="Montant"
                  placeholder="2000000"
                />
                <FormTextArea name="description" label="Description" />
              </div>
            </div>
            {(receiverDataGetWithPhoneNumber) && (
              <>
                {/* <div className="flex justify-center">
                  <ArrowSmallDownIcon className="h-6 text-red-500" />
                </div> */}
                <div className="">
                  <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                    <img
                      className="w-10 h-10 rounded-full"
                      src={
                        (receiverDataGetWithPhoneNumber)
                          ?.profilImageUrl
                          ? (receiverDataGetWithPhoneNumber)
                              ?.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              (
                                receiverDataGetWithPhoneNumber
                              )?.displayName || "S"
                            }`
                      }
                      alt=""
                    />
                    <div>
                      <div className="font-semibold">
                        {
                          (receiverDataGetWithPhoneNumber)
                            ?.displayName
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Form>
        )}
        {currentFormStep.id === "detail" && (
          <div className="flow-root mt-4">
            <div className="mb-5">
              <h3>Les validateurs</h3>
            </div>
            <ul className="">
              {reviewers.length > 0 ? (
                reviewers.map((user: any, eventIdx: any) => {
                  return (
                    <li key={eventIdx}>
                      <div className="relative pb-8">
                        {eventIdx !== reviewers.length - 1 ? (
                          <span
                            className="absolute left-4 top-4 -ml-px h-full w-0.5 bg-gray-200"
                            aria-hidden="true"
                          />
                        ) : null}
                        <div className="relative flex space-x-3">
                          <div>
                            <span
                              className={classNames(
                                "bg-green-500",
                                "h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white dark:ring-slate-800"
                              )}
                            >
                              <CheckIcon
                                className="w-5 h-5 text-white"
                                aria-hidden="true"
                              />
                            </span>
                          </div>
                          <div className="flex justify-between flex-1 min-w-0 space-x-4">
                            <div>
                              <p className="space-x-1 text-sm text-gray-500">
                                <span>{user.data.phoneNumber}</span>
                                <span className="font-semibold">
                                  {user.data.displayName}
                                </span>
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <li>
                  <div className="relative pb-8">
                    <div className="relative flex space-x-3">
                      <div>
                        <span
                          className={classNames(
                            "bg-red-500",
                            "h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white dark:ring-slate-800"
                          )}
                        >
                          <XMarkIcon
                            className="w-5 h-5 text-white"
                            aria-hidden="true"
                          />
                        </span>
                      </div>
                      <div className="flex justify-between flex-1 min-w-0 space-x-4">
                        <p className="space-x-1 text-sm text-gray-500">
                          <span>Pas de validateur</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </li>
              )}
            </ul>
            <div className="mb-5">
              <h3>Détails de la Recharge</h3>
            </div>
            <ul className="">
              <li>
                <div className="relative pb-8">
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className={classNames(
                          "bg-green-500",
                          "h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white dark:ring-slate-800"
                        )}
                      ></span>
                    </div>
                    <div className="flex justify-between flex-1 min-w-0 space-x-4">
                      <div>
                        <p className="space-x-1 text-sm text-gray-500">
                          <span>
                            Montant:{" "}
                            {new Intl.NumberFormat().format(
                              parseInt(reloadData?.amount ?? "0")
                            )}{" "}
                            Fcfa
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="relative pb-8">
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className={classNames(
                          "bg-green-500",
                          "h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white dark:ring-slate-800"
                        )}
                      ></span>
                    </div>
                    <div className="flex justify-between flex-1 min-w-0 space-x-4">
                      <div>
                        <p className="space-x-1 text-sm text-gray-500">
                          <span>
                            Type de compte:{" "}
                            {accountMap.get(reloadData?.accountType as string)}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="relative pb-8">
                  <div className="relative flex space-x-3">
                    <div>
                      <span
                        className={classNames(
                          "bg-green-500",
                          "h-6 w-6 rounded-full flex items-center justify-center ring-8 ring-white dark:ring-slate-800"
                        )}
                      ></span>
                    </div>
                    <div className="flex justify-between flex-1 min-w-0 space-x-4">
                      <div>
                        <p className="space-x-1 text-sm text-gray-500">
                          <span>Description: {reloadData?.description}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <div className="sm:flex sm:items-center">
              <div className="mt-2 sm:mt-0 sm:flex-none">
                <button
                  type="button"
                  className={classNames(
                    reviewers?.length < 2 ? "cursor-not-allowed" : "",
                    "relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-red-200 "
                  )}
                  disabled={reviewers?.length < 2 && true}
                  onClick={() => setShowPinCodeModal(true)}
                >
                  <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                    Lancer la procédure
                  </span>
                </button>
              </div>
              {reviewers?.length < 2 && (
                <div className="mt-2 sm:mt-0 sm:flex-none">
                  <button
                    type="button"
                    className={classNames(
                      "relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-green-700 rounded-lg border border-green-300 bg-white hover:bg-green-50 focus:ring-4 focus:outline-none focus:ring-green-200 "
                    )}
                    onClick={() =>
                      navigate("/reload_sank_money_dealer_account/add_reviewer")
                    }
                  >
                    <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                      <UserGroupIcon
                        className="self-center flex-shrink-0 w-5 h-5 mr-1 text-green-500"
                        aria-hidden="true"
                      />
                      Ajouter un validateur
                    </span>
                  </button>
                </div>
              )}
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => setShowPinCodeModal(false)}
      >
        <div className="flex justify-center">
          <CodePinComponent
            onSubmit={onSubmit}
            disabled={isCodePinBtnDesabled}
            isLoad={isLoad}
          />
        </div>
      </Modal>
    </>
  );
};

export default ReloadSankMoneyDealerAccount;
