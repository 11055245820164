import { ExclamationCircleIcon } from '@heroicons/react/24/solid';
import { InputHTMLAttributes } from 'react';
import { useFormContext } from 'react-hook-form';
import { classNames, unwindObject } from '../../../utils';
import { HelpTooltip } from '../HelpTooltip';

export interface FormTextAreaProps
  extends Omit<InputHTMLAttributes<HTMLTextAreaElement>, 'id' | 'className'> {
  name: string;
  label?: string;
  optional?: boolean;
  helpLabel?: string;
  condensed?: boolean;
  nbRows?: number;
  nbCols?: number;
  tooltip?: string;
}

export function FormTextArea({
  name,
  label,
  optional,
  helpLabel,
  condensed = false,
  nbRows = 3,
  nbCols = 3,
  tooltip,
  ...rest
}: FormTextAreaProps): JSX.Element {
  const methods = useFormContext();
  const errorName = unwindObject((methods as any).formState.errors, name);
  return (
    <div className={classNames(condensed ? "my-1" : "my-4")}>
      <div className={classNames(optional ? "flex justify-between" : "")}>
        {label && (
          <label
            htmlFor={name}
            className="flex justify-between text-sm font-medium text-gray-700"
          >
            <span
              className={classNames(
                rest.required ? "is-required" : "",
                "block"
              )}
            >
              {label}
            </span>
            {tooltip && <HelpTooltip value={tooltip} place="left" />}
          </label>
        )}
        {optional && <span className="text-sm text-gray-500">Optional</span>}
      </div>
      <div className="relative mt-1 rounded-md shadow-sm">
        <textarea
          id={name}
          {...methods.register(name)}
          {...rest}
          rows={nbRows}
          cols={nbCols}
          className={classNames(
            errorName
              ? "border-red-300 focus:ring-red-500 focus:border-red-500"
              : "focus:ring-indigo-500 focus:border-indigo-500",
            "appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none  sm:text-sm"
          )}
        />
        {errorName && (
          <div className="absolute inset-y-0 right-0 flex items-center pr-3 pointer-events-none">
            <ExclamationCircleIcon
              className="w-5 h-5 text-red-500"
              aria-hidden="true"
            />
          </div>
        )}
      </div>
      {helpLabel && (
        <p
          className={classNames(
            condensed ? "mt-1" : "mt-2",
            "text-sm text-gray-500"
          )}
        >
          {helpLabel}
        </p>
      )}
      {errorName && (
        <p
          className={classNames(
            condensed ? "mt-1" : "mt-2",
            "text-sm text-red-600"
          )}
        >
          {errorName.message}
        </p>
      )}
    </div>
  );
}

export default FormTextArea;
