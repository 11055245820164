import React, { useCallback, useContext, useEffect, useState } from "react";
import FolderComponent from "../../../components/FolderComponent";
import { db } from "../../../firebase";
import { UserContext } from "../../../contexts";
import {
  companyCollectionName,
  companyReportCollectionsName,
} from "../../../config";
import { useNavigate } from "react-router-dom";
import NoContent from "../../../components/TableNoContentPage/NoContent";

const ReportCompanyTransactions = () => {
  const { company } = useContext(UserContext);
  const [reports, setReports] = useState<any>(undefined);
  const navigate = useNavigate();

  const retirveReports = useCallback(async () => {
    const reportsSnap = await db
      .collection(companyCollectionName)
      .doc(company?.id)
      .collection(companyReportCollectionsName)
      .orderBy("createdAt", "desc")
      .get();
    if (reportsSnap.empty) {
      setReports(undefined);
    } else {
      const data = reportsSnap.docs.map((doc) => {
        return { data: doc.data() };
      });
      const groupedData: { [year: string]: any[] } = {};

      data.forEach((item) => {
        const yearMatch = item.data.reportFileName.match(/\d{4}/);
        if (yearMatch) {
          const year = yearMatch[0];
          if (groupedData[year]) {
            groupedData[year].push(item.data);
          } else {
            groupedData[year] = [item.data];
          }
        }
      });
      setReports(groupedData);
    }
  }, [company?.id]);

  useEffect(() => {
    company && retirveReports();
  }, [company, retirveReports]);

  return (
    <div className="flex flex-col max-w-3xl mx-4 ">
      <p className="pb-4 my-4 text-3xl font-bold leading-none tracking-tight text-gray-900 border-b border-gray-200 sm:text-4xl md:text-5xl lg:text-2xl dark:text-white">
        Vos rapports
      </p>
      <div className="grid grid-cols-4 my-3 lg:grid-cols-8 gap-x-6">
        {reports ? (
          Object.keys(reports).map((val, index) => (
            <FolderComponent
              key={index}
              title={val}
              onclick={() =>
                navigate(`/companies/transactions-report/items/${val}`, {
                  state: {
                    reportIems: reports[val],
                  },
                })
              }
            />
          ))
        ) : (
          <NoContent />
        )}
      </div>
    </div>
  );
};

export default ReportCompanyTransactions;
