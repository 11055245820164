import React from "react";
import DisplayItemDetails from "../../components/DisplayItemComponent";
import { useLocation } from "react-router-dom";
import UserDetailsPageComponent from "../../components/UserDetailsComponent";

const UserDetailsPage = () => {
  const location = useLocation();
  const currentUser = location.state?.entity;
  const isSearch = location.state?.isSearch;
  return isSearch ? (
    <UserDetailsPageComponent userDetailsData={currentUser} makeDealer={false}/>
  ) : (
    <DisplayItemDetails currentUser={currentUser} />
  );
};

export default UserDetailsPage;
