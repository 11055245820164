import React, { useCallback, useContext, useState } from "react";
import toast from "react-hot-toast";
import Papa from "papaparse";
import { yupResolver } from "@hookform/resolvers/yup";
import { Form, FormInput } from "../../../../components/FormComponents";
import * as yup from "yup";
import { CsvModel } from "../../../../components/ExportCsv";
import { db, functions } from "../../../../firebase";
import { UserContext } from "../../../../contexts";
import { useLocation, useNavigate } from "react-router-dom";
import {
  PhoneNumberDefaultLength,
  usersCollectionName,
} from "../../../../config";
import { UserRole } from "../../../../interfaces";
import FolderComponent from "../../../../components/FolderComponent";

const excelSMSFileHeaders = [
  { label: "Nom", key: "name" },
  { label: "Email", key: "email" },
  { label: "Numéro", key: "number" },
];

const AddCompanyContact = () => {
  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState<any[]>([]);
  const [contactFrom, setContactFom] = useState("manual");
  const { company, user } = useContext(UserContext);
  const location = useLocation();
  const contact = location.state?.entity;
  const navigate = useNavigate();
  const [noSankMoneyContactFileData, setNoSankMoneyContactFileData] = useState<
    any[]
  >([]);
  const [userData, setUserData] = useState<any>(undefined);
  const userRef = db.collection(usersCollectionName);
  const [contactEmail, setContactEmail] = useState("");
  const [contactDisplayName, setContactDisplayName] = useState("");
  const handleOptionChange = (event: any) => {
    setContactFom(event.target.value);
  };

  const sankMoneyCreateCompanyContactsFn = functions.httpsCallable(
    "sankMoneyCreateCompanyContactsFn"
  );
  const sankMoneyUpdateCompanyContactFn = functions.httpsCallable(
    "sankMoneyUpdateCompanyContactFn"
  );

  const getFilterdByPhoneNumber = useCallback(
    async (phoneNumber: any) => {
      if (phoneNumber.length >= PhoneNumberDefaultLength + 4) {
        if (phoneNumber === user?.phoneNumber) {
          toast.error(
            `Vous ne pouvez pas vous rajouter dans la liste des contacts ${phoneNumber}`
          );
        } else
          await userRef
            .orderBy("createdAt", "desc")
            .where(
              "phoneNumber",
              "==",
              phoneNumber ? phoneNumber : contact?.phoneNumber
            )
            .where("userRole", "==", UserRole.USER)
            .get()
            .then((response) => {
              if (!response.empty) {
                const data = response.docs.map((d) => {
                  return { id: d.id, data: d.data() };
                });
                setUserData(data[0]);
              }
            });
      } else setUserData(undefined);
    },
    [contact?.phoneNumber, user?.phoneNumber, userRef]
  );

  const handleSubmit = async (data: any) => {
    if (contactFrom === "manual") {
      if (contact) {
        return toast.promise(
          sankMoneyUpdateCompanyContactFn({
            companyId: company?.id,
            contactId: contact?.id,
            documentData: {
              ...data,
            },
          }).then(() => navigate("/companies/contacts/contact_lists")),
          {
            loading: `Edition...`,
            success: "Contact(s) édité(s) avec succès",
            error: (err) =>
              err.message ? err.message : "Echec d'édition du (des) contact(s)",
          }
        );
      } else {
        const dataVals = {
          displayName: userData ? userData?.data.displayName : data.displayName,
          email: userData ? userData?.data.email : data.email,
          phoneNumber: data.phoneNumber,
        };
        toast.promise(
          sankMoneyCreateCompanyContactsFn({
            companyId: company?.id,
            contacts: [dataVals],
          }).then(() => navigate("/companies/contacts/contact_lists")),
          {
            loading: `Création...`,
            success: "Contact(s) créé(s) avec succès",
            error: (err) =>
              err.message
                ? err.message
                : "Echec de création du (des) contact(s)",
          }
        );
      }
    } else {
      return toast.promise(
        sankMoneyCreateCompanyContactsFn({
          companyId: company?.id,
          contacts: fileData,
        }).then(() => navigate("/companies/contacts/contact_lists")),
        {
          loading: `Création...`,
          success: "Contact(s) créé(s) avec succès",
          error: (err) =>
            err.message ? err.message : "Echec de création du (des) contact(s)",
        }
      );
    }
  };
  const handleReadCSV = (event: any) => {
    setFileName(event.target.files[0]?.name);
    Papa.parse(event.target.files[0], {
      header: true,
      skipEmptyLines: true,
      complete: async function (results) {
        let noSankMoneyContact: any[] = [];
        let sankMoneyContact: any[] = [];

        for (const data of results.data as any) {
          const phoneNumber = data.Numéro.startsWith("+")
            ? data.Numéro
            : `+${data.Numéro}`;
          if (phoneNumber === company?.phoneNumber) {
            toast.error(
              `Vous ne pouvez pas vous rajouter dans la liste des contacts ${phoneNumber}`
            );
          } else {
            const userSnap = await userRef
              .orderBy("createdAt", "desc")
              .where("phoneNumber", "==", phoneNumber)
              .where("userRole", "==", UserRole.USER)
              .get();

            if (userSnap.empty) {
              const noSankObj = {
                number: data.Numéro.startsWith("+")
                  ? data.Numéro
                  : `+${data.Numéro}`,
                name: data.Nom,
                email: data.Email,
                isSankAccount: false,
              };
              noSankMoneyContact.push(noSankObj);
            } else {
              const obj = {
                displayName: data.Nom,
                email: data.Email,
                phoneNumber: data.Numéro.startsWith("+")
                  ? data.Numéro
                  : `+${data.Numéro}`,
                isSankAccount: true,
              };
              sankMoneyContact.push(obj);
            }
          }
        }
        setNoSankMoneyContactFileData(noSankMoneyContact);
        setFileData([
          ...sankMoneyContact,
          ...noSankMoneyContact.map((val) => {
            return {
              displayName: val.name,
              email: val.email,
              phoneNumber: val.number,
            };
          }),
        ]);
      },
    });
  };

  return (
    <div className="flex flex-col max-w-3xl mx-4">
      <p className="max-w-lg mt-2 mb-6 font-semibold leading-normal text-gray-900 text-md dark:text-gray-300 ">
        {contact
          ? `Editer le contact de ${contact.displayName}`
          : "Créer des contact"}
      </p>
      {!contact && (
        <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-slate-800 dark:border-slate-700 sm:flex ">
          <li className="w-full border-b border-gray-200 dark:border-slate-700 sm:border-b-0 sm:border-r">
            <div className="flex items-center pl-3">
              <input
                id="horizontal-list-radio-id"
                type="radio"
                checked={contactFrom === "manual"}
                value="manual"
                name="list-radio"
                onChange={handleOptionChange}
                className="w-4 h-4 bg-gray-100 border-gray-300 text-emerald-600 dark:text-emerald-600 focus:ring-emerald-500"
              />
              <label
                htmlFor="horizontal-list-radio-id"
                className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Saisir manuellement
              </label>
            </div>
          </li>
          <li className="w-full border-b border-gray-200 dark:border-slate-700 sm:border-b-0 sm:border-r">
            <div className="flex items-center pl-3">
              <input
                id="horizontal-list-radio-license"
                type="radio"
                value="file"
                name="list-radio"
                onChange={handleOptionChange}
                className="w-4 h-4 bg-gray-100 border-gray-300 text-emerald-600 dark:text-emerald-600 focus:ring-emerald-500 "
              />
              <label
                htmlFor="horizontal-list-radio-license"
                className="w-full py-3 ml-2 text-sm font-medium text-gray-900 dark:text-gray-300"
              >
                Télécharger un fichier CSV
              </label>
            </div>
          </li>
        </ul>
      )}

      <div className="flex flex-col w-full">
        {contactFrom === "manual" && (
          <Form
            onSubmit={handleSubmit}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  displayName: yup.string().optional(),
                  email: yup.string().email().optional(),
                  phoneNumber: yup
                    .string()
                    .required(`Le numéro est obligatoire`)
                    .matches(
                      /^\+226[0567][0123456789]{7}$/,
                      `Numéro invalide ou incomplet !`
                    ),
                })
              ),
              defaultValues: {
                displayName: contact ? contact.displayName : "",
                phoneNumber: contact ? contact.phoneNumber : "",
                email: contact ? contact.email : "",
              },
            }}
            submitButtonLabel={`${contact ? "Editer" : "Créer"}`}
          >
            <FormInput
              required
              type="text"
              name="phoneNumber"
              label={`Numéro`}
              onChange={(e) => {
                getFilterdByPhoneNumber(
                  contact ? contact.phoneNumber : e.target.value
                );
              }}
              placeholder="+226701808XX"
            />
            <FormInput
              type="text"
              name="displayName"
              label={`Nom et Prénom`}
              value={
                contactDisplayName
                  ? contactDisplayName
                  : userData
                  ? userData?.data.displayName
                  : contact
                  ? contact.displayName
                  : contactDisplayName
              }
              onChange={(e) => {
                setContactDisplayName(e.target.value);
                setUserData(undefined);
              }}
              placeholder="Elon Musk"
            />
            <FormInput
              type="text"
              name="email"
              label="Email"
              value={
                contactEmail
                  ? contactEmail
                  : userData
                  ? userData?.data.email
                  : contact
                  ? contact.email
                  : contactEmail
              }
              onChange={(e) => {
                setContactEmail(e.target.value);
                setUserData(undefined);
              }}
              placeholder="elonmusk@email.com"
            />
          </Form>
        )}
        {contactFrom === "file" && (
          <Form
            downloadCsvOrXlsxFileBtn={
              <CsvModel
                csvData={[
                  {
                    number: "+22670180879",
                    name: "Abdoul aziz",
                    email: "bandeabdelaziz03@gmail.com",
                  },
                  {
                    number: "+22655995629",
                    name: "Abdoul aziz",
                    email: "aziz.@gmail.com",
                  },
                ]}
                headers={excelSMSFileHeaders}
                fileName={`contacts-${new Date().toLocaleDateString()}`}
                text={`Télécharger le model CSV`}
                className="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold transition-all duration-200 bg-white border rounded-md shadow-sm gap-x-2 dark:bg-slate-800 border-emerald-700 hover:border-emerald-900 text-emerald-700 hover:text-emerald-900 dark:hover:text-emerald-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
              />
            }
            otherBtn={
              noSankMoneyContactFileData &&
              noSankMoneyContactFileData.length > 0 ? (
                <CsvModel
                  csvData={noSankMoneyContactFileData}
                  headers={excelSMSFileHeaders}
                  fileName={`contacts-${new Date().toLocaleDateString()}`}
                  text={`Ne sont pas des utilisateurs`}
                  className="inline-flex items-center justify-center w-full px-4 py-2 ml-3 text-sm font-semibold text-blue-700 transition-all duration-200 bg-white border border-blue-700 rounded-md shadow-sm gap-x-2 dark:bg-slate-800 hover:border-blue-900 hover:text-blue-900 dark:hover:text-blue-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600"
                />
              ) : undefined
            }
            onSubmit={handleSubmit}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  // name: yup.string().optional(),
                  // email: yup.string().email().optional(),
                  // number: yup.string().required("Le numéro est obligatoire").matches(/^\+226[0567][0123456789]{7}$/, "Numéro invalide ou incomplet !"),
                })
              ),
            }}
            submitButtonLabel={`${contact ? "Editer" : "Créer"}`}
          >
            {
              <div className="flex flex-col items-end">
                <div className="flex items-center justify-center w-full mt-5">
                  <label
                    htmlFor="dropzone-file"
                    className="flex flex-col items-center justify-center w-full h-64 border-2 border-gray-300 border-dashed rounded-lg cursor-pointer bg-gray-50 dark:hover:bg-bray-800 dark:bg-gray-700 hover:bg-gray-100 dark:border-gray-600 dark:hover:border-gray-500 dark:hover:bg-gray-600"
                  >
                    <div className="flex flex-col items-center justify-center pt-5 pb-6">
                      {fileName ? (
                        <div className="text-sm text-gray-500 dark:text-gray-400">
                          {fileName}{" "}
                        </div>
                      ) : (
                        <>
                          <svg
                            aria-hidden="true"
                            className="w-10 h-10 mb-3 text-gray-400"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                            ></path>
                          </svg>
                          <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                            <span className="font-semibold">
                              Click to upload
                            </span>{" "}
                            or drag and drop
                          </p>
                          <p className="text-xs text-gray-500 dark:text-gray-400">
                            Uploader un fichier CSV (CSV)
                          </p>
                        </>
                      )}
                    </div>
                    <input
                      id="dropzone-file"
                      type="file"
                      className="hidden"
                      onChange={handleReadCSV}
                    />
                  </label>
                </div>
              </div>
            }
          </Form>
        )}
      </div>
    </div>
  );
};

export default AddCompanyContact;
