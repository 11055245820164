/* eslint-disable jsx-a11y/alt-text */
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import CodePinComponent from "../../../components/CodePinComponent";
import Modal from "../../../components/Modal";
import ReadeQrcode from "../../../components/Qrcode/ReadeQrcode";
import {
  defaultAmount,
  usersCollectionName,
  accountsCollectionName,
  agencyCollectionsName,
  withdrawCodeLength,
  pendingTransactionsCollectionsName,
} from "../../../config";
import { UserContext } from "../../../contexts";
import { db, functions } from "../../../firebase";
import { useRouter } from "../../../hooks/useRouter";
import { classNames } from "../../../utils";
import { UserRole } from "../../../interfaces";

const WithdrawWithCode = () => {
  const [receiverData, setreceiverData] = useState<any>([]);
  const [qrcodeScannedData, setQrcodeScannedData] = useState<string[]>([]);
  const [isDataGet, setIsDataGet] = useState(false);
  const [amount, setAmount] = useState<string>(defaultAmount);
  const { user, setUserAccounts, agencyAgent } = useContext(UserContext);
  const [showQrcodeReadeModal, setShowQrcodeReadeModal] = useState(false);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [withdrawCode, setWithdrawCode] = useState("");
  const [isLoad, setIsLoad] = useState(false);
  const [pendingTransactionData, setPendingTransactionData] =
    useState<any>(null);
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const router = useRouter();
  const [description, setDescription] = useState("");

  const userRef = db.collection(usersCollectionName);
  const senderAccountRef = userRef
    .doc(user?.id)
    .collection(accountsCollectionName);
  const sankMoneywithdrawPendingTransactionMoneyFn = functions.httpsCallable(
    "sankMoneywithdrawPendingTransactionMoneyFn"
  );

  const handleDescriptionChange = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    setDescription(value);
  };

  const getReceiverData = async () => {
    setShowQrcodeReadeModal(false);
    if (qrcodeScannedData.length > 0 && !isDataGet) {
      await db
        .collection(agencyCollectionsName)
        .doc(qrcodeScannedData[0])
        .get()
        .then((doc) => {
          setIsDataGet(true);
          const data = doc.data();
          setreceiverData([data]);
          return data;
        });
    }
  };

  const getFilterdByWithdrawCode = async (withdrawCode: string) => {
    if (withdrawCode !== undefined) {
      if (withdrawCode.length >= withdrawCodeLength) {
        setWithdrawCode(withdrawCode);
        const transData = await db
          .collection(pendingTransactionsCollectionsName)
          .where("withdrawCode", "==", withdrawCode)
          .get();
        if (transData.empty) {
          toast.error("Le code de retrait n'existe pas.");
          setPendingTransactionData(null);
        } else {
          setPendingTransactionData(transData.docs[0].data());
        }
      } else {
        setPendingTransactionData(null);
      }
    } else {
      setPendingTransactionData(null);
    }
  };

  const onSubmit = async (pinCode: string) => {
    setIsCodePinBtnDesabled(true);
    if (user && pendingTransactionData) {
      setIsLoad(true);
      await sankMoneywithdrawPendingTransactionMoneyFn({
        pinCode: pinCode,
        withdrawCode: withdrawCode,
        agentCode: agencyAgent ? agencyAgent.agentCode : user?.agentCode,
        description: description ?? "",
        withConnection: true,
        currentUserId: user?.id,
      })
        .then(async () => {
          setShowPinCodeModal(false);
          setIsLoad(false);
          router.push("/dashboard");
          if (user?.userRole === UserRole.AGENCYSTAFF && agencyAgent) {
            const accounts = await db
              .collection(usersCollectionName)
              .doc(agencyAgent?.id)
              .collection(accountsCollectionName)
              .get();
            setUserAccounts(accounts.docs.map((account) => account.data()));
          } else {
            const accounts = await senderAccountRef.get();
            setUserAccounts(accounts.docs.map((account) => account.data()));
          }
          return toast.success("Le retrait a été effectué avec succès");
        })
        .catch((err: { details: any; message: any }) => {
          setShowPinCodeModal(false);
          setIsCodePinBtnDesabled(false);
          setIsLoad(false);
          toast.error(err.message);
        });
    }
  };

  const onQrcodeResult = (result: any) => {
    setShowQrcodeReadeModal(false);
    if (result) {
      setQrcodeScannedData(result);
      setShowQrcodeReadeModal(false);
      getReceiverData();
    }
  };
  const onQrcodeError = (error: any) => {
    setShowQrcodeReadeModal(false);
  };
  return (
    <div className="flex justify-center w-full">
      <div className="w-full max-w-md px-2 py-16 sm:px-0">
        <div className="flex items-center justify-center w-full font-manrope">
          <div className="box-border w-full p-4 mx-auto bg-white border">
            {receiverData.length > 0 ? (
              <div></div>
            ) : (
              <div className="mt-6">
                <div>
                  <label
                    htmlFor="code"
                    className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                  >
                    Entrez le code de retrait.
                  </label>
                  <input
                    type="text"
                    id="code"
                    maxLength={7}
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                    placeholder="Ex: 12345"
                    onChange={(e) => getFilterdByWithdrawCode(e.target.value)}
                  />

                  {pendingTransactionData && (
                    <p className="mt-1 text-xs text-green-500">Code valide</p>
                  )}
                </div>
              </div>
            )}
            <div className="mt-6">
              <label
                htmlFor="amount"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Combien souhaitez-vous retirer ?
              </label>
              <input
                id="amount"
                className="mt-1 w-full rounded-[4px] border border-[#A0ABBB] p-2"
                type="number"
                placeholder="Ex: 1000"
                value={
                  pendingTransactionData ? pendingTransactionData.amount : 0
                }
                pattern="[0-9]*"
                disabled
                min={0}
              />
            </div>
            <div className="mt-6">
              <label
                htmlFor="message"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Entrez une descriptions de la transaction.
              </label>
              <textarea
                id="message"
                rows={4}
                onChange={handleDescriptionChange}
                maxLength={200}
                className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                placeholder="Écrivez votre description ici..."
              ></textarea>
            </div>
            <div className="mt-6">
              <div
                className={classNames(
                  amount ? "bg-red-600  cursor-pointer" : "bg-gray-600",
                  "w-full rounded-[4px]  px-3 py-[6px] text-center font-semibold text-white"
                )}
                onClick={() => amount && setShowPinCodeModal(true)}
              >
                Retirer:{" "}
                {new Intl.NumberFormat().format(
                  pendingTransactionData
                    ? parseInt(pendingTransactionData.amount)
                    : 0
                )}{" "}
                Fcfa
              </div>
            </div>
          </div>
        </div>
      </div>
      <ReadeQrcode
        onQrcodeResult={(result: any) => onQrcodeResult(result)}
        onQrcodeError={(error: any) => onQrcodeError(error)}
        showQrcodeReadeModal={showQrcodeReadeModal}
        setShowQrcodeReadeModal={setShowQrcodeReadeModal}
      />
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => setShowPinCodeModal(false)}
      >
        <div className="flex justify-center">
          <CodePinComponent
            onSubmit={onSubmit}
            disabled={isCodePinBtnDesabled}
            isLoad={isLoad}
          />
        </div>
      </Modal>
    </div>
  );
};

export default WithdrawWithCode;
