import { useCallback, useContext, useEffect, useState } from "react";
import NoContent from "../../components/TableNoContentPage/NoContent";
import Pagination from "../../components/Pagination/Pagination";
import {
  MagnifyingGlassIcon,
  ArrowPathIcon,
  PlusIcon,
  ListBulletIcon,
  Squares2X2Icon,
  BookOpenIcon,
  CubeTransparentIcon,
} from "@heroicons/react/24/outline";
import HasRoles from "../../components/HasRoles";
import {
  superMerchantAndMerchantsForeingKeysCollectionsName,
  usersCollectionName,
} from "../../config";
import { UserRole } from "../../interfaces";
import { classNames } from "../../utils";
import { UserContext } from "../../contexts";
import { useNavigate } from "react-router-dom";
import { db } from "../../firebase";

const AffiliateMerchants = () => {
  const { user } = useContext(UserContext);
  const [merchants, setMerchants] = useState<any[]>([]);
  const navigate = useNavigate();
  const [searchedValue, setSearchedValue] = useState("");

  const retriveMerchants = useCallback(async () => {
    const userRef = db.collection(usersCollectionName);
    const membershipSnapshot = await db
      .collection(superMerchantAndMerchantsForeingKeysCollectionsName)
      .where("ownerId", "==", user?.id)
      .get();
    if (!membershipSnapshot.empty) {
      const data: any[] = [];
      for (const membership of membershipSnapshot.docs) {
        const res = await userRef.doc(membership?.data()?.memberId).get();
        res.exists && data.push(res.data());
      }
      setMerchants(data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onPageLimitChange = async (pageLimit: number) => {
    db.collection(superMerchantAndMerchantsForeingKeysCollectionsName)
      .where("ownerId", "==", user?.id)
      .get()
      .then(async (response) => {
        const members = [];
        for (let i = 0; i < pageLimit; i++) {
          const data = await db
            .collection(usersCollectionName)
            .doc(response.docs[i]?.id)
            .get();
          members.push(data.data());
        }
        // setMerchants(members);
      });
  };

  const onDisplayTransactions = (merchant: any) => {
    if (merchant) {
      navigate(
        `/superMerchants/affiliate-merchants/${merchant?.id}/transactions`,
        {
          state: { entity: merchant },
        }
      );
    }
  };
  const onDisplayMerchantDetails = (merchant: any) => {
    if (merchant) {
      navigate(`/superMerchants/affiliate-merchants/${merchant?.id}/details`, {
        state: { entity: merchant },
      });
    }
  };

  useEffect(() => {
    retriveMerchants();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex flex-col max-w-5xl 2xl:max-w-7xl">
      <div className="flex flex-col">
        <div className="py-2 border-b border-gray-200 dark:border-slate-700">
          <h3 className="text-lg font-semibold leading-6 text-gray-900 dark:text-gray-300">
            La liste des marchants
          </h3>
          <p className="mt-1 text-base text-gray-500">
            {`Gérez ${
              user?.userRole === UserRole.ADMIN ? "les" : "vos"
            } marchants depuis cette page. Vous pouvez sélectionner un marchant pour voir ses informations.`}
          </p>
        </div>
        <div className="flex items-start justify-between grid-cols-1 my-3 max-md:flex-wrap lg:grid-cols-4 gap-x-5 ">
          <div className="flex self-stretch flex-1 h-10 col-span-1 border border-b border-gray-200 rounded-lg lg:col-span-2 gap-x-4 lg:gap-x-6 bg-gray-50 dark:bg-slate-800 dark:border-slate-700">
            <form className="relative flex flex-1" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">
                Search
              </label>
              <MagnifyingGlassIcon
                className="absolute inset-y-0 w-5 h-full text-gray-400 pointer-events-none left-2"
                aria-hidden="true"
              />
              <input
                id="search-field"
                className="block w-full h-full py-0 pl-10 pr-2 text-gray-900 border-0 rounded-lg placeholder:text-gray-400 bg-gray-50 dark:bg-slate-800 focus:ring-0 sm:text-base"
                type="text"
                name="search"
                value={searchedValue}
                // disabled={isTableView}
                // onChange={(e) => handleSearchCard(e.target.value)}
              />
            </form>
          </div>

          <div className="flex items-center justify-end col-span-1 my-2 space-x-2 md:my-0 lg:my-0">
            <button className="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold text-green-700 transition-all duration-200 bg-white border border-green-700 rounded-md shadow-sm gap-x-2 dark:bg-slate-800 hover:border-green-900 hover:text-green-900 dark:hover:text-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
              <div
                onClick={() => {
                  // onRefresh();
                }}
                className="flex gap-1"
              >
                <ArrowPathIcon className="h-5" /> Refresh
              </div>
            </button>
            <HasRoles userRole={["ADMIN", "AGENCYSTAFF"]}>
              <button className="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold text-red-700 transition-all duration-200 bg-white border border-red-700 rounded-md shadow-sm gap-x-2 dark:bg-slate-800 hover:border-red-900 hover:text-red-900 dark:hover:text-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                <div
                  onClick={() => {
                    // setShowModal(true);
                    // setCard([]);
                  }}
                  className="flex gap-1"
                >
                  <PlusIcon className="h-5" /> Attribuer
                </div>
              </button>
            </HasRoles>
            <HasRoles userRole={["ADMIN", "AGENCYSTAFF"]}>
              <span className="inline-flex rounded-md shadow-sm isolate">
                <button
                  type="button"
                  onClick={() => {
                    // setIsTableView(true);
                  }}
                  className={classNames(
                    "isTableView"
                      ? "bg-red-100 dark:bg-red-900 border-red-500 border"
                      : "bg-white dark:bg-slate-800",
                    "relative inline-flex items-center rounded-l-md  px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-slate-700 hover:bg-gray-50 focus:z-10"
                  )}
                >
                  <ListBulletIcon className="w-5 h-5" />
                </button>
                <button
                  type="button"
                  onClick={() => {
                    // setIsTableView(false);
                  }}
                  className={classNames(
                    !"isTableView"
                      ? "bg-red-100 dark:bg-red-900 border-red-500 border"
                      : "bg-white dark:bg-slate-800",
                    "relative -ml-px inline-flex items-center rounded-r-md  px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-slate-700 hover:bg-gray-50 focus:z-10"
                  )}
                >
                  <Squares2X2Icon className="w-5 h-5" />
                </button>
              </span>
            </HasRoles>
          </div>
        </div>
        {merchants && merchants.length > 0 ? (
          <>
            <ul className="grid grid-cols-1 gap-6 my-4 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
              {merchants.map((merchant, eventIdx) => {
                return (
                  <li
                    key={eventIdx}
                    className="col-span-1 bg-white border divide-y divide-gray-200 rounded-lg shadow-md dark:divide-slate-700 dark:bg-slate-800 dark:border-slate-700"
                  >
                    <div className="flex items-center justify-between w-full px-6 py-4 space-x-6">
                      <div className="flex-1 truncate">
                        <div className="flex items-center space-x-3">
                          <h3 className="text-sm font-medium text-gray-900 truncate dark:text-gray-300">
                            {merchant.displayName}
                          </h3>
                        </div>
                        {merchant.phoneNumber && (
                          <p className="mt-1 text-sm text-gray-500 truncate">
                            {`${merchant.phoneNumber}`}
                          </p>
                        )}
                        {merchant.email && (
                          <p className="text-xs text-gray-500 truncate">
                            {`${merchant.email}`}
                          </p>
                        )}
                      </div>
                      <span className="inline-block w-8 h-8 overflow-hidden bg-gray-100 rounded-full dark:bg-slate-700">
                        <svg
                          className="w-full h-full text-gray-300"
                          fill="currentColor"
                          viewBox="0 0 24 24"
                        >
                          <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                        </svg>
                      </span>
                    </div>
                    <div>
                      <div className="flex -mt-px divide-x divide-gray-200 dark:divide-slate-700">
                        <div className="flex flex-1 w-0">
                          <div
                            onClick={() => onDisplayMerchantDetails(merchant)}
                            className="relative inline-flex items-center justify-center flex-1 w-0 py-2 -mr-px text-sm font-semibold text-gray-900 border border-transparent rounded-bl-lg cursor-pointer gap-x-2 dark:text-gray-300"
                          >
                            <button
                              disabled={!merchant?.active}
                              className="relative inline-flex items-center justify-center flex-1 w-0 py-2 text-sm font-semibold text-gray-900 border border-transparent rounded-br-lg gap-x-2 dark:text-gray-300"
                            >
                              <BookOpenIcon
                                className={classNames(
                                  !merchant?.active
                                    ? "text-gray-400"
                                    : "text-red-400",
                                  "w-5 h-5 "
                                )}
                                aria-hidden="true"
                              />
                              Details
                            </button>
                          </div>
                        </div>
                        <HasRoles userRole={["SUPERMERCHANT"]}>
                          <div
                            onClick={() => onDisplayTransactions(merchant)}
                            className="flex flex-1 w-0 -ml-px cursor-pointer"
                          >
                            <div className="relative inline-flex items-center justify-center flex-1 w-0 py-2 text-sm font-semibold text-gray-900 border border-transparent rounded-br-lg gap-x-2 dark:text-gray-300">
                              <button
                                disabled={!merchant?.active}
                                className="relative inline-flex items-center justify-center flex-1 w-0 py-2 text-sm font-semibold text-gray-900 border border-transparent rounded-br-lg gap-x-2 dark:text-gray-300"
                              >
                                <CubeTransparentIcon
                                  className={classNames(
                                    !merchant?.active
                                      ? "text-gray-400"
                                      : "text-red-400",
                                    "w-5 h-5 "
                                  )}
                                  aria-hidden="true"
                                />
                                Historique
                              </button>
                            </div>
                          </div>
                        </HasRoles>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
            <Pagination
              skip={merchants.length}
              take={merchants.length}
              total={merchants.length}
              collectionName={usersCollectionName}
              onPageChange={(pageIndex: number) => pageIndex}
              onPageLimitChange={(pageLimit: number) => {
                onPageLimitChange(pageLimit);
                // setCardLimit(pageLimit);
              }}
            />
          </>
        ) : (
          <NoContent />
        )}
      </div>
    </div>
  );
};

export default AffiliateMerchants;
