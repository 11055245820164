/* eslint-disable jsx-a11y/no-redundant-roles */
import {
  LockClosedIcon,
  AtSymbolIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import { useCallback, useContext, useEffect, useState } from "react";
import {
  UserInterface,
  UserRole,
  mapsGender,
} from "../../../../interfaces";
import { db, functions } from "../../../../firebase";
import { useNavigate, useLocation } from "react-router-dom";
import { UserContext } from "../../../../contexts";
import { useConfirm } from "../../../../hooks/useConfirm";
import {
  companiesMembershipCollectionName,
  usersCollectionName,
} from "../../../../config";
import toast from "react-hot-toast";
import { classNames } from "../../../../utils";
import {
  Form,
  FormInput,
  FormSelect,
} from "../../../../components/FormComponents";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import {
  CompanyCollaboratorRole,
  CompanyCollabRoleMap,
} from "../../../../interfaces/Company";
import { EditFormFooter } from "../../../../components/EditFormFooter";
import { UseFormReturn } from "react-hook-form";

type CompanyAttendantMinInfos = Pick<
  UserInterface,
  "displayName" | "email" | "password" | "confirmPassword"
>;

type CompanyAttendantInfos = Pick<
  UserInterface,
  | "displayName"
  | "email"
  | "active"
  | "agence"
  | "city"
  | "adresse"
  | "cnibNumber"
  | "createdAt"
  | "gender"
  | "phoneNumber"
  | "profession"
  | "userRole"
  | "companyAttendantRole"
  | "updatedAt"
>;

type Step = {
  id: "companyAttendantMinInfos" | "companyAttendantInfos";
  index: number;
  name: string;
  description: string;
  icon: any;
};

const steps: Step[] = [
  {
    id: "companyAttendantMinInfos",
    index: 0,
    name: "Les informations de connexion",
    description: "Renseigner les informations de connexion du collaborateur",
    icon: LockClosedIcon,
  },
  {
    id: "companyAttendantInfos",
    index: 1,
    name: "Informations du collaborateur",
    description: "Renseigner les informations du collaborateur",
    icon: AtSymbolIcon,
  },
];

const attendantRoles = [
  CompanyCollaboratorRole.MANAGER,
  CompanyCollaboratorRole.COLLABORATOR,
];

const CompanyStaffEdit = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentCompanyAttendant = location.state?.entity;
  const [currentFormStep, setCurrentFormStep] = useState<Step>(steps[0]);
  const { user } = useContext(UserContext);
  const [companyMembershipId, setCompanyMembershipId] = useState("");
  const [companyStaffPhoneNumber, setCompanyStaffPhoneNumber] = useState("");
  const [isValidNumber, setIsValidNumber] = useState<boolean>(false);
  const [memberConnexionInfos, setMemberConnexionInfos] = useState<any>();

  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );

  const sankmoneyCreateSankStaffFn = functions.httpsCallable(
    "sankmoneyCreateSankStaffFn"
  );

  const sankMoneyCreateMembershipFn = functions.httpsCallable(
    "sankMoneyCreateMembershipFn"
  );
  const deleteUserFn = functions.httpsCallable("sankMoneyDeleteUserAccountFn");

  const [
    companyAttendantMinInfosFormReturn,
    setCompanyAttendantMinInfosFormReturn,
  ] = useState<UseFormReturn<CompanyAttendantMinInfos>>();
  const [
    companyAttendantMinInfosFormSubmitButton,
    setCompanyAttendantMinInfosFormSubmitButton,
  ] = useState<HTMLButtonElement | null>(null);
  const [companyAttendantFormReturn, setCompanyAttendantFormReturn] =
    useState<UseFormReturn<CompanyAttendantInfos>>();
  const [
    companyAttendantFormSubmitButton,
    setCompanyAttendantFormSubmitButton,
  ] = useState<HTMLButtonElement | null>(null);

  const onSelectedStepChanged: any = async (step: Step) => {
    let canSwitchTab = true;
    switch (currentFormStep.index) {
      case 0:
        if (companyAttendantMinInfosFormReturn?.formState.isValid) {
          if (companyAttendantMinInfosFormReturn.formState.isDirty) {
            companyAttendantMinInfosFormSubmitButton &&
              companyAttendantMinInfosFormSubmitButton.click();
          }
        } else {
          canSwitchTab = false;
          companyAttendantMinInfosFormReturn?.trigger();
        }
        break;
      case 1:
        if (companyAttendantFormReturn?.formState.isValid) {
          if (companyAttendantFormReturn.formState.isDirty) {
            companyAttendantFormSubmitButton &&
              companyAttendantFormSubmitButton.click();
          }
        } else {
          canSwitchTab = false;
          companyAttendantFormReturn?.trigger();
        }
        break;
      default:
        break;
    }
    if (canSwitchTab) {
      setCurrentFormStep(step);
    }
  };

  const onSubmitMemberMinInfos = (data: any) => {
    data.confirmPassword && delete data.confirmPassword;
    setMemberConnexionInfos(data);
    setCurrentFormStep(steps[1]);
  };

  const onSubmitDefaultInfos = async (data: any) => {
    if (memberConnexionInfos) {
      const response = sankmoneyCreateSankStaffFn({
        collectionName: usersCollectionName,
        email: memberConnexionInfos.email,
        password: memberConnexionInfos.password,
        userRole: UserRole.COMPANYSTAFF,
      }).then(async (result) => {
        // setMemberId(result.data.success.uid);
        const memberId = result.data.success.uid;
        setCurrentFormStep(steps[1]);
        if (result.data.success.uid) {
          const memberShipData = {
            companyId: companyMembershipId,
            memberId: result.data.success.uid,
          };
          await sankMoneyCreateMembershipFn({
            collectionName: companiesMembershipCollectionName,
            documentData: memberShipData,
          })
            .then(() => {
              const res = sankmoneyUpdateAccountFn({
                collectionName: usersCollectionName,
                documentData: {
                  id: memberId,
                  ...data,
                  phoneNumber: companyStaffPhoneNumber,
                  companyAttendantRole: data.companyAttendantRole,
                },
                documentId: memberId,
              }).then((result) => {
                navigate("/companies/sank_staff");
              });
              toast.promise(res, {
                error: (e) => e.message,
                success:
                  "Les informations du membre a été enregistrer avec succès",
                loading: "chargement...",
              });
            })
            .catch(async (err: { details: any; message: any }) => {
              toast.error(err.details.message);
              await deleteUserFn({ userId: result.data.success.uid });
            });
          return result.data.success.uid;
        }
      });
      toast.promise(response, {
        error: (e) => e.message,
        success: "Le membre a été enregistrer avec succès",
        loading: "chargement...",
      });
    }
  };

  const retriveCompanyMembership = useCallback(async () => {
    const membershipRef = db.collection(companiesMembershipCollectionName);
    const membershipSnaphot = await membershipRef
      .where("memberId", "==", user?.id)
      .get();
    const membershipData = membershipSnaphot.docs[0].data();
    if (membershipData) setCompanyMembershipId(membershipData?.companyId);
  }, [user?.id]);

  const retrivePhoneNumber = async (phoneNumber: any) => {
    if (phoneNumber) {
      if (isValidPhoneNumber(phoneNumber)) {
        const destUserSnapshot = await db
          .collection(usersCollectionName)
          .where("phoneNumber", "==", phoneNumber)
          .get();
        if (!destUserSnapshot.empty) {
          toast.error("Ce numéro de téléphone est déjà utilisé.");
          setIsValidNumber(false);
        } else {
          setIsValidNumber(true);
          setCompanyStaffPhoneNumber(phoneNumber);
        }
      }
    }
  };

  useEffect(() => {
    user && retriveCompanyMembership();
  }, [retriveCompanyMembership, user]);

  return (
    <>
      <div className="lg:border-t lg:border-b lg:border-gray-200">
        <nav
          className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
          aria-label="Progress"
        >
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
          >
            <li className="relative px-2 py-4 overflow-hidden lg:py-2">
              <button
                className="p-1 border border-transparent rounded-full hover:border-gray-200 hover:bg-gray-50"
                onClick={() => navigate("/companies/sank_staff")}
              >
                <XMarkIcon className="w-8 h-8 text-gray-700" />
              </button>
            </li>
            {steps.map((step, stepIdx) => (
              <li key={stepIdx} className="relative overflow-hidden lg:flex-1">
                <div
                  className={classNames(
                    stepIdx === 0 ? "border-b-0 rounded-t-md" : "",
                    stepIdx === steps.length - 1
                      ? "border-t-0 rounded-b-md"
                      : "",
                    "border border-gray-200 overflow-hidden lg:border-0 h-full"
                  )}
                >
                  {
                    //TODO remove comment on check current user
                  }
                  <button
                    className={classNames(
                      currentCompanyAttendant ? "cursor-not-allowed" : "",
                      "w-full group h-full"
                    )}
                    onClick={() =>
                      currentCompanyAttendant
                        ? setCurrentFormStep(steps[1])
                        : setCurrentFormStep(steps[0])
                    }
                  >
                    <span
                      className={classNames(
                        step.index !== currentFormStep.index
                          ? "group-hover:bg-gray-200 bg-transparent "
                          : "bg-red-600",
                        "absolute top-0 left-0 w-1 h-full  lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "px-4 py-4 lg:py-2 flex items-start text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span
                          className={classNames(
                            step.index < currentFormStep.index
                              ? "bg-red-600"
                              : "",
                            step.index === currentFormStep.index
                              ? "bg-white border-2 border-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "bg-white border-2 border-gray-300"
                              : "",
                            "flex items-center justify-center w-10 h-10  rounded-full"
                          )}
                        >
                          <step.icon
                            className={classNames(
                              step.index < currentFormStep.index
                                ? "text-white"
                                : "",
                              step.index === currentFormStep.index
                                ? "text-red-600"
                                : "",
                              step.index > currentFormStep.index
                                ? "text-gray-500"
                                : "",
                              "w-6 h-6 "
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex-col flex  xl:flex">
                        <span
                          className={classNames(
                            step.index === currentFormStep.index
                              ? "text-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "text-gray-500"
                              : "",
                            "text-xs font-semibold tracking-wide uppercase md:block lg:hidden xl:block"
                          )}
                        >
                          {step.name}
                        </span>
                        <span className="flex items-start text-sm font-medium text-gray-500">
                          {step.description}
                        </span>
                      </span>
                    </span>
                  </button>
                  <div
                    className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="w-full h-full text-gray-300"
                      viewBox="0 0 12 82"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0.5 0V31L10.5 41L0.5 51V82"
                        stroke="currentcolor"
                        vectorEffect="non-scaling-stroke"
                      />
                    </svg>
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="items-center w-full mx-auto md:w-11/12 lg:w-9/12">
        {currentFormStep.id === "companyAttendantMinInfos" && (
          <Form<CompanyAttendantMinInfos>
            onSubmit={onSubmitMemberMinInfos}
            formReturnAware={setCompanyAttendantMinInfosFormReturn}
            useCustomFooter
            form={{
              mode: "onChange",
              resolver: yupResolver(
                yup.object().shape({
                  email: yup.string().required("Le champs est obligatoire"),
                  password: yup
                    .string()
                    .required("Le champs est obligatoire")
                    .min(6, "6 charactère minimum"),
                  confirmPassword: yup
                    .string()
                    .required("Le champs est obligatoire")
                    .oneOf(
                      [yup.ref("password")],
                      "Les mots de passes ne sont pas compatible"
                    ),
                })
              ),
              defaultValues: {
                email: "",
                password: "",
                confirmPassword: "",
              },
            }}
            submitButtonLabel="Suivant"
          >
            <div className="flex flex-col content-center w-full px-5 mt-5 align-top md:flex-row">
              <div className="w-full mx-auto ">
                <FormInput
                  name="email"
                  label="E-mail"
                  placeholder="@exemple.com"
                />
                <FormInput
                  name="password"
                  label="Mot de passe"
                  type="password"
                  placeholder="Votre mot de passe en six (06) caractères"
                />
                <FormInput
                  type="password"
                  name="confirmPassword"
                  label="Confirmez votre mot de passe"
                  placeholder="Votre mot de passe"
                />
              </div>
            </div>
            <EditFormFooter<CompanyAttendantMinInfos>
              currentStep={currentFormStep}
              hasNext={false}
              hasPrevious={false}
              steps={steps}
              onStepChanged={onSelectedStepChanged}
              setSubmitButtonRef={setCompanyAttendantMinInfosFormSubmitButton}
              canSubmit
              submitBtnLabel="Suivant"
            />
          </Form>
        )}
        {currentFormStep.id === "companyAttendantInfos" && (
          <Form<CompanyAttendantInfos>
            onSubmit={onSubmitDefaultInfos}
            formReturnAware={setCompanyAttendantFormReturn}
            useCustomFooter
            form={{
              mode: "onChange",
              resolver: yupResolver(
                yup.object().shape({
                  displayName: yup
                    .string()
                    .required("Le champs est obligatoire"),
                  city: yup.string().optional(),
                  adresse: yup.string().optional(),
                  companyAttendantRole: yup
                    .string()
                    .required("Le champs est obligatoire"),
                  gender: yup.string().optional(),
                })
              ),
              defaultValues: {
                displayName: currentCompanyAttendant
                  ? currentCompanyAttendant.displayName
                  : "",
                city: currentCompanyAttendant
                  ? currentCompanyAttendant.city
                  : "",
                adresse: currentCompanyAttendant
                  ? currentCompanyAttendant.adresse
                  : "",
                profession: currentCompanyAttendant
                  ? currentCompanyAttendant.profession
                  : "",
                cnibNumber: currentCompanyAttendant
                  ? currentCompanyAttendant.cnibNumber
                  : "",
                companyAttendantRole: currentCompanyAttendant
                  ? currentCompanyAttendant.companyAttendantRole
                  : "",
              },
            }}
            isSubmitBtnDisabled={isValidNumber}
            submitButtonLabel={"Ajouter un(e) collaborateur(e)"}
          >
            <FormInput
              name="displayName"
              label="Nom/Prénom"
              placeholder="John Doe"
            />
            <PhoneInput
              international={false}
              placeholder="Entrer un numéro de téléphone"
              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
              defaultCountry="BF"
              value={
                currentCompanyAttendant
                  ? currentCompanyAttendant.phoneNumber
                  : companyStaffPhoneNumber
              }
              onChange={retrivePhoneNumber}
            />
            <FormSelect<string>
              options={attendantRoles}
              selectedOption={
                currentCompanyAttendant
                  ? currentCompanyAttendant.companyAttendantRole
                  : "Choisir..."
              }
              name="companyAttendantRole"
              optionLabel={(option) =>
                (CompanyCollabRoleMap.get(option) as string) ?? "Choisir..."
              }
              label="Role dans l'entreprise"
            />

            <FormSelect<string>
              label={"Genre"}
              name="gender"
              selectedOption={
                currentCompanyAttendant
                  ? currentCompanyAttendant.gender
                  : "Choisir..."
              }
              options={["FEMALE", "MALE", "NONBINARY", "UNKNOWN"]}
              optionLabel={(option) =>
                (mapsGender.get(option) as string) ?? "Choisir..."
              }
            />
            <FormInput
              name="city"
              label="Ville"
              optional
              placeholder="Ouagadougou"
            />
            <FormInput
              name="adresse"
              optional
              label="Adresse"
              placeholder="Rue 10.34, secteur 30, Ouaga2000"
            />
            <EditFormFooter<CompanyAttendantMinInfos>
              currentStep={currentFormStep}
              hasNext={false}
              hasPrevious={false}
              steps={steps}
              onStepChanged={onSelectedStepChanged}
              setSubmitButtonRef={setCompanyAttendantFormSubmitButton}
              submitBtnLabel={
                !currentCompanyAttendant
                  ? "Ajouter un(e) collaborateur(e)"
                  : "Enregistrer"
              }
              canSubmit
            />
          </Form>
        )}
      </div>
    </>
  );
};

export default CompanyStaffEdit;
