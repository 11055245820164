import { BanknotesIcon } from '@heroicons/react/24/outline';
import React from 'react'
import { NavLink, Outlet } from 'react-router-dom';
import { classNames } from '../../../utils';

const ManageAgriInvoicesPage = () => {
    return (
      <div className="bg-white relativ">
        <div className="divide-y lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
          <aside className="lg:col-span-2 xl:col-span-2">
            <nav className="">
              <NavLink
                to={{
                  pathname: "payment",
                }}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "bg-slate-300 text-red-500"
                      : "text-black hover:bg-gray hover:text-red-200",
                    "group w-full p-3 flex text-md font-medium"
                  )
                }
              >
                {({ isActive }) => (
                  <>
                    <BanknotesIcon
                      className={classNames(
                        isActive
                          ? "text-red-500"
                          : "text-black group-hover:text-red-200",
                        "mr-3 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    <span className="">Paiement</span>
                  </>
                )}
              </NavLink>
              {/* <NavLink
                to={{
                  pathname: "removal",
                }}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "bg-slate-300 text-red-500"
                      : "text-black hover:bg-gray hover:text-red-200",
                    "group w-full p-3 flex text-md font-medium"
                  )
                }
              >
                {({ isActive }) => (
                  <>
                    <BanknotesIcon
                      className={classNames(
                        isActive
                          ? "text-red-500"
                          : "text-black group-hover:text-red-200",
                        "mr-3 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    <span className="">Enlevement</span>
                  </>
                )}
              </NavLink> */}
            </nav>
          </aside>
          <div className="divide-y lg:col-span-9 xl:col-span-10">
            <Outlet />
          </div>
        </div>
      </div>
    );
  };
  

export default ManageAgriInvoicesPage
