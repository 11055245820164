import { FunnelIcon } from "@heroicons/react/24/outline";
import moment from "moment";
import { useMemo, useState } from "react";
import Moment from "react-moment";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../../../components/FirestoreTable";
import {
  commissionsPaidCollectionsName,
  usersCollectionName,
} from "../../../../config";
import { db } from "../../../../firebase";
import { getTimestamp } from "../../../../utils";

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "senderPhoneNumber",
    label: "Auteur",
  },
  {
    field: "userId",
    label: "Destinataire",
  },
  {
    field: "startDate",
    label: "Date de début",
  },
  {
    field: "endDate",
    label: "Date de fin",
  },
  {
    field: "amount",
    label: "Montant",
  },
  {
    field: "userRole",
    label: "Rôle du destinataire",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "startDate":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "endDate":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "amount":
      return <span>{new Intl.NumberFormat().format(value)} Fcfa</span>;
    case "userRole":
      return <span>{value}</span>;
    default:
      return value as string;
  }
};
const RettrivePaidCommission = () => {
  const [desUserData, setDesUserData] = useState<any>();
  const [filterByDate, setFilterByDate] = useState<boolean>(true);

  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>("");
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] =
    useState<string>("");

  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithEndDateValue(value.target.value);
  };

  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);

  const getFilteredByDate = async () => {
    if (seachWithStartDateValue) {
      setStartTime(getTimestamp(seachWithStartDateValue, true));
    }

    if (seachWithEndDateValue) {
      setEndTime(getTimestamp(seachWithEndDateValue, false));
    }
  };

  const firestoreTableQueriesWhere = useMemo(() => {
    let queries = [];

    if (startTime && filterByDate) {
      queries.push({
        fieldPath: "createdAt",
        opStr: ">=",
        value: startTime,
      });
    }

    if (endTime && filterByDate) {
      queries.push({
        fieldPath: "createdAt",
        opStr: "<=",
        value: endTime,
      });
    }

    if (desUserData) {
      queries = [
        {
          fieldPath: "userId",
          opStr: "==",
          value: desUserData ? desUserData?.id : "",
        },
      ];
    }

    return queries;
  }, [startTime, filterByDate, endTime, desUserData]);

  const getFilterdByPhoneNumber = async (value: any) => {
    const phoneNumber: string = value.target.value;
    setFilterByDate(false);
    if (phoneNumber.length === 8) {
      await db
        .collection(usersCollectionName)
        .where("phoneNumber", "==", `+226${phoneNumber}`)
        .get()
        .then((response) => {
          setDesUserData(response.docs[0]?.data());
        });
    }
  };
  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Historique des commissions payées
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Vous trouverez ci-dessous tous les historique des commissions
              payées.
            </p>
          </div>
          <div className="h-8" />
          <div className="flex self-end justify-between max-md:flex-col">
            <div className="mr-4">
              <label
                htmlFor="tel"
                className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
              >
                Filtrer par numéro du destinatiare
              </label>
              <div className="relative mt-2 space-x-3 rounded-md shadow-sm">
                <input
                  type="tel"
                  id="tel"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  placeholder="70707070"
                  onChange={getFilterdByPhoneNumber}
                />
              </div>
            </div>
            <div className="flex px-1 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
              <div className="mr-1">
                <label
                  htmlFor="dateDebut"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date début
                </label>
                <input
                  type="date"
                  id="dateDebut"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  value={seachWithStartDateValue}
                  onChange={handleChangeStartDateValue}
                />
              </div>
              <div className="mr-1">
                <label
                  htmlFor="dateFin"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date fin
                </label>
                <input
                  type="date"
                  id="dateFin"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  value={seachWithEndDateValue}
                  onChange={handleChangeEndDtaeValue}
                />
              </div>
              <div className="flex items-center justify-center mt-5 h-14">
                <div className="flex">
                  <button
                    type="button"
                    className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    onClick={() => {
                      setFilterByDate(true);
                      getFilteredByDate();
                    }}
                  >
                    <FunnelIcon
                      className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                      aria-hidden="true"
                    />
                    Filtrer
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-8">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm">
                <FirestoreTable
                  collectionRef={db.collection(commissionsPaidCollectionsName)}
                  condensed
                  collectionQueriesWhere={firestoreTableQueriesWhere}
                  columns={columns}
                  rowRender={renderRow}
                  paginate={{
                    pageIndex: 0,
                    limit: 100,
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RettrivePaidCommission;
