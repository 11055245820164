import React, { useState } from "react";
import { QrReader } from "react-qr-reader";
import Modal from "../Modal";
import { ViewFinder } from "./ViewFinder";

const ReadeQrcode = (props: {
  onQrcodeResult: any;
  onQrcodeError: any;
  showQrcodeReadeModal: boolean;
  setShowQrcodeReadeModal: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const {
    onQrcodeResult,
    onQrcodeError,
    showQrcodeReadeModal,
    setShowQrcodeReadeModal,
  } = props;
  const [result, setResult] = useState<string[]>([]);
  const handleResulteSucced = (result: any) => {
    const userIds = result.split("_");
    if (showQrcodeReadeModal) setResult(userIds);
  };
  const handleError = (error: any) => {
    !showQrcodeReadeModal && onQrcodeError && onQrcodeError(error);
  };
  const getResult = () => {
    result.length > 0 && onQrcodeResult(result);
  };
  getResult();

  return (
    <Modal
      // modalTitle="QrCode"
      isOpen={showQrcodeReadeModal}
      maxSize="md"
      closeModal={() => setShowQrcodeReadeModal(false)}
    >
      <div className="flex justify-center">
        <div className="block max-w-sm bg-white rounded-lg shadow-lg dark:bg-neutral-700">
          <QrReader
            ViewFinder={ViewFinder}
            onResult={(result, error) => {
              if (!!result) {
                setShowQrcodeReadeModal(false);
                handleResulteSucced(result?.getText());
              }

              if (!!error) {
                handleError(error);
              }
            }}
            scanDelay={100}
            constraints={{
              facingMode: "environment",
              width: { ideal: 640 },
              height: { ideal: 480 },
              frameRate: { ideal: 10, max: 15 },
            }}
          />
          <div className="p-6">
            <h5 className="mb-2 text-xl font-medium leading-tight text-neutral-800 dark:text-neutral-50">
              Scanner un Qrcode
            </h5>
            <p className="mb-4 text-base text-neutral-600 dark:text-neutral-200">
              Placez le qrcode au milieu du but.
            </p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ReadeQrcode;
