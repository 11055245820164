import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { db } from "../../../firebase";
import { eventsCollections, ticketsCollections, usersCollectionName } from "../../../config";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import { classNames, getTimestamp } from "../../../utils";
import Pagination from "../../../components/Pagination/Pagination";
import moment from "moment";
import { FunnelIcon } from "@heroicons/react/24/outline";
import { Timestamp } from "firebase-admin/firestore";

const tableHeader = [
  "N°",
  "ID du ticket",
  // "Prix du ticket",
  "Nombre de place",
  "Status",
  "Type de ticket",
  "Tickets Disponible",
  "Client",
  "N° du client",
  "Date de paiement",
];

const TicketDetailsPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentEvent = location.state?.eventId;
  const [, setTicket] = useState<any>()
  const [ticketPaid, setTicketPaid] = useState<any>()
  const [, setEvents] = useState<any>()
  const [isLoading, setIsLoading] = useState(true);
  const [idTicket, setIdTicket] = useState<string>("");
  const [ticketStatus, setTicketStatus] = useState<string>("");
  const [filterData, setFilterData] = useState<any[]>([]);

  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithEndDateValue(value.target.value);
  };
  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );
 
  const handleIdTicketChange = (idTicket: string) => {
    const data = filterData.filter((val: any) =>
      (val.data?.id as string).includes(idTicket)
    );
    if (idTicket.length !== 0) {
      setFilterData(data);
    } else {
      setFilterData(ticketPaid);
    }
  };

  const handleTicketStatutChange = (ticketStatus: string) => {
    const data = filterData.filter((val: any) =>
      (val.data?.status as string).includes(ticketStatus)
    );
    if (ticketStatus.length !== 0) {
      setFilterData(data);
    } else {
      setFilterData(ticketPaid);
    }
  };

  const handleInputChange = (event: any) => {
    const { value } = event.target;
    setIdTicket(value);
    handleIdTicketChange(value);
  };

  const handleStatutInputChange = (event: any) => {
    const { value } = event.target;
    setTicketStatus(value);
    handleTicketStatutChange(value);
  };

  const getAllEvents = useCallback(async (startTime: any, endTime: any) => {
    setIsLoading(true);
    console.log("start", startTime, "end", endTime)
    const eventRef = db.doc(`${eventsCollections}/${currentEvent}`);
    const getEventRef = db
      .collection(ticketsCollections)
      .where("eventRef", "==", eventRef)
      .where("createdAt", ">=", startTime)
      .where("createdAt", "<=", endTime);

    await getEventRef.get().then(async (event) => {
      const tempTickets: any[] = [];
      const tempEvents: any[] = [];
      const users: any[] = [];

      await Promise.all(event.docs.map(async (d) => {
        const ticketType = await d.data()?.ticketTypeRef.get();
        const eventTitle = await d.data()?.eventRef.get();
        const userTicket = await db.collection(usersCollectionName).doc(d.data()?.userId).get();

        // Stocke les valeurs temporairement dans des tableaux
        tempTickets.push(ticketType.data());
        tempEvents.push(eventTitle.data());
        users.push(userTicket.data())
      }));

      // Met à jour le state après la boucle
      setTicket(tempTickets);
      setEvents(tempEvents);

      setTicketPaid(event.docs.map((d, index) => {
        return {
          id: d.id,
          data: d.data(),
          ticket: tempTickets[index],
          event: tempEvents[index],
          user: users[index],
        };
      }));
      setFilterData(event.docs.map((d, index) => {
        return {
          id: d.id,
          data: d.data(),
          ticket: tempTickets[index],
          event: tempEvents[index],
          user: users[index],
        };
      }))
      setIsLoading(false);
    });
  }, [currentEvent]);

  const getFilteredByDate = async () => {
    // const startTime = new Date(seachWithStartDateValue).getTime();
    const startTime = getTimestamp(seachWithStartDateValue, true);
    const endTime = getTimestamp(seachWithEndDateValue, false);
    getAllEvents(startTime, endTime);
  };

  useEffect(() => {
    getFilteredByDate()
  }, [])


  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="flex flex-col">
        <div className="">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les Tickets
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ici l'ensemble des tickets payés.
          </p>
        </div>
      </div>
      <div className="h-6" />
      <div className="grid grid-cols-1 gap-5 sm:mt-10 md:grid-cols-3">
      <div className="my-auto">
          <input
            type="search"
            id="search"
            className="block w-full p-3 pl-10 text-sm text-gray-900 mt-4 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
            placeholder="statut"
            onChange={handleStatutInputChange}
            value={ticketStatus}
          />
        </div>
        <div className="my-auto">
          <input
            type="search"
            id="search"
            className="block w-full p-3 pl-10 text-sm text-gray-900 mt-4 ml-3 mr-3 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
            placeholder="N° du ticket"
            onChange={handleInputChange}
            value={idTicket}
          />
        </div>
        <div className="flex px-1 bg-white rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
          <div className="mr-1">
            <label
              htmlFor="dateDebut"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Date début
            </label>
            <input
              type="date"
              id="dateDebut"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
              onChange={handleChangeStartDateValue}
            />
          </div>
          <div className="mr-1">
            <label
              htmlFor="dateFin"
              className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
            >
              Date fin
            </label>
            <input
              type="date"
              id="dateFin"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
              defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
              onChange={handleChangeEndDtaeValue}
            />
          </div>
          <div className="flex items-center justify-center mt-5 h-14">
            <div className="flex">
              <button
                type="button"
                className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={() => {
                  getFilteredByDate();
                }}
              >
                <FunnelIcon
                  className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                  aria-hidden="true"
                />
                Filtrer
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex flex-col mt-8">
        {
          isLoading ? (
            <>
              <div className="p-4 animate-pulse">
                <div className="h-4 mt-3 mb-6 bg-gray-400 rounded"></div>
                {[...Array(5)].map((e, i) => (
                  <div key={i}>
                    <div className="h-4 mb-6 bg-gray-200 rounded"></div>
                    <div className="h-4 mb-6 bg-gray-300 rounded"></div>
                  </div>
                ))}
              </div>
            </>
          ) : (
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="shadow-sm">
                  <table
                    className="min-w-full border-separate"
                    style={{ borderSpacing: 0 }}
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        {tableHeader.map((th, index) => (
                          <th
                            key={index}
                            scope="col"
                            className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                          >
                            {th}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {filterData && filterData.length > 0 ? (
                        filterData.map((event: any, eventIdx: number) => (
                          <tr
                            key={eventIdx}
                            className={
                              eventIdx % 2 === 0
                                ? "bg-white"
                                : "bg-gray-100 cursor-pointer"
                            }
                          // onClick={() =>
                          //   onDetails(transaction.data, transaction.id)
                          // }
                          >
                            <td
                              className={classNames(
                                eventIdx !== filterData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(eventIdx as number) + 1}
                            </td>
                            <td
                              className={classNames(
                                eventIdx !== filterData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(event.data?.id ?? "-" as string)}
                            </td>
                            {/* <td
                              className={classNames(
                                eventIdx !== filterData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(event.data?.paidTicketPrice ?? "-" as string)}
                            </td> */}
                            <td
                              className={classNames(
                                eventIdx !== filterData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(parseInt(event.data?.numberOfPlace) ?? 0 as number)}
                            </td>

                            <td
                              className={classNames(
                                eventIdx !== filterData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(event.data?.status ?? "-" as string)}
                            </td>
                            <td
                              className={classNames(
                                eventIdx !== filterData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(event.ticket?.standard ?? "-" as string)}
                            </td>
                            <td
                              className={classNames(
                                eventIdx !== filterData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(parseInt(event.data?.numberOfTicketAvailableAfterTransaction) as number ?? 0)}
                            </td>
                            <td
                              className={classNames(
                                eventIdx !== filterData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(event.user?.displayName ?? "-" as string)}
                            </td>
                            <td
                              className={classNames(
                                eventIdx !== filterData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(event.user?.phoneNumber ?? "-" as string)}
                            </td>
                            <td
                              className={classNames(
                                eventIdx !== filterData.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(event.data?.createdAt as Timestamp)
                                  .toDate()
                                  .toLocaleString()}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoContent />
                      )}
                    </tbody>
                  </table>
                  {/* <Pagination
                skip={events.length}
                take={events.length}
                total={events.length}
                collectionName={eventsCollections}
                isChangedPage={true}
                onPageChange={(pageIndex: number) => pageIndex}
                onPageLimitChange={() =>
                  getAllEvents()
                }
              // balance={transactionTotalSum}
              /> */}
                </div>
              </div>
            </div>
          )
        }
      </div>
    </div>
  );
};

export default TicketDetailsPage;
