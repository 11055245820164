import React, { useContext, useEffect, useState } from "react";
import {
  GasStationOrderInterface,
  OrderStatus,
} from "../../../../interfaces/GasStation/order.interface";
import { Form, FormInput } from "../../../../components/FormComponents";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { UseFormReturn } from "react-hook-form";
import { db } from "../../../../firebase";
import { UserContext } from "../../../../contexts";
import { v4 as uuidv4 } from "uuid";
import { QRCodeCanvas } from "qrcode.react";
import Modal from "../../../../components/Modal";
import imageSrc from "../../../../favicon.png";
import { AccountInterface, AccountType } from "../../../../interfaces";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../../../utils";
import toast from "react-hot-toast";
import {
  accountsCollectionName,
  gasStationCollectionName,
  gasStationMembershipCollectionName,
  gasStationOrderCollectionName,
} from "../../../../config";

const goods = "bien et service";
const full = "carburant";
const OrderDetailPage = () => {
  const { user } = useContext(UserContext);
  const navigate = useNavigate();
  const [gasStationMembershipId, setGasStationMembershipId] = useState("");
  const imageOptions = {
    src: imageSrc,
    height: 50,
    width: 50,
    excavate: true,
  };
  const [, setGasStationOrderFormReturn] =
    useState<UseFormReturn<GasStationOrderInterface>>();
  const [showQrcodeReadeModal, setShowQrcodeReadeModal] =
    useState<boolean>(false);
  const [orderId, setOrderId] = useState<string>();
  const [account, setAccount] = useState<AccountInterface | any>();
  const [gasstationService, setGasstationService] = useState<string>(goods);
  const onsubmit = async (data: GasStationOrderInterface) => {
    const uid = uuidv4();
    const createdAt = new Date();
    const updateAt = new Date();
    if (parseInt(data.amount) < 100) {
      toast.error(
        "Le montant minimum pour initié la transaction est de 100 FCFA"
      );
    } else {
      const response = db
        .collection(gasStationCollectionName)
        .doc(gasStationMembershipId)
        .collection(gasStationOrderCollectionName)
        .doc(uid)
        .set({
          id: uid,
          amount: data.amount,
          description: gasstationService && gasstationService,
          orderStatus: OrderStatus.PENDING,
          createdAt,
          updateAt,
        })
        .then((response) => {
          setOrderId(uid);
          setShowQrcodeReadeModal(true);
        });
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success: "Le code pin a été mis à jours avec success",
        loading: "chargement...",
      });
    }
  };

  const retriveGasStationsMembership = async () => {
    const membershipRef = db.collection(gasStationMembershipCollectionName);
    const membershipSnaphot = await membershipRef
      .where("memberId", "==", user?.id)
      .get();
    if (membershipSnaphot.docs[0].exists) {
      const membershipData = membershipSnaphot.docs[0].data();
      setGasStationMembershipId(membershipData?.gasStationId);
      const userAccountSnapShot = await db
        .collection(gasStationCollectionName)
        .doc(membershipData?.gasStationId)
        .collection(accountsCollectionName)
        .get();
      const userAccountsData = userAccountSnapShot.docs
        .map((account) => account.data())
        .find((data) => data.accountType === AccountType.FUEL);
      setAccount(userAccountsData);
    }
  };

  useEffect(() => {
    retriveGasStationsMembership();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="max-w-md sm:flex sm:items-center">
          <div className="flex items-center justify-center w-full font-manrope">
            <div className="sm:flex-auto">
              <h1 className="py-2 text-xl font-semibold text-gray-900">
                Initialisez une commande
              </h1>
              <p className="mt-2 text-sm text-gray-700">
                Renseigner les informations de la commande
              </p>
            </div>
          </div>
        </div>
        <div className="flex flex-col mt-8">
          <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="shadow-sm">
                <div className="items-center w-full md:w-11/12 lg:w-9/12 mx-auto">
                  <Form<GasStationOrderInterface>
                    onSubmit={onsubmit}
                    formReturnAware={setGasStationOrderFormReturn}
                    form={{
                      resolver: yupResolver(
                        yup.object().shape({
                          amount: yup
                            .string()
                            .required("Le champs est obligatoire"),
                        })
                      ),
                    }}
                    submitButtonLabel={`Ajouter une commande`}
                  >
                    <div className="flex align-top flex-col px-5 mt-5 md:flex-row content-center w-full">
                      <div className="w-full mx-auto ">
                        <div className="mt-6">
                          <div className="flex items-center pl-4 border border-gray-200 rounded cursor-pointer dark:border-gray-700">
                            <input
                              checked
                              id="bordered-radio-2"
                              type="radio"
                              name="bordered-radio"
                              onClick={() => setGasstationService(goods)}
                              className="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              htmlFor="bordered-radio-2"
                              className="w-full py-4 ml-2 text-sm font-medium text-gray-900 cursor-pointer dark:text-gray-300"
                            >
                              Biens et services.
                            </label>
                          </div>
                          <div className="flex items-center pl-4 mt-2 border border-gray-200 rounded dark:border-gray-700">
                            <input
                              id="bordered-radio-1"
                              type="radio"
                              name="bordered-radio"
                              onClick={() => setGasstationService(full)}
                              className="w-4 h-4 text-red-600 bg-gray-100 border-gray-300 focus:ring-red-500 dark:focus:ring-red-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                            />
                            <label
                              htmlFor="bordered-radio-1"
                              className="w-full py-4 ml-2 text-sm font-medium text-gray-900 cursor-pointer dark:text-gray-300"
                            >
                              Carburant
                            </label>
                          </div>
                        </div>
                        <FormInput
                          minLength={3}
                          type="text"
                          name="amount"
                          label="Entrez un montant"
                          placeholder="XOF"
                        />
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        modalTitle="QrCode"
        isOpen={showQrcodeReadeModal}
        maxSize="lg"
        closeModal={() => {
          setShowQrcodeReadeModal(false);
          navigate("/gas-stations/orders");
        }}
      >
        <div className="w-full max-w-md px-2 py-16 sm:px-0">
          <div
            className={classNames(
              "rounded-xl bg-white p-3 flex justify-center",
              "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2 w-full"
            )}
          >
            <QRCodeCanvas
              className="w-full"
              id="qrCode"
              value={`${gasStationMembershipId}_${account?.id}_${orderId}`}
              size={300}
              level={"H"}
              imageSettings={imageOptions}
            />
          </div>
        </div>
      </Modal>
    </>
  );
};

export default OrderDetailPage;
