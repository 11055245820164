import {
  UserIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import React from "react";
import toast from "react-hot-toast";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../../components/FirestoreTable";
import { agencyCollectionsName } from "../../../config";
import { db } from "../../../firebase";
import useCrudDocument from "../../../hooks/useCrudDocument";
import { UserRole } from "../../../interfaces";

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "profilImageUrl",
    label: "Profil",
  },
  {
    field: "displayName",
    label: "Nom/prénom",
  },
  {
    field: "email",
    label: "Email",
  },
  {
    field: "phoneNumber",
    label: "Téléphone",
  },
  {
    field: "identificationCode",
    label: "Code de l'agence",
  },
  {
    field: "city",
    label: "Ville",
  },
  {
    field: "lat",
    label: "Latitude",
  },
  {
    field: "long",
    label: "Longitude",
  },
  {
    field: "active",
    label: "Active",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "displayName":
      return <span>{value}</span>;
    case "profilImageUrl":
      return (
        <span>
          {" "}
          {value ? (
            <img
              className="w-10 h-10 p-1 border rounded-full"
              src={
                value
                  ? value
                  : `https://ui-avatars.com/api/?background=random&name=${
                      value || "S"
                    }`
              }
              alt=""
            />
          ) : (
            <UserIcon className="w-10 h-10 p-1 border rounded-full" />
          )}
        </span>
      );
    case "email":
      return <span>{value}</span>;
    case "city":
      return <span>{value}</span>;
    case "phoneNumber":
      return <span>{value ? value : ""}</span>;
    case "lat":
      return <span>{value}</span>;
    case "long":
      return <span>{value}</span>;
    case "active":
      return (
        <span>
          {value ? (
            <CheckCircleIcon className="w-6 h-6 text-green-500" />
          ) : (
            <XCircleIcon className="w-6 h-6 text-red-500" />
          )}
        </span>
      );
    default:
      return value as string;
  }
};

const AllAgenciesPage = () => {
  let refreshDataFn: (() => void) | undefined = undefined;

  const navigate = useNavigate();
  const { deleteDocument } = useCrudDocument();

  const onEditRow = (id: number, entity: any) => {
    if (entity) {
      navigate(`/agencies/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onRowDetails = (id: number, entity: any) => {
    if (entity) {
      navigate(`/agencies/${entity.id}/details`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onDeleteRow = (id: number, row: any) => {
    if (row) {
      toast.promise(
        deleteDocument(row.id, agencyCollectionsName).then(() => {
          if (refreshDataFn) {
            refreshDataFn();
          }
        }),
        {
          loading: "Suppression...",
          success: "L'agent a été supprimé avec succès.",
          error: "Échec ! Veuillez réessayer.",
        }
      );
    }
  };
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Les agences
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ici l'ensemble des agences.
          </p>
        </div>
        <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
          <button
            type="button"
            className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
            onClick={() => {
              navigate("/agencies/new");
            }}
          >
            <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
              Ajouter une agence
            </span>
          </button>
        </div>
      </div>
      <FirestoreTable
        collectionRef={db.collection(agencyCollectionsName)}
        collectionQueriesWhere={[
          {
            fieldPath: "userRole",
            opStr: "==",
            value: UserRole.AGENCY,
          },
        ]}
        condensed
        columns={columns}
        rowRender={renderRow}
        rowEditHandler={onEditRow}
        // rowDeleteHandler={onDeleteRow}
        rowDetailsHandler={onRowDetails}
        shouldAddEditColumn
        displayDetails
        displayEditBtn
        paginate={{ pageIndex: 0, limit: 100, sort: "createdAt:desc" }}
      />
    </div>
  );
};
export default AllAgenciesPage;
