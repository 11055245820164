import PaginationFooter from "./PaginationFooter";

export class PaginationDto {
  total = 0;

  limit = 100;

  offset = 0;
}

export class PaginatedDto<TData> {
  pagination: PaginationDto = new PaginationDto();

  data: TData[] = [];
}

export interface PaginationProps {
  skip: number;
  take: number;
  total: number;
  collectionName: string;
  onPageChange?: (index: number) => void;
  onPageLimitChange?: (limit: number) => void;
  isChangedPage?: boolean;
  balance?: number;
}

const Pagination = (props: PaginationProps) => {
  const changePageLimit = (pageLimit: number) => {
    if (props.onPageLimitChange) props.onPageLimitChange(pageLimit);
  };
  const goToPage = (pageIndex: number) => {
    if (props.onPageChange) props.onPageChange(pageIndex);
  };
  return (
    <PaginationFooter
      // skip={props.skip}
      isChangedPage={props.isChangedPage}
      take={props.take}
      total={props.take}
      onPageChange={(pageIndex: number) => goToPage(pageIndex)}
      onPageLimitChange={(pageLimit: number) => changePageLimit(pageLimit)}
      currentPageIndex={0}
      balance={props.balance}
    />
  );
};

export default Pagination;
