import {
  CheckCircleIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  UserIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { db, functions } from "../../firebase";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../components/FirestoreTable";
import { usersCollectionName } from "../../config";
import { useEffect, useMemo, useState } from "react";
import Modal from "../../components/Modal";
import PhoneInput from "react-phone-number-input";
import { Timestamp } from "firebase-admin/firestore";
import moment from "moment";
import { parseDate, getTimestamp } from "../../utils";
import HasRoles from "../../components/HasRoles";

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "profilImageUrl",
    label: "Profil",
  },
  {
    field: "displayName",
    label: "Nom/prénom",
  },
  {
    field: "phoneNumber",
    label: "Téléphone",
  },
  {
    field: "city",
    label: "Ville",
  },
  {
    field: "userRole",
    label: "Role",
  },
  {
    field: "cnibNumber",
    label: "Numéro CNIB",
  },
  {
    field: "active",
    label: "Active",
  },
  {
    field: "isCertify",
    label: "Certifié",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        // <Moment locale="fr" format="dddd DD MMMM YYYY">
        //   {value?.toDate() ?? new Date().toLocaleDateString()}
        // </Moment>
        parseDate(value as Timestamp)
      );
    case "displayName":
      return <span>{value ? value : "-"}</span>;
    case "profilImageUrl":
      return (
        <span>
          {" "}
          {value ? (
            <img
              className="w-10 h-10 p-1 border rounded-full"
              src={
                value
                  ? value
                  : `https://ui-avatars.com/api/?background=random&name=${value || "S"
                  }`
              }
              alt=""
            />
          ) : (
            <UserIcon className="w-10 h-10 p-1 border rounded-full" />
          )}
        </span>
      );
    case "isCertify":
      return (
        <span>
          {" "}
          {value ? (
            <CheckCircleIcon className="w-6 h-6 text-green-500" />
          ) : (
            <XCircleIcon className="w-6 h-6 text-red-500" />
          )}
        </span>
      );
    case "city":
      return <span>{value ? value : "-"}</span>;
    case "phoneNumber":
      return <span>{value}</span>;
    case "userRole":
      return <span>{value}</span>;
    case "cnibNumber":
      return <span>{value ? value : "-"}</span>;
    case "active":
      return (
        <span>
          {" "}
          {value ? (
            <CheckCircleIcon className="w-6 h-6 text-green-500" />
          ) : (
            <XCircleIcon className="w-6 h-6 text-red-500" />
          )}
        </span>
      );
    default:
      return value as string;
  }
};

const UsersPage = () => {
  let refreshDataFn: (() => void) | undefined = undefined;
  const navigate = useNavigate();
  const collectionName = usersCollectionName;
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [userData, setUserData] = useState<any>();

  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );
  const sankmoneyAdminUpdateUserPinCodeFn = functions.httpsCallable(
    "sankmoneyAdminUpdateUserPinCodeFn"
  );

  const onEditRow = (id: any, entity: any) => {
    if (entity) {
      navigate(`/users/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onRowDetails = (id: any, entity: any) => {
    if (entity) {
      navigate(`/users/${entity.id}/details`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };

  const onUpdateUserPIN = (id: any, entity: any) => {
    if (entity) {
      toast.promise(
        sankmoneyAdminUpdateUserPinCodeFn({
          userId: entity?.id,
        }).then(() => {
          if (refreshDataFn) {
            refreshDataFn();
          }
        }),
        {
          loading: "Mise à jour du pin...",
          success:
            "Le code pin a été mise à jour avec succès. Un sms à été envoyer à l'utilisateur",
          error: "Échec ! Veuillez réessayer.",
        }
      );
    }
  };

  const onCertifyUser = (id: any, entity: any) => {
    if (entity) {
      navigate(`/users/${entity.id}/certify`, {
        state: { entity: entity, onCertify: true },
      });
    }
  };

  const onBlockedRow = (id: any, row: any) => {
    if (row) {
      toast.promise(
        sankmoneyUpdateAccountFn({
          collectionName: collectionName,
          documentData: {
            active: false,
          },
          documentId: row.id,
        }).then(() => {
          if (refreshDataFn) {
            refreshDataFn();
          }
        }),
        {
          loading: "Blocage...",
          success: "L'utilisateur a été bloqué avec succès.",
          error: "Échec ! Veuillez réessayer.",
        }
      );
    }
  };

  const retrivePhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber.length >= 12) {
      await db
        .collection(collectionName)
        .where("phoneNumber", "==", phoneNumber)
        .get()
        .then((response) => {
          setUserData(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
    } else {
      setUserData([]);
    }
  };

  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(moment(Date.now()).startOf("hour").format("YYYY-MM-DD"));
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    moment(Date.now()).format("YYYY-MM-DD")
  );

  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithEndDateValue(value.target.value);
  };

  const [startTime, setStartTime] = useState<Date | null>(null);
  const [endTime, setEndTime] = useState<Date | null>(null);

  const getFilteredByDate = async () => {
    if (seachWithStartDateValue) {
      setStartTime(getTimestamp(seachWithStartDateValue, true));
    }

    if (seachWithEndDateValue) {
      setEndTime(getTimestamp(seachWithEndDateValue, false));
    }
  };

  const firestoreTableQueriesWhere = useMemo(() => {
    const queries = [];

    if (startTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: ">=",
        value: startTime,
      });
    }

    if (endTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: "<=",
        value: endTime,
      });
    }

    return queries;
  }, [startTime, endTime]);


  useEffect(() => {
    getFilteredByDate();
  }, []);

  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="flex flex-col mt-8">
        <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle">
            <div className="flex flex-col">
              <div className="sm:flex-auto">
                <h1 className="py-2 text-xl font-semibold text-gray-900">
                  Les utilisateurs
                </h1>
                <p className="mt-2 text-sm text-gray-700">
                  Vous trouverez ici l'ensemble des utilisateurs.
                </p>
              </div>
              <div className="h-8" />
              <div className="flex self-end justify-between max-md:flex-col">
                <button
                  type="button"
                  className="relative my-auto  inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
                  onClick={(e) => {
                    setShowPinCodeModal(true);
                  }}
                >
                  <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                    <MagnifyingGlassIcon
                      className="self-center flex-shrink-0 w-5 h-5 mr-1 text-orange-500"
                      aria-hidden="true"
                    />
                    Rechercher un utilisateur
                  </span>
                </button>
                <div className="flex px-1 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
                  <div className="mr-1">
                    <label
                      htmlFor="dateDebut"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Date début
                    </label>
                    <input
                      type="date"
                      id="dateDebut"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={seachWithStartDateValue}
                      onChange={handleChangeStartDateValue}
                    />
                  </div>
                  <div className="mr-1">
                    <label
                      htmlFor="dateFin"
                      className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                    >
                      Date fin
                    </label>
                    <input
                      type="date"
                      id="dateFin"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                      value={seachWithEndDateValue}
                      onChange={handleChangeEndDtaeValue}
                    />
                  </div>
                  <div className="flex items-center justify-center mt-5 h-14">
                    <div className="flex">
                      <button
                        type="button"
                        className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                        onClick={() => {
                          getFilteredByDate();
                        }}
                      >
                        <FunnelIcon
                          className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                          aria-hidden="true"
                        />
                        Filtrer
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="shadow-sm">
              <FirestoreTable
                collectionRef={db.collection(collectionName)}
                condensed
                collectionQueriesWhere={[
                  ...firestoreTableQueriesWhere,
                  {
                    fieldPath: "userRole",
                    opStr: "==",
                    value: "USER",
                  },
                ]}
                columns={columns}
                rowRender={renderRow}
                rowEditHandler={onEditRow}
                rowCertifyUserHandler={onCertifyUser}
                rowDisableHandler={onBlockedRow}
                rowUpdateUserPINHandler={onUpdateUserPIN}
                shouldAddEditColumn
                displayEditBtn
                displayDisableBtn
                displayCertificationBtn
                displayUpdateUserPINBtn
                rowDetailsHandler={onRowDetails}
                displayDetails
                paginate={{
                  pageIndex: 0,
                  limit: 100,
                }}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={showPinCodeModal}
        maxSize="lg"
        closeModal={() => setShowPinCodeModal(false)}
        modalTitle="Rechercher un utilisateur"
      >
        <div className="flex justify-center">
          <div className="w-full mt-10">
            <PhoneInput
              international={false}
              placeholder="Entrer un numéro de téléphone"
              className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
              defaultCountry="BF"
              onChange={retrivePhoneNumber}
            />
            <div className="flex justify-center w-full">
              {userData && userData.length > 0 && (
                <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex flex-col items-center pb-10">
                    <img
                      className="w-24 h-24 mt-2 mb-3 rounded-full shadow-lg"
                      src={
                        userData[0].data.profilImageUrl
                          ? userData[0].data.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${userData[0].data.displayName || "S"
                          }`
                      }
                      alt=""
                    />
                    <h5 className="mb-1 text-xl font-medium text-center text-gray-900 dark:text-white">
                      {userData[0].data.displayName}
                    </h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {userData[0].data.phoneNumber}
                    </span>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      Membre SankMoney depuis : {new Date(
                        userData[0].data.createdAt.seconds * 1000 +
                        userData[0].data.createdAt.nanoseconds / 1000000
                      ).toLocaleDateString()}
                    </span>
                    <div className="flex mt-4 space-x-3 md:mt-5">
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() =>
                          navigate(`/users/${userData[0].id}/details`, {
                            state: {
                              entity: userData[0].data,
                              id: userData[0].id,
                              isSearch: true,
                            },
                          })
                        }
                      >
                        Details
                      </div>
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                        onClick={() => {
                          setShowPinCodeModal(false);
                          setUserData([]);
                        }}
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default UsersPage;
