import React from "react";

interface FolderComponentProps {
  title: string;
  onclick: () => void;
}

const FolderComponent = ({ title, onclick }: FolderComponentProps) => {
  return (
    <div className="flex items-center px-3 pb-3 mt-2 w-36" onClick={onclick}>
      <div className="items-center p-3 transition duration-500 ease-in-out rounded-md cursor-pointer hover:shadow ">
        <div className="text-blue-500">
          <svg
            fill="currentColor"
            className="w-10 h-10"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M0 4c0-1.1.9-2 2-2h7l2 2h7a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4z"></path>
          </svg>
        </div>
        <div className="">
          <h4 className="font-bold">{title}</h4>
        </div>
      </div>
    </div>
  );
};

export default FolderComponent;
