/* eslint-disable jsx-a11y/no-redundant-roles */
import { useEffect, useState } from "react";
import {
  gasStationCollectionName,
  gasStationMembershipCollectionName,
  generatedCodeGasStationCollectionsName,
  usersCollectionName,
} from "../../../../config";
import {
  AtSymbolIcon,
  LockClosedIcon,
  UserCircleIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import {
  AttendantRole,
  GasStationInterface,
  UserInterface,
  UserRole,
  mapUserAvailableZone,
  mapsGender,
  userAvailableZonesOptions,
} from "../../../../interfaces";
import { yupResolver } from "@hookform/resolvers/yup";
import toast from "react-hot-toast";
import { useNavigate, useLocation } from "react-router-dom";
import {
  FormInput,
  Form,
  FormSelect,
} from "../../../../components/FormComponents";
import { db, functions } from "../../../../firebase";
import { classNames, validateAndParseCoordinate } from "../../../../utils";
import * as yup from "yup";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";

type GasStationInfos = Pick<
  GasStationInterface,
  | "displayName"
  | "adresse"
  | "email"
  | "city"
  | "gasStationCode"
  | "phoneNumber"
  | "lat"
  | "long"
  | "availableZone"
  | "createdAt"
  | "updatedAt"
>;
type GasStationAttendantMinInfos = Pick<
  UserInterface,
  "displayName" | "email" | "password" | "confirmPassword"
>;

type GasStationAttendantInfos = Pick<
  UserInterface,
  | "displayName"
  | "email"
  | "active"
  | "agence"
  | "city"
  | "adresse"
  | "cnibNumber"
  | "createdAt"
  | "gender"
  | "phoneNumber"
  | "profession"
  | "userRole"
  | "userAgencySubRole"
  | "updatedAt"
>;

type Step = {
  id:
  | "gasStationInfos"
  | "gasStationAttendantMinInfos"
  | "gasStationAttendantInfos";
  index: number;
  name: string;
  description: string;
  icon: any;
};

const steps: Step[] = [
  {
    id: "gasStationInfos",
    index: 0,
    name: "Informations de la la station-service",
    description: "Renseigner les informations de la station-service",
    icon: UserCircleIcon,
  },
  {
    id: "gasStationAttendantMinInfos",
    index: 1,
    name: "Les informations de connexion",
    description: "Renseigner les informations de connexion du manager",
    icon: LockClosedIcon,
  },
  {
    id: "gasStationAttendantInfos",
    index: 2,
    name: "Informations du gérant",
    description: "Renseigner les informations du gérant",
    icon: AtSymbolIcon,
  },
];
const collectionName = gasStationCollectionName;

const EditGasStationPage = () => {
  const navigate = useNavigate();
  const [currentFormStep, setCurrentFormStep] = useState<Step>(steps[0]);
  const location = useLocation();
  const [gasStationPhoneNumber, setGasStationPhoneNumber] = useState("");
  const currentGasStation = location.state?.entity;
  const isAffilated = location.state?.affiliate;
  const [memberId, setMemberId] = useState<string>();
  const [gasstationId, setGasstationId] = useState("");
  const [gasStationCode, setGasStationCode] = useState<string>();

  const [phoneNumberIsValid, setPhoneNumberIsValid] = useState<boolean>(false);

  const generatedCodegasStationRef = db.collection(
    generatedCodeGasStationCollectionsName
  );

  const sankMoneyCreateGasStationFn = functions.httpsCallable(
    "sankMoneyCreateGasStationFn"
  );
  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );

  const sankmoneyCreateSankStaffFn = functions.httpsCallable(
    "sankmoneyCreateSankStaffFn"
  );
  const sankMoneyCreateMembershipFn = functions.httpsCallable(
    "sankMoneyCreateMembershipFn"
  );

  const onSubmitGasStation = async (data: any) => {

    const latitude = validateAndParseCoordinate(data.lat as string);
    const longitude = validateAndParseCoordinate(data.long as string);

    if (currentGasStation) {
      const documentData: any = {
        id: currentGasStation.id,
        phoneNumber: gasStationPhoneNumber && gasStationPhoneNumber,
        ...data,
      };

      if (latitude !== null && longitude !== null) {
        documentData.geoLocationLatitude = latitude;
        documentData.geoLocationLongitude = longitude;
      }

      const response = sankmoneyUpdateAccountFn({
        collectionName: collectionName,
        documentData: documentData,
        documentId: currentGasStation.id,
      })
        .then(() => {
          navigate("/gas-stations");
        })
        .catch((err: { details: any; message: any }) =>
          toast.error(err.details.message)
        );
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success:
          "Les informations de la station-service ont été mise à jour avec succès",
        loading: "chargement...",
      });
    } else {
      const gasStationData: any = {
        adresse: data.adresse,
        city: data.city,
        email: data.email,
        lat: data.lat,
        long: data.long,
        phoneNumber: gasStationPhoneNumber ?? "",
        displayName: data.displayName,
        userRole: UserRole.GASSTATION,
      };

      if (latitude !== null && longitude !== null) {
        gasStationData.geoLocationLatitude = latitude;
        gasStationData.geoLocationLongitude = longitude;
      }

      if (gasStationCode) {
        gasStationData.gasStationCode = gasStationCode;
        await generatedCodegasStationRef
          .doc(gasStationCode)
          .update({ isAffected: true });
      }
      const response = sankMoneyCreateGasStationFn({
        collectionName: collectionName,
        documentData: {
          ...gasStationData,
        },
      })
        .then((result) => {
          setGasstationId(result.data.id);
          setCurrentFormStep(steps[1]);
        })
        .catch((err: { details: any; message: any }) =>
          toast.error(err.details.message)
        );
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success: "Les informations de l'agence ont été ajouté avec succès",
        loading: "chargement...",
      });
    }
  };

  const retrivePhoneNumber = async (phoneNumber: any) => {
    if (phoneNumber) {
      setGasStationPhoneNumber(phoneNumber);
    }
  };

  const onSubmitMemberMinInfos = (data: any) => {
    data.confirmPassword && delete data.confirmPassword;
    const response = sankmoneyCreateSankStaffFn({
      collectionName: usersCollectionName,
      email: data.email,
      password: data.password,
      userRole: UserRole.GASSTATIONSTAFF,
    })
      .then(async (result) => {
        setMemberId(result.data.success.uid);
        setCurrentFormStep(steps[2]);
        if (result.data.success.uid) {
          const memberShipData = {
            gasStationId: currentGasStation ? currentGasStation.id : gasstationId,
            memberId: result.data.success.uid,
          };
          await sankMoneyCreateMembershipFn({
            collectionName: gasStationMembershipCollectionName,
            documentData: memberShipData,
          }).catch((err: { details: any; message: any }) =>
            toast.error(err.details.message)
          );
        }
      })
      .catch((err: { details: any; message: any }) =>
        toast.error(err.details.message)
      );
    toast.promise(response, {
      error: "Une erreur s'est produite. Veuillez réessayer !",
      success: "Les informations du membre ont été enregistrer avec succès",
      loading: "chargement...",
    });
  };

  const onSubmitDefaultInfos = (data: any) => {
    if (memberId) {
      data.phoneNumber = `+226${data.phoneNumber}`;
      const response = sankmoneyUpdateAccountFn({
        collectionName: usersCollectionName,
        documentData: {
          id: memberId,
          ...data,
          attendantRole: AttendantRole.MANAGER,
        },
        documentId: memberId,
      })
        .then((result) => {
          navigate("/gas-stations");
        })
        .catch((err: { details: any; message: any }) =>
          toast.error(err.details.message)
        );
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success: "Les informations du membre ont été enregistrées avec succès",
        loading: "chargement...",
      });
    }
  };

  const handleChange = async (e: any) => {
    const gasStationCode = e.target.value;
    if (gasStationCode.length === 5) {
      const generatedGasStationCodeSnap = await generatedCodegasStationRef
        .where("isAffected", "==", false)
        .where("code", "==", gasStationCode)
        .get();
      if (!generatedGasStationCodeSnap.empty) {
        toast.success(
          "Le code de la station-service que vous avez entré est correct"
        );
        return setGasStationCode(generatedGasStationCodeSnap.docs[0].id);
      } else {
        toast.error(
          "Le code de la station-service que vous avez entré est indisponible"
        );
      }
    }
  };

  useEffect(() => {
    currentGasStation && setCurrentFormStep(steps[0]);
    currentGasStation && isAffilated && setCurrentFormStep(steps[1]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      if (isValidPhoneNumber(gasStationPhoneNumber)) {
        const sankAccounts = await db
          .collection(usersCollectionName)
          .where("phoneNumber", "==", gasStationPhoneNumber)
          .get();

        if (sankAccounts.empty) {
          toast.success("Le numéro de téléphone est valide.");
          setPhoneNumberIsValid(true);
        } else {
          toast.error("Le numéro de téléphone renseigné est déja utilisé.");
          setPhoneNumberIsValid(false);
        }
      }
    })();
  }, [gasStationPhoneNumber]);

  return (
    <>
      {" "}
      <div className="lg:border-t lg:border-b lg:border-gray-200">
        <nav
          className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
          aria-label="Progress"
        >
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
          >
            <li className="relative px-2 py-4 overflow-hidden lg:py-2">
              <button
                className="p-1 border border-transparent rounded-full hover:border-gray-200 hover:bg-gray-50"
                onClick={() => navigate("/gas-stations")}
              >
                <XMarkIcon className="w-8 h-8 text-gray-700" />
              </button>
            </li>
            {steps.map((step, stepIdx) => (
              <li key={stepIdx} className="relative overflow-hidden lg:flex-1">
                <div
                  className={classNames(
                    stepIdx === 0 ? "border-b-0 rounded-t-md" : "",
                    stepIdx === steps.length - 1
                      ? "border-t-0 rounded-b-md"
                      : "",
                    "border border-gray-200 overflow-hidden lg:border-0 h-full"
                  )}
                >
                  {
                    //TODO remove comment on check current user
                  }
                  <button
                    className={classNames(
                      !currentGasStation ? "cursor-not-allowed" : "",
                      currentGasStation && step.index === 2 ? "" : "",
                      "w-full group h-full"
                    )}
                  >
                    <span
                      className={classNames(
                        step.index !== currentFormStep.index
                          ? "group-hover:bg-gray-200 bg-transparent "
                          : "bg-red-600",
                        "absolute top-0 left-0 w-1 h-full  lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "px-4 py-4 lg:py-2 flex items-start text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span
                          className={classNames(
                            step.index < currentFormStep.index
                              ? "bg-red-600"
                              : "",
                            step.index === currentFormStep.index
                              ? "bg-white border-2 border-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "bg-white border-2 border-gray-300"
                              : "",
                            "flex items-center justify-center w-10 h-10  rounded-full"
                          )}
                        >
                          <step.icon
                            className={classNames(
                              step.index < currentFormStep.index
                                ? "text-white"
                                : "",
                              step.index === currentFormStep.index
                                ? "text-red-600"
                                : "",
                              step.index > currentFormStep.index
                                ? "text-gray-500"
                                : "",
                              "w-6 h-6 "
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex-col flex  xl:flex">
                        <span
                          className={classNames(
                            step.index === currentFormStep.index
                              ? "text-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "text-gray-500"
                              : "",
                            "text-xs font-semibold tracking-wide uppercase md:block lg:hidden xl:block"
                          )}
                        >
                          {step.name}
                        </span>
                        <span className="flex items-start text-sm font-medium text-gray-500">
                          {step.description}
                        </span>
                      </span>
                    </span>
                  </button>
                  <div
                    className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="w-full h-full text-gray-300"
                      viewBox="0 0 12 82"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0.5 0V31L10.5 41L0.5 51V82"
                        stroke="currentcolor"
                        vectorEffect="non-scaling-stroke"
                      />
                    </svg>
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="items-center w-full mx-auto md:w-11/12 lg:w-9/12">
        {currentFormStep.id === "gasStationInfos" && (
          <Form<GasStationInfos>
            onSubmit={onSubmitGasStation}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  displayName: yup
                    .string()
                    .required("Le champ est obligatoire"),
                  email: yup
                    .string()
                    .email()
                    .required("Le champ est obligatoire"),
                  city: yup.string().optional(),
                  availableZone: yup.string().optional(),
                  lat: yup.string().required("Le champ est obligatoire"),
                  long: yup.string().required("Le champ est obligatoire"),
                  adresse: yup.string().required("Le champ est obligatoire"),
                  gasStationCode: yup
                    .string()
                    .required("Le champ est obligatoire"),
                })
              ),
              defaultValues: {
                adresse: currentGasStation?.adresse ?? "",
                email: currentGasStation?.email ?? "",
                city: currentGasStation?.city ?? "",
                lat: currentGasStation?.lat ?? "",
                long: currentGasStation?.long ?? "",
                displayName: currentGasStation?.displayName ?? "",
                phoneNumber: currentGasStation?.phoneNumber ?? "",
                gasStationCode: currentGasStation?.gasStationCode ?? "",
              },
            }}
            submitButtonLabel={`${currentGasStation ? "Editer la" : "Ajouter la"
              } station-service`}
          >
            <div className="flex flex-col content-center w-full px-5 mt-5 align-top md:flex-row">
              <div className="md:w-2/3">
                <FormInput
                  name="displayName"
                  label="Nom"
                  placeholder="Exemple: Total"
                />
                <PhoneInput
                  international={false}
                  placeholder="Entrer un numéro de téléphone"
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                  defaultCountry="BF"
                  value={
                    gasStationPhoneNumber || currentGasStation
                      ? gasStationPhoneNumber || currentGasStation?.phoneNumber
                      : ""
                  }
                  onChange={retrivePhoneNumber}
                />
                <FormInput
                  name="email"
                  label="E-mail"
                  placeholder="@exemple.com"
                />
                <FormInput
                  name="city"
                  label="Ma ville"
                  placeholder="Exemple: Ouagadougou"
                />
                <FormInput
                  name="adresse"
                  label="Adresse"
                  placeholder="Exemple: Avenue Kwame N'Kruma"
                />
                <FormInput
                  name="gasStationCode"
                  label="Saisir le code station service"
                  placeholder="Ex: 12345"
                  onChange={handleChange}
                  helpLabel={`${gasStationCode
                    ? ""
                    : "Veuillez entrer un code marchand qui est disponible"
                    }`}
                  maxLength={5}
                />
                <FormInput
                  name="lat"
                  label="Latitude"
                  placeholder="Exemple: 02.012563"
                />
                <FormInput
                  name="long"
                  label="Longitude"
                  placeholder="Exemple: 12.002035"
                />
                <FormSelect<string>
                  label={"Zone"}
                  name="availableZone"
                  selectedOption={"Choisir..."}
                  options={userAvailableZonesOptions}
                  optionLabel={(option) =>
                    (mapUserAvailableZone.get(option) as string) ?? "Choisir..."
                  }
                />
              </div>
            </div>
          </Form>
        )}
        {currentFormStep.id === "gasStationAttendantMinInfos" && (
          <Form<GasStationAttendantMinInfos>
            onSubmit={onSubmitMemberMinInfos}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  email: yup.string().required("Le champ est obligatoire"),
                  password: yup
                    .string()
                    .required("Le champ est obligatoire")
                    .min(6, "6 charactère minimum"),
                  confirmPassword: yup
                    .string()
                    .required("Le champ est obligatoire")
                    .oneOf(
                      [yup.ref("password")],
                      "Les mots de passe ne sont pas compatibles"
                    ),
                })
              ),
              defaultValues: {
                email: "",
                password: "",
                confirmPassword: "",
              },
            }}
            submitButtonLabel="Ajouter le manager"
          >
            <div className="flex flex-col content-center w-full px-5 mt-5">
              <div className="w-full mx-auto ">
                <FormInput
                  name="email"
                  label="E-mail"
                  placeholder="@exemple.com"
                />
                <FormInput
                  name="password"
                  label="Mot de passe"
                  type="password"
                  placeholder="Votre mot de passe en six (06) caractères"
                />
                <FormInput
                  type="password"
                  name="confirmPassword"
                  label="Confirmez votre mot de passe"
                  placeholder="Votre mot de passe"
                />
              </div>
            </div>
          </Form>
        )}
        {currentFormStep.id === "gasStationAttendantInfos" && (
          <Form<GasStationAttendantInfos>
            onSubmit={onSubmitDefaultInfos}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  displayName: yup
                    .string()
                    .required("Le champ est obligatoire"),
                  city: yup.string().optional(),
                  adresse: yup.string().required("Le champ est obligatoire"),
                  phoneNumber: yup
                    .string()
                    .required("Le champ est obligatoire"),
                  cnibNumber: yup.string().required("Le champ est obligatoire"),
                  gender: yup.string().required("Le champ est obligatoire"),
                })
              ),
            }}
            submitButtonLabel={"Enregistrer"}
          >
            <div className="flex flex-col content-center w-full px-5 mt-5">
              <FormInput
                name="displayName"
                label="Nom/Prénom"
                placeholder="John Doe"
              />
              <FormInput
                name="phoneNumber"
                label="Numéro de téléphone"
                placeholder="7X XX XX XX"
              />
              <FormInput
                name="city"
                label="Ville"
                optional
                placeholder="Ouagadougou"
              />
              <FormInput
                name="adresse"
                optional
                label="Adresse"
                placeholder="Rue 10.34, secteur 30, Ouaga2000"
              />
              <FormInput
                name="cnibNumber"
                label="Numéro de CNIB"
                placeholder="BXXXXXXX"
              />

              <FormSelect<string>
                label={"Genre"}
                name="gender"
                selectedOption={"Choisir..."}
                options={["FEMALE", "MALE", "NONBINARY", "UNKNOWN"]}
                optionLabel={(option) =>
                  (mapsGender.get(option) as string) ?? "Choisir..."
                }
              />
            </div>
          </Form>
        )}
      </div>
    </>
  );
};

export default EditGasStationPage;
