import { useState } from "react";
import { UseFormReturn } from "react-hook-form";
import Alert from "../../Alert";
import { Form } from "../../FormComponents";
import SankLogoTransparent from "../../../Sank_red.png";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { db, functions } from "../../../firebase";
import { toast } from "react-hot-toast";
import OtpInput from "react-otp-input";
import firebase from "firebase/compat/app";
import { useNavigate } from "react-router-dom";
import { UserRole } from "../../../interfaces";
import { usersCollectionName } from "../../../config";

interface FormFields {
  email: string;
  password: string;
}

const inputFieldStyle =
  "flex flex-col items-center text-black py-1 mx-1 md:py-1 w-10 h-10 justify-center text-lg text-center bg-white border border-gray-200 outline-none rounded-xl focus:bg-gray-50 focus:border-green focus:ring-1 ring-green-700";

const LoginWithOtpCode = () => {
  const [, setFormReturn] = useState<UseFormReturn<FormFields>>();
  const [error, setError] = useState<any>();
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isDisplayOtpForm, setIsDisplayOtpForm] = useState(false);
  const [otp, setOtp] = useState("");
  const [inputError, setInputError] = useState(false);
  const [btnDesable, setBtnDesable] = useState(false);
  const navigate = useNavigate();
  const [isLoad, setIsLoad] = useState(false);

  const sankMoneySendOtpCodeFn = functions.httpsCallable(
    "sankMoneySendOtpCodeFn"
  );
  const sankMoneyVerifyOtpCodeFn = functions.httpsCallable(
    "sankMoneyVerifyOtpCodeFn"
  );

  const userRef = db.collection(usersCollectionName);

  const retrivePhoneNumber = async (phoneNumber: any) => {
    if (phoneNumber) {
      setPhoneNumber(phoneNumber);
    }
  };

  const onSubmit = async () => {
    await sankMoneySendOtpCodeFn({
      phoneNumber: phoneNumber,
    })
      .then((result) => {
        if (result.data.result) {
          if (!result.data.active) {
            return toast.error(
              "Votre compte sur SankMoney a été temporairement bloqué. Merci de contacter le service client"
            );
          }
          setIsDisplayOtpForm(true);
        }
      })
      .catch((err: any) => toast.error(err));
  };

  const verifyOtpCode = async () => {
    setIsLoad(true);
    setBtnDesable(true);
    if (otp.length === 6) {
      sankMoneyVerifyOtpCodeFn({
        phoneNumber: phoneNumber,
        otpCode: otp,
      })
        .then(async (result) => {
          if (result.data.token) {
            await firebase
              .auth()
              .signInWithCustomToken(result.data.token)
              .then(async (result) => {
                setIsDisplayOtpForm(false);
                setInputError(false);
                const userSnap = await userRef.doc(result.user?.uid).get();
                if (userSnap.exists) {
                  redirectTo(userSnap.data()?.userRole);
                }
              });
          }
        })
        .catch((err: any) => {
          console.log(err);
          // setError(err);
          setIsLoad(false);
          setInputError(true);
          setBtnDesable(false);
        });
    }
  };

  const redirectTo = (userRole: string) => {
    switch (userRole) {
      case UserRole.SUPERMERCHANT:
        navigate("/superMerchants/home");
        break;
      case UserRole.SUPERSTATION:
        navigate("/superStation/home");
        break;
      default:
        navigate("/dashboard");
        break;
    }
  };

  return (
    <>
      <div className="m-0 font-sans text-base antialiased font-normal bg-white text-start leading-default text-slate-500">
        <main className="mt-0 transition-all duration-200 ease-soft-in-out">
          <div className="relative flex items-center justify-center p-0 mx-auto overflow-hidden bg-center bg-cover min-h-75-screen">
            <div className="z-10 mt-16 ">
              <div className="flex flex-wrap mt-0 -mx-3">
                <div className="flex flex-col w-full max-w-full py-10 mx-auto border rounded-md border-gradient-to-r from-pink-500 via-red-500 to-yellow-500 md:flex-0 shrink-0 md:w-96 ">
                  <div className="relative flex flex-col min-w-0 break-words bg-transparent border-0 shadow-none rounded-2xl bg-clip-border">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                      <div className="flex justify-center">
                        <img
                          className="w-auto h-28 md:h-32"
                          src={SankLogoTransparent}
                          alt="Sank"
                        />
                      </div>
                    </div>
                    {error && (
                      <Alert
                        type="danger"
                        title="Authentification à échouer !"
                        content={error}
                      />
                    )}
                    <div className="flex-auto px-6">
                      {!isDisplayOtpForm ? (
                        <Form<FormFields>
                          onSubmit={onSubmit}
                          formReturnAware={setFormReturn}
                          form={{}}
                          submitButtonFullWidth
                          submitButtonLabel="Se connecter"
                        >
                          <div className="space-y-4 text-black md:space-y-4">
                            <label
                              htmlFor="hs-inline-leading-pricing-select-label"
                              className="text-base text-left text-gray-500 dark:text-gray-400"
                            >
                              Vérifiez votre numéro de téléphone.
                            </label>
                            <PhoneInput
                              id="hs-inline-leading-pricing-select-label"
                              international={false}
                              placeholder="Entrer un numéro de téléphone"
                              className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md appearance-none form-input focus:border-emerald-500 focus:outline-none sm:text-sm"
                              defaultCountry="BF"
                              value={phoneNumber ? phoneNumber : ""}
                              onChange={retrivePhoneNumber}
                              style={{ border: "0" }}
                            />
                          </div>
                        </Form>
                      ) : (
                        <div className="relative flex flex-col justify-center overflow-hidden">
                          <div className="my-4">
                            <h2 className="text-3xl font-semibold text-center">
                              Vérification
                            </h2>
                            <div className="flex flex-row text-sm font-medium text-center text-gray-500 dark:text-gray-400">
                              {phoneNumber && (
                                <p>Entrez le code envoyé à {phoneNumber}</p>
                              )}
                            </div>
                          </div>
                          <div className="flex flex-col my-4 space-y-2">
                            <OtpInput
                              inputStyle="inputStyle"
                              value={otp}
                              onChange={setOtp}
                              numInputs={6}
                              shouldAutoFocus
                              renderInput={(props) => (
                                <input
                                  {...props}
                                  className={inputFieldStyle}
                                  style={{
                                    color: "black",
                                    borderColor: inputError
                                      ? "red"
                                      : "rgb(230, 230, 230)",
                                  }}
                                />
                              )}
                              containerStyle="flex flex-row items-center text-black justify-between w-full"
                            />
                          </div>
                          <div className="flex flex-col space-y-5">
                            <div>
                              <button
                                className="flex flex-row items-center justify-center w-full py-3 text-base font-semibold text-center text-white bg-red-600 border border-none shadow-sm outline-none rounded-xl"
                                onClick={() => verifyOtpCode()}
                                disabled={btnDesable}
                              >
                                {isLoad && (
                                  <svg
                                    className="w-5 h-5 mr-5 -ml-1 text-white animate-spin"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    ></circle>
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    ></path>
                                  </svg>
                                )}
                                Vérifier
                              </button>
                            </div>

                            {/* <div className="flex flex-row items-center justify-center space-x-1 text-sm font-medium text-center text-gray-500">
              <p>Didn't recieve code?</p>{" "}
              <a
                className="flex flex-row items-center text-green-600"
                href="http://"
                target="_blank"
                rel="noopener noreferrer"
              >
                Resend
              </a>
            </div> */}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      </div>
      <div className="justify-center px-5 sm:mx-auto sm:w-full sm:max-w-md">
        <span className="items-center text-sm text-left text-gray-500 dark:text-gray-400 sm:mx-auto sm:max-w-md">
          Vous êtes dans une agence ou une station-service{" "}
          <span
            className="text-blue-500 cursor-pointer"
            onClick={() => navigate("/login-with-email")}
          >
            Cliquez ici ?
          </span>{" "}
          pour vous connectez
        </span>
      </div>
    </>
  );
};

export default LoginWithOtpCode;
