import { classNames } from "../utils";

export type DisplayPriceProps = {
  price: number;
  money?: string;
  priceClasses?: string;
  moneyClasses?: string;
};

export const DisplayPrice = ({
  price,
  priceClasses,
  money = "Fcfa",
  moneyClasses,
}: DisplayPriceProps) => {
  function formatNumber(number: number): string {
    let numStr = number.toString();
  
    // Vérifier s'il y a un point décimal
    const decimalIndex = numStr.indexOf('.');
    let integerPart = numStr;
    let decimalPart = '';
  
    if (decimalIndex !== -1) {
      integerPart = numStr.substring(0, decimalIndex);
      decimalPart = numStr.substring(decimalIndex + 1);
    }
    
     // Formater la partie entière avec des séparateurs de milliers
    const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  
    if (decimalPart !== '') {
      return formattedIntegerPart;
    }
  
    return `${formattedIntegerPart}`;
  }
  
  return (
    <div className="flex items-baseline">
      <p
        className={classNames(
          priceClasses ?? "",
          "text-xl font-semibold text-gray-900"
        )}
      >
        {formatNumber(price)}
      </p>
      <p
        className={classNames(
          moneyClasses ?? "",
          "flex items-baseline ml-2 text-sm font-semibold"
        )}
      >
        {money}
      </p>
    </div>
  );
};
