import React, { useEffect, useState } from "react";
import DisplayItemDetails from "../../../../components/DisplayItemComponent";
import { useLocation } from "react-router-dom";
import { db } from "../../../../firebase";
import { membershipCollectionName, usersCollectionName } from "../../../../config";

const AgencyDetails = () => {
  const location = useLocation();
  const currentUser = location.state?.entity;
  const [agencyStaffData, setAgencyStaffData] = useState<any[]>([])

  const retriveAgencyStaff = async () => {
    const staffData = [];
    const memberships = await db
      .collection(membershipCollectionName)
      .orderBy("createdAt", "desc")
      .where("agencyId", "==", currentUser?.id)
      .get();
    if (!memberships.empty) {
      for (const membershipSnapshot of memberships.docs) {
        const membershipData = membershipSnapshot.data();
        const staffSnapshot = await db
          .collection(usersCollectionName)
          .doc(membershipData?.memberId)
          .get();
        staffData.push(staffSnapshot.data());
      }
    }
    return setAgencyStaffData(staffData);
  };
  useEffect(() => {
    currentUser && retriveAgencyStaff();
  }, [])
  
  return <DisplayItemDetails currentUser={currentUser} members={agencyStaffData}/>;
};

export default AgencyDetails;
