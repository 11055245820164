import {
  UserIcon,
  CheckCircleIcon,
  XCircleIcon,
} from "@heroicons/react/24/outline";
import { useCallback, useContext, useEffect, useState } from "react";
import Moment from "react-moment";
import { useNavigate } from "react-router-dom";
import FirestoreTable, {
  IColumn,
  RowRenderFunction,
  IRow,
} from "../../../components/FirestoreTable";
import {
  companiesMembershipCollectionName,
  usersCollectionName,
} from "../../../config";
import { db } from "../../../firebase";
import { CompanyAttendantRole, UserRole } from "../../../interfaces";
import { UserContext } from "../../../contexts";
import { CompanyCollabRoleMap } from "../../../interfaces/Company";

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: "Date de création",
  },
  {
    field: "profilImageUrl",
    label: "Profil",
  },
  {
    field: "displayName",
    label: "Nom/prénom",
  },
  {
    field: "email",
    label: "Email",
  },
  {
    field: "phoneNumber",
    label: "Téléphone",
  },
  {
    field: "city",
    label: "Ville",
  },
  {
    field: "companyAttendantRole",
    label: "Role",
  },
  {
    field: "active",
    label: "Active",
  },
];

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "displayName":
      return <span>{value}</span>;
    case "profilImageUrl":
      return (
        <span>
          {" "}
          {value ? (
            <img
              className="w-10 h-10 p-1 border rounded-full"
              src={
                value
                  ? value
                  : `https://ui-avatars.com/api/?background=random&name=${
                      value || "S"
                    }`
              }
              alt=""
            />
          ) : (
            <UserIcon className="w-10 h-10 p-1 border rounded-full" />
          )}
        </span>
      );
    case "email":
      return <span>{value}</span>;
    case "city":
      return <span>{value}</span>;
    case "phoneNumber":
      return <span>{value}</span>;
    case "companyAttendantRole":
      return <span>{CompanyCollabRoleMap.get(value)}</span>;
    case "active":
      return (
        <span>
          {" "}
          {value ? (
            <CheckCircleIcon className="w-6 h-6 text-green-500" />
          ) : (
            <XCircleIcon className="w-6 h-6 text-red-500" />
          )}
        </span>
      );
    default:
      return value as string;
  }
};
const CompanyAllStaffListPage = () => {
  const [companyMembersIds, setCompanyMembersIds] = useState<any[] | null>(
    null
  );

  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const onEditRow = (id: number, entity: any) => {
    if (entity) {
      navigate(`/companies/sank_staff/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onRowDetails = (id: number, entity: any) => {
    if (entity) {
      navigate(`/companies/sank_staff/${entity.id}/details`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };

  const getCompanyMembersIds = useCallback(async () => {
    const companyMembershipRef = db.collection(
      companiesMembershipCollectionName
    );

    if (user?.userRole === UserRole.COMPANYSTAFF) {
      const companyId = await companyMembershipRef
        .where("memberId", "==", user.id)
        .get()
        .then((snapShot) => snapShot.docs[0].data().companyId);

      const companyMemberIds = await companyMembershipRef
        .where("companyId", "==", companyId)
        .get()
        .then((snapShot) => {
          return snapShot.docs.map((doc) => doc.data().memberId);
        });

      setCompanyMembersIds(companyMemberIds);
    }
  }, [user?.id, user?.userRole]);

  useEffect(() => {
    getCompanyMembersIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 mt-10 sm:px-6 lg:px-8">
      <div className="sm:flex sm:items-center">
        <div className="sm:flex-auto">
          <h1 className="py-2 text-xl font-semibold text-gray-900">
            Le personnel de l'entreprise
          </h1>
          <p className="mt-2 text-sm text-gray-700">
            Vous trouverez ci-dessous tous le personnel de l'entreprise.
          </p>
        </div>
        {user?.companyAttendantRole === CompanyAttendantRole.MANAGER && (
          <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
            <button
              type="button"
              className="relative inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 "
              onClick={() => {
                navigate("/companies/sank_staff/new");
              }}
            >
              <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                Ajouter un membre
              </span>
            </button>
          </div>
        )}
      </div>
      <FirestoreTable
        collectionRef={db.collection(usersCollectionName)}
        collectionQueriesWhere={
          companyMembersIds
            ? [
                {
                  fieldPath: "userRole",
                  opStr: "==",
                  value: UserRole.COMPANYSTAFF,
                },
                {
                  fieldPath: "id",
                  opStr: "in",
                  value: companyMembersIds,
                },
              ]
            : [
                // this query used for retrieve none values
                {
                  fieldPath: "userRole",
                  opStr: "==",
                  value: "NULL",
                },
              ]
        }
        condensed
        columns={columns}
        rowRender={renderRow}
        rowEditHandler={onEditRow}
        rowDetailsHandler={onRowDetails}
        displayDetails
        shouldAddEditColumn
        displayEditBtn
        paginate={{ pageIndex: 0, limit: 10, sort: "createdAt:desc" }}
      />
    </div>
  );
};

export default CompanyAllStaffListPage;
