import { useContext, useEffect, useMemo, useState } from "react";
import Moment from "react-moment";
import {
  cardsCollectionName,
  userCardRelationCollection,
} from "../../../config";
import { db } from "../../../firebase";
import Pagination from "../../../components/Pagination/Pagination";
import {
  MagnifyingGlassIcon,
  PlusIcon,
  ListBulletIcon,
  Squares2X2Icon,
  BookOpenIcon,
  CubeTransparentIcon,
  CheckCircleIcon,
  XCircleIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { classNames } from "../../../utils";
import { UserContext } from "../../../contexts";
import { CardType, UserRole } from "../../../interfaces";
import CardImage from "../../../340_x_200_1.png";
import { QRCodeCanvas } from "qrcode.react";
import imageSrc from "../../../sankQrCodeImg.png";
import FirestoreTable, {
  RowRenderFunction,
  IRow,
  IColumn,
} from "../../../components/FirestoreTable";
import HasRoles from "../../../components/HasRoles";
import Modal from "../../../components/Modal";
import NoContent from "../../../components/TableNoContentPage/NoContent";
import toast from "react-hot-toast";

const renderRow: RowRenderFunction = (
  row: IRow,
  column: IColumn,
  value: any
) => {
  switch (column.field) {
    case "createdAt":
      if (!value) return "--";
      return (
        <Moment locale="fr" format="dddd DD MMMM YYYY à HH:mm:ss">
          {value.toDate() as Date}
        </Moment>
      );
    case "name":
      return <span className="select-all">{value ?? "-"}</span>;
    case "id":
      return <span className="select-all">{value as string}</span>;
    case "active":
      return (
        <span>
          {" "}
          {value ? (
            <CheckCircleIcon className="w-6 h-6 text-green-500" />
          ) : (
            <XCircleIcon className="w-6 h-6 text-red-500" />
          )}
        </span>
      );
    case "isAffected":
      return (
        <span>
          {" "}
          {value ? (
            <CheckCircleIcon className="w-6 h-6 text-green-500" />
          ) : (
            <XCircleIcon className="w-6 h-6 text-red-500" />
          )}
        </span>
      );
    default:
      return value as string;
  }
};

const columns: IColumn[] = [
  {
    field: "createdAt",
    label: `Date de création`,
  },
  {
    field: "name",
    label: `Nom de la carte`,
  },
  {
    field: "id",
    label: `Numéro de la carte`,
  },
  {
    field: "active",
    label: `Active`,
  },
  {
    field: "isAffected",
    label: `Attribuer`,
  },
];

enum SelectedType {
  ALL = "ALL",
  AFFECTED = "AFFECTED",
  NOTAFFECTED = "NOTAFFECTED",
}

const DebitCard = () => {
  const [isTableView, setIsTableView] = useState(false);
  const { user } = useContext(UserContext);
  const [cards, setCards] = useState<any[]>([]);
  const [showModal, setShowModal] = useState(false);
  const [card, setCard] = useState<any[]>([]);
  const navigate = useNavigate();
  const [cardId, setCardId] = useState("");
  const [searchedValue, setSearchedValue] = useState("");
  const [cardLimit, setCardLimit] = useState(25);
  const [selectedVal, setSelectedVal] = useState<SelectedType>(
    SelectedType.ALL
  );

  const imageOptions = {
    src: imageSrc,
    x: undefined,
    y: undefined,
    height: 30,
    width: 30,
    excavate: true,
  };

  const adminRetriveDebitCardsData = async () => {
    db.collection(cardsCollectionName)
      .limit(cardLimit)
      .get()
      .then((response) => {
        setCards(
          response.docs.map((doc) => {
            return { id: doc.id, data: doc.data() };
          })
        );
      });
  };
  const retriveDebitCardsDataForUser = async () => {
    db.collection(userCardRelationCollection)
      .orderBy("createdAt", "desc")
      .where("userId", "==", user?.id)
      .get()
      .then(async (response) => {
        const cards = [];
        for (const cardRelationship of response.docs) {
          const data = await db
            .collection(cardsCollectionName)
            .doc(cardRelationship?.data()?.cardId)
            .get();
          cards.push({ id: data.id, data: data.data() });
        }
        setCards(cards);
      });
  };

  const onPageLimitChange = async (pageLimit: number) => {
    if (user && user?.userRole === UserRole.USER) {
      db.collection(userCardRelationCollection)
        .orderBy("createdAt", "desc")
        .where("userId", "==", user?.id)
        .get()
        .then(async (response) => {
          const cards = [];
          for (let i = 0; i < pageLimit; i++) {
            const data = await db
              .collection(cardsCollectionName)
              .doc(response.docs[i]?.data()?.cardId)
              .get();
            cards.push({ id: data.id, data: data.data() });
          }
          setCards(cards);
        });
    } else {
      db.collection(cardsCollectionName)
        .limit(pageLimit)
        .get()
        .then((response) => {
          setCards(
            response.docs.map((doc) => {
              return { id: doc.id, data: doc.data() };
            })
          );
        });
    }
  };
  const onRefresh = async () => {
    setSearchedValue("");
    if (user && user?.userRole === UserRole.USER) {
      db.collection(userCardRelationCollection)
        .orderBy("createdAt", "desc")
        .where("userId", "==", user?.id)
        .get()
        .then(async (response) => {
          const cards = [];
          for (const cardRelationship of response.docs) {
            const data = await db
              .collection(cardsCollectionName)
              .doc(cardRelationship?.data()?.cardId)
              .get();
            cards.push({ id: data.id, data: data.data() });
          }
          setCards(cards);
        });
    } else {
      db.collection(cardsCollectionName)
        .limit(cardLimit)
        .get()
        .then((response) => {
          setCards(
            response.docs.map((doc) => {
              return { id: doc.id, data: doc.data() };
            })
          );
        });
    }
  };

  const retriveData = (userRole: string) => {
    switch (userRole) {
      case UserRole.USER:
        retriveDebitCardsDataForUser();
        break;
      default:
        adminRetriveDebitCardsData();
        break;
    }
  };

  const retriveCardData = (cardId: string) => {
    db.collection(cardsCollectionName)
      .where("id", "==", cardId)
      .where("isAffected", "==", false)
      .get()
      .then((response) => {
        if (response.empty) {
          toast.error("La carte est indisponible ou déjà affecté.");
        } else {
          setCard(
            response.docs.map((doc) => {
              return { id: doc.id, data: doc.data() };
            })
          );
        }
      });
  };
  const handleChange = (value: string) => {
    setCardId(value);
  };

  const handleSearchCard = async (val: string) => {
    setSearchedValue(val);
    if (val.length > 0) {
      if (user?.userRole === UserRole.USER) {
        const cardsSnap = await db
          .collection(userCardRelationCollection)
          .orderBy("createdAt", "desc")
          .where("userId", "==", user?.id)
          .get();
        const cards = [];
        for (const cardRelationship of cardsSnap.docs) {
          const data = await db
            .collection(cardsCollectionName)
            .doc(cardRelationship?.data()?.cardId)
            .get();
          cards.push({ id: data.id, data: data.data() });
        }
        const filteredCards = cards.filter((value) =>
          (value.id as string).includes(val)
        );
        setCards(filteredCards.slice(0, cardLimit));
      } else {
        const res = await db.collection(cardsCollectionName).get();
        const data = res.docs.map((doc) => {
          return { id: doc.id, data: doc.data() };
        });
        const filteredCards = data.filter((value) =>
          (value.id as string).includes(val)
        );
        setCards(filteredCards.slice(0, cardLimit));
      }
    } else {
      if (user) {
        retriveData(user?.userRole);
      }
    }
  };

  const handleFilteredWithSelect = async (selected: string) => {
    switch (selected) {
      case SelectedType.AFFECTED:
        await db
          .collection(cardsCollectionName)
          .where("isAffected", "==", true)
          .limit(cardLimit)
          .get()
          .then((response) => {
            setCards(
              response.docs.map((doc) => {
                return { id: doc.id, data: doc.data() };
              })
            );
          });
        break;
      case SelectedType.NOTAFFECTED:
        await db
          .collection(cardsCollectionName)
          .where("isAffected", "==", false)
          .limit(cardLimit)
          .get()
          .then((response) => {
            setCards(
              response.docs.map((doc) => {
                return { id: doc.id, data: doc.data() };
              })
            );
          });
        break;
      default:
        await db
          .collection(cardsCollectionName)
          .limit(cardLimit)
          .get()
          .then((response) => {
            setCards(
              response.docs.map((doc) => {
                return { id: doc.id, data: doc.data() };
              })
            );
          });
        break;
    }
  };

  const onCardDetails = (card: any) => {
    if (card) {
      navigate(`/cards/${card?.id}/deatils`, {
        state: { entity: card.data },
      });
    }
  };

  const onDisplayTransactionCard = (card: any) => {
    if (card) {
      navigate(`/cards/${card?.id}/histories`, {
        state: { entity: card.data },
      });
    }
  };

  const firestoreTableQueriesWhere = useMemo(() => {
    return {
      fieldPath: "isAffected",
      opStr: "==",
      value:
        selectedVal === SelectedType.AFFECTED
          ? true
          : selectedVal === SelectedType.NOTAFFECTED
          ? false
          : null,
    };
  }, [selectedVal]);

  useEffect(() => {
    if (user) {
      retriveData(user?.userRole);
    }
  }, []);

  return (
    <>
      <div className="flex flex-col max-w-5xl 2xl:max-w-7xl">
        <div className="flex flex-col">
          <div className="py-2 border-b border-gray-200 dark:border-slate-700">
            <h3 className="text-lg font-semibold leading-6 text-gray-900 dark:text-gray-300">
              La liste des cartes
            </h3>
            <p className="mt-1 text-base text-gray-500">
              {`Gérez ${
                user?.userRole === UserRole.ADMIN ? "les" : "vos"
              } cartes depuis cette page. Vous pouvez sélectionner une carte pour voir ses informations.`}
            </p>
          </div>
          <div className="flex items-start justify-between grid-cols-1 my-3 max-md:flex-wrap lg:grid-cols-4 gap-x-5 ">
            <div className="flex self-stretch flex-1 h-10 col-span-1 border border-b border-gray-200 rounded-lg lg:col-span-2 gap-x-4 lg:gap-x-6 bg-gray-50 dark:bg-slate-800 dark:border-slate-700">
              <form className="relative flex flex-1" action="#" method="GET">
                <label htmlFor="search-field" className="sr-only">
                  Search
                </label>
                <MagnifyingGlassIcon
                  className="absolute inset-y-0 w-5 h-full text-gray-400 pointer-events-none left-2"
                  aria-hidden="true"
                />
                <input
                  id="search-field"
                  className="block w-full h-full py-0 pl-10 pr-2 text-gray-900 border-0 rounded-lg placeholder:text-gray-400 bg-gray-50 dark:bg-slate-800 focus:ring-0 sm:text-base"
                  type="text"
                  name="search"
                  value={searchedValue}
                  disabled={isTableView}
                  onChange={(e) => handleSearchCard(e.target.value)}
                />
              </form>
            </div>
            <HasRoles userRole={["ADMIN", "AGENCYSTAFF"]}>
              <div className="hidden col-span-1 my-2 space-x-2 md:flex md:my-0 lg:my-0">
                <select
                  id="countries"
                  className="block w-40 p-2 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  onChange={(e) => {
                    !isTableView
                      ? handleFilteredWithSelect(e.target.value)
                      : setSelectedVal(e.target.value as any);
                  }}
                >
                  <option selected value={SelectedType.ALL}>
                    Tout
                  </option>
                  <option value={SelectedType.AFFECTED}>Attribués</option>
                  <option value={SelectedType.NOTAFFECTED}>
                    Pas attribués
                  </option>
                </select>
              </div>
            </HasRoles>

            <div className="flex items-center justify-end col-span-1 my-2 space-x-2 md:my-0 lg:my-0">
              <button className="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold text-green-700 transition-all duration-200 bg-white border border-green-700 rounded-md shadow-sm gap-x-2 dark:bg-slate-800 hover:border-green-900 hover:text-green-900 dark:hover:text-green-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-green-600">
                <div onClick={() => onRefresh()} className="flex gap-1">
                  <ArrowPathIcon className="h-5" /> Refresh
                </div>
              </button>
              <HasRoles userRole={["ADMIN", "AGENCYSTAFF"]}>
                <button className="inline-flex items-center justify-center px-4 py-2 text-sm font-semibold text-red-700 transition-all duration-200 bg-white border border-red-700 rounded-md shadow-sm gap-x-2 dark:bg-slate-800 hover:border-red-900 hover:text-red-900 dark:hover:text-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-600">
                  <div
                    onClick={() => {
                      setShowModal(true);
                      setCard([]);
                    }}
                    className="flex gap-1"
                  >
                    <PlusIcon className="h-5" /> Attribuer
                  </div>
                </button>
              </HasRoles>
              <HasRoles userRole={["ADMIN", "AGENCYSTAFF"]}>
                <span className="inline-flex rounded-md shadow-sm isolate">
                  <button
                    type="button"
                    onClick={() => setIsTableView(true)}
                    className={classNames(
                      isTableView
                        ? "bg-red-100 dark:bg-red-900 border-red-500 border"
                        : "bg-white dark:bg-slate-800",
                      "relative inline-flex items-center rounded-l-md  px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-slate-700 hover:bg-gray-50 focus:z-10"
                    )}
                  >
                    <ListBulletIcon className="w-5 h-5" />
                  </button>
                  <button
                    type="button"
                    onClick={() => setIsTableView(false)}
                    className={classNames(
                      !isTableView
                        ? "bg-red-100 dark:bg-red-900 border-red-500 border"
                        : "bg-white dark:bg-slate-800",
                      "relative -ml-px inline-flex items-center rounded-r-md  px-3 py-2 text-sm font-semibold text-gray-900 dark:text-gray-400 ring-1 ring-inset ring-gray-300 dark:ring-slate-700 hover:bg-gray-50 focus:z-10"
                    )}
                  >
                    <Squares2X2Icon className="w-5 h-5" />
                  </button>
                </span>
              </HasRoles>
            </div>
          </div>
          {!isTableView && (
            <>
              {cards && cards.length > 0 ? (
                <>
                  <ul className="grid grid-cols-1 gap-6 my-4 sm:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4">
                    {cards.map((card, eventIdx) => {
                      return (
                        <li
                          key={eventIdx}
                          className="col-span-1 w-[290px] bg-white border divide-y divide-gray-200 rounded-lg shadow-md dark:divide-slate-700 dark:bg-slate-800 dark:border-slate-700"
                        >
                          <div
                            className="relative flex items-center justify-between w-full px-6 py-4 space-x-6 bg-cover"
                            style={{
                              backgroundImage: `url(${CardImage})`,
                              width: "290px",
                              height: "150px",
                            }}
                          >
                            <div className="absolute top-0 right-0 m-2 text-sm font-semibold text-white">
                              SankMoney
                            </div>
                            <div className="absolute bottom-0 m-2 text-sm font-semibold text-white right-2">
                              {card?.id}
                            </div>
                            <div className="absolute top-8 right-3">
                              <QRCodeCanvas
                                className="w-full"
                                id="qrCode"
                                value={`${card?.id}`}
                                size={90}
                                level={"L"}
                                includeMargin={false}
                                bgColor="red"
                                fgColor="white"
                                imageSettings={imageOptions}
                              />
                            </div>
                          </div>
                          <div>
                            <div className="flex -mt-px divide-x divide-gray-200 dark:divide-slate-700">
                              <div className="flex flex-1 w-0">
                                <div
                                  onClick={() => onCardDetails(card)}
                                  className="relative inline-flex items-center justify-center flex-1 w-0 py-2 -mr-px text-sm font-semibold text-gray-900 border border-transparent rounded-bl-lg cursor-pointer gap-x-2 dark:text-gray-300"
                                >
                                  <button
                                    disabled={!card.data?.isAffected}
                                    className="relative inline-flex items-center justify-center flex-1 w-0 py-2 text-sm font-semibold text-gray-900 border border-transparent rounded-br-lg gap-x-2 dark:text-gray-300"
                                  >
                                    <BookOpenIcon
                                      className={classNames(
                                        !card.data?.isAffected
                                          ? "text-gray-400"
                                          : "text-red-400",
                                        "w-5 h-5 "
                                      )}
                                      aria-hidden="true"
                                    />
                                    Details
                                  </button>
                                </div>
                              </div>
                              <HasRoles
                                userRole={["ADMIN", "USER", "AGENCYSTAFF"]}
                              >
                                <div
                                  onClick={() => onDisplayTransactionCard(card)}
                                  className="flex flex-1 w-0 -ml-px cursor-pointer"
                                >
                                  <div className="relative inline-flex items-center justify-center flex-1 w-0 py-2 text-sm font-semibold text-gray-900 border border-transparent rounded-br-lg gap-x-2 dark:text-gray-300">
                                    <button
                                      disabled={!card.data?.isAffected}
                                      className="relative inline-flex items-center justify-center flex-1 w-0 py-2 text-sm font-semibold text-gray-900 border border-transparent rounded-br-lg gap-x-2 dark:text-gray-300"
                                    >
                                      <CubeTransparentIcon
                                        className={classNames(
                                          !card.data?.isAffected
                                            ? "text-gray-400"
                                            : "text-red-400",
                                          "w-5 h-5 "
                                        )}
                                        aria-hidden="true"
                                      />
                                      Historique
                                    </button>
                                  </div>
                                </div>
                              </HasRoles>
                              <HasRoles userRole={["ADMIN", "AGENCYSTAFF"]}>
                                <div
                                  onClick={() => {
                                    setShowModal(true);
                                    setCardId(card.id);
                                    retriveCardData(card.id);
                                  }}
                                  className="flex flex-1 w-0 -ml-px cursor-pointer"
                                >
                                  <button
                                    disabled={card.data?.isAffected}
                                    className="relative inline-flex items-center justify-center flex-1 w-0 py-2 text-sm font-semibold text-gray-900 border border-transparent rounded-br-lg gap-x-2 dark:text-red-300 focus:ring-1 focus:outline-none focus:ring-red-200"
                                  >
                                    <PlusIcon
                                      className={classNames(
                                        card.data?.isAffected
                                          ? "text-gray-400"
                                          : "text-red-400",
                                        "w-5 h-5 "
                                      )}
                                      aria-hidden="true"
                                    />
                                    Attribuer
                                  </button>
                                </div>
                              </HasRoles>
                            </div>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <Pagination
                    skip={cards.length}
                    take={cards.length}
                    total={cards.length}
                    collectionName={cardsCollectionName}
                    // onPageChange={(pageIndex: number) => pageIndex * page}
                    onPageLimitChange={(pageLimit: number) => {
                      onPageLimitChange(pageLimit);
                      setCardLimit(pageLimit);
                    }}
                  />
                </>
              ) : (
                <NoContent />
              )}
            </>
          )}
          {isTableView && (
            <FirestoreTable
              collectionRef={db.collection(cardsCollectionName)}
              condensed
              collectionQueriesWhere={[firestoreTableQueriesWhere]}
              columns={columns}
              rowRender={renderRow}
              paginate={{ pageIndex: 0, limit: 100 }}
            />
          )}
        </div>
      </div>
      <Modal
        isOpen={showModal}
        maxSize="md"
        closeModal={() => {
          setShowModal(false);
          setCardId("");
          setCard([]);
        }}
        modalTitle="Renseigner le numéro de la carte"
      >
        <div className="flex justify-center">
          <div className="w-full mt-10">
            <div className="flex space-x-4">
              <input
                type="text"
                name="card"
                id="card"
                placeholder="3518012345678"
                autoComplete="off"
                value={cardId}
                autoFocus={true}
                onChange={(e) => handleChange(e.target.value)}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
              />
              <button
                type="button"
                className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                onClick={() => {
                  retriveCardData(cardId);
                }}
              >
                <MagnifyingGlassIcon
                  className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                  aria-hidden="true"
                />
                Rechercher
              </button>
            </div>

            <div className="flex justify-center w-full">
              {card && card.length > 0 && (
                <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex flex-col items-center pb-10 mt-2">
                    <div
                      className="relative flex items-center justify-between w-full sm:w-[240px] sm:h-[150px] px-6 py-4 space-x-6 bg-cover rounded-md"
                      style={{
                        backgroundImage: `url(${CardImage})`,
                        width: "340px",
                        height: "200px",
                      }}
                    >
                      <div className="absolute top-0 left-0 m-2 text-sm font-semibold text-white">
                        {card[0].id}
                      </div>
                      <div className="absolute top-10 right-3">
                        <QRCodeCanvas
                          className="w-full"
                          id="qrCode"
                          value={`${card[0].id}`}
                          size={100}
                          level={"L"}
                          includeMargin={false}
                          bgColor="red"
                          fgColor="white"
                          imageSettings={imageOptions}
                        />
                      </div>
                    </div>
                    <div className="flex mt-4 space-x-3 md:mt-6">
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() =>
                          navigate(`/cards/${card[0].data.id}/assign-card`, {
                            state: {
                              entity: card[0].data,
                              id: card[0].id,
                              isCardAffected: true,
                            },
                          })
                        }
                      >
                        Continuer
                      </div>
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg cursor-pointer hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                        onClick={() => {
                          setShowModal(false);
                          setCard([]);
                          setCardId("");
                        }}
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default DebitCard;
