export interface GasStationOrderInterface {
  id?: string;
  amount: string;
  description: string;
  orderStatus: OrderStatus;
  createdAt: Date;
  updatedAt: Date;
}

export enum OrderStatus {
  PENDING = "PENDING",
  CANCELED = "CANCELED",
  VALID = "VALID",
}