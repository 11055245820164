import { useCallback, useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import CodePinComponent from "../../../components/CodePinComponent";
import Modal from "../../../components/Modal";
import { UserContext } from "../../../contexts";
import { AccountType } from "../../../interfaces";
import {
  amountFormater,
  classNames,
  isNumeric,
  isValidPhoneNumber,
} from "../../../utils";
import {
  accountsCollectionName,
  companyCollectionName,
  companyContactCollectionsName,
  defaultAmount,
  feesConfigId,
  globalConfigCollectionsName,
  numberOfCompanyBulkPaiementFileData,
} from "../../../config";
import { db, functions, storage } from "../../../firebase";
import toast from "react-hot-toast";
import { Form, FormInput, FormRadio } from "../../../components/FormComponents";
import Papa from "papaparse";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import moment from "moment";
import { format } from "date-fns";
import { useConfirm } from "../../../hooks/useConfirm";
import { read, utils, write, writeFileXLSX } from "xlsx";
import { CheckIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { CsvModel } from "../../../components/ExportCsv";
import { Controller, UseFormReturn } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import DataTable, { TableColumn } from "react-data-table-component";
import Alert from "../../../components/Alert";
import FormMultiInputSelect from "../../../components/FormComponents/FormMultiInputSelect";
import { Timestamp } from "firebase-admin/firestore";

export enum PaymentFormType {
  BYFORMPAYMENT = "BYFORMPAYMENT",
  BYCSVPAYMENT = "BYCSVPAYMENT",
}

interface FormFields {
  contactFile: any;
}
interface ContactFormFields {
  destphoneNumbers: any;
}

const excelFileHeaders = [
  { label: "Nom", key: "name" },
  { label: "Numero", key: "number" },
  { label: "Montant", key: "amount" },
];

const csvModelData = [
  {
    Nom: "Abdoul B",
    Numero: "+22670180847",
    Montant: "100",
  },
  {
    Nom: "Dicko Docko",
    Numero: "+22670081874",
    Montant: "100",
  },
  {
    Nom: "Omar Diallo",
    Numero: "+22655992656",
    Montant: "100",
  },
  {
    Nom: "Omar Yero",
    Numero: "+22655569926",
    Montant: "100",
  },
  {
    Nom: "Simo Kampiti",
    Numero: "+22655992856",
    Montant: "100",
  },
  {
    Nom: "Simo Kampitid",
    Numero: "+22670707070",
    Montant: "500",
  },
  {
    Nom: "TESt",
    Numero: "+22670180879",
    Montant: "500",
  },
];

const steps = [
  {
    step: 1,
    title: "Uploader un fichier de contact",
  },
  {
    step: 2,
    title: "Vérifier et ajuster les contacts",
  },
  {
    step: 3,
    title: "Voir le résumé et envoyer",
  },
];

interface DataRow {
  number: string;
  name: string;
  amount: string;
}

const columns: TableColumn<DataRow>[] = [
  {
    name: "Nom/Prénoms",
    selector: (row) => row.name,
  },
  {
    name: "Téléphone",
    selector: (row) => row.number,
  },
  {
    name: "Montant",
    selector: (row) => `${amountFormater(parseFloat(row.amount))} Fcfa`,
  },
];

const CompanyBulkPaiement = () => {
  const { user, company, userAccounts, setUserAccounts } =
    useContext(UserContext);
  const [description, setDescription] = useState("");
  const location = useLocation();
  const contacts = location.state?.contacts;
  const currentPaymentFormType = location.state?.paymentFormType;
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [campaignLabel, setCampaignLabel] = useState<string>("");
  const [amount, setAmount] = useState<string>("0");
  const [accountType, setAccountType] = useState(AccountType.MAIN);
  const [isLoad, setIsLoad] = useState(false);
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const [fees, setFees] = useState<string>();
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(1);
  const [validateStep, setValidateStep] = useState<string[]>([""]);
  const [cSVformReturn, setCSVFormReturn] =
    useState<UseFormReturn<FormFields>>();
  const [formReturn, setFormReturn] =
    useState<UseFormReturn<ContactFormFields>>();
  const [contactFrom, setContactFom] = useState<PaymentFormType>(
    PaymentFormType.BYFORMPAYMENT
  );
  const [contactsFieldLength, setContactsFieldLength] = useState<number>(
    contacts ? contacts.length : 0
  );
  const [formInputsData, setformInputsData] = useState<any>();
  const [companyContactList, setCompanyContactList] = useState<any[]>([]);

  const [simularNumber, setSimularNumber] = useState<any[]>([]);
  const [notDefineNumber, setNotDefineNumber] = useState<any[]>([]);
  const [notFormatedNumber, setNotFormatedNumber] = useState<any[]>([]);
  const [notDefineAmount, setNotDefineAmount] = useState<any[]>([]);
  const [notFormatedAmount, setNotFormatedAmount] = useState<any[]>([]);

  const [fileName, setFileName] = useState("");
  const [fileData, setFileData] = useState<any[]>([]);
  const [filter, setFilter] = useState<any[]>([]);
  const [XLSXTotalAmount, setXLSXTotalAmount] = useState(0);
  const { isConfirmModal } = useConfirm();

  const accountsRef = db
    .collection(companyCollectionName)
    .doc(company?.id)
    .collection(accountsCollectionName);

  const handleOptionChange = (event: any) => {
    setContactFom(event.target.value);
  };

  const retriveFeesData = async () => {
    const globalConfigsRef = db.collection(globalConfigCollectionsName);
    const feeGlobalConfigsRef = globalConfigsRef.doc(feesConfigId);
    const feeGlobalConfigsSnapshot = await feeGlobalConfigsRef.get();
    if (feeGlobalConfigsSnapshot.exists) {
      const feeGlobalConfigs = feeGlobalConfigsSnapshot.data();
      const bulkPaymentFees = company?.applyFee
        ? parseFloat(company?.fee) / 100
        : feeGlobalConfigs?.bulk_payment_fees;
      setFees(bulkPaymentFees ?? "0");
    }
  };

  const getPaiementInputs = (currentContacts: any) => {
    if (currentContacts) {
      const receiversTransInput = [];
      for (const contact of currentContacts) {
        receiversTransInput.push({
          phoneNumber: contact.number,
          name: contact.name,
          amount:
            contactFrom === PaymentFormType.BYFORMPAYMENT
              ? amount
              : contact.amount,
        });
      }
      return receiversTransInput;
    }
  };
  const getPaiementFormInputs = (currentContacts: any) => {
    if (currentContacts) {
      const receiversTransInput = [];
      for (const contact of currentContacts.destphoneNumbers) {
        receiversTransInput.push({
          phoneNumber: contact.value,
          name: retrieveContactFormName(contact.label, contact.value),
          amount: currentContacts.amount,
        });
      }
      return receiversTransInput;
    }
  };
  const retrieveContactFormName = (label: string, value: string) => {
    return label.includes(`[${value}]`) ? label.replace(`[${value}]`, "") : "";
  };

  const sankMoneyCompanyTransferUserMoneyFn = functions.httpsCallable(
    "sankMoneyCompanyTransferUserMoneyFn"
  );
  const sankMoneyCompanyTransactionsReportFn = functions.httpsCallable(
    "sankMoneyCompanyTransactionsReportFn"
  );

  const handleDescriptionChange = (e: any) => {
    const value = e.target.value;
    setDescription(value);
  };

  const readExcel = async (event: any) => {
    setFileName(event.target.files[0]?.name);
    let sankMoneyContact: any[] = [];
    let notDefineContactPhoneNumber: any[] = [];
    let notDefineContactAmount: any[] = [];
    let notFormatContactAmount: any[] = [];
    let notFormatContactNumber: any[] = [];

    const fileData = await event.target.files[0].arrayBuffer();
    const readFile = read(fileData);
    const xlsxData = readFile.Sheets[readFile.SheetNames[0]];
    const data = utils.sheet_to_json(xlsxData);

    if (data.length > numberOfCompanyBulkPaiementFileData) {
      return toast.error(
        `Vous ne pouvez pas transferer à plus de ${numberOfCompanyBulkPaiementFileData} contacts.`
      );
    }

    for (const el of data as any) {
      const phoneNumber = el.Numero
        ? el.Numero.startsWith("+")
          ? el.Numero
          : `+${el.Numero}`
        : "";
      if (!el.Montant) {
        notDefineContactAmount.push({
          name: el.Nom,
          number: phoneNumber,
          amount: "",
        });
      }
      if (!el.Numero) {
        notDefineContactPhoneNumber.push({
          name: el.Nom,
          number: "",
          amount: el.Montant,
        });
      }
      if (el.Montant) {
        if (!isNumeric(el.Montant)) {
          notFormatContactAmount.push({
            name: el.Nom,
            number: phoneNumber,
            amount: el.Montant,
          });
        }
        if (el.Montant < defaultAmount) {
          notFormatContactAmount.push({
            name: el.Nom,
            number: phoneNumber,
            amount: el.Montant,
          });
        }
      }

      if (el.Numero) {
        const phoneNumber = el.Numero
          ? el.Numero.startsWith("+")
            ? el.Numero
            : `+${el.Numero}`
          : "";
        if (!isValidPhoneNumber(phoneNumber)) {
          notFormatContactNumber.push({
            name: el.Nom,
            number: el.Numero,
            amount: el.Montant,
          });
        }
      }
      sankMoneyContact.push({
        name: el.Nom,
        number: phoneNumber,
        amount: parseInt(el.Montant),
      });
    }
    setSimularNumber(findDuplicateContacts(sankMoneyContact));
    setNotDefineNumber(notDefineContactPhoneNumber);
    setNotDefineAmount(notDefineContactAmount);
    setNotFormatedAmount(notFormatContactAmount);
    setNotFormatedNumber(notFormatContactNumber);

    // Retrieve duplicated phone number
    const duplicatedItems = findDuplicateContacts(sankMoneyContact);
    // Filter undefined phone number
    const removeNotDefineContactsPhoneNumbers = sankMoneyContact.filter(
      (vals) => vals.number !== ""
    );
    // Filter duplicated phone number
    const removeDuplicatedContacts = removeNotDefineContactsPhoneNumbers.filter(
      (vals) => !duplicatedItems.some((item) => vals.number === item.number)
    );
    // Filter undefined amount
    const removeNotDefineContactsAmount = removeDuplicatedContacts.filter(
      (vals) => !notDefineContactAmount.includes(vals.number)
    );
    // Filter unformated amount
    const removeNotFormatContactAmount = removeNotDefineContactsAmount.filter(
      (vals) => isNumeric(vals.amount)
    );
    // Filter unformated amount
    const removeNotFormatContactUnderDefaultAmount =
      removeNotFormatContactAmount.filter(
        (vals) => vals.amount >= defaultAmount
      );
    // Filter unformated phone number
    const removeNotFormatContactNumber =
      removeNotFormatContactUnderDefaultAmount.filter((vals) =>
        isValidPhoneNumber(vals.number)
      );

    const amount = removeNotFormatContactNumber.reduce(
      (accumulator, currentValue) =>
        accumulator + parseInt(currentValue.amount),
      0
    );
    setXLSXTotalAmount(amount);
    setFileData(removeNotFormatContactNumber);
    setFilter(removeNotFormatContactNumber);
  };

  const findDuplicateContacts = (contacts: any) => {
    const numberMap: any = {};
    const duplicates = [];

    contacts.forEach((contact: any) => {
      const number = contact.number;
      if (numberMap[number]) {
        numberMap[number].push(contact);
      } else {
        numberMap[number] = [contact];
      }
    });

    for (const number in numberMap) {
      if (numberMap[number].length > 1) {
        duplicates.push(...numberMap[number]);
      }
    }

    return duplicates;
  };

  const saveTransReport = (transactionResponses: any) => {
    const now = new Date();
    const formattedTime = format(now, "HHmmss");
    const fileName = `rapport-des-transactions-du-${moment(Date.now()).format(
      "YYYY-MM-DD"
    )}-${formattedTime}`;
    const xlsxData = (transactionResponses.response as []).map(
      (val: {
        phoneNumber: any;
        amount: any;
        name: any;
        status: { statusMessage: any };
        transactionId: any;
        createdAt: any;
      }) => {
        return {
          "Nom/Prenom": val.name,
          Telephone: val.phoneNumber,
          Montant: val.amount,
          Status: val.status.statusMessage,
          "Transaction ID": val.transactionId,
          "Date de paiement": val.createdAt,
        };
      }
    );
    const ws = utils.json_to_sheet(xlsxData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Transactions");

    const xlsxBinary = write(wb, { bookType: "xlsx", type: "binary" });

    const xlsxBlob = new Blob([s2ab(xlsxBinary)], {
      type: "application/octet-stream",
    });

    const paymentStatusInfo = {
      totalPaidAmount: transactionResponses.totalPaidAmount,
      totalPaidPersonne: transactionResponses.totalPaidPersonne,
      totalUnPaidPersonne: transactionResponses.totalUnPaidPersonne,
      campaignDescription: transactionResponses.campaignDescription,
      env: transactionResponses.env,
    };

    exportCSVFileToStorage(xlsxBlob, fileName, paymentStatusInfo);
  };
  const s2ab = (s: string) => {
    const buf = new ArrayBuffer(s.length);
    const view = new Uint8Array(buf);
    for (let i = 0; i < s.length; i++) {
      view[i] = s.charCodeAt(i) & 0xff;
    }
    return buf;
  };

  const exportCSVFileToStorage = (
    csvBlob: any,
    fileName: string,
    paymentStatusInfo: any
  ) => {
    // Upload CSV to Firebase storage
    const imageRef = ref(
      storage,
      `company/${company?.phoneNumber}/rapport-des-transactions/${fileName}.xlsx`
    );
    uploadBytes(imageRef, csvBlob)
      .then(() => {
        // Get CSV download URL
        getDownloadURL(imageRef)
          .then(async (url) => {
            // Create report document
            await sankMoneyCompanyTransactionsReportFn({
              companyId: company?.id,
              reportUrl: url,
              fileName,
              paymentStatusInfo,
            });
            // Set the URL wherever you need it
          })
          .catch((error) => {
            toast.error(
              `An error occurred while getting the file URL: ${error.message}`
            );
          });
      })
      .catch((error) => {
        toast.error(
          `An error occurred while uploading the CSV file: ${error.message}`
        );
      });
  };

  const handleSubmit = async (pinCode: any) => {
    setIsLoad(true);
    const formTotalAmount =
      parseInt(amount ?? "0") *
      (contactsFieldLength ?? 0) *
      parseFloat(fees ?? "0") +
      parseInt(amount ?? "0") * (contacts?.length ?? 0);
    const fileTotalAmount =
      XLSXTotalAmount +
      parseFloat((XLSXTotalAmount * parseFloat(fees ?? "0")).toFixed(3));
    if (contactFrom === PaymentFormType.BYFORMPAYMENT) {
      if (amount < defaultAmount) {
        setIsCodePinBtnDesabled(false);
        setShowPinCodeModal(false);
        return toast.error(
          `Le montant doit être suppérieur à ${defaultAmount} Fcfa`
        );
      }
      if (user && pinCode) {
        const paymentData = {
          senderId: company?.id,
          receiversPaiementInputs: getPaiementFormInputs(formInputsData),
          accountType: formInputsData.accountType ?? AccountType.MAIN,
          description: description ?? "",
        };
        if (
          (userAccounts?.find((doc) => doc.accountType === AccountType.MAIN)
            ?.balance as number) < formTotalAmount
        ) {
          setIsCodePinBtnDesabled(false);
          setShowPinCodeModal(false);
          return toast.error(
            `Votre solde est insuffisant pour effectuer cette transaction.`
          );
        } else {
          setIsCodePinBtnDesabled(true);
          const toastId = toast.loading("Paiement");
          await sankMoneyCompanyTransferUserMoneyFn({
            pinCode: pinCode,
            ...paymentData,
            currentUserId: user?.id,
            withConnection: true,
          })
            .then(async (result) => {
              setShowPinCodeModal(false);
              setIsCodePinBtnDesabled(false);
              setIsLoad(false);
              navigate("/companies/contacts/contact_lists");
              const accounts = await accountsRef.get();
              setUserAccounts(accounts.docs.map((account) => account.data()));
              saveTransReport(result.data);
              return toast.success("Le paiement a été effectuée avec succès", {
                id: toastId,
              });
            })
            .catch((err: { details: any; message: any }) => {
              setShowPinCodeModal(false);
              setIsCodePinBtnDesabled(false);
              setIsLoad(false);
              toast.error(err.message, {
                id: toastId,
              });
            });
        }
      }
    } else {
      if (
        (userAccounts?.find((doc) => doc.accountType === AccountType.MAIN)
          ?.balance as number) < fileTotalAmount
      ) {
        setIsCodePinBtnDesabled(false);
        setIsLoad(false);
        setShowPinCodeModal(false);
        return toast.error(
          `Votre solde est insuffisant pour effectuer cette transaction.`
        );
      }
      if (fileData.length > 0) {
        const paymentData = {
          senderId: company?.id,
          receiversPaiementInputs: getPaiementInputs(fileData),
          accountType: accountType,
          description: campaignLabel ?? "",
        };
        return toast.promise(
          sankMoneyCompanyTransferUserMoneyFn({
            pinCode: pinCode,
            ...paymentData,
            currentUserId: user?.id,
            withConnection: true,
          }).then((result) => {
            setShowPinCodeModal(false);
            setIsCodePinBtnDesabled(false);
            setIsLoad(false);
            navigate("/companies/contacts/contact_lists");
            saveTransReport(result.data);
          }),
          {
            loading: `Création...`,
            success: "Le paiement a été effectuée avec succès",
            error: (err) => (err.message ? err.message : "Echec de paiement."),
          }
        );
      }
    }
  };

  const exportContactData = useCallback(() => {
    const ws = utils.json_to_sheet(csvModelData);
    const wb = utils.book_new();
    utils.book_append_sheet(wb, ws, "Data");
    writeFileXLSX(wb, `contacts-${new Date().toLocaleDateString()}.xlsx`);
  }, []);

  const handleFilter = (value: any) => {
    const data = fileData.filter((item) =>
      item.number.includes(value.target.value)
    );
    setFilter(data);
  };

  const retrieveCompanyContacts = useCallback(async () => {
    const contactsSnap = await db
      .collection(companyCollectionName)
      .doc(company?.id)
      .collection(companyContactCollectionsName)
      .get();
    if (contactsSnap.empty) {
      setCompanyContactList([]);
    }
    setCompanyContactList(contactsSnap.docs.map((doc) => doc.data()));
  }, [company?.id]);

  const loadOptionsForContactSearch = useCallback(
    async (inputValue: any, callback: any) => {
      const filteredContacts = companyContactList.filter((doc) =>
        (doc.phoneNumber as string).includes(inputValue)
      );

      // Map over the filtered contacts to format them into the expected label and value format
      const formattedContacts = filteredContacts.map((contact: any) => ({
        label: `${contact.displayName ? contact.displayName : ""} [${contact.phoneNumber
          }]`,
        value: contact.phoneNumber,
      }));
      callback(formattedContacts);
    },
    [companyContactList]
  );

  useEffect(() => {
    user && retriveFeesData();
    currentPaymentFormType &&
      setContactFom(currentPaymentFormType ?? PaymentFormType.BYFORMPAYMENT);
    retrieveCompanyContacts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="flex mx-5 mt-4 max-md:block max-md:space-x-0 max-md:space-y-10 max-md:mx-5">
      <div className="w-full">
        <div className="flex mx-auto max-md:block max-md:space-x-0 max-md:mb-3 max-md:space-y-3 max-md:mx-5">
          <p className="max-w-lg mt-2 mb-6 font-semibold leading-normal text-gray-900 text-md dark:text-gray-300 ">
            Effectuer un paiement
          </p>
        </div>
        <ul className="sticky top-0 z-10 items-center w-full text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-lg dark:bg-slate-800 dark:border-slate-700 sm:flex ">
          <li className="w-full border-b border-gray-200 dark:border-slate-700 sm:border-b-0 sm:border-r">
            <div className="flex items-center pl-3">
              <input
                id="horizontal-list-radio-id"
                type="radio"
                checked={contactFrom === PaymentFormType.BYFORMPAYMENT}
                value={PaymentFormType.BYFORMPAYMENT}
                name="list-radio"
                disabled={
                  currentPaymentFormType &&
                    currentPaymentFormType === PaymentFormType.BYCSVPAYMENT
                    ? true
                    : false
                }
                onChange={handleOptionChange}
                className="w-4 h-4 bg-gray-100 border-gray-300 text-emerald-600 dark:text-emerald-600 focus:ring-emerald-500"
              />
              <label
                htmlFor="horizontal-list-radio-id"
                className="w-full py-3 ml-2 text-sm font-medium text-gray-900 cursor-pointer dark:text-gray-300"
              >
                Saisir manuellement
              </label>
            </div>
          </li>
          <li className="w-full border-b border-gray-200 dark:border-slate-700 sm:border-b-0 sm:border-r">
            <div className="flex items-center pl-3">
              <input
                id="horizontal-list-radio-license"
                type="radio"
                value={PaymentFormType.BYCSVPAYMENT}
                name="list-radio"
                checked={contactFrom === PaymentFormType.BYCSVPAYMENT}
                disabled={
                  currentPaymentFormType &&
                    currentPaymentFormType === PaymentFormType.BYCSVPAYMENT
                    ? true
                    : false
                }
                onChange={handleOptionChange}
                className="w-4 h-4 bg-gray-100 border-gray-300 text-emerald-600 dark:text-emerald-600 focus:ring-emerald-500 "
              />
              <label
                htmlFor="horizontal-list-radio-license"
                className="w-full py-3 ml-2 text-sm font-medium text-gray-900 cursor-pointer dark:text-gray-300"
              >
                Uploader un fichier XLSX
              </label>
            </div>
          </li>
        </ul>

        <div className="flex flex-col w-full">
          {contactFrom === PaymentFormType.BYFORMPAYMENT && (
            <div className="flex justify-center w-full">
              <div className="w-full max-w-md px-2 py-16 sm:px-0">
                <div className="flex items-center justify-center w-full font-manrope">
                  <div className="box-border w-full p-4 mx-auto bg-white border">
                    <Form
                      maximizeFormWidh={false}
                      formReturnAware={setFormReturn}
                      onSubmit={(data) => {
                        setformInputsData(data);
                        amount && setShowPinCodeModal(true);
                      }}
                      submitButtonLabel={`Payé :
                              ${new Intl.NumberFormat().format(
                        parseInt(amount ?? "0") *
                        (contactsFieldLength ?? 0)
                      )}
                              Fcfa (frais :
                                  ${parseInt(amount ?? "0") *
                        (contactsFieldLength ?? 0) *
                        parseFloat(fees ?? "0")
                        }
                                  )`}
                      form={{
                        resolver: yupResolver(
                          yup.object().shape({
                            amount: yup
                              .string()
                              .required("Le montant est obligatoire!"),
                            accountType: yup
                              .string()
                              .required(
                                "Le type de compte à rechargé est obligatoire!"
                              ),
                            destphoneNumbers: yup
                              .array()
                              .of(
                                yup.object<any>().shape({
                                  value: yup
                                    .string()
                                    .matches(/\d{11}/, "Numéro invalide"),
                                })
                              )
                              .min(
                                1,
                                `Vous devez saisir au moins un numéro de téléphone`
                              ),
                          })
                        ),
                        defaultValues: {
                          destphoneNumbers: contacts
                            ? contacts.map((contact: any) => {
                              return {
                                label: `${contact.displayName
                                    ? contact.displayName
                                    : ""
                                  } [${contact.phoneNumber}]`,
                                value: contact.phoneNumber,
                              };
                            })
                            : [],
                        },
                      }}
                    >
                      <div className="mt-6">
                        <div className="font-semibold">
                          Combien souhaitez-vous envoyer ?
                        </div>
                        <div>
                          <FormInput
                            required
                            type="text"
                            name="amount"
                            label={`Numéro`}
                            placeholder="1000"
                            onChange={(e) => setAmount(e.target.value)}
                          />
                        </div>
                      </div>
                      <div className="mt-6">
                        <div className="flex items-center pl-4 border border-gray-200 rounded dark:border-gray-700">
                          <FormRadio
                            label="Choisir un compte :"
                            name={"accountType"}
                            options={[
                              {
                                name: "Compte principale",
                                value: AccountType.MAIN,
                              },
                              {
                                name: "Compte carburant",
                                value: AccountType.FUEL,
                              },
                            ]}
                          />
                        </div>
                      </div>
                      <div className="mt-6">
                        <Alert
                          type={"warning"}
                          content={
                            "Si vous avez plus de 30 destinataires, merci d'utiliser le paiement"
                          }
                          titleLink={"via l'upload du fichier"}
                          title={""}
                          onClickLink={() =>
                            setContactFom(PaymentFormType.BYCSVPAYMENT)
                          }
                        />
                        <FormMultiInputSelect<{
                          label: string;
                          value: string;
                        }>
                          required
                          name="destphoneNumbers"
                          label={`Destinataire(s)`}
                          loadOptions={loadOptionsForContactSearch}
                          inputToNewOption={(input) => {
                            return { label: input, value: input };
                          }}
                          placeholder="Ex : +22670707070"
                          condensed
                          disabled={
                            contacts && contacts.length > 0 ? true : false
                          }
                          retrieveFieldLength={(val) => {
                            setContactsFieldLength(val);
                          }}
                          helpLabel="Vous pouvez entrer plusieurs numéros, avec l'indicatif et avec le +, exemple : +22670707070"
                        />
                      </div>
                      <div className="mt-6">
                        <div className="font-semibold">
                          Entrez une descriptions de la transaction
                        </div>
                        <div>
                          <textarea
                            id="message"
                            rows={4}
                            onChange={handleDescriptionChange}
                            maxLength={200}
                            className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                            placeholder="Écrivez votre description ici..."
                          ></textarea>
                        </div>
                      </div>
                      <div className="mt-6">
                        <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                          <img
                            className="w-10 h-10 rounded-full"
                            src={
                              user?.profilImageUrl
                                ? user?.profilImageUrl
                                : `https://ui-avatars.com/api/?background=random&name=${user?.displayName || "S"
                                }`
                            }
                            alt=""
                          />
                          <div>
                            <div className="font-semibold">
                              {user?.displayName}
                            </div>
                          </div>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            </div>
          )}
          {contactFrom === PaymentFormType.BYCSVPAYMENT && (
            <>
              <nav aria-label="Progress">
                <ol className="my-4 border border-gray-300 divide-y divide-gray-300 rounded-md dark:border-slate-700 dark:divide-slate-700 md:flex md:divide-y-0">
                  {steps.map((element) => {
                    return (
                      <li
                        key={element.step}
                        className="relative md:flex md:flex-1"
                      >
                        {element.step === currentStep ? (
                          <div className="flex items-center px-6 py-4 text-sm font-medium">
                            <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-red-600 rounded-full">
                              <span className="text-red-600">
                                {element.step}
                              </span>
                            </span>
                            <span className="ml-4 text-sm font-medium text-red-600">
                              {element.title}
                            </span>
                          </div>
                        ) : validateStep.includes(`${element.step}`) ? (
                          <span className="flex items-center px-6 py-4 text-sm font-medium">
                            <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 bg-red-600 rounded-full group-hover:bg-red-800">
                              <CheckIcon
                                className="w-6 h-6 text-white"
                                aria-hidden="true"
                              />
                            </span>
                            <span className="ml-4 text-sm font-medium text-gray-900 dark:text-gray-400">
                              {element.title}
                            </span>
                          </span>
                        ) : (
                          <span className="flex items-center px-6 py-4 text-sm font-medium">
                            <span className="flex items-center justify-center flex-shrink-0 w-10 h-10 border-2 border-gray-300 rounded-full dark:border-slate-700 group-hover:border-gray-400">
                              <span className="text-gray-500 group-hover:text-gray-900">
                                {element.step}
                              </span>
                            </span>
                            <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                              {element.title}
                            </span>
                          </span>
                        )}
                        {element.step !== steps.length && (
                          <div
                            className="absolute top-0 right-0 hidden w-5 h-full md:block"
                            aria-hidden="true"
                          >
                            <svg
                              className="w-full h-full text-gray-300 dark:text-slate-700"
                              viewBox="0 0 22 80"
                              fill="none"
                              preserveAspectRatio="none"
                            >
                              <path
                                d="M0 -2L20 40L0 82"
                                vectorEffect="non-scaling-stroke"
                                stroke="currentcolor"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        )}
                      </li>
                    );
                  })}
                </ol>
              </nav>
              {currentStep === 1 && (
                <Form<FormFields>
                  downloadCsvOrXlsxFileBtn={
                    <button
                      type="button"
                      onClick={exportContactData}
                      className="inline-flex items-center px-4 py-2 text-sm font-semibold transition-all duration-200 bg-white border rounded-md shadow-sm justify-centerl gap-x-2 dark:bg-slate-800 border-emerald-700 hover:border-emerald-900 text-emerald-700 hover:text-emerald-900 dark:hover:text-emerald-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-emerald-600"
                    >
                      Télécharger le model XLSX
                    </button>
                  }
                  maximizeFormWidh={false}
                  maximizeFormActionBtnWidh={false}
                  formReturnAware={setCSVFormReturn}
                  submitButtonLabel="Continuer"
                  isSubmitBtnDisabled={
                    fileData.length > 0 &&
                      simularNumber.length <= 0 &&
                      notDefineNumber.length <= 0 &&
                      notFormatedNumber.length <= 0 &&
                      notDefineAmount.length <= 0 &&
                      notFormatedAmount.length <= 0
                      ? false
                      : true
                  }
                  onSubmit={() => {
                    setCurrentStep(currentStep + 1);
                    setValidateStep([...validateStep, "1"]);
                  }}
                  form={{
                    resolver: yupResolver(
                      yup.object().shape({
                        contactFile: yup
                          .mixed()
                          .required("Vous devez ajouter un fichier"),
                      })
                    ),
                  }}
                >
                  <div className="grid grid-cols-1 md:grid-cols-2">
                    <Controller
                      control={cSVformReturn?.control}
                      name="contactFile"
                      render={({ field, fieldState }) => (
                        <div className="mt-3">
                          <div className="items-center justify-between flex-1 max-w-lg mx-auto">
                            <div className="flex justify-between">
                              <p className="my-2 text-base font-semibold leading-6 text-gray-900 dark:text-gray-300">
                                Importez votre fichier de contact
                              </p>
                            </div>
                            <div className="flex justify-center w-full">
                              <label
                                htmlFor="dropzone-file"
                                className="flex flex-col items-center justify-center w-full border-2 border-gray-300 border-dashed rounded-lg cursor-pointer h-96 bg-gray-50 dark:hover:bg-slate-700 dark:bg-gray-800 hover:bg-gray-100 dark:border-slate-700 dark:hover:border-slate-700"
                              >
                                <div className="flex flex-col items-center justify-center pt-5 pb-6">
                                  {fileName ? (
                                    <div className="text-sm text-gray-500 dark:text-gray-400">
                                      {fileName}{" "}
                                    </div>
                                  ) : (
                                    <>
                                      <svg
                                        aria-hidden="true"
                                        className="w-10 h-10 mb-3 text-gray-400"
                                        fill="none"
                                        stroke="currentColor"
                                        viewBox="0 0 24 24"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                          strokeWidth="2"
                                          d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                                        ></path>
                                      </svg>
                                      <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                                        <span className="font-semibold">
                                          Click to upload
                                        </span>{" "}
                                        or drag and drop
                                      </p>
                                      <p className="text-xs text-gray-500 dark:text-gray-400">
                                        Uploader un fichier XLSX (XLSX)
                                      </p>
                                    </>
                                  )}
                                </div>
                                <input
                                  name="contactFile"
                                  id="dropzone-file"
                                  type="file"
                                  accept=".xls, .xlsx"
                                  className="hidden"
                                  onChange={(event) => {
                                    readExcel(event);
                                    cSVformReturn?.setValue(
                                      "contactFile",
                                      null
                                    );
                                    field.onChange(event);
                                  }}
                                />
                              </label>
                            </div>
                          </div>
                          {fieldState.error && (
                            <p
                              className={classNames(
                                fieldState.error
                                  ? "opacity-100 translate-y-3 mb-0"
                                  : "",
                                "text-sm text-red-600 opacity-0 transform transition-all duration-700 -mt-3"
                              )}
                            >
                              {fieldState.error && fieldState.error.message}
                            </p>
                          )}
                        </div>
                      )}
                    />
                    <div className="w-full max-md:w-full">
                      <div className="flex items-center justify-center w-full px-6 mt-10 bg-white md:mt-2">
                        <div className="w-full border-l-2 border-dashed">
                          <div className="relative w-full">
                            {simularNumber.length > 0 ? (
                              <XMarkIcon
                                className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-red-500 bg-red-500 rounded-full text-white"
                                aria-hidden="true"
                              />
                            ) : (
                              <CheckIcon
                                className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-green-500 bg-green-500 rounded-full text-white"
                                aria-hidden="true"
                              />
                            )}
                            <div className="ml-6">
                              <h4
                                className={classNames(
                                  simularNumber.length > 0
                                    ? "text-red-500"
                                    : "text-blue-500",
                                  "font-bold"
                                )}
                              >
                                Numéro de téléphone similaire.
                              </h4>
                              <p className="max-w-screen-sm mt-2 text-sm text-gray-500">
                                Cette rubrique vérifie les utilisateurs qui ont
                                le même numéro de téléphone.
                              </p>
                              <span className="block mt-1 text-sm font-semibold text-red-300 hover:text-red-500">
                                {simularNumber.length > 0 && (
                                  <span
                                    className={classNames(
                                      simularNumber.length > 0
                                        ? "cursor-pointer"
                                        : "cursor-not-allowed",
                                      "p-1 border rounded-md "
                                    )}
                                  >
                                    <CsvModel
                                      csvData={simularNumber}
                                      headers={excelFileHeaders}
                                      fileName={` Numero de telephone similaire.`}
                                      text={`Voir le fichier`}
                                      className={classNames(
                                        simularNumber.length > 0
                                          ? "cursor-pointer"
                                          : "cursor-not-allowed",
                                        ""
                                      )}
                                    />
                                  </span>
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="relative w-full mt-1 md:mt-4">
                            {notDefineNumber.length > 0 ? (
                              <XMarkIcon
                                className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-red-500 bg-red-500 rounded-full text-white"
                                aria-hidden="true"
                              />
                            ) : (
                              <CheckIcon
                                className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-green-500 bg-green-500 rounded-full text-white"
                                aria-hidden="true"
                              />
                            )}
                            <div className="ml-6">
                              <h4
                                className={classNames(
                                  notDefineNumber.length > 0
                                    ? "text-red-500"
                                    : "text-blue-500",
                                  "font-bold"
                                )}
                              >
                                Numéro de téléphone non définie
                              </h4>
                              <p className="max-w-screen-sm mt-2 text-sm text-gray-500">
                                Cette rubrique vérifie les utilisateurs qui
                                n'ont pas de numéro de téléphone définie.
                              </p>
                              <span className="block mt-1 text-sm font-semibold text-red-300 hover:text-red-500">
                                {notDefineNumber.length > 0 && (
                                  <span
                                    className={classNames(
                                      notDefineNumber.length > 0
                                        ? "cursor-pointer"
                                        : "cursor-not-allowed",
                                      "p-1 border rounded-md "
                                    )}
                                  >
                                    <CsvModel
                                      csvData={notDefineNumber}
                                      headers={excelFileHeaders}
                                      fileName={`Numero de telephone non definie`}
                                      text={`Voir le fichier`}
                                      className={classNames(
                                        notDefineNumber.length > 0
                                          ? "cursor-pointer"
                                          : "cursor-not-allowed",
                                        ""
                                      )}
                                    />
                                  </span>
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="relative w-full mt-1 md:mt-4">
                            {notFormatedNumber.length > 0 ? (
                              <XMarkIcon
                                className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-red-500 bg-red-500 rounded-full text-white"
                                aria-hidden="true"
                              />
                            ) : (
                              <CheckIcon
                                className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-green-500 bg-green-500 rounded-full text-white"
                                aria-hidden="true"
                              />
                            )}
                            <div className="ml-6">
                              <h4
                                className={classNames(
                                  notFormatedNumber.length > 0
                                    ? "text-red-500"
                                    : "text-blue-500",
                                  "font-bold"
                                )}
                              >
                                Numéro de téléphone mal formaté
                              </h4>
                              <p className="max-w-screen-sm mt-2 text-sm text-gray-500">
                                Cette rubrique vérifie les utilisateurs qui ont
                                pas de numéro de téléphone mal formaté.
                              </p>
                              <span className="block mt-1 text-sm font-semibold text-red-300 hover:text-red-500">
                                {notFormatedNumber.length > 0 && (
                                  <span
                                    className={classNames(
                                      notFormatedNumber.length > 0
                                        ? "cursor-pointer"
                                        : "cursor-not-allowed",
                                      "p-1 border rounded-md "
                                    )}
                                  >
                                    <CsvModel
                                      csvData={notFormatedNumber}
                                      headers={excelFileHeaders}
                                      fileName={`Numero de telephone mal formate`}
                                      text={`Voir le fichier`}
                                      className={classNames(
                                        notFormatedNumber.length > 0
                                          ? "cursor-pointer"
                                          : "cursor-not-allowed",
                                        ""
                                      )}
                                    />
                                  </span>
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="relative w-full mt-1 md:mt-4">
                            {notDefineAmount.length > 0 ? (
                              <XMarkIcon
                                className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-red-500 bg-red-500 rounded-full text-white"
                                aria-hidden="true"
                              />
                            ) : (
                              <CheckIcon
                                className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-green-500 bg-green-500 rounded-full text-white"
                                aria-hidden="true"
                              />
                            )}
                            <div className="ml-6">
                              <h4
                                className={classNames(
                                  notDefineAmount.length > 0
                                    ? "text-red-500"
                                    : "text-blue-500",
                                  "font-bold"
                                )}
                              >
                                Montant non definie
                              </h4>
                              <p className="max-w-screen-sm mt-2 text-sm text-gray-500">
                                Cette rubrique vérifie les utilisateurs qui
                                n'ont pas de montant définie.
                              </p>
                              <span className="block mt-1 text-sm font-semibold text-red-300 hover:text-red-500">
                                {notDefineAmount.length > 0 && (
                                  <span
                                    className={classNames(
                                      notDefineAmount.length > 0
                                        ? "cursor-pointer"
                                        : "cursor-not-allowed",
                                      "p-1 border rounded-md "
                                    )}
                                  >
                                    <CsvModel
                                      csvData={notDefineAmount}
                                      headers={excelFileHeaders}
                                      fileName={`Montant non definie`}
                                      text={`Voir le fichier`}
                                      className={classNames(
                                        notDefineAmount.length > 0
                                          ? "cursor-pointer"
                                          : "cursor-not-allowed",
                                        ""
                                      )}
                                    />
                                  </span>
                                )}
                              </span>
                            </div>
                          </div>
                          <div className="relative w-full mt-1 md:mt-4">
                            {notFormatedAmount.length > 0 ? (
                              <XMarkIcon
                                className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-red-500 bg-red-500 rounded-full text-white"
                                aria-hidden="true"
                              />
                            ) : (
                              <CheckIcon
                                className="absolute -top-0.5 z-10 -ml-3.5 h-6 w-6 border border-green-500 bg-green-500 rounded-full text-white"
                                aria-hidden="true"
                              />
                            )}
                            <div className="ml-6">
                              <h4
                                className={classNames(
                                  notFormatedAmount.length > 0
                                    ? "text-red-500"
                                    : "text-blue-500",
                                  "font-bold"
                                )}
                              >
                                Montant mal formater
                              </h4>
                              <p className="max-w-screen-sm mt-2 text-sm text-gray-500">
                                Cette rubrique vérifie les utilisateurs qui ont
                                le montant mal formaté.
                              </p>
                              <span className="block mt-1 text-sm font-semibold text-red-300 hover:text-red-500">
                                {notFormatedAmount.length > 0 && (
                                  <span
                                    className={classNames(
                                      notFormatedAmount.length > 0
                                        ? "cursor-pointer"
                                        : "cursor-not-allowed",
                                      "p-1 border rounded-md "
                                    )}
                                  >
                                    <CsvModel
                                      csvData={notFormatedAmount}
                                      headers={excelFileHeaders}
                                      fileName={`Montant mal formater`}
                                      text={`Voir le fichier`}
                                      className={classNames(
                                        notFormatedAmount.length > 0
                                          ? "cursor-pointer"
                                          : "cursor-not-allowed",
                                        ""
                                      )}
                                    />
                                  </span>
                                )}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}

              {currentStep === 2 && (
                <div className="mt-6 space-y-8">
                  <Form
                    maximizeFormWidh={false}
                    submitButtonLabel={`Continuer`}
                    onSubmit={() => {
                      setCurrentStep(currentStep + 1);
                      setValidateStep([...validateStep, "2"]);
                    }}
                    form={{}}
                  >
                    <div className="flex justify-end"></div>
                    <DataTable
                      title="Recaputilatif des données"
                      columns={columns}
                      data={filter}
                      pagination
                      highlightOnHover
                      subHeader
                      subHeaderComponent={
                        <input
                          type="text"
                          className="rounded-md"
                          placeholder="Search number here"
                          onChange={handleFilter}
                        />
                      }
                    />
                  </Form>
                </div>
              )}

              {currentStep === 3 && (
                <Form<{
                  campaignName: string;
                  message: string;
                }>
                  cancelButtonLabel="Précedent"
                  onCancel={() => {
                    setCurrentStep(currentStep - 1);
                  }}
                  maximizeFormWidh={false}
                  onSubmit={(data) => {
                    setShowPinCodeModal(true);
                    setCampaignLabel(data.campaignName);
                  }}
                  submitButtonLabel={"Lancer le paiement"}
                  form={{}}
                >
                  <div className="py-6 bg-white dark:bg-slate-800 sm:py-6">
                    <div className="flex flex-col items-center w-full px-6 lg:px-8">
                      <div className="w-full mx-auto mt-2 sm:mt-5 lg:mt-6">
                        <div className="flex flex-col items-center w-full max-w-sm gap-8 mx-auto md:max-w-3xl xl:max-w-sm max-xl:mx-auto">
                          <div className="w-full p-6 transition-all duration-500 border border-gray-200 rounded-3xl group hover:border-gray-400 ">
                            <div className="pb-6 leading-10 border-b border-gray-200">
                              <FormInput
                                type="textarea"
                                name="campaignName"
                                label={"la Campagne"}
                                placeholder="paiement de masse de la zone-ouest"
                                maxLength={100}
                              />
                            </div>
                            <h2 className="pb-6 text-3xl font-bold leading-10 text-black border-b border-gray-200 font-manrope ">
                              Details du paiement
                            </h2>
                            <div className="py-6 border-b border-gray-200 data">
                              <div className="flex items-center justify-between gap-4 mb-5">
                                <p className="text-lg font-normal leading-8 text-gray-400 transition-all duration-500 group-hover:text-gray-700">
                                  Total des contacts
                                </p>
                                <p className="text-lg font-medium leading-8 text-gray-900">
                                  {fileData.length}
                                </p>
                              </div>
                              <div className="flex items-center justify-between gap-4 mb-5">
                                <p className="text-lg font-normal leading-8 text-gray-400 transition-all duration-500 group-hover:text-gray-700">
                                  Montant total
                                </p>
                                <p className="text-lg font-medium leading-8 text-gray-600">
                                  {XLSXTotalAmount}
                                </p>
                              </div>
                              <div className="flex items-center justify-between gap-4 mb-5">
                                <p className="text-lg font-normal leading-8 text-gray-400 transition-all duration-500 group-hover:text-gray-700">
                                  Frais
                                </p>
                                <p className="text-lg font-medium leading-8 text-gray-600">
                                  {parseFloat(
                                    (
                                      XLSXTotalAmount * parseFloat(fees ?? "0")
                                    ).toFixed(3)
                                  )}
                                </p>
                              </div>
                              <div className="flex items-center justify-between gap-4 ">
                                <p className="text-lg font-normal leading-8 text-gray-400 transition-all duration-500 group-hover:text-gray-700 ">
                                  Contacts
                                </p>
                                <p
                                  className={classNames(
                                    fileData.length > 0 &&
                                      simularNumber.length <= 0 &&
                                      notDefineNumber.length <= 0 &&
                                      notFormatedNumber.length <= 0 &&
                                      notDefineAmount.length <= 0 &&
                                      notFormatedAmount.length <= 0
                                      ? "text-emerald-500"
                                      : "text-red-500",
                                    "text-lg font-medium leading-8 "
                                  )}
                                >
                                  {fileData.length > 0 &&
                                    simularNumber.length <= 0 &&
                                    notDefineNumber.length <= 0 &&
                                    notFormatedNumber.length <= 0 &&
                                    notDefineAmount.length <= 0 &&
                                    notFormatedAmount.length <= 0
                                    ? "#VALID"
                                    : "#INVALID"}
                                </p>
                              </div>
                            </div>
                            <div className="flex items-center justify-between pt-6 total">
                              <p className="text-xl font-normal leading-8 text-black ">
                                Total
                              </p>
                              <h5 className="text-2xl font-bold leading-9 text-indigo-600 font-manrope">
                                {XLSXTotalAmount +
                                  parseFloat(
                                    (
                                      XLSXTotalAmount * parseFloat(fees ?? "0")
                                    ).toFixed(3)
                                  )}
                              </h5>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </>
          )}
        </div>
        <Modal
          isOpen={showPinCodeModal}
          maxSize="md"
          closeModal={() => setShowPinCodeModal(false)}
        >
          <div className="flex justify-center">
            <CodePinComponent
              onSubmit={handleSubmit}
              disabled={isCodePinBtnDesabled}
              isLoad={isLoad}
            />
          </div>
        </Modal>
      </div>
    </div>
  );
};

export default CompanyBulkPaiement;
