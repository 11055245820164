/* eslint-disable react-hooks/exhaustive-deps */
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/solid";
import { Timestamp } from "firebase-admin/firestore";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../contexts";
import { db } from "../../firebase";
import { AgencyStaffRole, UserRole } from "../../interfaces";
import { classNames, getTimestamp, parseDate } from "../../utils";
import { accountsCollectionName, usersCollectionName } from "../../config";
import {
  ArrowDownTrayIcon,
  FunnelIcon,
  MagnifyingGlassIcon,
  UserPlusIcon,
} from "@heroicons/react/24/outline";
import PhoneInput from "react-phone-number-input";
import Modal from "../../components/Modal";
import HasRoles from "../../components/HasRoles";
import moment from "moment";
import * as XLSX from "xlsx";
import FirestoreTable, {
  IColumn,
  IRow,
  RowRenderFunction,
} from "../../components/FirestoreTable";
import Pagination from "../../components/Pagination/Pagination";
import NoContent from "../../components/TableNoContentPage/NoContent";
import TableRowEditMenu from "../../components/TableRowEditMenu/TableRowEditMenu";

const AgentsPage = () => {
  const tableHeader = [
    "N°",
    "",
    "Nom/prénom",
    "Adresse",
    "Ville",
    "Téléphone",
    "Code agent",
    "Role",
    "Agence",
    "Active",
    "Date de création",
    "Actions",
  ];

  const columns: IColumn[] = [
    {
      field: "displayName",
      label: "Nom/prénom",
    },
    {
      field: "adresse",
      label: "Adresse",
    },
    {
      field: "city",
      label: "Ville",
    },
    {
      field: "phoneNumber",
      label: "Téléphone",
    },
    {
      field: "agentCode",
      label: "Code agent",
    },
    {
      field: "city",
      label: "Agence",
    },
    {
      field: "active",
      label: "Active",
    },
    {
      field: "createdAt",
      label: "Date de création",
    },
  ];

  const renderRow: RowRenderFunction = (
    row: IRow,
    column: IColumn,
    value: any
  ) => {
    switch (column.field) {
      case "displayName":
        return <span>{value ? value : "-"}</span>;
      case "adresse":
        return <span>{value ? value : "-"}</span>;
      case "city":
        return <span>{value ? value : "-"}</span>;
      case "phoneNumber":
        return <span>{value ?? "-"}</span>;
      case "agentCode":
        return <span>{value ?? "-"}</span>;
      case "createdAt":
        return parseDate(value as Timestamp);
      case "active":
        return (
          <span>
            {" "}
            {value ? (
              <CheckCircleIcon className="w-6 h-6 text-green-500" />
            ) : (
              <XCircleIcon className="w-6 h-6 text-red-500" />
            )}
          </span>
        );
      default:
        return value ? value : "-";
    }
  };

  const [agents, setAgents] = useState<any[]>([]);
  const navigate = useNavigate();
  const collectionName = usersCollectionName;
  const { user, agencyAgent } = useContext(UserContext);
  const userRole = UserRole.AGENT;
  const [userData, setUserData] = useState<any>();
  const [showModal, setShowModal] = useState(false);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [agentCodeSearch, setAgentCodeSearch] = useState<string | undefined>();
  const [phoneNumberSearch, setPhoneNumberSearch] = useState<
    string | undefined
  >();
  const [agensDataLimitLength, setAgensDataLimitLength] = useState(100);
  const handleChangeStartDateValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithStartDateValue(value.target.value);
  };
  const handleChangeEndDtaeValue = (value: any) => {
    value.preventDefault();
    setSetSeachWithEndDateValue(value.target.value);
  };

  const [seachWithStartDateValue, setSetSeachWithStartDateValue] =
    useState<string>(`${moment(Date.now()).format("YYYY-MM-DD")}`);
  const [seachWithEndDateValue, setSetSeachWithEndDateValue] = useState<string>(
    `${moment(Date.now()).format("YYYY-MM-DD")}`
  );

  const [startTime, setStartTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, true)
  );
  const [endTime, setEndTime] = useState<any>(
    getTimestamp(`${moment(Date.now()).format("YYYY-MM-DD")}`, false)
  );

  const getFilteredByDate = async () => {
    const startTime = getTimestamp(seachWithStartDateValue, true);
    const endTime = getTimestamp(seachWithEndDateValue, false);
    setEndTime(endTime);
    setStartTime(startTime);
    onPageLimitChange(agensDataLimitLength, startTime, endTime);
  };

  useEffect(() => {
    onPageLimitChange(agensDataLimitLength);
  }, []);

  const onPageLimitChange = useCallback(
    async (pageLimit: number, startTime?: Date, endTime?: Date) => {
      let agentRef =
        startTime && endTime
          ? db
              .collection(collectionName)
              .orderBy("createdAt", "desc")
              .where("createdAt", ">=", startTime)
              .where("createdAt", "<=", endTime)
              .where("userRole", "==", userRole)
              .limit(pageLimit)
          : db
              .collection(collectionName)
              .orderBy("createdAt", "desc")
              .where("userRole", "==", userRole)
              .limit(pageLimit);

      switch (user?.userRole) {
        case UserRole.DEALER:
          await agentRef
            .where("dealerId", "==", user?.id)
            .get()
            .then((snapshot) => {
              setAgents(
                snapshot.docs
                  .filter((d) => d.id !== agencyAgent?.id)
                  .map((d) => {
                    return { id: d.id, data: d.data() };
                  })
              );
            });
          break;
        case UserRole.SUPERVISOR:
          await agentRef
            .where("supervisorId", "==", user?.id)
            .get()
            .then((snapshot) => {
              setAgents(
                snapshot.docs
                  .filter((d) => d.id !== agencyAgent?.id)
                  .map((d) => {
                    return { id: d.id, data: d.data() };
                  })
              );
            });
          break;
        default:
          await agentRef.get().then((snapshot) => {
            setAgents(
              snapshot.docs
                .filter((d) => d.id !== agencyAgent?.id)
                .map((d) => {
                  return { id: d.id, data: d.data() };
                })
            );
          });
          break;
      }
    },
    [agensDataLimitLength]
  );

  const onEditRow = (id: number, entity: any) => {
    if (entity) {
      navigate(`/agents/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onRowDetails = (id: any, entity: any) => {
    if (entity) {
      navigate(`/agents/${entity.id}/details`, {
        state: { entity: entity, id: entity.id },
      });
    }
  };
  const onAffiliateToDealer = (id: number, entity: any) => {
    if (entity) {
      navigate(`/agents/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id, affiliate: true },
      });
    }
  };
  const onEditAgentLocalisation = (id: number, entity: any) => {
    if (entity) {
      navigate(`/agents/${entity.id}/edit`, {
        state: { entity: entity, id: entity.id, editLocalisation: true },
      });
    }
  };

  const retrivePhoneNumber = async (phoneNumber: string) => {
    if (phoneNumber.length >= 12) {
      await db
        .collection(collectionName)
        .where("userRole", "==", UserRole.USER)
        .where("phoneNumber", "==", phoneNumber)
        .get()
        .then((response) => {
          setUserData(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
    } else {
      setUserData([]);
    }
  };
  const retriveAgentByPhoneNumber = useCallback(async () => {
    let agentRef = db
      .collection(collectionName)
      .where("userRole", "==", UserRole.AGENT)
      .where("phoneNumber", "==", phoneNumberSearch ?? "");
    switch (user?.userRole) {
      case UserRole.DEALER:
        await agentRef
          .where("dealerId", "==", user?.id)
          .get()
          .then((response) => {
            setUserData(
              response.docs.map((d) => {
                return { id: d.id, data: d.data() };
              })
            );
          });
        break;
      case UserRole.SUPERVISOR:
        await agentRef
          .where("supervisorId", "==", user?.id)
          .get()
          .then((response) => {
            setUserData(
              response.docs.map((d) => {
                return { id: d.id, data: d.data() };
              })
            );
          });
        break;
      default:
        await agentRef.get().then((response) => {
          setUserData(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
        break;
    }
  }, [phoneNumberSearch]);

  const retriveAgentByAgentCode = useCallback(async () => {
    let agentRef = db
      .collection(collectionName)
      .where("userRole", "==", UserRole.AGENT)
      .where("agentCode", "==", agentCodeSearch ?? "");
    switch (user?.userRole) {
      case UserRole.DEALER:
        await agentRef
          .where("dealerId", "==", user?.id)
          .get()
          .then((response) => {
            setUserData(
              response.docs.map((d) => {
                return { id: d.id, data: d.data() };
              })
            );
          });
        break;
      case UserRole.SUPERVISOR:
        await agentRef
          .where("supervisorId", "==", user?.id)
          .get()
          .then((response) => {
            setUserData(
              response.docs.map((d) => {
                return { id: d.id, data: d.data() };
              })
            );
          });
        break;

      default:
        await agentRef.get().then((response) => {
          setUserData(
            response.docs.map((d) => {
              return { id: d.id, data: d.data() };
            })
          );
        });
        break;
    }
  }, [agentCodeSearch]);

  useEffect(() => {
    if (phoneNumberSearch?.length === 12) {
      retriveAgentByPhoneNumber();
    } else if (agentCodeSearch?.length === 6) {
      retriveAgentByAgentCode();
    }
  }, [agentCodeSearch, phoneNumberSearch]);

  const handleChangeAgentCodeSearch = (event: any) => {
    setAgentCodeSearch(event.target.value);
  };

  const handleChangePhoneNumberSearch = (value: string) => {
    setPhoneNumberSearch(value);
  };
  const exportToXLSData = useCallback(async() => {
    const worksheet = XLSX.utils.json_to_sheet(
      await Promise.all(
      (agents as [])?.map(async (user: any) => {
        const userRef = db
        .collection(usersCollectionName)
        .doc(user.id)
        const sankAcountRef = userRef.collection(accountsCollectionName).where("accountType", "==", "MAIN");
        const sankAcountsData = await sankAcountRef.get();
        const sankAccountData: any = sankAcountsData.docs[0].data()
        const balance: number = sankAccountData.balance
        return {
          "Date de création de compte": (user.data.createdAt as Timestamp)
            .toDate()
            .toLocaleString(),
          "Nom prénom": user.data.displayName ?? "-",
          Email: user.data.email ?? "-",
          Ville: user.data.city ?? "-",
          Téléphone: user.data.phoneNumber ?? "-",
          "Code agent": user.data.agentCode ?? "-",
          // @ts-ignores
          "Solde": balance ?? "0",
        };
      }))
    );
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "export");

    XLSX.writeFile(
      workbook,
      `La-liste-de-${
        agents?.length
      }-agents-${new Date().toLocaleDateString()}.xlsx`,
      { compression: true }
    );
  }, [agents]);

  const firestoreTableQueriesWhere = useMemo(() => {
    const queries = [];

    if (startTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: ">=",
        value: startTime,
      });
    }

    if (endTime) {
      queries.push({
        fieldPath: "createdAt",
        opStr: "<=",
        value: endTime,
      });
    }
    return queries;
  }, [startTime, endTime]);

  return (
    <>
      <div className="px-4 mt-10 sm:px-6 lg:px-8">
        <div className="flex flex-col">
          <div className="">
            <h1 className="py-2 text-xl font-semibold text-gray-900">
              Les agents
            </h1>
            <p className="mt-2 text-sm text-gray-700">
              Vous trouverez ci-dessous tous les agents affiliés à votre compte.
            </p>
          </div>
          <div className="h-8" />
          <div className="flex self-end max-md:flex-wrap">
            <HasRoles userRole={["ADMIN", "AGENCYSTAFF", "SUPERVISOR", "TECHNICALSUPPORT"]}>
              <>
                {(user?.userRole === UserRole.ADMIN ||
                  user?.userRole === UserRole.SUPERVISOR ||
                  user?.userRole === UserRole.TECHNICALSUPPORT ||
                  user?.userAgencySubRole === AgencyStaffRole.MANAGER) && (
                  <button
                    type="button"
                    className="relative my-auto inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 max-md:w-48"
                    onClick={(e) => {
                      setShowModal(true);
                    }}
                  >
                    <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                      <UserPlusIcon
                        className="self-center flex-shrink-0 w-5 h-5 mr-1 text-orange-500"
                        aria-hidden="true"
                      />
                      Ajouter un agent
                    </span>
                  </button>
                )}
              </>
            </HasRoles>
            <button
              type="button"
              className="relative my-auto inline-flex items-center justify-center p-0.5 mb-2 mr-2 overflow-hidden text-sm font-medium text-red-700 rounded-lg border border-red-300 bg-white hover:bg-red-50 focus:ring-4 focus:outline-none focus:ring-purple-200 max-md:w-52"
              onClick={(e) => {
                e.preventDefault();
                setShowDetailsModal(true);
              }}
            >
              <span className="relative flex px-5 py-2.5 transition-all ease-in duration-75 bg-transparent dark:bg-white rounded-md group-hover:bg-opacity-0">
                <MagnifyingGlassIcon
                  className="self-center flex-shrink-0 w-5 h-5 mr-1 text-orange-500"
                  aria-hidden="true"
                />
                Rechercher un agent
              </span>
            </button>
            <div className="flex px-1 bg-white border border-gray-200 rounded-lg dark:bg-gray-800 dark:border-gray-700 dark:hover:bg-gray-700 sm:mr-1 lg:mr-1">
              <div className="mr-1">
                <label
                  htmlFor="dateDebut"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date début
                </label>
                <input
                  type="date"
                  id="dateDebut"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeStartDateValue}
                />
              </div>
              <div className="mr-1">
                <label
                  htmlFor="dateFin"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Date fin
                </label>
                <input
                  type="date"
                  id="dateFin"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  defaultValue={`${moment(Date.now()).format("YYYY-MM-DD")}`}
                  onChange={handleChangeEndDtaeValue}
                />
              </div>
              <div className="flex items-center justify-center mt-5 h-14">
                <div className="flex">
                  <button
                    type="button"
                    className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-red-700 bg-white border border-red-300 rounded-md shadow-sm hover:bg-red-50 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                    onClick={() => {
                      getFilteredByDate();
                    }}
                  >
                    <FunnelIcon
                      className="self-center flex-shrink-0 w-5 h-5 mr-1 text-red-500"
                      aria-hidden="true"
                    />
                    Filtrer
                  </button>
                </div>
              </div>
            </div>
            <HasRoles userRole={["SUPERVISOR", "DEALER"]}>
              <div className="mx-2">
                <label
                  htmlFor="dateFin"
                  className="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
                >
                  Exporter
                </label>
                <button
                  type="button"
                  className={classNames(
                    agents.length > 0
                      ? "text-green-700 border-green-300 hover:bg-green-50 focus:ring-green-500"
                      : "text-gray-700 border-gray-300 hover:bg-gray-50 focus:ring-gray-500 cursor-not-allowed",
                    "inline-flex items-center h-10 px-4 py-2 text-sm font-medium bg-white border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2"
                  )}
                  onClick={() => {
                    agents.length > 0 && exportToXLSData();
                  }}
                  disabled={agents.length > 0 ? false : true}
                >
                  <ArrowDownTrayIcon
                    className={classNames(
                      agents.length > 0 ? "text-green-500" : "text-gray-500",
                      "self-center flex-shrink-0 w-5 h-5 mr-1"
                    )}
                    aria-hidden="true"
                  />
                  <span className="sr-only">Exporter en XLS</span>
                </button>
              </div>
            </HasRoles>
          </div>
        </div>
        <HasRoles userRole={["SUPERVISOR", "DEALER"]}>
          <div className="flex flex-col mt-8">
            <div className="-mx-4 -my-2 sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle">
                <div className="shadow-sm">
                  <table
                    className="min-w-full border-separate"
                    style={{ borderSpacing: 0 }}
                  >
                    <thead className="bg-gray-50">
                      <tr>
                        {tableHeader.map((th, index) => (
                          <th
                            key={index}
                            scope="col"
                            className="sticky top-0 border-b border-gray-300 bg-gray-50 bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                          >
                            {th}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody className="bg-white">
                      {agents && agents.length > 0 ? (
                        agents.map((agent: any, agentIdx: number) => (
                          <tr key={agentIdx}>
                            <td
                              className={classNames(
                                agentIdx !== agents.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {(agentIdx as number) + 1}
                            </td>
                            <td
                              className={classNames(
                                agentIdx !== agents.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              <img
                                className="w-10 h-10 p-1 border rounded-full"
                                src={
                                  agent.data?.profilImageUrl
                                    ? agent.data?.profilImageUrl
                                    : `https://ui-avatars.com/api/?background=random&name=${
                                        agent.data?.displayName || "S"
                                      }`
                                }
                                alt=""
                              />
                            </td>
                            <td
                              className={classNames(
                                agentIdx !== agents.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {agent.data.displayName
                                ? agent.data.displayName
                                : "-"}
                            </td>
                            <td
                              className={classNames(
                                agentIdx !== agents.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {agent.data.adresse ? agent.data.adresse : "-"}
                            </td>
                            <td
                              className={classNames(
                                agentIdx !== agents.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {agent.data.city ? agent.data.city : "-"}
                            </td>
                            <td
                              className={classNames(
                                agentIdx !== agents.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {agent.data.phoneNumber as string}
                            </td>
                            <td
                              className={classNames(
                                agentIdx !== agents.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {agent.data.agentCode as string}
                            </td>

                            <td
                              className={classNames(
                                agentIdx !== agents.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {agent.data.userRole as string}
                            </td>
                            <td
                              className={classNames(
                                agentIdx !== agents.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {agent.data.userAgencySubRole ? "OUI" : "Non"}
                            </td>
                            <td
                              className={classNames(
                                agentIdx !== agents.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {agent.data.active ? (
                                <CheckCircleIcon className="w-6 h-6 text-green-500" />
                              ) : (
                                <XCircleIcon className="w-6 h-6 text-red-500" />
                              )}
                            </td>
                            <td
                              className={classNames(
                                agentIdx !== agents.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "whitespace-nowrap py-4 pl-4 pr-3 text-sm  text-gray-500 sm:pl-6 lg:pl-8"
                              )}
                            >
                              {parseDate(agent.data?.createdAt as Timestamp)}
                            </td>
                            <td
                              className={classNames(
                                agentIdx !== agents.length - 1
                                  ? "border-b border-gray-200"
                                  : "",
                                "relative whitespace-nowrap py-4 pr-4 pl-3 text-right text-sm font-medium sm:pr-6 lg:pr-8"
                              )}
                            >
                              <TableRowEditMenu
                                rowEditHandler={() =>
                                  onEditRow(agentIdx, agent)
                                }
                                rowAffiliateUserHandler={() =>
                                  onAffiliateToDealer(agentIdx, agent)
                                }
                                rowDetailsHandler={() =>
                                  onRowDetails(agentIdx, agent.data)
                                }
                                rowEditMapHandler={() =>
                                  onEditAgentLocalisation(agentIdx, agent.data)
                                }
                              />
                            </td>
                          </tr>
                        ))
                      ) : (
                        <NoContent />
                      )}
                    </tbody>
                  </table>
                  {agents && agents.length > 0 && (
                    <Pagination
                      skip={agents.length}
                      take={agents.length}
                      total={agents.length}
                      collectionName={collectionName}
                      onPageChange={(pageIndex: number) => pageIndex}
                      onPageLimitChange={(pageLimit: number) => {
                        onPageLimitChange(pageLimit);
                        setAgensDataLimitLength(pageLimit);
                      }}
                      isChangedPage={false}
                    />
                  )}
                </div>
              </div>
            </div>
          </div>
        </HasRoles>
        <HasRoles userRole={["ADMIN", "AGENCYSTAFF", "TECHNICALSUPPORT"]}>
          <FirestoreTable
            collectionRef={db.collection(usersCollectionName)}
            collectionQueriesWhere={[
              ...firestoreTableQueriesWhere,
              {
                fieldPath: "userRole",
                opStr: "==",
                value: UserRole.AGENT,
              },
            ]}
            condensed
            columns={columns}
            rowRender={renderRow}
            rowEditHandler={onEditRow}
            rowDetailsHandler={onRowDetails}
            rowEditMapHandler={onEditAgentLocalisation}
            rowAffiliateUserHandler={onAffiliateToDealer}
            displayDetails
            shouldAddEditColumn
            displayEditBtn
            displayDisableBtn
            displayAffiliateUserBtn
            displayEditMapBtn
            paginate={{ pageIndex: 0, limit: 100 }}
          />
        </HasRoles>
      </div>
      <Modal
        isOpen={showDetailsModal}
        maxSize="md"
        closeModal={() => setShowDetailsModal(false)}
        modalTitle="Rechercher un agent"
      >
        <div className="flex justify-center">
          <div className="w-full mt-10">
            <div className="">
              <PhoneInput
                international={false}
                placeholder="Entrer un numéro de téléphone"
                className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                defaultCountry="BF"
                value={phoneNumberSearch}
                onChange={handleChangePhoneNumberSearch}
              />

              <p className="my-3 font-semibold text-center">OU</p>

              <div className="mr-1">
                <input
                  type="text"
                  id="agenCode"
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  placeholder="Entrer un code agent"
                  value={agentCodeSearch}
                  onChange={handleChangeAgentCodeSearch}
                />
              </div>
            </div>
            <div className="flex justify-center w-full">
              {userData && userData.length > 0 && (
                <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex flex-col items-center pb-10">
                    <img
                      className="w-24 h-24 mt-2 mb-3 rounded-full shadow-lg"
                      src={
                        userData[0].data.profilImageUrl
                          ? userData[0].data.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              userData[0].data.displayName || "S"
                            }`
                      }
                      alt=""
                    />
                    <h5 className="mb-1 text-xl font-medium text-center text-gray-900 dark:text-white">
                      {userData[0].data.displayName}
                    </h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {userData[0].data.phoneNumber}
                    </span>
                    <div className="flex mt-4 space-x-3 md:mt-6">
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() =>
                          navigate(`/agents/${userData[0].id}/details`, {
                            state: {
                              entity: userData[0].data,
                              id: userData[0].id,
                              isSearch: !(user?.userRole === UserRole.DEALER),
                            },
                          })
                        }
                      >
                        Details
                      </div>
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                        onClick={() => {
                          setShowDetailsModal(false);
                          setUserData([]);
                        }}
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={showModal}
        maxSize="md"
        closeModal={() => setShowModal(false)}
        modalTitle="Renseigner le numéro de téléphone"
      >
        <div className="flex justify-center">
          <div className="w-full mt-10">
            <PhoneInput
              international={false}
              placeholder="Entrer un numéro de téléphone"
              className="block w-full px-3 py-2 placeholder-gray-400 border appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
              defaultCountry="BF"
              onChange={retrivePhoneNumber}
            />
            <div className="flex justify-center w-full">
              {userData && userData.length > 0 && (
                <div className="w-full max-w-sm my-3 bg-white border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700">
                  <div className="flex flex-col items-center pb-10">
                    <img
                      className="w-24 h-24 mt-2 mb-3 rounded-full shadow-lg"
                      src={
                        userData[0].data.profilImageUrl
                          ? userData[0].data.profilImageUrl
                          : `https://ui-avatars.com/api/?background=random&name=${
                              userData[0].data.displayName || "S"
                            }`
                      }
                      alt=""
                    />
                    <h5 className="mb-1 text-xl font-medium text-center text-gray-900 dark:text-white">
                      {userData[0].data.displayName}
                    </h5>
                    <span className="text-sm text-gray-500 dark:text-gray-400">
                      {userData[0].data.phoneNumber}
                    </span>
                    <div className="flex mt-4 space-x-3 md:mt-6">
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-white bg-red-700 rounded-lg cursor-pointer hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-red-300 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-800"
                        onClick={() =>
                          navigate(`/agents/make_user_agent`, {
                            state: {
                              entity: userData[0].data,
                              id: userData[0].id,
                            },
                          })
                        }
                      >
                        Continuer
                      </div>
                      <div
                        className="inline-flex items-center px-4 py-2 text-sm font-medium text-center text-gray-900 bg-white border border-gray-300 rounded-lg hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 dark:bg-gray-800 dark:text-white dark:border-gray-600 dark:hover:bg-gray-700 dark:hover:border-gray-700 dark:focus:ring-gray-700"
                        onClick={() => {
                          setShowModal(false);
                          setUserData([]);
                        }}
                      >
                        Annuler
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default AgentsPage;
