import { BookOpenIcon, CalendarDaysIcon, CreditCardIcon } from "@heroicons/react/24/outline";
import { NavLink, Outlet } from "react-router-dom";
import { classNames } from "../../../utils";
import HasRoles from "../../../components/HasRoles";

const AdminAuditLogsPage = () => {
  return (
    <div className="bg-white relativ">
      <div className="divide-y lg:grid lg:grid-cols-12 lg:divide-y-0 lg:divide-x">
        <aside className="lg:col-span-2 xl:col-span-2">
          <nav className="">
            <HasRoles userRole={["ADMIN"]}>
              <NavLink
                to={{
                  pathname: "logs",
                }}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "bg-slate-300 text-red-500"
                      : "text-black hover:bg-gray hover:text-red-200",
                    "group w-full p-3 flex text-md font-medium"
                  )
                }
              >
                {({ isActive }) => (
                  <>
                    <BookOpenIcon
                      className={classNames(
                        isActive
                          ? "text-red-500"
                          : "text-black group-hover:text-red-200",
                        "mr-3 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    <span className="">Audit Logs</span>
                  </>
                )}
              </NavLink>
            </HasRoles>
            <HasRoles userRole={["ADMIN"]}>
              <NavLink
                to={{
                  pathname: "transactions",
                }}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "bg-slate-300 text-red-500"
                      : "text-black hover:bg-gray hover:text-red-200",
                    "group w-full p-3 flex text-md font-medium"
                  )
                }
              >
                {({ isActive }) => (
                  <>
                    <CalendarDaysIcon
                      className={classNames(
                        isActive
                          ? "text-red-500"
                          : "text-black group-hover:text-red-200",
                        "mr-3 h-6 w-6"
                      )}
                      aria-hidden="true"
                    />
                    <span className="">Transactions Audit Logs</span>
                  </>
                )}
              </NavLink>
            </HasRoles>
            <HasRoles userRole={["ADMIN"]}>
              <NavLink
                to={{
                  pathname: "agent_create_user_histories",
                }}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "bg-slate-300 text-red-500"
                      : "text-black hover:bg-gray hover:text-red-200",
                    "group w-full p-3 flex text-sm font-medium"
                  )
                }
              >
                {({ isActive }) => (
                  <>
                    <BookOpenIcon
                      className={classNames(
                        isActive
                          ? "text-red-500"
                          : "text-black group-hover:text-red-200",
                        "mr-3 h-7 w-h-7"
                      )}
                      aria-hidden="true"
                    />
                    <span className="">
                      Logs de création d'utilisateurs par les agents
                    </span>
                  </>
                )}
              </NavLink>
            </HasRoles>
            <HasRoles userRole={["AGENCYSTAFF", "ADMIN"]}>
              <NavLink
                to={{
                  pathname: "selle_cards_histories",
                }}
                className={({ isActive }) =>
                  classNames(
                    isActive
                      ? "bg-slate-300 text-red-500"
                      : "text-black hover:bg-gray hover:text-red-200",
                    "group w-full p-3 flex text-sm font-medium"
                  )
                }
              >
                {({ isActive }) => (
                  <>
                    <CreditCardIcon
                      className={classNames(
                        isActive
                          ? "text-red-500"
                          : "text-black group-hover:text-red-200",
                        "mr-3 h-7 w-h-7"
                      )}
                      aria-hidden="true"
                    />
                    Logs de vente de carte
                  </>
                )}
              </NavLink>
            </HasRoles>
          </nav>
        </aside>
        <div className="divide-y lg:col-span-9 xl:col-span-10">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default AdminAuditLogsPage;
