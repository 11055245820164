import { yupResolver } from "@hookform/resolvers/yup";
import { useContext, useState } from "react";
import toast from "react-hot-toast";
import { FormInput, Form } from "../../../components/FormComponents";
import Modal from "../../../components/Modal";
import ReadeQrcode from "../../../components/Qrcode/ReadeQrcode";
import { UserContext } from "../../../contexts";
import { db, functions } from "../../../firebase";
import { classNames, isNumeric } from "../../../utils";
import * as yup from "yup";
import { QrCodeIcon } from "@heroicons/react/24/outline";
import "react-phone-number-input/style.css";
import {
  accountsCollectionName,
  defaultAmount,
  userCardRelationCollection,
  usersCollectionName,
} from "../../../config";
import { useRouter } from "../../../hooks/useRouter";

const GasStationPaymentWithCard = () => {
  const [isCodePinBtnDesabled, setIsCodePinBtnDesabled] = useState(false);
  const { user, setUserAccounts, gasStationInfo } = useContext(UserContext);
  const [description, setDescription] = useState("");
  const router = useRouter();
  const [isDataGet, setIsDataGet] = useState(false);
  const [qrcodeScannedData, setQrcodeScannedData] = useState<string[]>([]);
  const [showPinCodeModal, setShowPinCodeModal] = useState(false);
  const [senderData, setSenderData] = useState<any>();
  const [amount, setAmount] = useState<string>(defaultAmount);
  const [showQrcodeReadeModal, setShowQrcodeReadeModal] = useState(false);
  const [withCard, setWithCard] = useState<boolean>(false);
  const sankMoneyGasStationPaymentProductsFn = functions.httpsCallable(
    "sankMoneyGasStationPaymentProductsFn"
  );
  const userRef = db.collection(usersCollectionName);
  const accountsRef = userRef.doc(user?.id).collection(accountsCollectionName);

  const onSubmit = async (data: any) => {
    setIsCodePinBtnDesabled(true);
    if (user) {
      if (!isNumeric(amount)) {
        setIsCodePinBtnDesabled(false);
        setShowPinCodeModal(false);
        return toast.error(`Le montant n'est pas bien formatté`);
      }
      if (amount < defaultAmount) {
        setIsCodePinBtnDesabled(false);
        setShowPinCodeModal(false);
        return toast.error(
          `Le montant doit être suppérieur à ${defaultAmount} Fcfa`
        );
      }

      await sankMoneyGasStationPaymentProductsFn({
        pinCode: data.pinCode,
        amount: amount.replace(/\s/g, "").trim(),
        senderPhoneNumber: senderData.length > 0 && senderData[0]?.phoneNumber,
        receiverPhoneNumber: gasStationInfo?.gasStationCode,
        isCardPayment: withCard ? true : false,
        cardId: qrcodeScannedData[0] ?? "",
        description: description ?? "",
        appPlatform: "web",
        withConnection: true,
      })
        .then(async (result) => {
          setWithCard(false);
          setShowPinCodeModal(false);
          router.push("/dashboard");
          const accounts = await accountsRef.get();
          setUserAccounts(accounts.docs.map((account) => account.data()));
          return toast.success(
            "Le compte du marchant a été crédité avec succès"
          );
        })
        .catch((err: { details: any; message: any }) => {
          setWithCard(false);
          setShowPinCodeModal(false);
          toast.error(err.message);
        });
    }
  };

  const getsenderData = async () => {
    setShowQrcodeReadeModal(false);
    if (qrcodeScannedData.length > 0 && !isDataGet) {
      const cardId = qrcodeScannedData[0];
      await db
        .collection(userCardRelationCollection)
        .where("cardId", "==", cardId)
        .get()
        .then(async (response) => {
          setIsDataGet(true);
          if (response.empty) {
            setSenderData(undefined);
            return toast.error("La carte est indisponible");
          } else {
            setWithCard(true);
            await db
              .collection(usersCollectionName)
              .doc(response.docs[0].data()?.userId)
              .get()
              .then((res) => {
                setIsDataGet(true);
                const data = res.data();
                setSenderData(data);
                return data;
              });
          }
        });
    }
  };

  const handleDescriptionChange = (e: any) => {
    e.preventDefault();
    const value = e.target.value;
    setDescription(value);
  };

  const handleChangeAmountValue = (e: any) => {
    e.preventDefault();
    setAmount(`${e.target.value}` ?? defaultAmount);
  };

  const onQrcodeResult = (result: any) => {
    setShowQrcodeReadeModal(false);
    if (result && !isDataGet) {
      setQrcodeScannedData(result);
      setShowQrcodeReadeModal(false);
      getsenderData();
    }
  };
  const onQrcodeError = (error: any) => {
    setShowQrcodeReadeModal(false);
  };
  return (
    <div className="flex justify-center w-full">
      <div className="w-full max-w-md px-2 py-16 sm:px-0">
        <div className="flex items-center justify-center w-full font-manrope">
          <div className="box-border w-full p-4 mx-auto bg-white border">
            <div className="flex justify-end text-red-500">
              <QrCodeIcon
                className="w-6 h-6 cursor-pointer text-red-red-500"
                aria-hidden="true"
                onClick={() => {
                  setShowQrcodeReadeModal(true);
                }}
              />
            </div>
            <div className="mt-6">
              <div className="font-semibold">Le numéro du client</div>
              <div>
                <input
                  type="text"
                  id="code"
                  disabled
                  className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
                  value={senderData?.phoneNumber}
                />

                {senderData ? (
                  <p className="mt-1 text-xs text-green-500">Numéro valide</p>
                ) : (
                  <p className="mt-1 text-xs text-red-500">
                    Scannez le code qr de l'utilisateur
                  </p>
                )}
              </div>
            </div>
            <div className="mt-6">
              <div className="font-semibold">
                Combien souhaitez-vous payer ?
              </div>
              <div>
                <input
                  className="mt-1 w-full rounded-[4px] border border-[#A0ABBB] p-2"
                  type="number"
                  placeholder="Ex: 1000"
                  pattern="[0-9]{9}"
                  min={0}
                  onChange={handleChangeAmountValue}
                />
              </div>
            </div>
            <div className="mt-6">
              <div className="font-semibold">
                Entrez une descriptions de la transaction
              </div>
              <div>
                <textarea
                  id="message"
                  rows={4}
                  onChange={handleDescriptionChange}
                  maxLength={200}
                  className="block p-2.5 w-full text-sm text-gray-900 bg-gray-50 rounded-lg border border-gray-300 focus:ring-red-500 focus:border-red-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-red-500 dark:focus:border-red-500"
                  placeholder="Écrivez votre description ici..."
                ></textarea>
              </div>
            </div>
            <div className="mt-6">
              <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                <img
                  className="w-10 h-10 rounded-full"
                  src={
                    user?.profilImageUrl
                      ? user?.profilImageUrl
                      : `https://ui-avatars.com/api/?background=random&name=${
                          user?.displayName || "S"
                        }`
                  }
                  alt=""
                />
                <div>
                  <div className="font-semibold">{user?.displayName}</div>
                </div>
              </div>
            </div>
            {senderData && (
              <div className="mt-6">
                <div className="flex items-center gap-x-[10px] bg-neutral-100 p-3 mt-2 rounded-[4px]">
                  <img
                    className="w-10 h-10 rounded-full"
                    src={
                      senderData?.profilImageUrl
                        ? senderData?.profilImageUrl
                        : `https://ui-avatars.com/api/?background=random&name=${
                            senderData?.displayName || "S"
                          }`
                    }
                    alt=""
                  />
                  <div>
                    <div className="font-semibold">
                      {senderData?.displayName}
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div className="mt-6">
              <div
                className={classNames(
                  amount ? "bg-red-600  cursor-pointer" : "bg-gray-600",
                  "w-full rounded-[4px]  px-3 py-[6px] text-center font-semibold text-white"
                )}
                onClick={() => amount && setShowPinCodeModal(true)}
              >
                Retirer:{" "}
                {new Intl.NumberFormat().format(parseInt(amount ?? "0"))} Fcfa
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={showPinCodeModal}
        maxSize="md"
        closeModal={() => {
          setShowPinCodeModal(false);
          setIsCodePinBtnDesabled(false);
        }}
      >
        <div className="flex justify-center">
          <Form<{ pinCode: string }>
            onSubmit={onSubmit}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  pinCode: yup
                    .string()
                    .required("Le champs est obligatoire")
                    .min(4, "Le champs doit contenir plus de 4 caractères"),
                })
              ),
            }}
            submitButtonFullWidth
            submitButtonLabel={`Valider la transaction`}
            isSubmitBtnDisabled={isCodePinBtnDesabled}
          >
            <div className="flex flex-col content-center w-full px-5 mt-5 align-top md:flex-row">
              <div className="w-full mx-auto ">
                <FormInput
                  type="password"
                  name="pinCode"
                  maxLength={4}
                  autoFocus={false}
                  label="Entrez votre code pin"
                  helpLabel="Veillez entrer un code pin de quatre (04) chiffres"
                />
              </div>
            </div>
          </Form>
        </div>
      </Modal>
      <ReadeQrcode
        onQrcodeResult={(result: any) => onQrcodeResult(result)}
        onQrcodeError={(error: any) => onQrcodeError(error)}
        showQrcodeReadeModal={showQrcodeReadeModal}
        setShowQrcodeReadeModal={setShowQrcodeReadeModal}
      />
    </div>
  );
};

export default GasStationPaymentWithCard;
