import {
  ExclamationCircleIcon,
  ChevronDownIcon,
  XMarkIcon,
} from "@heroicons/react/24/solid";
import {
  Dispatch,
  InputHTMLAttributes,
  SetStateAction,
  useCallback,
  useEffect,
  useState,
} from "react";
import {
  Controller,
  ControllerRenderProps,
  FieldErrors,
  FieldValues,
  useFormContext,
} from "react-hook-form";
import { HelpTooltip } from "../HelpTooltip";
import AsyncCreatable from "react-select/async-creatable";
import {
  ClearIndicatorProps,
  DropdownIndicatorProps,
  MultiValueRemoveProps,
  components,
} from "react-select";
import { unwindObject, classNames } from "../../../utils";

export interface FormMultiInputSelectProps<T>
  extends Omit<InputHTMLAttributes<HTMLInputElement>, "id" | "className"> {
  name: string;
  label?: string;
  optionLabel?: (option: T) => string;
  inputToNewOption: (input: string) => T;
  loadOptions?: (inputValue: any, callback: any) => Promise<any>;
  selectedOptions?: T[];
  isMulti?: boolean;
  tooltip?: string;
  condensed?: boolean;
  placeholder?: string;
  helpLabel?: string;
  retrieveFieldLength?: (length: number) => void;
}

export function FormMultiInputSelect<T>({
  name,
  label,
  optionLabel = (option) => `${option}`,
  loadOptions,
  placeholder,
  helpLabel,
  selectedOptions = [],
  isMulti = true,
  condensed = false,
  tooltip,
  inputToNewOption,
  retrieveFieldLength,
  ...rest
}: FormMultiInputSelectProps<T>): JSX.Element {
  const methods = useFormContext();
  const [selected, setSelected] = useState(selectedOptions);
  const [inputValue, setInputValue] = useState("");
  const [value, setValue] = useState<readonly T[]>([]);
  const handleKeyDown = (
    event: any,
    field: ControllerRenderProps<FieldValues, string>
  ) => {
    if (!inputValue) return;
    const newCreatedItem = inputToNewOption && inputToNewOption(inputValue);
    switch (event.key) {
      case "Enter":
      case "Tab":
        if (newCreatedItem) {
          setValue((prev) => [...prev, newCreatedItem]);
        }
        setInputValue("");
        event.preventDefault();
    }
  };

  // handle input change event
  const handleInputChange = (value: any) => {
    setValue(value);
  };

  const errorName = unwindObject(methods.formState.errors, name);
  const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
      <components.DropdownIndicator {...props}>
        <ChevronDownIcon className="w-4 h-4" />
      </components.DropdownIndicator>
    );
  };

  const ClearIndicator = (props: ClearIndicatorProps) => {
    return (
      <components.ClearIndicator {...props}>
        <XMarkIcon className="w-4 h-4" />
      </components.ClearIndicator>
    );
  };

  const MultiValueRemove = (props: MultiValueRemoveProps) => {
    return (
      <components.MultiValueRemove {...props}>
        <XMarkIcon className="w-4 h-4 text-red-500" />
      </components.MultiValueRemove>
    );
  };

  const retrieveFieldLengthVal = useCallback(
    (length: number) => {
      retrieveFieldLength && retrieveFieldLength(length);
    },
    [retrieveFieldLength]
  );

  const controlStyles = {
    base: "border rounded-lg bg-white dark:bg-slate-800 hover:cursor-pointer",
    focus: "border-emerald-600 ring-1 ring-emerald-500",
    nonFocus:
      "border-gray-300 dark:border-slate-700 hover:border-gray-400 dark:hover:border-slate-700",
  };
  const placeholderStyles = "!text-gray-400 pl-1 py-0.5";
  const selectInputStyles = "pl-1 py-0.5 !text-gray-700 dark:!text-gray-300";
  const valueContainerStyles = "p-1 gap-1";
  const singleValueStyles = "leading-7 ml-1";
  const multiValueStyles =
    "!bg-gray-200 dark:!bg-slate-700  rounded items-center py-0.5 pl-2 pr-1 gap-1.5";
  const multiValueLabelStyles = "leading-6 py-0.5 dark:!text-gray-300";
  const multiValueRemoveStyles =
    "border border-gray-200 dark:border-transparent bg-white dark:bg-slate-800 hover:bg-red-50 hover:text-red-800 text-gray-500 hover:border-red-300 rounded-md";
  const indicatorsContainerStyles = "p-1 gap-1 dark:bg-slate-800";
  const clearIndicatorStyles =
    "text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800";
  const indicatorSeparatorStyles = "bg-gray-300 dark:bg-slate-700";
  const dropdownIndicatorStyles =
    "p-1 hover:bg-gray-100 text-gray-500 dark:hover:bg-slate-600 rounded-md hover:text-black";
  const menuStyles =
    "p-1 mt-2 border border-gray-200 dark:border-slate-700 bg-white dark:bg-slate-800 dark:text-gray-400 rounded-lg";
  const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm";
  const optionStyles = {
    base: "hover:cursor-pointer px-3 py-2 !text-gray-800 hover:!text-white dark:!text-gray-300 rounded hover:bg-emerald-600 dark:hover:bg-emerald-600",
    focus:
      "!bg-emerald-600 active:bg-emerald-600 dark:bg-emerald-600 !text-white dark:active:bg-emerald-600",
    selected:
      "after:content-['✔'] after:ml-2 after:text-green-500 text-gray-700 bg-emerald-600",
  };
  const noOptionsMessageStyles =
    "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm";
  return (
    <div className="my-4">
      <Controller
        control={methods.control}
        defaultValue={selected}
        name={name}
        render={({ field }) => {
          // Use useEffect to call retrieveFieldLengthVal after render
          // eslint-disable-next-line react-hooks/rules-of-hooks
          useEffect(() => {
            retrieveFieldLengthVal(field.value.length);
          }, [field.value.length]);

          return (
            <>
              {label && (
                <label
                  htmlFor={name}
                  className="flex justify-between text-sm font-medium text-gray-700 dark:text-gray-300"
                >
                  <span
                    className={classNames(
                      rest.required ? "is-required" : "",
                      "block"
                    )}
                  >
                    {label}
                  </span>
                  {field.value && Array.isArray(field.value) && (
                    <p
                      className={classNames(
                        condensed ? "mt-1" : "mt-2",
                        "text-sm text-gray-500 justify-end flex"
                      )}
                    >
                      {`Nombre de contacts saisis : ${field.value.length}`}
                    </p>
                  )}
                  {tooltip && <HelpTooltip value={tooltip} />}
                </label>
              )}
              <div className="relative mt-1 rounded-md shadow-sm">
                <AsyncCreatable
                  {...field}
                  isClearable
                  isMulti={isMulti}
                  defaultOptions
                  allowCreateWhileLoading
                  isSearchable
                  noOptionsMessage={(value) =>
                    `Aucun résultat${
                      value && value.inputValue
                        ? ` pour ${value.inputValue}.`
                        : `.`
                    }`
                  }
                  placeholder={placeholder}
                  loadOptions={loadOptions}
                  onInputChange={handleInputChange}
                  styles={{
                    input: (base) => ({
                      ...base,
                      "input:focus": {
                        boxShadow: "none",
                      },
                    }),
                    multiValueLabel: (base) => ({
                      ...base,
                      whiteSpace: "normal",
                      overflow: "visible",
                    }),
                    control: (base) => ({
                      ...base,
                      transition: "none",
                    }),
                  }}
                  components={{
                    DropdownIndicator,
                    ClearIndicator,
                    MultiValueRemove,
                  }}
                  classNames={{
                    control: ({ isFocused }) =>
                      classNames(
                        isFocused
                          ? controlStyles.focus
                          : controlStyles.nonFocus,
                        methods.formState.errors[name]
                          ? "border-red-300 dark:border-red-500 focus:ring-red-500 focus:border-red-500"
                          : "focus:ring-emerald-500 focus:border-emerald-500",
                        controlStyles.base
                      ),
                    placeholder: () => placeholderStyles,
                    input: () => selectInputStyles,
                    valueContainer: () => valueContainerStyles,
                    singleValue: () => singleValueStyles,
                    multiValue: () => multiValueStyles,
                    multiValueLabel: () => multiValueLabelStyles,
                    multiValueRemove: () => multiValueRemoveStyles,
                    indicatorsContainer: () => indicatorsContainerStyles,
                    clearIndicator: () => clearIndicatorStyles,
                    indicatorSeparator: () => indicatorSeparatorStyles,
                    dropdownIndicator: () => dropdownIndicatorStyles,
                    menu: () => menuStyles,
                    groupHeading: () => groupHeadingStyles,
                    option: ({ isFocused, isSelected }) =>
                      classNames(
                        isFocused ? optionStyles.focus : "",
                        isSelected ? optionStyles.selected : "",
                        optionStyles.base
                      ),
                    noOptionsMessage: () => noOptionsMessageStyles,
                  }}
                />
                {methods.formState.errors[name] && (
                  <div className="absolute inset-y-0 right-0 flex items-center pb-8 pointer-events-none">
                    <ExclamationCircleIcon
                      className="w-5 h-5 text-red-500"
                      aria-hidden="true"
                    />
                  </div>
                )}
              </div>

              {helpLabel && !errorName && (
                <p
                  className={classNames(
                    condensed ? "mt-1" : "mt-2",
                    "text-sm text-gray-500"
                  )}
                >
                  {helpLabel}
                </p>
              )}
              {methods.formState.errors[name] && (
                <p className="flex mt-1 space-x-1 text-sm text-red-600">
                  {Array.isArray(methods.formState.errors[name]) &&
                    (
                      methods.formState.errors[
                        name
                      ] as unknown as FieldErrors<FieldValues>[]
                    ).map((error, idx) => (
                      <span key={idx} className="">
                        {idx > 0 &&
                        (
                          methods.formState.errors[
                            name
                          ] as unknown as FieldErrors<FieldValues>[]
                        ).length > 0
                          ? ", "
                          : ""}
                        {`[${methods.getValues(name)[idx]?.value}] : `}
                        {(error as any).value?.message}
                      </span>
                    ))}
                </p>
              )}
              {methods.formState.errors[name] &&
                !Array.isArray(methods.formState.errors[name]) && (
                  <p className="mt-1 text-sm text-red-600">
                    {`${methods.formState.errors[name]?.message}`}
                  </p>
                )}
            </>
          );
        }}
      />
    </div>
  );
}

export default FormMultiInputSelect;
