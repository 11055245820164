import {
  getCountFromServer,
  collection,
  query as firestoreQuery,
  where,
  WhereFilterOp,
} from "firebase/firestore";
import { useState, useEffect, useCallback, useMemo } from "react";
import { db } from "../../firebase";

function useFetch(
  collectionRef: firebase.default.firestore.CollectionReference<firebase.default.firestore.DocumentData>,
  query: firebase.default.firestore.Query<firebase.default.firestore.DocumentData>,
  cursorDocumentSnapshot?: firebase.default.firestore.DocumentSnapshot,
  collectionQueriesWhere?: { fieldPath: string; opStr: string; value: any }[]
) {
  const [data, setData] = useState<any[]>();
  const [totalItems, setTotalItems] = useState<number>();
  const [loading, setLoading] = useState(false);
  const [lastDocSnapshot, setlastDocSnapshot] =
    useState<
      firebase.default.firestore.DocumentSnapshot<firebase.default.firestore.DocumentData>
    >();
  const [error, setError] = useState<any>();

  const refreshData = useCallback(() => {
    setLoading(true);
    const transformedQuery =
      cursorDocumentSnapshot != null
        ? query.startAfter(cursorDocumentSnapshot)
        : query;
    transformedQuery
      .get()
      .then((querySnapshot) => {
        setLoading(false);
        const data = querySnapshot.docs.map((d) => d.data());
        setData(data);
        setlastDocSnapshot(querySnapshot.docs[querySnapshot.docs.length - 1]);
      })
      .catch((err) => {
        console.error(err);
        setError(err);
        setLoading(false);
      });
  }, [cursorDocumentSnapshot, query]);

  const queriesWhere = useMemo(() => {
    const firebaseInequalitiesFilters = ["<", "<=", "!=", "not-in", ">", ">="];

    let filterQueries: {
      fieldPath: string;
      opStr: string;
      value: any;
    }[] = [];

    if (collectionQueriesWhere) {
      collectionQueriesWhere?.forEach((query) => {
        if (firebaseInequalitiesFilters.includes(query.opStr)) {
          filterQueries = [query, ...filterQueries];
          return;
        }

        filterQueries.push(query);
      });

      return filterQueries;
    }

    return [];
  }, [collectionQueriesWhere]);
  useEffect(() => {
    refreshData();
    setLoading(true);
    setData(undefined);
    setError(undefined);

    const queryCollection = collection(db, collectionRef.path);

    const wheres =
      collectionQueriesWhere?.map((el) =>
        where(el.fieldPath, el.opStr as WhereFilterOp, el.value)
      ) || [];

    getCountFromServer(firestoreQuery(queryCollection, ...wheres))
      .then((count) => {
        const totalSize = count.data().count;
        setTotalItems(totalSize);
      })
      .catch((err) => {
        console.error(err);
      });
  }, [refreshData, collectionRef, queriesWhere, collectionQueriesWhere]);

  return { data, lastDocSnapshot, totalItems, loading, error, refreshData };
}

export default useFetch;
