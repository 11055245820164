import React from 'react'
import { useLocation } from 'react-router-dom';
import DisplayItemDetails from '../../components/DisplayItemComponent';
import UserDetailsPageComponent from '../../components/UserDetailsComponent';

const SuperMerchantDetails = () => {
  const location = useLocation();
  const currentUser = location.state?.entity;
  const isSearch = location.state?.isSearch;
  return isSearch ? (
    <UserDetailsPageComponent
      userDetailsData={currentUser}
      makeDealer={false}
    />
  ) : (
    <DisplayItemDetails currentUser={currentUser} />
  );
}

export default SuperMerchantDetails