import React, { FormHTMLAttributes, ReactElement, useEffect } from "react";
import {
  FieldValues,
  FormProvider,
  SubmitHandler,
  useForm,
  UseFormProps,
  UseFormReturn,
} from "react-hook-form";
import ActionButton from "../ActionButton";
import { classNames } from "../../../utils";

export interface FormProps<T extends FieldValues>
  extends Omit<FormHTMLAttributes<HTMLFormElement>, "onSubmit"> {
  form: UseFormProps<T>;
  condensed?: boolean;
  formId?: string;
  children: ReactElement | ReactElement[];
  onSubmit: SubmitHandler<T>;
  resetAfterSubmit?: boolean;
  maximizeFormWidh?: boolean;
  maximizeFormActionBtnWidh?: boolean;
  onCancel?: () => void;
  formReturnAware?: (useFormReturn: UseFormReturn<T>) => void;
  submitButtonLabel?: string;
  isSubmitBtnDisabled?: boolean;
  cancelButtonLabel?: string;
  submitButtonType?: "submit" | "button" | "reset" | undefined;
  submitButtonFullWidth?: boolean;
  addStyle?: boolean;
  useCustomFooter?: boolean;
  formDefaultRevalidateMode?: "onSubmit" | "onBlur" | "onChange";
  showGlobalFormErrorMessage?: boolean;
  downloadCsvOrXlsxFileBtn?: JSX.Element | undefined;
  otherBtn?: JSX.Element | undefined;
}

export function Form<T extends FieldValues>({
  children,
  onSubmit,
  resetAfterSubmit = false,
  onCancel,
  formReturnAware,
  form,
  condensed = false,
  formId,
  submitButtonLabel = "Save",
  cancelButtonLabel = "Cancel",
  submitButtonType = "submit",
  submitButtonFullWidth = false,
  maximizeFormWidh = true,
  maximizeFormActionBtnWidh = true,
  addStyle,
  useCustomFooter = false,
  formDefaultRevalidateMode = "onChange",
  showGlobalFormErrorMessage = true,
  isSubmitBtnDisabled,
  downloadCsvOrXlsxFileBtn,
  otherBtn,
  ...rest
}: FormProps<T>): JSX.Element {
  const methods = useForm<T>(form);
  if (form.reValidateMode === undefined) {
    form.reValidateMode = formDefaultRevalidateMode;
  }
  useEffect(() => {
    formReturnAware && formReturnAware(methods);
  }, [formReturnAware, methods]);
  const { isDirty, isValid } = methods.formState;

  const isChildHandleByForm = (child: ReactElement) => {
    return (
      child &&
      child.type instanceof Function &&
      (child.type.name === "FormSelect" ||
        child.type.name === "FormMultiSelect" ||
        child.type.name === "FormInput") &&
      child.props.name
    );
  };

  return (
    <FormProvider {...methods}>
      <form
        id={formId}
        className={classNames(
          condensed ? 'space-y-2' : 'space-y-4',
          maximizeFormWidh ? 'max-w-3xl' : '',
          'divide-y divide-gray-200 dark:divide-slate-600'
        )}
        onSubmit={methods.handleSubmit(onSubmit)}
        noValidate
      >
        <div
          className={classNames(
            condensed ? "space-y-2" : "space-y-6",
            "py-2  sm:p-2 lg:pb-2"
          )}
        >
          {React.Children.map(children, (child) => {
            return isChildHandleByForm(child)
              ? React.createElement(child.type, {
                  ...{
                    key: child.props.name,
                    ...methods.register,
                    ...child.props,
                  },
                })
              : child;
          })}
        </div>
        {!useCustomFooter && (
          <div className={classNames(submitButtonFullWidth ? "" : "", "mt-2 ")}>
            {showGlobalFormErrorMessage &&
              methods.formState.isSubmitted &&
              Object.keys((methods as any).formState.errors).length > 0 && (
                <p className="mt-2 text-sm text-center text-red-600">
                  Le(s) champ(s) est(sont) obligatoire(s)
                </p>
              )}
            <div className="flex justify-end py-2 mt-2 ">
              {downloadCsvOrXlsxFileBtn}
              {otherBtn}
              {onCancel && (
                <button
                  type="button"
                  onClick={onCancel}
                  className="inline-flex items-center h-10 px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-offset-2"
                >
                  {cancelButtonLabel}
                </button>
              )}
              <div className={classNames(maximizeFormActionBtnWidh ? "w-full" : "")}>
                <ActionButton
                  label={submitButtonLabel}
                  type={submitButtonType}
                  fullWitdh={submitButtonFullWidth}
                  addStyle={addStyle}
                  isBtnDisabled={
                    isSubmitBtnDisabled
                      ? isSubmitBtnDisabled
                      : form.mode === "all" && (!isDirty || !isValid)
                  }
                />
              </div>
            </div>
          </div>
        )}
      </form>
    </FormProvider>
  );
}

export default Form;
