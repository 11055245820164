/* eslint-disable jsx-a11y/no-redundant-roles */
import {
  LockClosedIcon,
  AtSymbolIcon,
  XMarkIcon,
} from "@heroicons/react/24/outline";
import React, { useContext, useEffect, useState } from "react";
import {
  AttendantRole,
  UserInterface,
  UserRole,
  mapsGasStationAttendantRoles,
  mapsGender,
} from "../../../../interfaces";
import { UseFormReturn } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useConfirm } from "../../../../hooks/useConfirm";
import { db, functions } from "../../../../firebase";
import toast from "react-hot-toast";
import {
  PhoneNumberDefaultLength,
  gasStationMembershipCollectionName,
  usersCollectionName,
} from "../../../../config";
import { classNames } from "../../../../utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { EditFormFooter } from "../../../../components/EditFormFooter";
import {
  FormInput,
  FormSelect,
  Form,
} from "../../../../components/FormComponents";
import * as yup from "yup";
import { UserContext } from "../../../../contexts";
import PhoneInput from "react-phone-number-input";

type GasStationAttendantMinInfos = Pick<
  UserInterface,
  "displayName" | "email" | "password" | "confirmPassword"
>;

type GasStationAttendantInfos = Pick<
  UserInterface,
  | "displayName"
  | "email"
  | "active"
  | "agence"
  | "city"
  | "adresse"
  | "cnibNumber"
  | "createdAt"
  | "gender"
  | "phoneNumber"
  | "profession"
  | "userRole"
  | "attendantRole"
  | "updatedAt"
>;

type Step = {
  id: "gasStationAttendantMinInfos" | "gasStationAttendantInfos";
  index: number;
  name: string;
  description: string;
  icon: any;
};

const steps: Step[] = [
  {
    id: "gasStationAttendantMinInfos",
    index: 0,
    name: "Les informations de connexion",
    description: "Renseigner les informations de connexion du manager",
    icon: LockClosedIcon,
  },
  {
    id: "gasStationAttendantInfos",
    index: 1,
    name: "Informations du gérant",
    description: "Renseigner les informations du gérant",
    icon: AtSymbolIcon,
  },
];

const attendantRoles = [AttendantRole.MANAGER, AttendantRole.ATTENDANT];

const GasStationStaffEditPage = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const currentGasStationAttendant = location.state?.entity;
  const { isConfirmModal } = useConfirm();
  const [currentFormStep, setCurrentFormStep] = useState<Step>(steps[0]);
  const { user } = useContext(UserContext);
  const [gasStationMembershipId, setGasStationMembershipId] = useState("");
  const [gasStationStaffPhoneNumber, setGasStationStaffPhoneNumber] =
    useState("");

  const [
    gasStationAttendantMinInfosFormReturn,
    setGasStationAttendantMinInfosFormReturn,
  ] = useState<UseFormReturn<GasStationAttendantMinInfos>>();
  const [
    gasStationAttendantMinInfosFormSubmitButton,
    setGasStationAttendantMinInfosFormSubmitButton,
  ] = useState<HTMLButtonElement | null>(null);
  const [gasStationAttendantFormReturn, setGasStationAttendantFormReturn] =
    useState<UseFormReturn<GasStationAttendantInfos>>();
  const [
    gasStationAttendantFormSubmitButton,
    setGasStationAttendantFormSubmitButton,
  ] = useState<HTMLButtonElement | null>(null);
  const [memberId, setMemberId] = useState<string>();

  const onSelectedStepChanged: any = async (step: Step) => {
    let canSwitchTab = true;
    switch (currentFormStep.index) {
      case 0:
        if (gasStationAttendantMinInfosFormReturn?.formState.isValid) {
          if (gasStationAttendantMinInfosFormReturn.formState.isDirty) {
            const isConfirm = await isConfirmModal(
              "Vous avez des modifications non enregistrées.",
              "Voulez vous les sauvegarder avant de quitter cet écran?",
              "Sauvegarder mes modifications",
              false,
              false,
              "Annuler" as any
            );
            if (isConfirm) {
              await gasStationAttendantMinInfosFormReturn.trigger();
              gasStationAttendantMinInfosFormSubmitButton &&
                gasStationAttendantMinInfosFormSubmitButton.click();
            }
          }
        } else {
          canSwitchTab = false;
          gasStationAttendantMinInfosFormReturn?.trigger();
        }
        break;
      case 1:
        if (gasStationAttendantFormReturn?.formState.isValid) {
          if (gasStationAttendantFormReturn.formState.isDirty) {
            const isConfirm = await isConfirmModal(
              "Vous avez des modifications non enregistrées.",
              "Voulez vous les sauvegarder avant de quitter cet écran?",
              "Sauvegarder mes modifications",
              false,
              false,
              "Annuler" as any
            );
            if (isConfirm) {
              await gasStationAttendantFormReturn.trigger();
              gasStationAttendantFormSubmitButton &&
                gasStationAttendantFormSubmitButton.click();
            }
          }
        } else {
          canSwitchTab = false;
          gasStationAttendantFormReturn?.trigger();
        }
        break;
      default:
        break;
    }
    if (canSwitchTab) {
      setCurrentFormStep(step);
    }
  };

  const sankmoneyUpdateAccountFn = functions.httpsCallable(
    "sankmoneyUpdateAccountFn"
  );

  const sankmoneyCreateSankStaffFn = functions.httpsCallable(
    "sankmoneyCreateSankStaffFn"
  );

  const sankMoneyCreateMembershipFn = functions.httpsCallable(
    "sankMoneyCreateMembershipFn"
  );

  const onSubmitMemberMinInfos = (data: any) => {
    data.confirmPassword && delete data.confirmPassword;
    const response = sankmoneyCreateSankStaffFn({
      collectionName: usersCollectionName,
      email: data.email,
      password: data.password,
      userRole: UserRole.GASSTATIONSTAFF,
    })
      .then(async (result) => {
        setMemberId(result.data.success.uid);
        setCurrentFormStep(steps[1]);
        if (result.data.success.uid) {
          const memberShipData = {
            gasStationId: gasStationMembershipId,
            memberId: result.data.success.uid,
          };
          await sankMoneyCreateMembershipFn({
            collectionName: gasStationMembershipCollectionName,
            documentData: memberShipData,
          }).catch((err: { details: any; message: any }) =>
            toast.error(err.details.message)
          );
        }
      })
      .catch((err: { details: any; message: any }) =>
        toast.error(err.details.message)
      );
    toast.promise(response, {
      error: "Une erreur s'est produite. Veuillez réessayer !",
      success: "Les informations du membre ont été enregistrer avec succès",
      loading: "chargement...",
    });
  };

  const onSubmitDefaultInfos = (data: any) => {
    if (memberId) {
      const response = sankmoneyUpdateAccountFn({
        collectionName: usersCollectionName,
        documentData: {
          id: memberId,
          ...data,
          phoneNumber: gasStationStaffPhoneNumber,
          attendantRole: data.attendantRole,
        },
        documentId: memberId,
      })
        .then((result) => {
          navigate("/gas-stations/sank_staff");
        })
        .catch((err: { details: any; message: any }) =>
          toast.error(err.details.message)
        );
      toast.promise(response, {
        error: "Une erreur s'est produite. Veuillez réessayer !",
        success: "Les informations du membre ont été enregistrer avec succès",
        loading: "chargement...",
      });
    }
  };

  const retriveGasStationsMembership = async () => {
    const membershipRef = db.collection(gasStationMembershipCollectionName);
    const membershipSnaphot = await membershipRef
      .where("memberId", "==", user?.id)
      .get();
    const membershipData = membershipSnaphot.docs[0].data();
    if (membershipData) setGasStationMembershipId(membershipData?.gasStationId);
  };

  const retrivePhoneNumber = async (phoneNumber: any) => {
    if (phoneNumber) {
      if (phoneNumber?.length >= PhoneNumberDefaultLength + 4) {
        const destUserSnapshot = await db
          .collection(usersCollectionName)
          .where("phoneNumber", "==", phoneNumber)
          .where("userRole", "==", UserRole.GASSTATIONSTAFF)
          .get();
        if (!destUserSnapshot.empty) {
          toast.error("Ce numéro de téléphone est déjà utilisé.");
        } else {
          setGasStationStaffPhoneNumber(phoneNumber);
        }
      }
    }
  };

  useEffect(() => {
    user && retriveGasStationsMembership();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="lg:border-t lg:border-b lg:border-gray-200">
        <nav
          className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8"
          aria-label="Progress"
        >
          <ol
            role="list"
            className="overflow-hidden rounded-md lg:flex lg:rounded-none lg:border-l lg:border-r lg:border-gray-200"
          >
            <li className="relative px-2 py-4 overflow-hidden lg:py-2">
              <button
                className="p-1 border border-transparent rounded-full hover:border-gray-200 hover:bg-gray-50"
                onClick={() => navigate("/gas-stations/sank_staff")}
              >
                <XMarkIcon className="w-8 h-8 text-gray-700" />
              </button>
            </li>
            {steps.map((step, stepIdx) => (
              <li key={stepIdx} className="relative overflow-hidden lg:flex-1">
                <div
                  className={classNames(
                    stepIdx === 0 ? "border-b-0 rounded-t-md" : "",
                    stepIdx === steps.length - 1
                      ? "border-t-0 rounded-b-md"
                      : "",
                    "border border-gray-200 overflow-hidden lg:border-0 h-full"
                  )}
                >
                  {
                    //TODO remove comment on check current user
                  }
                  <button className="w-full h-full group">
                    <span
                      className={classNames(
                        step.index !== currentFormStep.index
                          ? "group-hover:bg-gray-200 bg-transparent "
                          : "bg-red-600",
                        "absolute top-0 left-0 w-1 h-full  lg:w-full lg:h-1 lg:bottom-0 lg:top-auto"
                      )}
                      aria-hidden="true"
                    />
                    <span
                      className={classNames(
                        stepIdx !== 0 ? "lg:pl-9" : "",
                        "px-4 py-4 lg:py-2 flex items-start text-sm font-medium"
                      )}
                    >
                      <span className="flex-shrink-0">
                        <span
                          className={classNames(
                            step.index < currentFormStep.index
                              ? "bg-red-600"
                              : "",
                            step.index === currentFormStep.index
                              ? "bg-white border-2 border-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "bg-white border-2 border-gray-300"
                              : "",
                            "flex items-center justify-center w-10 h-10  rounded-full"
                          )}
                        >
                          <step.icon
                            className={classNames(
                              step.index < currentFormStep.index
                                ? "text-white"
                                : "",
                              step.index === currentFormStep.index
                                ? "text-red-600"
                                : "",
                              step.index > currentFormStep.index
                                ? "text-gray-500"
                                : "",
                              "w-6 h-6 "
                            )}
                            aria-hidden="true"
                          />
                        </span>
                      </span>
                      <span className="mt-0.5 ml-4 min-w-0 flex-col flex  xl:flex">
                        <span
                          className={classNames(
                            step.index === currentFormStep.index
                              ? "text-red-600"
                              : "",
                            step.index > currentFormStep.index
                              ? "text-gray-500"
                              : "",
                            "text-xs font-semibold tracking-wide uppercase md:block lg:hidden xl:block"
                          )}
                        >
                          {step.name}
                        </span>
                        <span className="flex items-start text-sm font-medium text-gray-500">
                          {step.description}
                        </span>
                      </span>
                    </span>
                  </button>
                  <div
                    className="absolute inset-0 top-0 left-0 hidden w-3 lg:block"
                    aria-hidden="true"
                  >
                    <svg
                      className="w-full h-full text-gray-300"
                      viewBox="0 0 12 82"
                      fill="none"
                      preserveAspectRatio="none"
                    >
                      <path
                        d="M0.5 0V31L10.5 41L0.5 51V82"
                        stroke="currentcolor"
                        vectorEffect="non-scaling-stroke"
                      />
                    </svg>
                  </div>
                </div>
              </li>
            ))}
          </ol>
        </nav>
      </div>
      <div className="items-center w-full mx-auto md:w-11/12 lg:w-9/12">
        {currentFormStep.id === "gasStationAttendantMinInfos" && (
          <Form<GasStationAttendantMinInfos>
            onSubmit={onSubmitMemberMinInfos}
            formReturnAware={setGasStationAttendantMinInfosFormReturn}
            useCustomFooter
            form={{
              mode: "onChange",
              resolver: yupResolver(
                yup.object().shape({
                  email: yup.string().required("Le champs est obligatoire"),
                  password: yup
                    .string()
                    .required("Le champs est obligatoire")
                    .min(6, "6 charactère minimum"),
                  confirmPassword: yup
                    .string()
                    .required("Le champs est obligatoire")
                    .oneOf(
                      [yup.ref("password")],
                      "Les mots de passes ne sont pas compatible"
                    ),
                })
              ),
              defaultValues: {
                email: "",
                password: "",
                confirmPassword: "",
              },
            }}
            submitButtonLabel="Ajouter le manager"
          >
            <div className="flex flex-col content-center w-full px-5 mt-5 align-top md:flex-row">
              <div className="w-full mx-auto ">
                <FormInput
                  name="email"
                  label="E-mail"
                  placeholder="@exemple.com"
                />
                <FormInput
                  name="password"
                  label="Mot de passe"
                  type="password"
                  placeholder="Votre mot de passe en six (06) caractères"
                />
                <FormInput
                  type="password"
                  name="confirmPassword"
                  label="Confirmez votre mot de passe"
                  placeholder="Votre mot de passe"
                />
              </div>
            </div>
            <EditFormFooter<GasStationAttendantMinInfos>
              currentStep={currentFormStep}
              hasNext={false}
              hasPrevious={false}
              steps={steps}
              onStepChanged={onSelectedStepChanged}
              setSubmitButtonRef={
                setGasStationAttendantMinInfosFormSubmitButton
              }
              submitBtnLabel={"Suivant"}
              canSubmit
            />
          </Form>
        )}
        {currentFormStep.id === "gasStationAttendantInfos" && (
          <Form<GasStationAttendantInfos>
            onSubmit={onSubmitDefaultInfos}
            formReturnAware={setGasStationAttendantFormReturn}
            form={{
              resolver: yupResolver(
                yup.object().shape({
                  displayName: yup
                    .string()
                    .required("Le champs est obligatoire"),
                  city: yup.string().optional(),
                  adresse: yup.string().required("Le champs est obligatoire"),
                  cnibNumber: yup
                    .string()
                    .required("Le champs est obligatoire"),
                  attendantRole: yup
                    .string()
                    .required("Le champs est obligatoire"),
                })
              ),
              defaultValues: {
                displayName: currentGasStationAttendant
                  ? currentGasStationAttendant.displayName
                  : "",
                city: currentGasStationAttendant
                  ? currentGasStationAttendant.city
                  : "",
                adresse: currentGasStationAttendant
                  ? currentGasStationAttendant.adresse
                  : "",
                profession: currentGasStationAttendant
                  ? currentGasStationAttendant.profession
                  : "",
                cnibNumber: currentGasStationAttendant
                  ? currentGasStationAttendant.cnibNumber
                  : "",
                attendantRole: currentGasStationAttendant
                  ? currentGasStationAttendant.userAgencySubRole
                  : "",
              },
            }}
            submitButtonLabel={"Enregistrer"}
          >
            <div className="flex flex-col content-center w-full px-5 mt-5 align-top md:flex-row">
              <div className="w-full mx-auto md:w-1/2">
                <FormInput
                  name="displayName"
                  label="Nom/Prénom"
                  placeholder="John Doe"
                />
                <PhoneInput
                  international={false}
                  placeholder="Entrer un numéro de téléphone"
                  className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:border-emerald-500 focus:outline-none sm:text-sm"
                  defaultCountry="BF"
                  value={
                    currentGasStationAttendant
                      ? currentGasStationAttendant.phoneNumber
                      : gasStationStaffPhoneNumber
                  }
                  onChange={retrivePhoneNumber}
                />
                <FormInput
                  name="city"
                  label="Ville"
                  optional
                  placeholder="Ouagadougou"
                />
                <FormInput
                  name="adresse"
                  optional
                  label="Adresse"
                  placeholder="Rue 10.34, secteur 30, Ouaga2000"
                />
              </div>
              <div className="w-full mx-auto md:w-1/2">
                <FormInput
                  name="cnibNumber"
                  label="Numéro de CNIB"
                  placeholder="BXXXXXXX"
                />

                <FormSelect<string>
                  options={attendantRoles}
                  selectedOption={
                    currentGasStationAttendant
                      ? currentGasStationAttendant.userAgencySubRole
                      : "Choisir..."
                  }
                  name="attendantRole"
                  optionLabel={(option) =>
                    (mapsGasStationAttendantRoles.get(option) as string) ??
                    "Choisir..."
                  }
                  label="Role dans l'agence"
                />

                <FormSelect<string>
                  label={"Genre"}
                  name="gender"
                  selectedOption={"Choisir..."}
                  options={["FEMALE", "MALE", "NONBINARY", "UNKNOWN"]}
                  optionLabel={(option) =>
                    (mapsGender.get(option) as string) ?? "Choisir..."
                  }
                />
              </div>
            </div>
          </Form>
        )}
      </div>
    </>
  );
};

export default GasStationStaffEditPage;
