import React from "react";
import DisplayItemDetails from "../../components/DisplayItemComponent";
import { useLocation } from "react-router-dom";

const DealeryDetailsPage = () => {
  const location = useLocation();
  const currentUser = location.state?.entity;
  return <DisplayItemDetails currentUser={currentUser} />;
};

export default DealeryDetailsPage;
