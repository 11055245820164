import { FieldValues, UseFormReturn, useFormContext } from "react-hook-form";
import { classNames } from "../../utils";

export type Step = {
  id: any;
  index: number;
  name: string;
  description: string;
  icon: any;
};
interface EditFormFooterProps<TFieldValues extends FieldValues> {
  hasPrevious: boolean;
  hasNext: boolean;
  currentStep: Step;
  steps: Step[];
  onStepChanged?: (step: Step) => void;
  setSubmitButtonRef?: (submitButton: HTMLButtonElement | null) => void;
  submitBtnLabel?: string;
  onNextClick?: (
    formReturn: UseFormReturn<TFieldValues, object> | undefined
  ) => Promise<any>;
  canSubmit: boolean;
}

export function EditFormFooter<TFieldValues extends FieldValues>({
  hasPrevious,
  hasNext,
  currentStep,
  steps,
  onStepChanged,
  setSubmitButtonRef,
  submitBtnLabel,
  canSubmit = false,
}: EditFormFooterProps<TFieldValues>) {
  const formReturn = useFormContext<TFieldValues>();
  return (
    <div className="flex justify-end py-2 space-x-2 xl:py-2">
      {hasPrevious && (
        <button
          type="button"
          onClick={() => {
            if (hasPrevious) {
              onStepChanged && onStepChanged(steps[currentStep.index - 1]);
            }
          }}
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-600 bg-white border border-red-600 rounded-md shadow-sm dark:bg-white dark:border-red-600 hover:bg-gray-200 hoverdark:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Précédent
        </button>
      )}
      {hasNext && (
        <button
          type="button"
          onClick={() => {
            if (hasNext) {
              onStepChanged && onStepChanged(steps[currentStep.index + 1]);
            }
          }}
          className="inline-flex justify-center px-4 py-2 text-sm font-medium text-red-600 bg-white border border-red-600 rounded-md shadow-sm dark:bg-white dark:border-red-600 hover:bg-gray-200 hoverdark:bg-red-100 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
        >
          Suivant
        </button>
      )}
      {/* Submit button */}
      {canSubmit && (
        <button
          type={"submit"}
          ref={(input) => setSubmitButtonRef && setSubmitButtonRef(input)}
          className={classNames(
            formReturn?.formState.isSubmitting
              ? "bg-purple-200 cursor-not-allowed"
              : "bg-white hover:bg-gray-200",
            hasPrevious ? "ml-8" : "",
            "border border-red-600 transition-colors duration-500 rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-red-600  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
          )}
          disabled={formReturn?.formState.isSubmitting}
          data-cy="submitBtn"
          // onClick={async (e) => {
          //   if (hasNext && onNextClick && (await onNextClick(formReturn))) {
          //     onStepChanged && onStepChanged(steps[currentStep.index + 1]);

          //     e.preventDefault();
          //   }
          // }}
        >
          {formReturn?.formState.isSubmitting && (
            <div className="flex items-center justify-center">
              <svg
                className="w-5 h-5 mr-3 -ml-1 text-purple-500 animate-spin"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
            </div>
          )}
          {submitBtnLabel}
        </button>
      )}
    </div>
  );
}
